import {
  Bar,
  BarItem,
  Card,
  CardSection,
  Grid,
  GridCol,
  Icon,
} from '@dovera/design-system';

import { Obdobie } from '../../slices/hodnotiaceParametre.slice';

import { text } from '../../utils/strings.utils';
import { endOf, formatDate } from '../../utils/date.utils';
import { getPriceFormat, replaceWithComma } from '../../utils/number.utils';
import { CustomTooltip } from '../../components/CustomTooltip';

interface Props {
  obdobie: Obdobie;
}

const StaryDashboardCenaBodu = ({ obdobie }: Props) => (
  <Grid>
    <GridCol equalizeHeight offset={{ m: 0, l: 1 }} size={{ s: 3 }}>
      <Card>
        <CardSection isCondensed>
          <span>{text('dashboard.hodnotenie.text')}</span>
          {obdobie && (
            <Bar space="small">
              <BarItem>
                <h3 className="h4">
                  {replaceWithComma(
                    obdobie?.vysledokObdobia?.CenaBodu?.[0]?.cena || '0',
                  )}
                </h3>
              </BarItem>

              <BarItem isFilling>
                <CustomTooltip
                  dialog={text('dashboard.graf.plnenieObdobiaOdDo', {
                    od: formatDate(obdobie.obdobieVypocetOd.toString()),
                    do: formatDate(endOf(obdobie.obdobieVypocetDo.toString())),
                  })}
                  id={`tooltip-PricePoint-${obdobie}`}
                >
                  <Icon
                    className="text-color-grey"
                    id={`icon-info-PricePoint-${obdobie}`}
                    name="info"
                  />
                </CustomTooltip>
              </BarItem>
            </Bar>
          )}
        </CardSection>
      </Card>
    </GridCol>
    <GridCol equalizeHeight size={{ s: 9, l: 7 }}>
      {obdobie && (
        <Card>
          <CardSection isCondensed>
            <p>
              {text('dashboard.info', {
                HodnZisk: getPriceFormat(
                  obdobie?.vysledokObdobia?.HodnZisk?.[0]?.cena || '0',
                ),
                HodnPar: getPriceFormat(
                  obdobie?.vysledokObdobia?.HodnPar?.[0]?.cena || '0',
                ),
              })}
            </p>
          </CardSection>
        </Card>
      )}
    </GridCol>
  </Grid>
);

export default StaryDashboardCenaBodu;
