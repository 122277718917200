import { useEffect } from 'react';
import {
  Container,
  Link,
  Notification,
  PageSection,
} from '@dovera/design-system';
import { useSelector } from 'react-redux';
import { RootState } from '../../rootReducer';
import DashboardSkeleton from './DashboardSkeleton';
import DashboardGraph from './DashboardGraph';
import StaryDashboardCategoryCards from '../staryDashboard/StaryDashboardCategoryCards';
import { NoData } from '../../components/EmptyState';
import {
  dajObdobie,
  fetchHodnotiacePrametrePublic,
} from '../../slices/hodnotiaceParametre.slice';
import strings from '../../constants/strings';
import { useQuery } from '../../hooks/useQuery';
import StaticMenuLayout from '../../layouts/StaticMenuLayout';
import { useAppDispatch } from '../../hooks/useStore';

const DashboardPublic = () => {
  const dispatch = useAppDispatch();
  const query = useQuery();
  const guid = useSelector((state: RootState) => state.auth.guid);
  const {
    obdobia: { error, isLoaded, isLoading },
    poradie: { aktualneId, aktualnePoradie },
  } = useSelector((state: RootState) => state.hodnotiaceParametre);

  useEffect(() => {
    if (guid && !error && !isLoading && !aktualneId)
      dispatch(fetchHodnotiacePrametrePublic(guid));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [aktualneId, dispatch, guid]);

  useEffect(() => {
    if (
      aktualneId &&
      isLoaded &&
      query.get('obdobie') &&
      query.get('obdobie') === 'uzavrete'
    ) {
      dispatch(dajObdobie({ poradie: aktualnePoradie - 1 }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoaded]);

  return (
    <StaticMenuLayout>
      <>
        {isLoading ? (
          <DashboardSkeleton />
        ) : (
          !error && (
            <>
              <PageSection space="small">
                <Container>
                  <Notification
                    message={
                      <>
                        {strings.notificationPublic}
                        <Link href="/" placeholder>
                          Prihlásiť sa
                        </Link>
                      </>
                    }
                    variant="blue"
                  />
                  <div className="mb-small" />
                  <DashboardGraph />
                </Container>
              </PageSection>

              <PageSection spaceBottom="small" spaceTop="none">
                <Container>
                  <StaryDashboardCategoryCards />
                </Container>
              </PageSection>
            </>
          )
        )}
        {error && <NoData illustration="woman-question" login />}
      </>
    </StaticMenuLayout>
  );
};

export default DashboardPublic;
