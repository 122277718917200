import { createUseStyles } from 'react-jss';

export default createUseStyles({
  iframe: {
    width: '100%',
    height: '100vh',
    border: 'none',
    marginTop: -56,
  },
});
