import { ButtonLink, Grid, GridCol, Icon } from '@dovera/design-system';
import cx from 'classnames';
import { useSelector } from 'react-redux';

import { RootState } from '../../rootReducer';

import useStyles from './StaryDashboardGraph.styles';

import { endOf, formatDate } from '../../utils/date.utils';
import { text } from '../../utils/strings.utils';
import {
  Obdobie,
  VysledokObdobia,
  dajObdobie,
} from '../../slices/hodnotiaceParametre.slice';

import { PieGraph, PieGraphBlured } from '../../components';
import { useAppDispatch } from '../../hooks/useStore';

const StaryDashboardGraph = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const {
    nazovZobrazPzs,
    obdobia: { zoznamObdobi },
    poradie: { aktualneId, aktualnePoradie, nasledujuceId, predchadzajuceId },
  } = useSelector((state: RootState) => ({
    obdobia: state.hodnotiaceParametre.obdobia,
    poradie: state.hodnotiaceParametre.poradie,
    nazovZobrazPzs: state.poskytovatel.nazovZobrazPzs,
  }));

  function plneniePar(id: number): Obdobie {
    return zoznamObdobi[id];
  }
  function vysledokObdobia(id: number): VysledokObdobia | undefined {
    return plneniePar(id)?.vysledokObdobia?.PlneniePar?.[0];
  }

  return (
    <>
      <h1 className="h3 text-center mb-xsmall">
        {text('vseobecne.vitajte')} {nazovZobrazPzs}
      </h1>
      {plneniePar(aktualneId) && (
        <h4 className="text-center">
          {text('dashboard.graf.plnenieObdobiaOdDo', {
            od: formatDate(plneniePar(aktualneId).obdobieVypocetOd.toString()),
            do: formatDate(
              endOf(plneniePar(aktualneId).obdobieVypocetDo.toString()),
            ),
          })}
        </h4>
      )}
      <Grid className="mb">
        <GridCol
          className="d-flex align-items-middle align-items-right show-m"
          offset={{ l: 2 }}
          size={{ m: 3, l: 2 }}
        >
          {plneniePar(predchadzajuceId) && (
            <PieGraphBlured
              skupinyParametrov={zoznamObdobi[predchadzajuceId]?.skupiny}
              vysledokObdobia={vysledokObdobia(predchadzajuceId)}
            />
          )}
        </GridCol>
        <GridCol
          className="text-center"
          offset={{ s: 2, m: 0 }}
          size={{ s: 8, m: 6, l: 4 }}
        >
          {zoznamObdobi[aktualneId] && (
            <PieGraph
              predbezny={zoznamObdobi[aktualneId].predbezny}
              skupinyParametrov={zoznamObdobi[aktualneId].skupiny}
              vysledokObdobia={vysledokObdobia(aktualneId)}
            />
          )}
        </GridCol>
        <GridCol
          className="d-flex align-items-middle align-items-left show-m"
          size={{ m: 3, l: 2 }}
        >
          {plneniePar(nasledujuceId) && (
            <PieGraphBlured
              skupinyParametrov={zoznamObdobi[nasledujuceId]?.skupiny}
              vysledokObdobia={vysledokObdobia(nasledujuceId)}
            />
          )}
        </GridCol>
      </Grid>

      <Grid>
        <GridCol
          className={cx(
            'd-flex',
            'align-items-middle',
            'align-items-right',
            'show-m',
            classes.navigationButtons,
          )}
          offset={{ l: 1 }}
          size={{ m: 3 }}
        >
          {plneniePar(predchadzajuceId) && (
            <ButtonLink
              className="d-flex mb no-pad text-right"
              onClick={() =>
                dispatch(dajObdobie({ poradie: aktualnePoradie - 1 }))
              }
            >
              <Icon className="icon--left" name="arrow-left" size="medium" />{' '}
              {text('dashboard.graf.minuleObdobie')}
            </ButtonLink>
          )}
        </GridCol>
        <GridCol
          className="text-center"
          offset={{ s: 2, m: 0 }}
          size={{ s: 8, m: 6, l: 4 }}
        >
          <span>{text('dashboard.graf.popis')}</span>
        </GridCol>
        <GridCol
          className={cx(
            'd-flex',
            'align-items-middle',
            'align-items-left',
            'show-m',
            classes.navigationButtons,
          )}
          size={{ m: 3 }}
        >
          {plneniePar(nasledujuceId) && (
            <ButtonLink
              className="d-flex mb no-pad text-left"
              onClick={() =>
                dispatch(
                  dajObdobie({
                    poradie: aktualnePoradie + 1,
                    datNasledujuce: true,
                  }),
                )
              }
            >
              {text('dashboard.graf.nasledujuceObdobie')}
              <Icon className="icon--right" name="arrow-right" size="medium" />
            </ButtonLink>
          )}
        </GridCol>
      </Grid>
    </>
  );
};

export default StaryDashboardGraph;
