import React, { ReactNode } from 'react';
import { Bar, BarItem, Disc } from '@dovera/design-system';
import { getColor, getIcon } from '../../utils/hodnotiaceParametre.utils';
import { SkupinaParametrov } from '../../slices/hodnotiaceParametre.slice';
import useStyles from './CategoryCardTitle.styles';
import { cx } from '../../utils/exports';
import OverlayContent from '../OverlayContent/OverlayContent';

type Props = {
  disabled?: boolean;
  ikona?: ReactNode;
  nazov: string;
  primarnaFarba?: boolean;
  skupina?: SkupinaParametrov;
  special?: boolean;
} & React.JSX.IntrinsicElements['div'];

const CategoryCardTitle = ({
  disabled,
  ikona,
  nazov,
  primarnaFarba,
  skupina,
  special,
  ...other
}: Props) => {
  const classes = useStyles();
  return (
    <div {...other}>
      <OverlayContent isOverlayed={disabled}>
        <Bar className={classes.bar} space="small">
          {!special && !ikona && skupina && (
            <BarItem>
              <Disc
                size="small"
                style={{ background: getColor(skupina?.poradie) }}
              >
                {getIcon({ id: skupina?.poradie })}
              </Disc>
            </BarItem>
          )}
          <BarItem style={{ width: '100%' }}>
            <h4 className={cx(primarnaFarba && classes.primaryColor)}>
              {ikona && <span className={classes.icon}>{ikona}</span>}
              {nazov}
              {other.children}
            </h4>
          </BarItem>
        </Bar>
      </OverlayContent>
    </div>
  );
};

export default CategoryCardTitle;
