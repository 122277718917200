import strings from '../../constants/strings';
import { formatDate } from '../../utils/date.utils';

export const formatNtfDate = (date: string, time: string): string => {
  const day = formatDate(date, 'D. M.');
  const hours =
    time.split(':').length > 2
      ? `${time.split(':')[0]}:${time.split(':')[1]}`
      : time;
  if (hours !== '00:00') return `${day} ${hours}`;
  return day;
};

export const getNtfMessage = ({
  message,
}: {
  dateFrom: string;
  dateTo: string;
  message: string;
  ntfCount: number;
}): string => `${strings.dlekUnavailabillity}<br />${message}`;
// return strings.systemNotification(dateFrom, dateTo);
