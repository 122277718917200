import { ReactNode } from 'react';
import useStyles from './OverlayContent.styles';

interface Props {
  children: ReactNode;
  isOverlayed?: boolean;
}

const OverlayContent = ({ children, isOverlayed }: Props) => {
  const classes = useStyles();
  if (!isOverlayed) return children;
  return <div className={classes.overlayed}>{children}</div>;
};

export default OverlayContent;
