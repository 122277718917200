import { Suspense, lazy } from 'react';
import { Route, Routes } from 'react-router';
import AppPreloaderSkeleton from '../components/AppPreloaderSkeleton/AppPreloaderSkeleton';
import routes, {
  cakackyIndex,
  diagnozyRoutes,
  navrhyRoutes,
  zuctovanieIndex,
} from '../routes';

import StaryDashboard from '../pages/staryDashboard/StaryDashboard';
import Dashboard from '../pages/dashboard/Dashboard';
import { shallowEqual, useSelector } from 'react-redux';
import { RootState } from '../rootReducer';
import Profil from '../pages/profil/ProfilPage/Profil';
import KontaktneOsoby from '../pages/profil/KontaktneOsoby/KontaktneOsoby';
import BankoveSpojenie from '../pages/profil/BankoveSpojenie/BankoveSpojenie';
import ZmluvnePristroje from '../pages/profil/ZmluvnePristroje/ZmluvnePristroje';
import OvereniaPrehlady from '../pages/profil/OvereniaPrehlady/OvereniaPrehlady';
import DetailAmbulancie from '../pages/profil/OvereniaPrehlady/DetailAmbulancie/DetailAmbulancie';
import PrihlasovacieUdaje from '../pages/profil/PrihlasovacieUdaje/PrihlasovacieUdaje';
import SchvaleniePristupu from '../pages/profil/SchvaleniePristupu/SchvaleniePristupu';
import SubmittedProposals from '../pages/proposals/SubmittedProposals/SubmittedProposals';
import ApprovedProposals from '../pages/proposals/ApprovedProposals/ApprovedProposals';
import NewProposal from '../pages/proposals/NewProposal/NewProposal';
import OldEpFunc from '../pages/ep-func/OldEpFunc';
import PageNotFoundPrivate from '../pages/404/PageNotFoundPrivate';
import TableAmbulances from '../pages/profil/OvereniaPrehlady/Table/TableAmbulances';
import TableDoctors from '../pages/profil/OvereniaPrehlady/Table/TableDoctors';
import Spravy from '../pages/spravy/Spravy';
import Menu from '../components/Menu/Menu';
import Faktury from '../pages/zuctovanie/Faktury/Faktury';
import DetailFaktury from '../pages/zuctovanie/Faktury/DetailFaktury/DetailFaktury';
import VysledokOdoslaniaDavok from '../pages/zuctovanie/RozdelenieDavok/VysledokOdoslaniaDavok';
import VysledokOdoslaniaCLDavok from '../pages/cakacky/OdoslanieDavok/VysledokOdoslaniaDavok';

const DashboardHP = lazy(() => import('../pages/hp-dashboard/Dashboard'));
const PrehladPacientov = lazy(
  () => import('../pages/pacienti/PrehladPacientov/PrehladPacientov'),
);
const DetailPacientov = lazy(
  () => import('../pages/pacienti/DetailPacientov/DetailPacientov'),
);
const PrehladDiagnozy = lazy(() => import('../pages/diagnozy/PrehladDiagnozy'));
const PrehladDiagnoz = lazy(() => import('../pages/diagnozy/PrehladDiagnoz'));
const Parameter = lazy(() => import('../pages/parameter/Parameter'));
const DetailZmluvy = lazy(
  () => import('../pages/zmluvy/ASOC/DetailZmluvy/DetailZmluvy'),
);
const ZmluvyDodatkyPage = lazy(
  () => import('../pages/zmluvy/ASOC/ZmluvyDodatkyPage'),
);
const ZmluvyDodatky = lazy(() => import('../pages/zmluvy/PZS/ZmluvyDodatky'));
const HromadnyPodpis = lazy(
  () => import('../pages/zmluvy/ASOC/HromadnyPodpis'),
);
const IndividualnyPodpis = lazy(
  () => import('../pages/zmluvy/ASOC/IndividualnyPodpis'),
);
const DetailZmluvyPZS = lazy(
  () => import('../pages/zmluvy/PZS/DetailZmluvy/DetailZmluvyPZS'),
);
const DetailDodatku = lazy(
  () => import('../pages/zmluvy/PZS/DetailDodatku/DetailDodatku'),
);
const IndividualnyPodpisPZS = lazy(
  () => import('../pages/zmluvy/PZS/IndividualnyPodpisPZS'),
);
const AuthPage = lazy(() => import('../pages/auth-pzs/AuthPage'));
const KapSpor = lazy(() => import('../pages/poistenci/kapitovani/KapSpor'));

// ZUCTOVANIE
const ZUCIndex = lazy(() => import('../pages/zuctovanie/Index'));
const OdoslanieDavok = lazy(
  () => import('../pages/zuctovanie/OdoslanieDavok/OdoslanieDavok'),
);
const PrehladChybDavok = lazy(
  () => import('../pages/zuctovanie/OdoslanieDavok/PrehladChyb'),
);
const PrehladDavok = lazy(
  () => import('../pages/zuctovanie/PrehladDavok/PrehladDavok'),
);
const OvereneDavky = lazy(
  () => import('../pages/zuctovanie/PrehladDavok/OvereneDavky'),
);
const OdoslaneDavky = lazy(
  () => import('../pages/zuctovanie/PrehladDavok/OdoslaneDavky'),
);
const RozdelenieDavok = lazy(
  () => import('../pages/zuctovanie/RozdelenieDavok'),
);

// Cakacie listiny
const CakackyIndex = lazy(() => import('../pages/cakacky/Index'));
const CakackyOdoslanieDavok = lazy(
  () => import('../pages/cakacky/OdoslanieDavok/OdoslanieDavok'),
);
const CakackyPrehladChybDavok = lazy(
  () => import('../pages/cakacky/OdoslanieDavok/PrehladChyb'),
);
const CakackyPrehladDavok = lazy(
  () => import('../pages/cakacky/PrehladDavok/PrehladDavok'),
);

// Manazersky prehlad zmluv
const PricesAttributes = lazy(
  () => import('../pages/prehlad-cenovych-podmienok/Index'),
);

function PrivateRouter() {
  const {
    auth: { isASOC },
  } = useSelector(
    (state: RootState) => ({
      autentifikovaneOsoby: state.poskytovatel.autentifikovaneOsoby,
      auth: state.auth,
      dostupneOdbornosti: state.poskytovatel.dostupneOdbornostiHP,
      errorType: state.poskytovatel.errorType,
      ico: state.poskytovatel.ico,
      idHodnotenyPZS: state.poskytovatel.idHodnotenyPZS,
      obdobia: state.hodnotiaceParametre.obdobia,
      preLekara: state.poskytovatel.preLekara,
    }),
    shallowEqual,
  );
  return (
    <>
      <Menu />
      <Suspense fallback={<AppPreloaderSkeleton />}>
        <Routes>
          <Route element={<StaryDashboard />} path={routes.staryDashboard} />
          <Route element={<Dashboard />} path={routes.dashboard} />
          <Route element={<DashboardHP />} path={routes.hp} />
          <Route element={<PrehladPacientov />} path={routes.pp} />
          <Route element={<DetailPacientov />} path={routes.ppDetail} />
          <Route element={<PrehladDiagnozy />} path={routes.diagnozyPrehlad} />
          <Route element={<PrehladDiagnoz />} path={diagnozyRoutes.index} />
          <Route element={<Parameter />} path={routes.parameter} />
          <Route element={<Parameter />} path={routes.skupina} />
          <Route element={<DetailZmluvy />} path={routes.detailZmluvyASOC} />
          <Route element={<DetailZmluvy />} path={routes.detailDodatkuASOC} />
          <Route element={<DetailZmluvy />} path={routes.detailKampane} />
          <Route
            element={isASOC ? <ZmluvyDodatkyPage /> : <ZmluvyDodatky />}
            path={routes.zmluvyDodatky}
          />
          <Route
            element={<HromadnyPodpis />}
            path={routes.hromadnyPodpisZmluvASOC}
          />
          <Route
            element={<IndividualnyPodpis />}
            path={routes.podpisZmluvyASOC}
          />
          <Route
            element={<IndividualnyPodpis />}
            path={routes.podpisDodatkuASOC}
          />
          <Route element={<DetailZmluvyPZS />} path={routes.detailZmluvyPZS} />
          <Route element={<DetailDodatku />} path={routes.detailDodatkuPZS} />
          <Route
            element={<IndividualnyPodpisPZS />}
            path={routes.podpisZmluvyPZS}
          />
          <Route
            element={<IndividualnyPodpisPZS />}
            path={routes.podpisDodatkuPZS}
          />
          <Route element={<Profil />} path={routes.profil} />
          <Route element={<KontaktneOsoby />} path={routes.kontaktneOsoby} />
          <Route element={<BankoveSpojenie />} path={routes.bankoveSpojenie} />
          <Route
            element={<ZmluvnePristroje />}
            path={routes.zmluvnePristroje}
          />
          <Route
            element={<OvereniaPrehlady />}
            path={`${routes.overeniaPrehlady}`}
          >
            <Route element={<TableDoctors />} path="lekari" />
            <Route element={<TableAmbulances />} path="ambulancie" />
          </Route>
          <Route
            element={<DetailAmbulancie />}
            path={`${routes.overeniaPrehladyDetailAmbulancie}`}
          />
          <Route
            element={<PrihlasovacieUdaje />}
            path={routes.prihlasovacieUdaje}
          />
          <Route
            element={<SchvaleniePristupu />}
            path={`${routes.schvaleniePristupu}/:idPouzivatel?`}
          />
          <Route element={<AuthPage />} path={routes.autentifikacia} />
          <Route
            element={<SubmittedProposals />}
            path={navrhyRoutes.zoznamPodanychNavrhov}
          />
          <Route
            element={<ApprovedProposals />}
            path={navrhyRoutes.zoznamSchvalenychNavrhov}
          />
          <Route element={<NewProposal />} path={navrhyRoutes.novyNavrh} />
          <Route element={<KapSpor />} path={routes.poistenci} />
          <Route element={<OldEpFunc />} path={routes.staraEP} />
          <Route element={<Spravy />} path={routes.spravy} />
          <Route element={<ZUCIndex />} path={zuctovanieIndex}>
            <Route element={<OdoslanieDavok />} path={routes.odoslanieDavok} />
            <Route
              element={<RozdelenieDavok />}
              path={routes.rozdelenieDavok}
            />
            <Route
              element={<PrehladChybDavok />}
              path={`${routes.prehladChybDavok}/:davka?`}
            />
            <Route
              element={<PrehladChybDavok />}
              path={`${routes.prehladChybOvernychDavok}/:davka?`}
            />
            <Route element={<PrehladDavok />} path={routes.prehladDavok}>
              <Route element={<OdoslaneDavky />} path={routes.odoslaneDavky} />
              <Route element={<OvereneDavky />} path={routes.overeneDavky} />
            </Route>
            <Route
              element={<DetailFaktury />}
              path={`${routes.faktury}/:cisloFaktury`}
            />
            <Route element={<Faktury />} path={routes.faktury} />
            <Route
              element={<VysledokOdoslaniaDavok />}
              path={routes.vysledokOdoslaniaDavok}
            />
          </Route>
          <Route element={<CakackyIndex />} path={cakackyIndex}>
            <Route
              element={<CakackyOdoslanieDavok />}
              path={routes.cakackyOdoslanieDavok}
            />
            <Route
              element={<CakackyPrehladChybDavok />}
              path={`${routes.cakackyPrehladChybDavok}/:davka?`}
            />
            <Route
              element={<CakackyPrehladDavok />}
              path={routes.cakackyPrehladDavok}
            />
            <Route
              element={<VysledokOdoslaniaCLDavok />}
              path={routes.cakackyVysledokOdoslaniaDavok}
            />
          </Route>
          <Route
            element={<PricesAttributes />}
            path={routes.manazerskyPrehladZmluv}
          />
          <Route element={<PageNotFoundPrivate />} path="*" />
        </Routes>
      </Suspense>
    </>
  );
}

export default PrivateRouter;
