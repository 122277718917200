import useStyles from './Breadcrumbs.styles';
import { Icon } from '@dovera/design-system';
import { Link } from 'react-router-dom';
import IconAngleRight from '../CustomIcons/IconAngleRight';
import { BreadcrumbItem } from './types';

interface Props {
  items: BreadcrumbItem[];
}

const Breadcrumbs = ({ items }: Props) => {
  const classes = useStyles();
  const renderHomeEl = (
    <li className={classes.home}>
      <Link to="/">
        <Icon name="16-home" />
      </Link>
    </li>
  );
  return (
    <nav className={classes.breadcrumbs}>
      <ol className="mb">
        {renderHomeEl}
        {items.map((i, key) => (
          <li key={`breadcrumbs--item--${key}`}>
            <IconAngleRight
              color="black"
              height={16}
              id={`breadcrumbs--angle--item--${key}`}
              width={16}
            />
            {i.route ? (
              <Link to={i.route}>{i.name}</Link>
            ) : (
              <span>{i.name}</span>
            )}
          </li>
        ))}
      </ol>
    </nav>
  );
};

export default Breadcrumbs;
