import { Circle, Path, Svg, Text } from '@react-pdf/renderer';
import { safeNode } from '../../../../utils/app.utils';
import { ReactNode } from 'react';
import { SkupinyParametrov } from '../../../../slices/hodnotiaceParametre.slice';
import { getGraphValues } from '../../../../utils/hodnotiaceParametre.utils';

/**
 * Static graph values in percentage
 * Quality = pink ; Effectivity = green ; innovations = blue
 * @date 3. 5. 2023 - 16:59:05
 *
 * @type {{ 0: { quality: string; effectivity: string; innovations: string; isFill: boolean; }; 5: { quality: string; effectivity: string; innovations: string; isFill: boolean; }; 10: { quality: string; effectivity: string; innovations: string; isFill: boolean; }; ... 17 more ...; 100: { ...; }; }}
 */
const graphValues = {
  0: {
    quality: '',
    effectivity: '',
    innovations: '',
    isFill: false,
  },
  5: {
    quality: 'M86 56C88.2 56 91.5 56.5 94.9251 57.8',
    effectivity: 'M86 33C91.4491 33 96.8643 34.1184 102.047 35.8',
    innovations: 'M86 10C93.8463 10 101.535 11.4824 109 13.9',
    isFill: false,
  },
  10: {
    quality: 'M85 56C91.1355 56 97.1129 57.946 102.073 61.558',
    effectivity: 'M85 33C96.0105 33 106.737 36.4949 115.634 42.9814',
    innovations: 'M85 10C100.815 10 116.221 14.902 129 24',
    isFill: false,
  },
  15: {
    quality:
      'M100.378 73.8419C103.621 78.312 109.875 79.3065 114.345 76.0631C118.815 72.8197 119.809 66.5666 116.566 62.0965L100.378 73.8419ZM85.029 46C79.5062 45.9958 75.0256 50.4696 75.0215 55.9925C75.0173 61.5153 79.4911 65.9958 85.014 66L85.029 46ZM116.566 62.0965C112.952 57.1156 108.211 53.0606 102.729 50.263L93.6372 68.0769C96.3076 69.4398 98.6174 71.4153 100.378 73.8419L116.566 62.0965ZM102.729 50.263C97.2482 47.4654 91.1828 46.0046 85.029 46L85.014 66C88.012 66.0023 90.9669 66.7139 93.6372 68.0769L102.729 50.263Z',
    innovations:
      'M85 23C79.4772 23 75 27.4772 75 33C75 38.5228 79.4772 43 85 43L85 23ZM118.968 60.2984C122.216 64.7651 128.47 65.7529 132.937 62.5047C137.404 59.2565 138.392 53.0024 135.143 48.5357L118.968 60.2984ZM85 43C91.625 43 98.156 44.5672 104.06 47.5736L113.136 29.7515C104.421 25.3135 94.7797 23 85 23L85 43ZM104.06 47.5736C109.963 50.58 115.072 54.9404 118.968 60.2984L135.143 48.5357C129.392 40.6262 121.85 34.1896 113.136 29.7515L104.06 47.5736Z',
    effectivity:
      'M85 0C79.4772 -6.58593e-08 75 4.47715 75 10C75 15.5228 79.4772 20 85 20L85 0ZM137.643 46.8727C140.883 51.3456 147.135 52.3455 151.608 49.1059C156.081 45.8663 157.081 39.6141 153.841 35.1412L137.643 46.8727ZM85 20C95.2741 20 105.402 22.4355 114.553 27.1067L123.646 9.29334C111.679 3.18485 98.4354 1.60215e-07 85 0L85 20ZM114.553 27.1067C123.704 31.7779 131.617 38.5517 137.643 46.8727L153.841 35.1412C145.96 24.2599 135.612 15.4018 123.646 9.29334L114.553 27.1067Z',
    isFill: true,
  },
  20: {
    quality:
      'M85 56C91.1376 56 97.117 57.9473 102.078 61.5616C107.038 65.1759 110.724 70.2709 112.605 76.1132',
    effectivity:
      'M85 33C95.9584 33 106.637 36.462 115.51 42.8917C124.384 49.3214 131 58.3902 134.413 68.8035',
    innovations:
      'M85 10C100.876 10 116.343 15.038 129.173 24.3886C142.003 33.7393 151.536 46.9202 156.397 62.0336',
    isFill: false,
  },
  25: {
    quality:
      'M85 56C88.8083 56 92.5794 56.7501 96.0978 58.2075C99.6163 59.6649 102.813 61.801 105.506 64.4939C108.199 67.1868 110.335 70.3837 111.793 73.9022C113.25 77.4206 114 81.1917 114 85',
    effectivity:
      'M85 33C91.8287 33 98.5906 34.345 104.9 36.9583C111.208 39.5715 116.941 43.4018 121.77 48.2305C126.598 53.0591 130.428 58.7915 133.042 65.1005C135.655 71.4094 137 78.1713 137 85',
    innovations:
      'M85 10C94.8491 10 104.602 11.9399 113.701 15.709C122.801 19.4781 131.069 25.0026 138.033 31.967C144.997 38.9314 150.522 47.1993 154.291 56.2988C158.06 65.3982 160 75.1509 160 85',
    isFill: false,
  },
  30: {
    quality:
      'M85 56C89.5769 56 94.0888 57.0833 98.1668 59.1614C102.245 61.2394 105.773 64.2532 108.463 67.9562C111.153 71.6592 112.928 75.9463 113.644 80.467C114.359 84.9876 113.994 89.6134 112.579 93.9661',
    effectivity:
      'M85 33C93.1966 33 101.277 34.9376 108.582 38.6548C115.887 42.372 122.21 47.7634 127.036 54.3893C131.861 61.0152 135.051 68.6879 136.346 76.7814C137.642 84.8749 137.006 93.1601 134.49 100.961',
    innovations:
      'M85 10C96.8464 10 108.524 12.8062 119.077 18.1887C129.63 23.5713 138.758 31.377 145.713 40.9668C152.668 50.5565 157.253 61.6573 159.091 73.3602C160.93 85.063 159.97 97.0349 156.29 108.295',
    isFill: false,
  },
  35: {
    quality:
      'M85 56C90.341 56 95.5783 57.475 100.134 60.2623C104.69 63.0497 108.388 67.0411 110.82 71.7964C113.252 76.5517 114.323 81.8863 113.916 87.2117C113.508 92.5372 111.638 97.6467 108.511 101.977',
    effectivity:
      'M85 33C94.5873 33 103.988 35.6505 112.163 40.6586C120.339 45.6667 126.97 52.8375 131.325 61.3785C135.68 69.9195 137.589 79.4983 136.842 89.0564C136.094 98.6145 132.718 107.78 127.087 115.539',
    innovations:
      'M85 10C98.8244 10 112.38 13.8209 124.169 21.0408C135.958 28.2606 145.523 38.5984 151.806 50.9124C158.089 63.2265 160.847 77.0375 159.774 90.8202C158.701 104.603 153.84 117.821 145.727 129.014',
    isFill: false,
  },
  40: {
    quality:
      'M85 56C91.1277 56 97.098 57.941 102.054 61.5446C107.01 65.1482 110.698 70.2291 112.587 76.0583C114.476 81.8874 114.471 88.1653 112.571 93.9911C110.671 99.8169 106.975 104.891 102.012 108.486',
    effectivity:
      'M85 33C95.9794 33 106.677 36.4753 115.56 42.9279C124.444 49.3805 131.056 58.4793 134.452 68.9207C137.847 79.362 137.85 90.61 134.46 101.053C131.071 111.496 124.462 120.599 115.583 127.056',
    innovations:
      'M85 10C100.854 10 116.301 15.0241 129.121 24.3509C141.942 33.6776 151.477 46.8273 156.358 61.9115C161.238 76.9958 161.213 93.2387 156.286 108.308C151.359 123.377 141.784 136.497 128.934 145.785',
    isFill: false,
  },
  45: {
    quality:
      'M85 56C91.9033 56 98.5801 58.4626 103.83 62.945C109.08 67.4274 112.559 73.6356 113.641 80.4535C114.724 87.2714 113.338 94.2518 109.734 100.14C106.131 106.028 100.544 110.437 93.9806 112.574',
    effectivity:
      'M85 33C97.3889 33 109.371 37.4232 118.788 45.473C128.205 53.5229 134.439 64.6705 136.367 76.9084C138.294 89.1464 135.79 101.671 129.303 112.226C122.817 122.781 112.775 130.673 100.986 134.482',
    innovations:
      'M85 10C102.858 10 120.13 16.372 133.709 27.9699C147.288 39.5678 156.283 55.6303 159.077 73.2684C161.87 90.9065 158.278 108.963 148.947 124.189C139.616 139.415 125.158 150.812 108.174 156.33',
    isFill: false,
  },
  50: {
    quality:
      'M85 114C101.016 114 114 101.016 114 85C114 68.9837 101.016 56 85 56',
    effectivity:
      'M85 137C113.719 137 137 113.719 137 85C137 56.2812 113.719 33 85 33',
    innovations:
      'M85 10C94.8492 10 104.602 11.9399 113.701 15.709C122.801 19.4781 131.069 25.0026 138.033 31.967C144.997 38.9314 150.522 47.1993 154.291 56.2988C158.06 65.3982 160 75.1509 160 85C160 94.8492 158.06 104.602 154.291 113.701C150.522 122.801 144.997 131.069 138.033 138.033C131.069 144.997 122.801 150.522 113.701 154.291C104.602 158.06 94.8491 160 85 160',
    isFill: false,
  },
  55: {
    quality:
      'M85.2297 56C89.4252 56 93.5706 56.9103 97.38 58.6681C101.189 60.4259 104.572 62.9893 107.295 66.1814C110.017 69.3735 112.015 73.1183 113.149 77.1574C114.284 81.1965 114.528 85.4337 113.866 89.5765C113.204 93.7194 111.651 97.6692 109.314 101.154C106.977 104.638 103.912 107.574 100.33 109.758C96.7487 111.943 92.7356 113.324 88.5681 113.807C84.4005 114.29 80.1779 113.863 76.1914 112.556',
    effectivity:
      'M85.2305 33C92.7408 33 100.162 34.6269 106.984 37.7687C113.805 40.9105 119.865 45.4927 124.747 51.2001C129.629 56.9075 133.216 63.6047 135.263 70.8308C137.309 78.057 137.766 85.6407 136.602 93.0603C135.438 100.48 132.68 107.559 128.519 113.811C124.358 120.064 118.892 125.34 112.497 129.278C106.101 133.216 98.9291 135.722 91.4731 136.624C84.017 137.526 76.4542 136.801 69.3047 134.501',
    innovations:
      'M85.2311 10C96.0749 10 106.79 12.3515 116.637 16.8923C126.484 21.4331 135.23 28.0553 142.271 36.3024C149.312 44.5494 154.481 54.2252 157.421 64.6628C160.362 75.1003 161.004 86.0514 159.303 96.761C157.603 107.471 153.6 117.684 147.571 126.698C141.542 135.711 133.631 143.31 124.382 148.971C115.132 154.631 104.766 158.219 93.9963 159.486C83.2268 160.753 72.3105 159.67 62 156.311',
    isFill: false,
  },
  60: {
    quality:
      'M84.9962 56C89.5761 56 94.091 57.0847 98.171 59.1654C102.251 61.2461 105.78 64.2635 108.47 67.9707C111.159 71.6778 112.932 75.9692 113.644 80.4935C114.356 85.0178 113.986 89.6463 112.564 94.0001C111.143 98.3539 108.711 102.309 105.466 105.542C102.222 108.775 98.2585 111.193 93.8997 112.599C89.541 114.006 84.9112 114.359 80.3894 113.632C75.8676 112.904 71.5825 111.116 67.8848 108.414',
    effectivity:
      'M84.9967 33C93.2025 33 101.292 34.942 108.603 38.6673C115.915 42.3925 122.241 47.7952 127.065 54.4338C131.888 61.0723 135.072 68.7581 136.356 76.8628C137.64 84.9676 136.988 93.2612 134.453 101.066C131.917 108.87 127.571 115.964 121.769 121.766C115.967 127.569 108.875 131.917 101.071 134.453C93.2666 136.99 84.9731 137.643 76.8681 136.361C68.7632 135.078 61.0768 131.895 54.4375 127.073',
    innovations:
      'M84.995 10C96.8261 10 108.489 12.799 119.032 18.1683C129.575 23.5376 138.697 31.3249 145.655 40.8939C152.613 50.4629 157.208 61.5423 159.065 73.2267C160.922 84.9112 159.989 96.8693 156.341 108.124C152.693 119.379 146.435 129.611 138.077 137.984C129.718 146.358 119.498 152.635 108.25 156.304C97.0015 159.972 85.0452 160.927 73.3573 159.092C61.6695 157.256 50.5818 152.681 41 145.741',
    isFill: false,
  },
  65: {
    quality:
      'M84.576 56C89.5353 56 94.4118 57.2718 98.7394 59.6939C103.067 62.116 106.701 65.6074 109.295 69.8346C111.888 74.0617 113.354 78.8833 113.553 83.8386C113.751 88.794 112.676 93.7174 110.429 98.1386C108.182 102.56 104.839 106.331 100.719 109.091C96.5993 111.852 91.8403 113.51 86.8969 113.907C81.9535 114.304 76.9909 113.426 72.4833 111.358C67.9757 109.29 64.0738 106.101 61.1504 102.095',
    effectivity:
      'M84.5767 33C93.4693 33 102.213 35.2805 109.973 39.6235C117.733 43.9666 124.249 50.2271 128.9 57.8068C133.55 65.3865 136.179 74.0321 136.535 82.9175C136.891 91.8029 134.963 100.631 130.934 108.559C126.905 116.486 120.911 123.248 113.523 128.198C106.136 133.149 97.6024 136.122 88.7384 136.833C79.8744 137.545 70.976 135.972 62.8934 132.263C54.8109 128.555 47.8143 122.836 42.5723 115.653',
    innovations:
      'M84.576 10C97.4012 10 110.012 13.2889 121.204 19.5524C132.396 25.8159 141.794 34.8448 148.502 45.7763C155.209 56.7078 159.001 69.1767 159.516 81.9917C160.03 94.8066 157.25 107.539 151.44 118.973C145.631 130.407 136.986 140.161 126.332 147.301C115.679 154.441 103.372 158.731 90.5878 159.759C77.8038 160.787 64.9698 158.519 53.3119 153.173C41.6541 147.827 31.562 139.58 24 129.222',
    isFill: false,
  },
  70: {
    quality:
      'M85.2842 56C90.5764 56 95.7657 57.477 100.279 60.2679C104.793 63.0588 108.454 67.055 110.861 71.8153C113.267 76.5756 114.323 81.9148 113.914 87.2434C113.505 92.5721 111.645 97.6828 108.541 102.011C105.436 106.34 101.208 109.718 96.322 111.772C91.4363 113.826 86.0835 114.477 80.8546 113.653C75.6256 112.828 70.724 110.561 66.691 107.1C62.6579 103.64 59.6502 99.1207 58 94.0425',
    effectivity:
      'M85.2493 33C94.7976 33 104.16 35.6544 112.3 40.6694C120.44 45.6844 127.04 52.8646 131.371 61.4153C135.702 69.9661 137.594 79.554 136.837 89.1182C136.081 98.6824 132.706 107.85 127.086 115.606C121.466 123.363 113.82 129.406 104.994 133.066C96.1685 136.727 86.5066 137.863 77.0781 136.348C67.6495 134.833 58.8219 130.726 51.572 124.483C44.3221 118.239 38.9327 110.102 36 100.971',
    innovations:
      'M85.1755 10C98.9766 10 112.509 13.826 124.276 21.0548C136.043 28.2836 145.586 38.6336 151.849 50.9602C158.113 63.2869 160.854 77.1098 159.768 90.9004C158.682 104.691 153.812 117.912 145.697 129.101C137.582 140.29 126.537 149.012 113.785 154.301C101.032 159.59 87.069 161.241 73.4387 159.072C59.8085 156.902 47.0425 150.995 36.5525 142.006C26.0625 133.017 18.2573 121.294 14 108.136',
    isFill: false,
  },
  75: {
    quality:
      'M85 46C79.4771 46 75 50.4771 75 56C75 61.5228 79.4771 66 85 66L85 46ZM105.506 105.506L98.435 98.435L105.506 105.506ZM73.9022 111.792L77.729 102.554L73.9022 111.792ZM60.8874 101.112L52.5727 106.667L52.5727 106.667L60.8874 101.112ZM66 85C66 79.4771 61.5228 75 56 75C50.4772 75 46 79.4771 46 85L66 85ZM85 66C88.7578 66 92.4313 67.1143 95.5558 69.2021L106.667 52.5727C100.254 48.2873 92.7135 46 85 46L85 66ZM95.5558 69.2021C98.6804 71.2898 101.116 74.2572 102.554 77.729L121.031 70.0753C118.079 62.949 113.081 56.858 106.667 52.5727L95.5558 69.2021ZM102.554 77.729C103.992 81.2008 104.368 85.0211 103.635 88.7067L123.251 92.6085C124.755 85.0432 123.983 77.2016 121.031 70.0753L102.554 77.729ZM103.635 88.7067C102.902 92.3923 101.092 95.7778 98.435 98.435L112.577 112.577C118.031 107.123 121.746 100.174 123.251 92.6085L103.635 88.7067ZM98.435 98.435C95.7778 101.092 92.3924 102.902 88.7067 103.635L92.6085 123.251C100.174 121.746 107.123 118.031 112.577 112.577L98.435 98.435ZM88.7067 103.635C85.0211 104.368 81.2008 103.992 77.729 102.554L70.0753 121.031C77.2017 123.983 85.0433 124.755 92.6085 123.251L88.7067 103.635ZM77.729 102.554C74.2572 101.116 71.2898 98.6804 69.2021 95.5558L52.5727 106.667C56.8581 113.081 62.949 118.079 70.0753 121.031L77.729 102.554ZM69.2021 95.5558C67.1143 92.4313 66 88.7578 66 85L46 85C46 92.7135 48.2873 100.254 52.5727 106.667L69.2021 95.5558Z',
    effectivity:
      'M85 23C79.4771 23 75 27.4771 75 33C75 38.5228 79.4771 43 85 43L85 23ZM133.042 65.1004L142.281 61.2736L133.042 65.1004ZM136.001 95.1447L145.809 97.0956L136.001 95.1447ZM121.77 121.77L114.698 114.698L121.77 121.77ZM95.1447 136.001L93.1938 126.193L95.1447 136.001ZM43 85C43 79.4771 38.5228 75 33 75C27.4771 75 23 79.4771 23 85L43 85ZM85 43C93.3068 43 101.427 45.4632 108.334 50.0783L119.445 33.4489C109.25 26.6362 97.2624 23 85 23L85 43ZM108.334 50.0783C115.241 54.6933 120.624 61.2528 123.803 68.9273L142.281 61.2736C137.588 49.9446 129.641 40.2615 119.445 33.4489L108.334 50.0783ZM123.803 68.9273C126.982 76.6018 127.814 85.0466 126.193 93.1938L145.809 97.0956C148.201 85.0688 146.973 72.6026 142.281 61.2736L123.803 68.9273ZM126.193 93.1938C124.572 101.341 120.572 108.825 114.698 114.698L128.841 128.841C137.511 120.17 143.416 109.122 145.809 97.0956L126.193 93.1938ZM114.698 114.698C108.825 120.572 101.341 124.572 93.1938 126.193L97.0956 145.809C109.122 143.416 120.17 137.511 128.841 128.841L114.698 114.698ZM93.1938 126.193C85.0466 127.814 76.6018 126.982 68.9273 123.803L61.2736 142.281C72.6026 146.973 85.0688 148.201 97.0956 145.809L93.1938 126.193ZM68.9273 123.803C61.2528 120.624 54.6933 115.241 50.0783 108.334L33.4489 119.445C40.2615 129.641 49.9446 137.588 61.2736 142.281L68.9273 123.803ZM50.0783 108.334C45.4633 101.427 43 93.3068 43 85L23 85C23 97.2624 26.6362 109.249 33.4489 119.445L50.0783 108.334Z',
    innovations:
      'M20 85C20 97.8558 23.8122 110.423 30.9545 121.112C38.0968 131.801 48.2484 140.132 60.1256 145.052C72.0028 149.972 85.0721 151.259 97.6809 148.751C110.29 146.243 121.872 140.052 130.962 130.962C140.052 121.871 146.243 110.29 148.751 97.6808C151.259 85.0721 149.972 72.0027 145.052 60.1255C140.132 48.2483 131.801 38.0967 121.112 30.9544C110.423 23.8122 97.8558 20 85 20C79.4771 20 75 15.5228 75 9.99997C75 4.47712 79.4771 -3.39916e-05 85 -3.4233e-05C101.811 -3.49679e-05 118.245 4.98513 132.223 14.3251C146.202 23.665 157.096 36.9401 163.53 52.4719C169.963 68.0036 171.646 85.0942 168.367 101.583C165.087 118.071 156.992 133.217 145.104 145.104C133.217 156.991 118.071 165.087 101.583 168.367C85.0943 171.646 68.0036 169.963 52.4719 163.53C36.9402 157.096 23.665 146.202 14.3251 132.223C4.98516 118.245 -2.98062e-06 101.811 -3.71547e-06 85C-3.95688e-06 79.4771 4.47715 75 10 75C15.5228 75 20 79.4771 20 85Z',
    isFill: true,
  },
  80: {
    quality:
      'M85 56C91.1254 56 97.0934 57.9395 102.049 61.5405C107.004 65.1415 110.691 70.219 112.583 76.045C114.474 81.8711 114.472 88.1464 112.577 93.9713C110.683 99.7962 106.992 104.871 102.035 108.47C97.0774 112.068 91.1082 114.004 84.9828 114C78.8575 113.996 72.8906 112.053 67.9376 108.449C62.9846 104.845 59.3 99.7658 57.412 93.9387C55.5239 88.1115 55.5295 81.8362 57.4279 76.0124',
    effectivity:
      'M85 33C95.9781 33 106.675 36.4744 115.557 42.9255C124.44 49.3766 131.053 58.4735 134.449 68.913C137.845 79.3525 137.85 90.5991 134.464 101.042C131.077 111.484 124.472 120.587 115.596 127.046C106.719 133.506 96.0257 136.99 85.0476 137C74.0695 137.01 63.3699 133.545 54.4814 127.102C45.5929 120.659 38.9715 111.569 35.5657 101.132C32.1599 90.6958 32.1445 79.4493 35.5216 69.0036',
    innovations:
      'M85 10C100.84 10 116.273 15.0149 129.087 24.3259C141.901 33.6369 151.438 46.7659 156.332 61.8309C161.225 76.8958 161.223 93.1232 156.326 108.187C151.429 123.251 141.888 136.377 129.072 145.685C116.255 154.993 100.821 160.004 84.9813 160C69.1415 159.996 53.7097 154.977 40.8979 145.663C28.0861 136.349 18.5521 123.218 13.6627 108.151C8.77318 93.0851 8.77927 76.8577 13.68 61.7952',
    isFill: false,
  },
  85: {
    quality:
      'M85 56C91.5116 56 97.8337 58.1915 102.948 62.2215C108.063 66.2516 111.672 71.8857 113.196 78.2167C114.719 84.5476 114.067 91.207 111.345 97.1224C108.623 103.038 103.989 107.865 98.1903 110.827C92.3912 113.788 85.7642 114.712 79.3762 113.449C72.9882 112.187 67.2111 108.811 62.9751 103.865C58.7391 98.92 56.2908 92.6929 56.0243 86.1867C55.7578 79.6806 57.6887 73.2741 61.5061 67.9989',
    effectivity:
      'M85 33C96.6838 33 108.027 36.9348 117.201 44.1701C126.375 51.4053 132.845 61.5194 135.568 72.8815C138.291 84.2436 137.108 96.1916 132.21 106.799C127.312 117.407 118.984 126.056 108.57 131.352C98.155 136.647 86.2603 138.282 74.8033 135.99C63.3463 133.699 52.9947 127.617 45.4176 118.723C37.8404 109.829 33.4793 98.6428 33.0372 86.9673C32.5952 75.2919 36.0981 63.8077 42.9811 54.3666',
    innovations:
      'M85 10C101.843 10 118.196 15.6693 131.424 26.0949C144.652 36.5204 153.987 51.0951 157.923 67.4716C161.859 83.848 160.169 101.073 153.124 116.371C146.079 131.67 134.089 144.152 119.086 151.807C104.083 159.462 86.9402 161.843 70.4187 158.569C53.8972 155.294 38.9588 146.554 28.0097 133.756C17.0606 120.957 10.7381 104.846 10.0607 88.0165C9.38326 71.1872 14.3903 54.6198 24.2753 40.9828',
    isFill: false,
  },
  90: {
    quality:
      'M85 56C91.9043 56 98.582 58.4633 103.832 62.9468C109.083 67.4303 112.561 73.64 113.642 80.459C114.723 87.2781 113.336 94.2592 109.73 100.147C106.124 106.034 100.536 110.442 93.9699 112.578C87.4043 114.713 80.2921 114.436 73.9124 111.797C67.5328 109.157 62.3041 104.328 59.1669 98.1775C56.0296 92.0273 55.1895 84.9595 56.7977 78.2451C57.8896 73.6863 60.0615 69.4935 63.0911 66',
    effectivity:
      'M85.0001 33C97.3762 33 109.347 37.4141 118.76 45.4491C128.173 53.484 134.412 64.6129 136.355 76.8355C138.298 89.0582 135.818 101.573 129.361 112.131C122.904 122.689 112.892 130.598 101.127 134.436C89.3606 138.274 76.6114 137.79 65.1705 133.071C53.7295 128.351 44.3469 119.706 38.709 108.688C33.0711 97.671 31.5476 85.0039 34.4122 72.9639C37.2768 60.9238 44.3418 50.3002 54.3374 43.0024',
    innovations:
      'M85.0001 10C102.855 10 120.124 16.3698 133.702 27.964C147.28 39.5582 156.277 55.6162 159.074 73.2505C161.871 90.8849 158.285 108.939 148.962 124.166C139.638 139.392 125.187 150.793 108.209 156.319C91.2305 161.844 72.8379 161.131 56.3383 154.307C39.8387 147.484 26.3147 134.998 18.1979 119.095C10.0811 103.192 7.90415 84.9145 12.0584 67.5498C16.2127 50.185 26.4257 34.8719 40.8609 24.3639',
    isFill: false,
  },
  95: {
    quality:
      'M85.0001 56C92.3007 56 99.3327 58.7536 104.692 63.711C110.052 68.6684 113.344 75.4648 113.912 82.7433C114.48 90.0218 112.282 97.2468 107.757 102.976C103.232 108.705 96.712 112.516 89.4997 113.649C82.2875 114.782 74.9134 113.152 68.8497 109.087C62.786 105.021 58.4789 98.8176 56.7883 91.7154C55.0977 84.6132 56.1481 77.1347 59.7297 70.773C60.6704 69.102 61.7676 67.5405 63 66.1055',
    effectivity:
      'M85.0001 33C98.0901 33 110.699 37.9369 120.308 46.8251C129.918 55.7133 135.822 67.8988 136.842 80.949C137.862 93.9993 133.922 106.954 125.81 117.227C117.697 127.5 106.009 134.336 93.0774 136.369C80.1463 138.402 66.924 135.484 56.0502 128.196C45.1764 120.909 37.4512 109.788 34.4166 97.0546C31.3821 84.3211 33.2617 70.9117 39.68 59.5032C44.2846 51.3186 50.9822 44.5958 59 39.9666',
    innovations:
      'M85.0002 10C103.878 10 122.062 17.1191 135.922 29.9364C149.782 42.7538 158.298 60.3264 159.771 79.1468C161.245 97.9672 155.566 116.651 143.87 131.469C132.173 146.287 115.319 156.149 96.6708 159.086C78.0228 162.024 58.9528 157.821 43.2673 147.317C27.5817 136.812 16.4345 120.779 12.0505 102.417C7.66656 84.055 10.3684 64.7152 19.6166 48.2577C28.18 33.0191 41.7727 21.2896 58 15.0285',
    isFill: false,
  },
  100: {
    quality: '',
    effectivity: '',
    innovations: '',
    isFill: false,
  },
};

interface Props {
  effectivity: number;
  innovations: number;
  percentage: number;
  quality: number;
  skupiny?: SkupinyParametrov;
}

const BaseChart = ({
  effectivity,
  innovations,
  percentage,
  quality,
  skupiny,
}: Props) => {
  const graphGroups = skupiny
    ? getGraphValues(skupiny, {
        isVLD: false,
        primaryVLD: false,
      })
    : [];
  const renderCircle = ({
    type,
  }: {
    type: 'quality' | 'effectivity' | 'innovations';
  }): ReactNode => {
    let chartValue: {
      color: string;
      value: number;
    } = { color: '#EA5DD1', value: quality }; // for quality
    if (type === 'effectivity')
      chartValue = { color: '#42D848', value: effectivity };
    if (type === 'innovations')
      chartValue = { color: '#3EAEFF', value: innovations };
    const { color, value } = chartValue;
    switch (true) {
      case value === 100: {
        let r = 29;
        if (type === 'effectivity') r = 52;
        if (type === 'innovations') r = 74;
        return <Circle cx="85" cy="85" r={r} stroke={color} strokeWidth="20" />;
      }
      case value === 0:
        return <Circle cx="85" cy="56" fill={color} r="10" />;
      case graphValues[value - (value % 5)]?.isFill:
        return <Path d={graphValues[value - (value % 5)][type]} fill={color} />;
      case !!value:
        return (
          <Path
            d={graphValues[value - (value % 5)][type]}
            stroke={color}
            // @ts-ignore
            strokeLinecap="round"
            strokeWidth="20"
          />
        );
      default:
        return (
          <Circle cx="85" cy="85" r="29" stroke={color} strokeWidth="20" />
        );
    }
  };
  const hasGroup = (group: 'kvalita' | 'efektívnosť' | 'inovácie'): boolean =>
    graphGroups?.some((g) => g?.nazov?.toLowerCase()?.includes(group));

  return (
    // @ts-ignore
    <Svg style={{ width: 170, height: 170 }}>
      {safeNode(
        <Path
          d="M85 -3.71547e-06C131.944 -5.76746e-06 170 38.0558 170 85C170 131.944 131.944 170 85 170C38.0558 170 -1.66347e-06 131.944 -3.71547e-06 85C-5.76746e-06 38.0558 38.0558 -1.66347e-06 85 -3.71547e-06ZM85 149.316C120.521 149.316 149.316 120.521 149.316 85C149.316 49.4793 120.521 20.6841 85 20.6841C49.4793 20.6841 20.6841 49.4793 20.6841 85C20.6841 120.521 49.4793 149.316 85 149.316Z"
          fill="#EAEBEC"
        />,
        innovations >= 0 && hasGroup('inovácie'),
        <Path d="" />,
      )}
      {safeNode(
        <Path
          d="M85.0013 23.2709C119.094 23.2709 146.731 50.9083 146.731 85.0007C146.731 119.093 119.094 146.73 85.0013 146.73C50.9089 146.73 23.2715 119.093 23.2715 85.0007C23.2715 50.9083 50.9088 23.2709 85.0013 23.2709ZM85.0013 126.054C107.674 126.054 126.055 107.674 126.055 85.0007C126.055 62.3275 107.674 43.9473 85.0013 43.9473C62.3281 43.9473 43.9478 62.3275 43.9479 85.0007C43.9479 107.674 62.3281 126.054 85.0013 126.054Z"
          fill="#EAEBEC"
        />,
        effectivity >= 0 && hasGroup('efektívnosť'),
        <Path d="" />,
      )}
      {safeNode(
        <Path
          d="M85.0009 46.5396C106.242 46.5396 123.461 63.7587 123.461 84.9996C123.461 106.24 106.242 123.459 85.0009 123.459C63.7601 123.459 46.541 106.24 46.541 84.9996C46.541 63.7587 63.7601 46.5396 85.0009 46.5396ZM85.0009 102.768C94.8142 102.768 102.769 94.8128 102.769 84.9996C102.769 75.1863 94.8142 67.231 85.0009 67.231C75.1876 67.231 67.2324 75.1863 67.2324 84.9996C67.2324 94.8128 75.1876 102.768 85.0009 102.768Z"
          fill="#EAEBEC"
        />,
        quality >= 0 && hasGroup('kvalita'),
        <Path d="" />,
      )}

      {hasGroup('kvalita') && renderCircle({ type: 'quality' })}
      {hasGroup('efektívnosť') && renderCircle({ type: 'effectivity' })}
      {hasGroup('inovácie') && renderCircle({ type: 'innovations' })}

      <Text fill="#000000" style={{ fontSize: 11 }} x="73" y="88">
        {`${percentage} %`}
      </Text>

      {safeNode(
        <Path
          // @ts-ignore
          clipRule="evenodd"
          d="M80 8.17108C80 5.31517 82.3152 3 85.1711 3C88.027 3 90.3422 5.31517 90.3422 8.17108C90.3422 9.63221 89.7353 10.9527 88.7616 11.8924C88.5542 12.0926 88.4047 12.3933 88.3246 12.7514C88.2253 13.1958 88.0328 13.6194 87.7566 13.9885V14.6349C87.7566 15.5294 87.1511 16.2824 86.3276 16.5063C86.2655 16.6304 86.1838 16.7439 86.0854 16.8423C85.9653 16.9624 85.8228 17.0576 85.666 17.1226C85.5091 17.1875 85.341 17.221 85.1713 17.221C85.0015 17.221 84.8334 17.1875 84.6765 17.1226C84.5197 17.0576 84.3772 16.9624 84.2571 16.8423C84.1587 16.744 84.077 16.6305 84.015 16.5064C83.1913 16.2826 82.5855 15.5295 82.5855 14.6349V13.9884C82.3096 13.6194 82.1174 13.1955 82.018 12.7523C81.9375 12.3936 81.7878 12.0925 81.5801 11.892C80.6067 10.9524 80 9.63202 80 8.17108ZM85.1711 4.29277C83.0292 4.29277 81.2928 6.02915 81.2928 8.17108C81.2928 9.26697 81.7464 10.2558 82.478 10.9619C82.9201 11.3887 83.1622 11.9467 83.2794 12.4694C83.2966 12.5461 83.3184 12.6216 83.3445 12.6955H84.5258L84.5258 8.81856C84.5258 8.81874 84.5258 8.81838 84.5258 8.81856L83.8761 8.81803C83.5191 8.81803 83.2297 8.52863 83.2297 8.17164C83.2297 7.81465 83.5191 7.52526 83.8761 7.52526H86.4616C86.8186 7.52526 87.108 7.81465 87.108 8.17164C87.108 8.52863 86.8186 8.81803 86.4616 8.81803H85.8186C85.8186 8.81785 85.8186 8.81821 85.8186 8.81803V12.6955H86.9981C87.0242 12.6216 87.0459 12.5461 87.063 12.4694C87.1799 11.9466 87.4218 11.3888 87.8639 10.9622C88.5956 10.256 89.0494 9.26712 89.0494 8.17108C89.0494 6.02915 87.313 4.29277 85.1711 4.29277ZM85.1302 13.9883C85.1441 13.9892 85.1581 13.9896 85.1722 13.9896C85.1863 13.9896 85.2004 13.9892 85.2143 13.9883H86.4639V14.6349C86.4639 14.9919 86.1745 15.2813 85.8175 15.2813H84.5247C84.1677 15.2813 83.8783 14.9919 83.8783 14.6349V13.9883H85.1302Z"
          fill="#F7F7F7"
          fillRule="evenodd"
        />,
        innovations >= 0 && hasGroup('inovácie'),
        <Path d="" />,
      )}

      {safeNode(
        <>
          <Path
            d="M86.8415 26.0549C85.2908 25.8508 83.7188 26.2173 82.4184 27.0862C82.1128 27.2904 82.0306 27.7036 82.2348 28.0092C82.4389 28.3147 82.8522 28.3969 83.1577 28.1928C84.1897 27.5032 85.4372 27.2123 86.6678 27.3743C87.8983 27.5363 89.028 28.1402 89.8464 29.0733C90.4257 29.734 90.8211 30.5279 91.0026 31.3756H90.107C89.5643 31.3756 89.2498 31.9903 89.5674 32.4304L91.1806 34.6657C91.4462 35.0337 91.9942 35.0337 92.2598 34.6657L93.873 32.4304C94.1906 31.9903 93.8761 31.3756 93.3334 31.3756H92.3572C92.1596 30.203 91.6401 29.1004 90.8469 28.1959C89.8157 27.02 88.3921 26.2591 86.8415 26.0549Z"
            fill="white"
          />
          <Path
            d="M89.5816 38.3568C88.2812 39.2257 86.7092 39.5922 85.1585 39.3881C83.6079 39.1839 82.1843 38.423 81.1531 37.2471C80.3596 36.3423 79.84 35.2392 79.6426 34.0661H78.6666C78.1239 34.0661 77.8094 33.4514 78.127 33.0113L79.7402 30.776C80.0058 30.408 80.5538 30.408 80.8194 30.776L82.4326 33.0113C82.7502 33.4514 82.4357 34.0661 81.893 34.0661H80.9971C81.1785 34.9143 81.574 35.7087 82.1536 36.3697C82.972 37.3028 84.1017 37.9067 85.3322 38.0687C86.5628 38.2307 87.8103 37.9398 88.8423 37.2503C89.1478 37.0461 89.5611 37.1283 89.7652 37.4338C89.9694 37.7394 89.8872 38.1526 89.5816 38.3568Z"
            fill="white"
          />
        </>,
        effectivity >= 0 && hasGroup('efektívnosť'),
        <Path d="" />,
      )}

      {safeNode(
        <Path
          d="M86.2783 61.2422C86.0241 61.6089 85.4819 61.6089 85.2277 61.2422L80.2525 54.0647C80.1007 53.8457 80.1007 53.5555 80.2525 53.3365L81.7271 51.2091C81.8465 51.0369 82.0428 50.9341 82.2524 50.9341L89.2536 50.9341C89.4632 50.9341 89.6595 51.0369 89.7789 51.2091L91.2535 53.3365C91.4053 53.5555 91.4053 53.8457 91.2535 54.0647L86.2783 61.2422Z"
          stroke="white"
          strokeWidth="1.27825"
        />,
        quality >= 0 && hasGroup('kvalita'),
        <Path d="" />,
      )}
    </Svg>
  );
};

export default BaseChart;
