import { createUseStyles } from 'react-jss';

import { color, fontWeight } from '../../constants';

export default createUseStyles({
  label: {
    fontSize: '0.875rem',
    fontWeight: fontWeight.semiBold,
    '& tspan': {
      fill: color('black'),
      '&.text-color-error': {
        fill: color('error'),
      },
      '&.text-color-secondary': {
        fill: color('secondary'),
      },
    },
    '& > tspan': {
      fill: color('grey', 300),
    },
  },
});
