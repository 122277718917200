import React from 'react';

const IconCycle = ({ ...other }: React.JSX.IntrinsicElements['svg']) => (
  <svg
    fill="none"
    height="32"
    viewBox="0 0 32 32"
    width="32"
    xmlns="http://www.w3.org/2000/svg"
    {...other}
  >
    <path
      clipRule="evenodd"
      d="M21.2699 24.425C19.3309 25.7206 16.9869 26.2671 14.6748 25.9627C12.3627 25.6583 10.2401 24.5238 8.70249 22.7704C7.51937 21.4214 6.74467 19.7767 6.45033 18.0277H4.99391C4.1847 18.0277 3.71584 17.1111 4.18939 16.455L6.59477 13.122C6.99077 12.5733 7.8078 12.5733 8.20381 13.122L10.6092 16.455C11.0827 17.1111 10.6139 18.0277 9.80466 18.0277H8.47001C8.74047 19.2924 9.33011 20.4766 10.1944 21.4621C11.4146 22.8535 13.099 23.7538 14.9338 23.9954C16.7687 24.237 18.6287 23.8033 20.1675 22.7751C20.6231 22.4707 21.2392 22.5932 21.5436 23.0488C21.848 23.5044 21.7255 24.1205 21.2699 24.425ZM23.6556 18.9215C24.0516 19.4702 24.8686 19.4702 25.2646 18.9215L27.67 15.5885C28.1435 14.9324 27.6747 14.0158 26.8655 14.0158H25.4088C25.1143 12.2673 24.3397 10.6232 23.1569 9.27454C21.6193 7.52121 19.4966 6.38665 17.1845 6.08226C14.8724 5.77786 12.5285 6.32439 10.5895 7.62001C10.1339 7.92443 10.0113 8.54056 10.3158 8.99616C10.6202 9.45177 11.2363 9.57432 11.6919 9.2699C13.2307 8.24173 15.0907 7.80803 16.9255 8.04958C18.7604 8.29114 20.4448 9.19149 21.665 10.5829C22.5289 11.568 23.1184 12.7517 23.3891 14.0158H22.0547C21.2455 14.0158 20.7766 14.9324 21.2502 15.5885L23.6556 18.9215Z"
      fill="white"
      fillRule="evenodd"
    />
  </svg>
);

export default IconCycle;
