import {
  ButtonLink,
  Grid,
  GridCol,
  Skeleton,
  breakpoints,
} from '@dovera/design-system';
import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import IconArrowRightLong from '../../../components/CustomIcons/IconArrowRightLong';
import SafeHtml from '../../../components/SafeHtml/SafeHtml';
import strings from '../../../constants/strings';
import { useAppDispatch } from '../../../hooks/useStore';
import { useWindowSize } from '../../../hooks/useWindowSize';
import { RootState } from '../../../rootReducer';
import routes from '../../../routes';
import { feedbackTrigger } from '../../../slices/feedback.slices';
import {
  TypCeny,
  VysledokObdobia,
} from '../../../slices/hodnotiaceParametre.slice';
import { createViewUrl, userHasSection } from '../../../utils/app.utils';
import { getUserId } from '../../../utils/auth.utils';
import { endOf, formatDate } from '../../../utils/date.utils';
import { isVyhodnoteneObdobie } from '../../../utils/hodnotiaceParametre.utils';
import { getPriceFormat } from '../../../utils/number.utils';
import ExportXlsxReport from '../../hp-dashboard/ExportXlsxReport/ExportXlsxReport';
import useStyles from './WidgetCenyHP.styles';
import { getPZS } from '../../../slices/auth.slice';

const texts = strings.dashboard.widgetCenyHP;

/**
 * Widget pre zobrazenie aktualne platnych cien hodnotiacich parametrov za vyhodnotene obdobie
 * @date 14. 3. 2023 - 9:28:08
 *
 * @returns {*}
 */
const WidgetCenyHP = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const windowSize = useWindowSize();
  const [expertiseChanged, setExpertiseChanged] = useState(false);
  const isSmall: boolean =
    (windowSize.width < 1325 && windowSize.width > breakpoints.xl) ||
    windowSize.width < breakpoints.s;
  const path = createViewUrl(routes.hp, { obdobie: 'uzavrete' });
  const pzs = useSelector((state: RootState) => getPZS(state.auth));
  const { isLoaded, isLoading, zoznamObdobi } = useSelector(
    (state: RootState) => state.hodnotiaceParametre.obdobia,
  );
  const { kodTypZs, odbornosti, poslednaZmenaOdbornosti } = useSelector(
    (state: RootState) => state.poskytovatel,
  );
  /**
   * Vyskladanie objektu data pre zobrazenie vyhodnotenych cien
   * Vysledny objekt je vytvoreny z objektu zoznamObdobi
   * Vysledny objekt akceptuje tpy cien CenaBodu, DodKap alebo Vykony
   * @date 14. 3. 2023 - 9:28:53
   *
   * @type 
      ceny: { cena: string; typ: TypCeny | string }[];
      datumDo: number;
      datumOd: number;
   */
  const data: {
    ceny: { cena: string; typ: TypCeny | string }[];
    datumDo: number;
    datumOd: number;
    idCyklus: number;
    vypocetDo: number;
    vypocetOd: number;
  } = useMemo(() => {
    enum AcceptedTypes {
      CenaBodu = 'CenaBodu',
      DodKap = 'DodKap',
      Vykon1 = 'Vykon1',
      Vykon250 = 'Vykon250',
      Vykon4 = 'Vykon4',
      Vykon8 = 'Vykon8',
    }
    const vykonyOrder = ['Vykon8', 'Vykon4', 'Vykon1', 'Vykon250'];
    const prices: { cena: string; typ: TypCeny | string }[] = [];
    let dateFrom: number = 0;
    let dateTo: number = 0;
    const obdobie = Object.values(zoznamObdobi).find((o, index) =>
      isVyhodnoteneObdobie(
        Number(Object.keys(zoznamObdobi)[index + 1]),
        o,
        zoznamObdobi,
      ),
    );
    Object.keys(obdobie?.vysledokObdobia || {})?.forEach((v) => {
      if (v in AcceptedTypes) {
        const price: VysledokObdobia[] = obdobie?.vysledokObdobia?.[v] || [];
        if (price.length)
          prices[vykonyOrder.includes(v) ? vykonyOrder.indexOf(v) : 0] = {
            cena: getPriceFormat(price[0].cena, true),
            typ: v,
          };
        dateFrom = price[0].obdobiePlatnostOd || 0;
        dateTo = price[0].obdobiePlatnostDo || 0;
      }
    });
    return {
      ceny: prices,
      datumDo: dateTo,
      datumOd: dateFrom,
      idCyklus: obdobie?.idCyklus || 0,
      vypocetDo: obdobie?.obdobieVypocetDo || 0,
      vypocetOd: obdobie?.obdobieVypocetOd || 0,
    };
  }, [zoznamObdobi]);
  useEffect(() => {
    setExpertiseChanged(true);
  }, [poslednaZmenaOdbornosti]);
  useEffect(() => {
    setExpertiseChanged(false);
  }, [isLoading]);
  if (
    userHasSection('PZSHPKEI') &&
    odbornosti.length &&
    (isLoading || !isLoaded || expertiseChanged)
  )
    return (
      <div className={classes.root}>
        <h3 className="mb-small">
          <Skeleton width="100%" />
        </h3>
        <Skeleton width="70%" />
        <h4 className="mb-small">
          <Skeleton width="50%" />
        </h4>
        <Skeleton width="70%" />
      </div>
    );
  if (!userHasSection('PZSHPKEI') || !data.datumOd) return <span />;
  return (
    <div className={classes.root}>
      <h3 className="mb-small">
        {texts.title(data.ceny.length === 1 ? data.ceny[0].typ : '')}
      </h3>
      {data.ceny.length === 1 && (
        <h4 className="mb-small">{data.ceny[0].cena}</h4>
      )}
      <SafeHtml
        className="mb-small"
        html={texts.expertise(
          odbornosti.find(
            (o) =>
              o.kodTypZS === kodTypZs && o.kodOdbNz === poslednaZmenaOdbornosti,
          )?.nazovOdbNz || '',
        )}
        wrapper="div"
      />
      <SafeHtml
        className="mb-small"
        html={texts.description(
          formatDate(`${data.datumOd}01`),
          formatDate(`${data.datumDo}${endOf(data.datumDo.toString())}`),
        )}
        wrapper="div"
      />
      {data.ceny.length > 1 && (
        <Grid>
          {data.ceny.map((d, index) => (
            <GridCol
              key={`widget-price-col--${index}`}
              size={data.ceny.length > 1 ? 6 : 12}
            >
              <SafeHtml
                className={classes.priceType}
                html={texts.prices?.[d.typ] || ''}
              />
              <h4 className="mb-small">{d.cena}</h4>
            </GridCol>
          ))}
        </Grid>
      )}
      <ExportXlsxReport
        buttonTitle={isSmall ? texts.downloadBtnSmall : texts.downloadBtn}
        idCyklus={data.idCyklus}
        isRelative
        plnenie="vyhodnotene plnenie"
      />
      <ButtonLink
        className="no-mrg-bottom no-pad text-left"
        onClick={() => {
          dispatch(
            feedbackTrigger({
              delay: 15,
              path,
              userId: getUserId(pzs.userId),
            }),
          );
          navigate(path);
        }}
      >
        {isSmall ? texts.buttonSmall : texts.button}
        <IconArrowRightLong id="profile-card-arrow-icon" />
      </ButtonLink>
    </div>
  );
};

export default WidgetCenyHP;
