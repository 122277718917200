import { StyleSheet, Text, View } from '@react-pdf/renderer';
import { color } from '../../../constants';

const styles = StyleSheet.create({
  graph: {
    position: 'relative',
    width: '100%',
    minWidth: 200,
    height: 6,
    borderRadius: 3,
    display: 'flex',
    backgroundColor: color('grey', 200),
  },

  graphFill: {
    position: 'absolute',
    height: '100%',
    borderRadius: 3,
    left: 0,
    zIndex: 0,
  },
  pointer: {
    height: 12,
    width: 2,
    backgroundColor: '#000000',
    position: 'absolute',
    top: 0,
    zIndex: 99,
    display: 'flex',
  },
  label: {
    backgroundColor: color('grey', 200),
    borderRadius: 60,
    padding: '4px 10px 1px',
    fontWeight: 'bold',
    position: 'absolute',
    top: 12,
    fontSize: 10,
    width: 120,
  },
});

interface Props {
  fitColor: 'primary' | 'warning' | 'error';
  percentage: number;
  point: number;
  point2?: number;
  pointText: string;
  pointText2?: string;
}

const StandardGraph = ({
  fitColor,
  percentage,
  point,
  point2,
  pointText,
  pointText2,
}: Props) => (
  <View>
    <View style={styles.graph}>
      <View
        style={[
          styles.graphFill,
          {
            backgroundColor: color(fitColor),
            width: `${percentage > 100 ? 100 : percentage}%`,
            borderTopRightRadius: percentage < 100 ? 0 : 3,
            borderBottomRightRadius: percentage < 100 ? 0 : 3,
          },
        ]}
      />
      {pointText && percentage >= point ? (
        <View style={[styles.pointer, { left: `${point}%` }]} />
      ) : (
        <View />
      )}
      {point2 && percentage >= point2 ? (
        <View style={[styles.pointer, { left: `${point2}%` }]} />
      ) : (
        <View />
      )}
      {pointText && percentage >= point ? (
        <View
          style={[
            styles.label,
            {
              left: `${point2 ? -30 : point - 30}%`,
            },
          ]}
        >
          <Text style={{ textAlign: 'center' }}>{pointText}</Text>
        </View>
      ) : (
        <View />
      )}
      {pointText2 && point2 && percentage >= point2 ? (
        <View style={[styles.label, { left: `${point2 - 30}%` }]}>
          <Text style={{ textAlign: 'center' }}>{pointText2}</Text>
        </View>
      ) : (
        <View />
      )}
    </View>
    {pointText && percentage < point ? (
      <View style={[styles.pointer, { left: `${point}%` }]} />
    ) : (
      <View />
    )}
    {point2 && percentage < point2 ? (
      <View style={[styles.pointer, { left: `${point2}%` }]} />
    ) : (
      <View />
    )}
    {pointText && percentage < point ? (
      <View
        style={[
          styles.label,
          {
            left: `${point2 ? -30 : point - 30}%`,
          },
        ]}
      >
        <Text style={{ textAlign: 'center' }}>{pointText}</Text>
      </View>
    ) : (
      <View />
    )}
    {pointText2 && point2 && percentage < point2 ? (
      <View style={[styles.label, { left: `${point2 - 30}%` }]}>
        <Text style={{ textAlign: 'center' }}>{pointText2}</Text>
      </View>
    ) : (
      <View />
    )}
  </View>
);

export default StandardGraph;
