/* eslint-disable */
// @ts-nocheck
import { useEffect, useState } from 'react';
import { useAuthContext } from '../../hooks/useAuthContext';
import { setGuid, signinRedirect } from '../../slices/auth.slice';
import { AppLinksByEnv } from '../../constants/epLinks';
import { Roly } from '../../types';
import routes from '../../routes';
import { jwtDecode } from 'jwt-decode';
import { useAppDispatch } from '../../hooks/useStore';
import { Navigate } from 'react-router';
import { removeToken } from '../../utils/auth.utils';

function Callback() {
  const [redirectRoute, setRedirectRoute] = useState<any>(null);
  const authContext = useAuthContext();
  const dispatch = useAppDispatch();

  useEffect(() => {
    authContext
      .getUserManager()
      .signinRedirectCallback()
      .then((response) => {
        dispatch(signinRedirect(response));
        dispatch(setGuid(''));
        const jwt = jwtDecode(response?.access_token);
        if (window.exponea && jwt && jwt?.dzp_provider_id) {
          window.exponea.identify({
            id_pzs: `${jwt?.dzp_provider_id || 0}_XXX_XXX_XXX`,
          });
          setTimeout(() => {
            window.exponea.start({
              track: {
                visits: true,
                google_analytics: false,
                default_properties: {
                  id_pouzivatel_ep: `${jwt?.dzp_user_id || 0}`,
                },
              },
            });
          }, 50);
        }
        if (
          response.profile.dzp_initial_role &&
          ![Roly.distributor, Roly.poskytovatel].includes(
            response.profile.dzp_initial_role,
          ) &&
          /** redirect from claim dzp_initial_role use only after login, not later
           * (auth time + 10)
           * nbf is refreshed after every reload (token validity is extended)
           */
          // @ts-ignore
          ((response.profile.auth_time || 0) + 10 >= (jwt?.nbf || 0) ||
            !response.profile.dzp_provider_id)
        ) {
          //  @ts-ignore COMMENTED ROLE VALIDATION FOR IDP TEST
          // window.location.href = AppLinksByEnv(
          //   response.profile.dzp_initial_role,
          // );
          // @ts-ignore
          window.publicGuid = '';
          // return false;
        }
        setRedirectRoute(localStorage.getItem('redirect_uri') || '/');
        return true;
      })
      .catch((error) => {
        /* eslint-disable */
        console.log('Error login callback:');
        console.log(error);
        /* eslint-enable */
        localStorage.setItem('poskytovatel_neuspesne_prihlasenie', 'true');
        removeToken();
        setRedirectRoute(routes.chybaPrihlasenia);
      });
  }, [authContext, dispatch]);
  if (redirectRoute) return <Navigate to={redirectRoute} />;
  return null;
}

export default Callback;
