import { useSelector } from 'react-redux';
import ActualityRow from '../ActualityRow/ActualityRow';
import { RootState } from '../../../rootReducer';
import {
  aktualizujAktualityNovinky,
  selectHPActualities,
  selectUnreadActualities,
} from '../../../slices/poskytovatel.slice';
import { useAppDispatch } from '../../../hooks/useStore';
import { useCallback } from 'react';
import strings from '../../../constants/strings';
import { Card, CardSection } from '@dovera/design-system';
import WidgetPreloader from '../WidgetPreloader/WidgetPreloader';
import {
  getActualityRowTitle,
  getActualityRowUrl,
  gtActualityRowDescription,
} from '../../../utils/dashboard.utils';

const texts = strings.dashboard;

const DashboardNtf = () => {
  const dispatch = useAppDispatch();
  const hpActualities = useSelector((state: RootState) =>
    selectHPActualities(state.poskytovatel.dashboard),
  );
  const unreadActualities = useSelector((state: RootState) =>
    selectUnreadActualities(state.poskytovatel.dashboard),
  );
  const { pouzivatelId, vztahId } = useSelector(
    (state: RootState) => state.auth,
  );
  const isLoading = useSelector(
    (state: RootState) =>
      state.poskytovatel.dashboard.isLoading || state.poskytovatel.isLoading,
  );
  const clickCallback = useCallback(
    (aktualizovatelne, id, type) => {
      if (vztahId && aktualizovatelne)
        dispatch(
          aktualizujAktualityNovinky(
            vztahId,
            id,
            type.startsWith('HP') ? 'HP' : type === 'A' ? 'A' : 'N',
            pouzivatelId,
          ),
        );
    },
    [dispatch, vztahId, pouzivatelId],
  );
  const getHPActualities = () =>
    hpActualities?.map((a, key) => {
      if (a.kodAktualita && ['HPMV', 'HPV'].includes(a.kodAktualita))
        return (
          <ActualityRow
            key={`novinky-${a.id}-actuality-${key}`}
            aktualizovatelne={!a.precitane}
            code={a.kodAktualita}
            data={a.data}
            date={a.datum}
            description={a.text}
            id={a.id}
            onClick={clickCallback}
            read={a.precitane}
            title={a.nazov}
            type={a.kodAktualita}
            url=""
          />
        );
      return (
        <ActualityRow
          key={`novinky-${a.id}--row-${key}`}
          aktualizovatelne={!a.precitane}
          code={a.kodAktualita || ''}
          date={a.datum}
          description={gtActualityRowDescription(a.kodAktualita) || a.text}
          id={a.id}
          onClick={clickCallback}
          read={a.precitane}
          title={getActualityRowTitle(a.kodAktualita, a.nazov)}
          type={a.kodAktualita}
          url={getActualityRowUrl(a.kodAktualita)}
        />
      );
    });
  const renderSkeleton = isLoading && <WidgetPreloader />;
  return (
    <Card className="mb" id="actuality-card-unread">
      <CardSection isCondensed>
        <h3 id="actuality-title-unread">
          {texts.notifications.title}
          {!isLoading && <span> ({unreadActualities})</span>}
        </h3>
        {renderSkeleton}
        {getHPActualities()}
      </CardSection>
    </Card>
  );
};

export default DashboardNtf;
