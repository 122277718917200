import React from 'react';

const IconDiamond = ({ ...other }: React.JSX.IntrinsicElements['svg']) => (
  <svg
    fill="none"
    height="32"
    viewBox="0 0 32 32"
    width="32"
    xmlns="http://www.w3.org/2000/svg"
    {...other}
  >
    <path
      clipRule="evenodd"
      d="M17.5479 24.0974C16.776 25.1334 15.224 25.1334 14.4521 24.0974L6.38243 13.2671C5.87252 12.5827 5.87252 11.6447 6.38243 10.9604L8.75438 7.77697C9.11868 7.28805 9.69257 6.99998 10.3023 6.99998L21.6977 6.99998C22.3074 6.99998 22.8813 7.28805 23.2456 7.77698L25.6176 10.9604C26.1275 11.6447 26.1275 12.5827 25.6176 13.2671L17.5479 24.0974ZM16 22.944L24.0697 12.1137L21.6977 8.93032L10.3023 8.93032L7.93034 12.1137L16 22.944Z"
      fill="white"
      fillRule="evenodd"
    />
  </svg>
);

export default IconDiamond;
