import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import {
  filter,
  find,
  groupBy,
  mapValues,
  orderBy,
  sortBy,
  sumBy,
  values,
} from 'lodash-es';

import { AppThunk } from '../store';

import {
  dajHodnotiaceParametreApi,
  dajHodnotiaceParametrePublicApi,
} from '../api';
import {
  getSlugSkupiny,
  vypocetTypCeny,
} from '../utils/hodnotiaceParametre.utils';
import routes from '../routes';
import { LoadingState, Nullable } from '../types';
import { objectLength } from '../utils/object.utils';
import { publicOdbornost } from './poskytovatel.slice';
import {
  DajDetailParametraHodnoteniaResponse,
  DetailHPState,
  UlozParameterHodnoteniaResponse,
} from '../types/hp.types';
import { fetchHPDetail, saveHPExchange } from '../api/hp';
import strings from '../constants/strings';
import { arraySort } from '../utils/array.utils';

export interface Parameter {
  defaultParameter: boolean | null;
  hodnota: number | null;
  hodnota3: number | null;
  hodnotaCitatel: number;
  hodnotaMenovatel: number;
  hranicnaHodnotaMax: number;
  hranicnaHodnotaMin: number;
  id: number;
  idParametra: number;
  idPzsOdbCyklus: number;
  kodParametra: string | null;
  kodSkupinyParametra: string;
  moznaVymenaZaParamId: string | null;
  nazovParametra: string;
  obdobieSledovaneDo?: number;
  obdobieVypocetDo: number;
  obdobieVypocetOd: number | null;
  pasmo: string;
  plnenie: number;
  popisEmptyState: Nullable<string>;
  popisOutlier?: Nullable<string>;
  popisParametra: Nullable<string>;
  poradieParametra: number;
  poradieSkupinyParametra: number;
  poziciaPzs: string | null;
  priemernaHodnota: number | null;
  priznakNegativny: boolean;
  skupinaParametra: string;
  typHodnoty: string;
  vaha: number;
  vybranyParameterPZS: boolean | null;
}

export interface SkupinaParametrov {
  nazov: string;
  parametre: Parameter[];
  plnenie: number;
  poradie: number;
  vaha: number;
}

export type SkupinyParametrov = Record<string, SkupinaParametrov>;

export interface Cyklus {
  buduci?: boolean;
  id: number;
  idCyklus: number;
  obdobieSledovaneDo: number;
  obdobieVypocetDo: number;
  obdobieVypocetOd: number;
  popisEmptyState: Nullable<string>;
  popisObdobie: Nullable<string>;
  popisOutlier: string | null;
  poradie: number;
  posledny: number;
  prechodneObdobie: boolean;
  predbezny: number;
  stav: Nullable<string>;
}

export type TypCeny =
  | 'CenaBodu'
  | 'DodKap' // Dodatkova Kapitacia pri VAS
  | 'DoplnKap'
  | 'Vykon'
  | 'HodnPar'
  | 'PlneniePar'
  | 'HodnZisk'
  | 'LimitD'
  | 'LimitH'
  | 'NaklVykony'
  | 'NaklLieky'
  | 'NaklSVLZ'
  | 'NaklVPoiD'
  | 'NaklAvgInd'
  | 'NaklPocPoi'
  | 'AvgInd'
  | 'PocPoi'
  | 'KontPov'
  | 'KontVVyk'
  | 'KontVPoiN'
  | 'KontVPoiD'
  | 'KontPocPoi'
  | 'KontAvgInd'
  | 'NaklVYK'
  | 'NaklVYKV'
  | 'NaklVYKO'
  | 'NaklVYKS'
  | 'NaklLAB'
  | 'NaklLABV'
  | 'NaklLABO'
  | 'NaklLABS'
  | 'NaklLEK'
  | 'NaklLEKV'
  | 'NaklLEKO'
  | 'NaklLEKS'
  | 'NaklLAM'
  | 'NaklLAMV'
  | 'NaklLAMO'
  | 'NaklLAMS'
  | 'PlnKI'
  | 'PlnE'
  | 'PlnPouz'
  | 'PlnKIPouz'
  | 'PlnEPouz'
  | 'Vykon8'
  | 'Vykon4'
  | 'Vykon250'
  | 'Vykon1'
  | 'DodKapZakl'
  | 'KontSAS'
  | 'KontAPS'
  | 'KontUPS'
  | 'KontP'
  | 'PristrPOCT'
  | 'PristrABI'
  | 'NaklZS'
  | 'NaklP'
  | 'BodyVO'
  | 'BodySD'
  | 'BodySR'
  | 'BodySP'
  | 'BodyI'
  | 'PoiVO'
  | 'PoiSD'
  | 'PoiSR'
  | 'PoiSP'
  | 'OHA'
  | 'OHS'
  | 'VysUSG'
  | 'VysMMG'
  | 'DodKapPas1'
  | 'DodKapPas2'
  | 'DodKapPas3'
  | 'DodKapPas4'
  | 'PristrTKHO'
  | 'BodyVVH'
  | 'BodyVVR'
  | 'BodyVVZm'
  | 'BodyVLH'
  | 'BodyVLR'
  | 'BodyVLZm'
  | 'BodyH'
  | 'BodyR'
  | 'BodyZm'
  | 'CenaBoduD'
  | 'CenaBoduV'
  | 'DodKapD'
  | 'DodKapV'
  | 'ObjemD'
  | 'ObjemV'
  | 'PlnEAvg'
  | 'PlnKIAvg'
  | 'PlnParAvg'
  | 'PocPoiH'
  | 'PocPoiR'
  | 'PocPoiZm'
  | 'Vykon1D'
  | 'Vykon1V'
  | 'Vykon250D'
  | 'Vykon250V'
  | 'Vykon4D'
  | 'Vykon4V'
  | 'Vykon8D'
  | 'Vykon8V'
  | 'UvazokMax'
  | 'OH'; // ordinacne hodiny

export interface VysledokObdobia {
  cena: number;
  id?: number;
  idPzsOdbCyklus: number;
  kodParametra?: string | null;
  obdobiePlatnostDo: number | null;
  obdobiePlatnostOd: number;
  typCeny: string;
}

export type VysledkyObdobia = Record<TypCeny, VysledokObdobia[]>;

export type Navigacia = {
  href?: string;
  isActive?: boolean;
  isOpen?: boolean;
  items?: {
    href: string;
    isOpen: boolean;
    title: string;
  }[];
  title: string;
  withoutAngle?: boolean;
}[];

export type Obdobie = Cyklus & {
  navigacia: Navigacia;
  skupiny: SkupinyParametrov;
  typyCien: TypCeny[];
  vysledokObdobia?: VysledkyObdobia;
};

interface Poradie {
  aktualneId: number;
  aktualnePoradie: number;
  defaultPoradie: number;
  nasledujuceId: number;
  predchadzajuceId: number;
  pzsOdbCyklus: Cyklus[] | undefined;
}

export type ZoznamObdobi = Record<string, Obdobie> | {};

interface HodnotiaceParametreState {
  detailParametra: DetailHPState;
  obdobia: {
    error: string | null;
    isLoaded: boolean;
    isLoading: boolean;
    nacitatDalsie: boolean;
    ukonceneObdobie: boolean;
    zoznamObdobi: ZoznamObdobi;
  };
  poradie: Poradie;
  superDetailVypoctu: {
    kodParam: string;
    parameter: Nullable<Parameter>;
    typ: string;
    vysledokObdobia: Nullable<VysledkyObdobia>;
  };
}

interface HodnotiaceParametreAction {
  pzsOdbCyklus: Cyklus[];
  vysledokParametra: Parameter[];
  vysledokPzsOdb: VysledokObdobia[];
}

export const initialState: HodnotiaceParametreState = {
  detailParametra: {
    action: null,
    data: null,
    dataState: LoadingState.none,
    error: null,
    isEmptyState: false,
  },
  obdobia: {
    error: null,
    isLoaded: false,
    isLoading: false,
    nacitatDalsie: true,
    ukonceneObdobie: false,
    zoznamObdobi: {},
  },
  poradie: {
    aktualnePoradie: 0,
    aktualneId: 0,
    defaultPoradie: 0,
    nasledujuceId: 0,
    predchadzajuceId: 0,
    pzsOdbCyklus: [],
  },
  superDetailVypoctu: {
    kodParam: '',
    parameter: null,
    vysledokObdobia: null,
    typ: '',
  },
};

// rieši preskakovanie 0 poradia, ktoré sa nenachádza v dátach, lebo značí prázdny stav.
function modifikatorPoradia(poradie: number, typ?: 'predchadzajuce') {
  switch (poradie) {
    case 1:
      return typ === 'predchadzajuce' ? -2 : 1;
    case -1:
      return typ === 'predchadzajuce' ? -1 : 2;
    default:
      return typ === 'predchadzajuce' ? -1 : 1;
  }
}

// vracia obodbie `predchádzajúce` alebo `nasledujúce` vzhladom na obdobie na vstupe
function obdobie(
  aktualneObdobie: Obdobie | undefined,
  zoznamObdobi: ZoznamObdobi,
  typ?: 'predchadzajuce',
): Cyklus | undefined {
  return find(zoznamObdobi, (zaznam: Obdobie) =>
    aktualneObdobie
      ? zaznam.poradie ===
        aktualneObdobie.poradie +
          modifikatorPoradia(aktualneObdobie.poradie, typ)
      : false,
  );
}

// Na dashboarde je potrebné zobrazovať dáta pre tri obdobia
// táto funkcia vracia ID pre obodbie, ktoré sa aktuálne zobrazuje ako hlavne
// a vracia `predchadzajuce` a `nasledujuce` obdobia v poradi
function vypocitajObdobia(
  zoznamObdobi: ZoznamObdobi,
  buduce: boolean,
  poradie?: number,
): Record<string, number> {
  const buduceObdobie = find(
    zoznamObdobi,
    (zaznam: Obdobie) => zaznam.buduci === true,
  );
  let aktualneObdobie: Obdobie | undefined = find(
    zoznamObdobi,
    (zaznam: Obdobie) => poradie === zaznam.poradie,
  ) ||
    find(zoznamObdobi, (zaznam: Obdobie) => zaznam.buduci === true) ||
    find(zoznamObdobi, (zaznam: Obdobie) => zaznam.posledny === 1) || {
      buduci: false,
      id: 0,
      idCyklus: 0,
      navigacia: [],
      obdobieSledovaneDo: 0,
      obdobieVypocetDo: 0,
      obdobieVypocetOd: 0,
      popisEmptyState: '',
      popisObdobie: '',
      popisOutlier: '',
      poradie: 0,
      posledny: 0,
      prechodneObdobie: false,
      predbezny: 0,
      skupiny: {
        Inovácie: {
          nazov: '',
          parametre: [],
          plnenie: 0,
          poradie: 0,
          vaha: 0,
        },
      },
      stav: '',
      typyCien: [],
    };
  if (buduceObdobie && (buduce || aktualneObdobie?.buduci))
    aktualneObdobie = buduceObdobie;
  if (!aktualneObdobie || !aktualneObdobie.id)
    return {
      aktualneId: 0,
      aktualnePoradie: 0,
      predchadzajuceId: 0,
      nasledujuceId: 0,
    };
  if (
    aktualneObdobie.predbezny === 1 &&
    aktualneObdobie.poradie === 1 &&
    aktualneObdobie.posledny === 1
  )
    aktualneObdobie.poradie = objectLength(zoznamObdobi);
  const predchadzajuceObdobie = obdobie(
    aktualneObdobie,
    zoznamObdobi,
    'predchadzajuce',
  );
  const nasledujuceObdobie =
    (!buduceObdobie && aktualneObdobie.posledny) || aktualneObdobie.buduci
      ? undefined
      : obdobie(aktualneObdobie, zoznamObdobi);

  const aktualneId = aktualneObdobie.id || 0;
  const aktualnePoradie = aktualneObdobie!.poradie || 0;
  const defaultPoradie = aktualneObdobie.poradie || 0;
  const predchadzajuceId = predchadzajuceObdobie?.id || 0;
  const nasledujuceId = nasledujuceObdobie?.id || 0;
  return {
    aktualneId,
    aktualnePoradie,
    defaultPoradie,
    predchadzajuceId,
    nasledujuceId,
  };
}

const dashboardSlice = createSlice({
  name: 'hodnotiaceParametre',
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(fetchHPDetail.pending.toString(), (state) => ({
        ...state,
        detailParametra: {
          ...initialState.detailParametra,
          dataState: LoadingState.fetching,
        },
      }))
      .addCase(
        fetchHPDetail.fulfilled.toString(),
        (
          state,
          action: PayloadAction<DajDetailParametraHodnoteniaResponse>,
        ) => ({
          ...state,
          detailParametra: {
            ...state.detailParametra,
            data: action.payload,
            dataState: LoadingState.filled,
            error: null,
          },
        }),
      )
      .addCase(fetchHPDetail.rejected.toString(), (state) => ({
        ...state,
        detailParametra: {
          ...initialState.detailParametra,
          dataState: LoadingState.error,
          error: strings.technickaChyba,
        },
      }))
      .addCase(saveHPExchange.pending.toString(), (state) => ({
        ...state,
        detailParametra: {
          ...state.detailParametra,
          action: {
            dataState: LoadingState.fetching,
            error: null,
            success: null,
          },
        },
      }))
      .addCase(
        saveHPExchange.fulfilled.toString(),
        (state, action: PayloadAction<UlozParameterHodnoteniaResponse>) => ({
          ...state,
          detailParametra: {
            ...state.detailParametra,
            action: {
              dataState: LoadingState.filled,
              error: action.payload.chyba || null,
              success: action.payload.vysledok,
            },
          },
        }),
      )
      .addCase(saveHPExchange.rejected.toString(), (state) => ({
        ...state,
        detailParametra: {
          ...state.detailParametra,
          action: {
            dataState: LoadingState.error,
            error: strings.technickaChyba,
            success: false,
          },
        },
      }));
  },
  reducers: {
    dajHodnotiaceParametreStart(state) {
      state.obdobia.isLoading = true;
      state.obdobia.error = null;
      state.obdobia.zoznamObdobi =
        state.poradie.predchadzajuceId !== 0 ? [] : state.obdobia.zoznamObdobi;
    },
    dajHodnotiaceParametre(
      state,
      action: PayloadAction<HodnotiaceParametreAction>,
    ) {
      let { pzsOdbCyklus, vysledokParametra, vysledokPzsOdb } = action.payload;

      const futureIds = pzsOdbCyklus.filter((o) => o.buduci)?.map((o) => o.id);
      if (
        !!futureIds?.length &&
        !vysledokParametra.some(
          (p) =>
            futureIds.includes(p.idPzsOdbCyklus) && !!p.moznaVymenaZaParamId,
        )
      ) {
        pzsOdbCyklus = pzsOdbCyklus.filter((o) => !futureIds.includes(o.id));
        vysledokParametra = vysledokParametra.filter(
          (p) => !futureIds.includes(p.idPzsOdbCyklus),
        );
        vysledokPzsOdb = vysledokPzsOdb.filter(
          (v) => !futureIds.includes(v.idPzsOdbCyklus),
        );
      }

      // agregácia dát do `zoznamuObodbi` aby sa dali jednoduchšie použiť vrámci appky
      const zoznamObdobi = mapValues(
        groupBy(vysledokParametra, 'idPzsOdbCyklus'),
        (obdobia, id) => ({
          // spread dát z `pzsOdbCyklus` tabulky pre konkrétne obdobie
          ...pzsOdbCyklus.find((cyklus) => cyklus.id === Number(id)),

          // Generovanie navigacie pre kazdé obdobie zvlásť,
          // keďže zoznam hodnotiacich parametrov môže byť
          // pre každé obdobie v budúcnosti odlišný.
          // Vkladá sa do DDS komponentu `<Navigation />`
          navigacia: values(
            mapValues(
              groupBy(
                orderBy(obdobia, 'poradieSkupinyParametra'),
                'skupinaParametra',
              ),
              (skupina, nazov) => ({
                title: nazov,
                isOpen: true,
                isActive: true,
                // href: `/${getSlugSkupiny(nazov)}`,
                items: sortBy(skupina, 'poradieParametra', 'ASC').map(
                  (parameter) => ({
                    title: parameter.nazovParametra,
                    href: routes.dajParameter(
                      getSlugSkupiny(nazov),
                      parameter.kodParametra || '',
                    ),
                  }),
                ),
              }),
            ),
          ),

          typyCien: vypocetTypCeny(pzsOdbCyklus, Number(id), vysledokPzsOdb),

          // Priradenie záznamov z `vysledokPzsOdb` tabulky pre konkrétne obdobie.
          // `groupBy` vráti správny zoznam objektov ale v poli. Toto to agreguje do objektov.
          // Dalo by sa to možno riesiť cez lodash/fp alebo compose/flow/pipe
          vysledokObdobia: mapValues(
            groupBy(
              filter(vysledokPzsOdb, {
                idPzsOdbCyklus: Number(id),
              }),
              'typCeny',
            ),
            (typCeny) => typCeny,
          ),

          // zgrupenie parametrov z tabulky `vysledokParametra` podla názvu skupiny.
          skupiny: mapValues(
            groupBy(
              orderBy(obdobia, 'poradieSkupinyParametra'),
              'skupinaParametra',
            ),
            (skupina, key) => {
              const vaha = sumBy(skupina, (parameter) => parameter.vaha);
              const plnenie = sumBy(
                skupina,
                (parameter) => parameter.plnenie * parameter.vaha,
              );
              // Pre kazdu skupinu je potrebné mať aj poradie ale to sa nachádza v kazdom hodnotiacom parametri ako `poradieSkupinyParametra`
              // tu sa `poradie` vkladá aj do skupiny
              const poradie = skupina[0]?.poradieSkupinyParametra;

              return {
                vaha: sumBy(skupina, (parameter) => parameter.vaha),
                plnenie: plnenie / vaha, // vypocet plnenia skupiny, ktorý neprichádza z backendu
                nazov: key,
                parametre: sortBy(skupina, 'poradieParametra'),
                poradie,
              };
            },
          ),
        }),
      );

      // zabezpečuje ze nasledovný kód sa spustí iba pri prvom api requeste
      // Ide o to aby sa pri načítaní dalsej dávky dát z backendu,
      // nevyresetovalo poradie obdobi.
      if (
        pzsOdbCyklus.find(
          (cyklus) =>
            cyklus.poradie === 1 || cyklus.poradie === 2 || cyklus.posledny,
        )
      ) {
        const {
          aktualneId,
          aktualnePoradie,
          defaultPoradie,
          nasledujuceId,
          predchadzajuceId,
        } = vypocitajObdobia(zoznamObdobi, true);

        state.poradie.aktualneId = aktualneId;
        state.poradie.aktualnePoradie = aktualnePoradie;
        state.poradie.defaultPoradie = defaultPoradie;
        state.poradie.predchadzajuceId = predchadzajuceId;
        state.poradie.nasledujuceId = nasledujuceId;
      }

      // Prevencia pred zbytočným fetchovaním dát, ak už backend žiadne neposkytuje.
      state.obdobia.nacitatDalsie = pzsOdbCyklus.length > 4;

      if (objectLength(zoznamObdobi) > 0) state.obdobia.zoznamObdobi = [];
      state.obdobia.zoznamObdobi = {
        ...state.obdobia.zoznamObdobi,
        ...zoznamObdobi,
      };

      state.obdobia.isLoading = false;
      state.obdobia.isLoaded = true;
    },
    dajHodnotiaceParametreChyba(state, action: PayloadAction<any>) {
      state.obdobia.isLoading = false;
      state.obdobia.error = action.payload;
    },
    dajObdobie(
      state,
      action: PayloadAction<{
        datNasledujuce?: boolean;
        poradie: number | undefined;
      }>,
    ) {
      const { datNasledujuce, poradie } = action.payload;

      const { aktualneId, aktualnePoradie, nasledujuceId, predchadzajuceId } =
        vypocitajObdobia(
          state.obdobia.zoznamObdobi,
          false,
          // eslint-disable-next-line no-nested-ternary
          poradie === 0
            ? datNasledujuce
              ? poradie + 1
              : poradie - 1
            : poradie, // rieši preskakovanie 0 poradia, ktoré sa nenachádza v dátach, lebo značí prázdny stav.
        );

      state.poradie.aktualneId = aktualneId;
      state.poradie.aktualnePoradie = aktualnePoradie;
      state.poradie.predchadzajuceId = predchadzajuceId;
      state.poradie.nasledujuceId = nasledujuceId;
    },
    dajObdobiePodlaId(state, action: PayloadAction<{ idObdobie: number }>) {
      const obdobie =
        state.obdobia.zoznamObdobi?.[action.payload.idObdobie] || null;
      if (obdobie) {
        const idObdobi = arraySort(
          Object.values(state.obdobia.zoznamObdobi),
          'poradie',
        ).map((o) => o.id);
        state.poradie.aktualneId = obdobie.id;
        state.poradie.aktualnePoradie = obdobie.poradie;
        state.poradie.nasledujuceId =
          idObdobi[idObdobi.indexOf(obdobie.id) + 1] || 0;
        state.poradie.predchadzajuceId =
          idObdobi[idObdobi.indexOf(obdobie.id) - 1] || 0;
      }
    },
    dajBuduceObdobie(state) {
      if (state.obdobia.zoznamObdobi[state.poradie.aktualneId]?.buduci)
        return state;
      const buduciCyklus: Cyklus | undefined = find(
        state.obdobia.zoznamObdobi,
        (zaznam: Obdobie) => zaznam.buduci === true,
      );
      if (!buduciCyklus) return state;
      const { poradie } = buduciCyklus;
      const { aktualneId, aktualnePoradie, nasledujuceId, predchadzajuceId } =
        vypocitajObdobia(state.obdobia.zoznamObdobi, false, poradie);
      return {
        ...state,
        poradie: {
          ...state.poradie,
          aktualneId,
          aktualnePoradie,
          nasledujuceId,
          predchadzajuceId,
        },
      };
    },
    zobrazSuperdetailVypoctu(
      state,
      action: PayloadAction<{
        kodParam: string;
        parameter: Parameter;
        typ: string;
        vysledokObdobia: VysledkyObdobia;
      }>,
    ) {
      state.superDetailVypoctu = {
        ...state.superDetailVypoctu,
        kodParam: action.payload.kodParam,
        parameter: action.payload.parameter,
        vysledokObdobia: action.payload.vysledokObdobia,
        typ: action.payload.typ,
      };
    },
    zobrazUkonceneObdobie(state, action: PayloadAction<boolean>) {
      return {
        ...state,
        obdobia: {
          ...state.obdobia,
          ukonceneObdobie: action.payload,
        },
      };
    },
    setHPLoading(state) {
      return {
        ...state,
        obdobia: {
          ...state.obdobia,
          isLoading: true,
        },
      };
    },
    resetHP(state) {
      return {
        ...initialState,
        obdobia: {
          ...initialState.obdobia,
          isLoading: state.obdobia.isLoading,
        },
      };
    },
    resetHPDetail(state) {
      return {
        ...state,
        detailParametra: initialState.detailParametra,
      };
    },
    setDetailEmptyState(
      state,
      action: PayloadAction<{ isEmptyState: boolean }>,
    ) {
      return {
        ...state,
        detailParametra: {
          ...state.detailParametra,
          isEmptyState: action.payload.isEmptyState,
        },
      };
    },
  },
});

export const {
  dajBuduceObdobie,
  dajHodnotiaceParametre,
  dajHodnotiaceParametreChyba,
  dajHodnotiaceParametreStart,
  dajObdobie,
  dajObdobiePodlaId,
  resetHP,
  resetHPDetail,
  setDetailEmptyState,
  setHPLoading,
  zobrazSuperdetailVypoctu,
  zobrazUkonceneObdobie,
} = dashboardSlice.actions;
export default dashboardSlice.reducer;

export const fetchHodnotiacePrametre =
  (
    idHodnotenyPzs: number,
    idVZ: number,
    kodOdbAmb: string,
    kodTypZS: string,
    poradie?: number,
  ): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(dajHodnotiaceParametreStart());
      const parametre = await dajHodnotiaceParametreApi(
        idHodnotenyPzs,
        idVZ,
        kodOdbAmb,
        kodTypZS,
        poradie,
      );
      dispatch(dajHodnotiaceParametre(parametre));
    } catch (err) {
      dispatch(dajHodnotiaceParametreChyba(err));
    }
  };

export const fetchHodnotiacePrametrePublic =
  (token: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(dajHodnotiaceParametreStart());
      // @ts-ignore
      window.publicGuid = token;
      const data = await dajHodnotiaceParametrePublicApi(token);
      dispatch(
        dajHodnotiaceParametre({
          pzsOdbCyklus: data?.pzsOdbCyklus,
          vysledokParametra: data?.vysledokParametra,
          vysledokPzsOdb: data?.vysledokPzsOdb,
        }),
      );
      dispatch(publicOdbornost(data?.odbornost));
    } catch (err) {
      dispatch(dajHodnotiaceParametreChyba(err));
    }
  };
