import { useCallback, useEffect, useMemo, useState } from 'react';
import useStylesApp from '../../../App.styles';
import useStyles from '../Proposals.styles';
import { Button, Container, Icon, Notification } from '@dovera/design-system';
import { scrollToTop, userHasSection } from '../../../utils/app.utils';
import routes from '../../../routes';
import ContentHeader from '../../../components/ContentHeader/ContentHeader';
import Breadcrumbs from '../../../components/Breadcrumbs/Breadcrumbs';
import StaticMenuLayout from '../../../layouts/StaticMenuLayout';
import { cx } from '../../../utils/exports';
import { RootState } from '../../../rootReducer';
import Filter from './Filter/Filter';
import { getApprovedProposals } from '../../../api/spaProposals';
import TableList from './TableList/TableList';
import strings from '../../../constants/strings';
import { ApprovedProposalsFilter } from '../../../types/spaProposals.types';
import { NoDataIllustration } from '../../../components/EmptyState';
import ReservationCodeModal from '../Modals/ReservationCodeModal';
import ReservationDateModal from '../Modals/ReservationDateModal';
import { Toast } from '../../../components';
import { useQuery } from '../../../hooks/useQuery';
import ProposalDetail from '../ProposalDetail/ProposalDetail';
import TablePreloaderSkeleton from '../../../components/TablePreloaderSkeleton/TablePreloaderSkeleton';
import { useAppDispatch } from '../../../hooks/useStore';
import { useNavigate } from 'react-router';
import { useSelector } from 'react-redux';
import { setListFilter } from '../../../slices/spaProposals.slice';
import { resetTableOrder } from '../../../slices/table.slice';
import { getMoment } from '../../../utils/date.utils';

const texts = strings.proposals.list.approvedProposals;
const { emptyState } = strings.proposals.list;

const ApprovedProposals = () => {
  const dispatch = useAppDispatch();
  const classesApp = useStylesApp();
  const classes = useStyles();
  const query = useQuery();
  const navigate = useNavigate();
  const {
    approvedProposals: { data, error },
    isLoading,
  } = useSelector((state: RootState) => state.spaProposals.list);
  const [filtered, setFiltered] = useState<any>(data);
  const [isVisibleReservationModal, setIsVisibleReservationModal] =
    useState(false);
  const [isVisibleReservationDateModal, setIsVisibleReservationDateModal] =
    useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const [propososalModalId, setProposalModalId] = useState(0);
  const [isFilterChanged, setIsFilterChanged] = useState(false);
  useEffect(() => {
    if (!userHasSection('NKUP')) {
      navigate(routes.dashboard);
    } else if (!isLoading && !data?.length) dispatch(getApprovedProposals());
    // eslint-disable-next-line
  }, [dispatch]);
  useEffect(() => {
    if (data && data.length > 0) {
      setFiltered(data);
      scrollToTop();
    }
    setIsFilterChanged(false);
  }, [data]);
  const onFiltered = useCallback(
    (values: ApprovedProposalsFilter, showAll: boolean) => {
      if (!isLoading && data.length) {
        setFiltered(
          data.filter(
            (d) =>
              (!values.insuree ||
                `${d.poistenec.meno.toLowerCase()} ${d.poistenec.priezvisko.toLowerCase()} ${
                  d.poistenec.rodneCislo
                }`.includes(values.insuree.toLowerCase())) &&
              (!values.reservationCode ||
                d.rezervacia.pin
                  ?.toLowerCase()
                  ?.includes(values.reservationCode.toLowerCase())) &&
              (!values.validityFrom ||
                getMoment(values.validityFrom).isSameOrBefore(
                  getMoment(d.platnostNavrhuOd),
                )) &&
              (!values.validityTo ||
                getMoment(values.validityTo).isSameOrAfter(
                  getMoment(d.platnostNavrhuDo),
                )) &&
              (!values.proposalNumber ||
                d.cisloNavrhu
                  ?.toLowerCase()
                  ?.includes(values.proposalNumber.toLowerCase())) &&
              (!values.spaTreatment ||
                values.spaTreatment.includes('all') ||
                values.spaTreatment
                  .toLocaleLowerCase()
                  .includes(d.kupelnaLiecba?.toLowerCase())) &&
              (!values.insureeVZ ||
                values.insureeVZ.includes('all') ||
                values.insureeVZ.includes(d.poistenec.stav)),
          ),
        );
        setIsFilterChanged(true);
        dispatch(
          setListFilter({ values: { ...values, showAllFilters: showAll } }),
        );
        dispatch(resetTableOrder());
      }
    },
    [isLoading, data, dispatch],
  );
  const renderHeader = (
    <ContentHeader padBottom={false}>
      <Container>
        <Breadcrumbs
          items={[
            {
              name: 'Návrhy',
              route: '#',
            },
            {
              name: texts.title,
            },
          ]}
        />
      </Container>
      <h2 className="mb">{texts.title}</h2>
      <div className={classesApp.transparentNotification}>
        <Notification message={texts.infoReservation} variant="info" />
      </div>

      <Button
        isDisabled={isLoading}
        onClick={() => setIsVisibleReservationModal(true)}
      >
        <Icon color="white" name="16-plus" />
        <span className="text-space-half-left">
          {texts.labels.reservationCode}
        </span>
      </Button>
    </ContentHeader>
  );
  const renderTable = useMemo(() => {
    if (!isLoading && filtered.length > 0 && !error)
      return (
        <TableList
          data={filtered.map((d) => ({
            doctor: {
              code: d.lekar.kod,
              firstName: d.lekar.meno,
              lastName: d.lekar.priezvisko,
              titleAfter: d.lekar.titulZa,
              titleBefore: d.lekar.titulPred,
            },
            id: d.idNavrhNaZS,
            insuree: {
              insureeId: d.poistenec.rodneCislo,
              firstName: d.poistenec.meno,
              lastName: d.poistenec.priezvisko,
              state: d.poistenec.stav,
            },
            proposalNumber: d.cisloNavrhu,
            proposalType: d.typ,
            reservation: d.rezervacia.datumOd
              ? {
                  from: d.rezervacia.datumOd,
                  to: d.rezervacia.datumDo,
                }
              : null,
            validity: {
              from: d.platnostNavrhuOd,
              to: d.platnostNavrhuDo,
            },
          }))}
          onChooseDateClick={(id) => {
            setProposalModalId(id);
            setTimeout(() => {
              setIsVisibleReservationDateModal(true);
            }, 50);
          }}
        />
      );
    return <span />;
  }, [error, filtered, isLoading]);
  const renderEmptyState = (!data ||
    (data && filtered.length === 0 && isFilterChanged)) && (
    <NoDataIllustration title={emptyState} />
  );
  if (query.get('id') && query.get('cisloNavrhu'))
    return (
      <ProposalDetail
        id={Number(query.get('id'))}
        pin={query.get('pin')}
        proposalNumber={query.get('cisloNavrhu') || ''}
      />
    );
  return (
    <StaticMenuLayout
      backgroundWhite
      contentHeader={renderHeader}
      withoutPadding
    >
      <div className={cx(classesApp.pageLayout, classes.section)}>
        {isLoading && <TablePreloaderSkeleton columns={6} hasFilter />}
        {error && <Notification message={error} variant="error" />}
        {!isLoading && !error && (
          <>
            <Filter onFiltered={onFiltered} />
            {renderTable}
            {renderEmptyState}
            <ReservationCodeModal
              isVisible={isVisibleReservationModal}
              onHide={() => setIsVisibleReservationModal(false)}
            />
            <ReservationDateModal
              id={propososalModalId}
              isVisible={isVisibleReservationDateModal}
              onHide={(reload: boolean, toastMessage: string) => {
                if (reload) dispatch(getApprovedProposals());
                if (toastMessage) {
                  setToastMessage(toastMessage);
                  setTimeout(() => {
                    setToastMessage('');
                    dispatch(getApprovedProposals());
                  }, 3000);
                }
                setIsVisibleReservationDateModal(false);
              }}
            />
            {toastMessage && (
              <Toast
                iconType={'success'}
                show={!!toastMessage}
                text={toastMessage}
              />
            )}
          </>
        )}
      </div>
    </StaticMenuLayout>
  );
};

export default ApprovedProposals;
