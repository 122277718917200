import { createUseStyles } from 'react-jss';

import { color, fontWeight, spaces } from '../../constants';
import { isIE } from '../../utils/app.utils';

interface StylesProps {
  exchangeableParam?: boolean;
  isFuture?: boolean;
  plnenie: number | boolean;
  predbezny: boolean;
}

const getColor = ({
  exchangeableParam,
  isFuture,
  plnenie,
  predbezny,
}: StylesProps): string => {
  if (Number(plnenie) > 0 && Number(plnenie) < 1 && !exchangeableParam)
    return color('warning');
  if (predbezny && !plnenie) return color('black');
  if (
    plnenie ||
    (Number(plnenie) > 0 && Number(plnenie) < 1 && exchangeableParam)
  )
    return color('primary');
  return !plnenie && !predbezny && !isFuture
    ? color('error')
    : color('secondary', 500);
};

export default createUseStyles({
  row: {
    marginBottom: 0,
    '& a': {
      display: 'block',
      padding: '0.5rem 1rem',
      borderRadius: 8,
      textDecoration: 'none',
      '-ms-word-break': 'keep-all',
      '&:hover': {
        background: 'rgba(221, 237, 210, 0.5)',
        textDecoration: 'none',
      },
    },
  },
  icon: {
    marginRight: `${spaces.xxsmall}px !important`,
    '& svg': {
      marginTop: 3,
    },
  },
  hodnota: ({
    exchangeableParam,
    isFuture,
    plnenie,
    predbezny,
  }: StylesProps) => ({
    color: getColor({ exchangeableParam, isFuture, plnenie, predbezny }),
    fontSize: '1.125rem',
    fontWeight: fontWeight.bold,
  }),
  nameBarItem: {
    display: isIE ? 'inline-block' : 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start !important',
    textAlign: 'left',
  },
  valueBarItem: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end !important',
  },
});
