import axios, { AxiosResponse } from 'axios';
import { AuthService } from '../services/AuthService';
import store from '../store';
import { LogData } from '../types/models/LogData';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { setApiResponseError, setSystemOffline } from '../slices/app.slice';
import { GetSystemNotificationsResponse } from '../types/api';

const authService = new AuthService();

/* Add logging via interceptor for all requests */
axios.interceptors.request.use(
  (request) => {
    const logData: LogData = {
      ip: store?.getState()?.app?.ip,
      userAgent: navigator?.userAgent,
    };
    // @ts-ignore Add IP address to request header
    request.headers = { ...request.headers, 'Client-IP': logData.ip };
    if (request.method !== 'get' && store) {
      const idPouzivatel =
        request.data?.idPouzivatela ||
        request.data?.idPouzivatel ||
        store.getState().auth.pouzivatelId;
      const idVZ = store.getState().auth.vztahId;
      request.data = {
        ...request.data,
        ...{ logData },
        idPouzivatel,
        idVZ,
        isAsoc:
          request.data?.isASOC && !request.data?.isAsoc
            ? undefined
            : store.getState()?.auth?.isASOC,
      };
    }
    return authService
      .getUser()
      .then((user) => {
        if (user) {
          if (user.access_token && request.headers) {
            request.headers.Authorization = `Bearer ${user.access_token}`;
            return request;
          }
          authService.renewToken().then((renewedUser) => {
            if (request.headers)
              request.headers.Authorization = `Bearer ${renewedUser.access_token}`;
            return request;
          });
        }
        return request;
      })
      .catch((e) => {
        // eslint-disable-next-line no-console
        console.error('get token failured');
        // eslint-disable-next-line no-console
        console.error(e);
        return request;
      });
  },
  (error) => Promise.reject(error),
);

axios.interceptors.response.use(
  (response) => response,
  /**
   * Handle ERROR API responses
   *
   * ERROR 401 - try to renew jwt token and recall failed request
   * @date 23. 3. 2023 - 12:57:50
   *
   * @param {*} error
   * @returns {*}
   */
  (error) => {
    // handle custom timeout
    if (error.name === 'AxiosError' && error.message.includes('timeout')) {
      setTimeout(() => store.dispatch(setSystemOffline()), 100);
      return Promise.reject(error);
    }
    let request: any = {};
    request = error?.response?.config || {};
    if (store)
      store.dispatch(
        setApiResponseError({
          code: error?.response?.status || 200,
          endpoint: request?.url || '',
        }),
      );
    // if (error?.response?.status === 401) {
    //   authService.renewToken().then((renewedUser) => {
    //     request.headers.Authorization = `Bearer ${renewedUser.access_token}`;
    //     if (request.data) request.data = JSON.parse(request.data);
    //     return axios.request(request);
    //   });
    // }
    return Promise.reject(error);
  },
);

export async function dajHodnotiaceParametreApi(
  idHodnotenyPzs: number,
  idVZ: number,
  kodOdbAmb: string,
  kodTypZS: string,
  poradie?: number,
) {
  const url = `${window.env?.config?.apiPath}/HodnotiaceParametre/DajHodnotiaceParametre`;

  const { data } = await axios.post(url, {
    idHodnotenyPzs,
    kodOdbAmb,
    kodTypZS,
    poradie,
  });

  return data;
}

export async function dajHodnotiaceParametrePublicApi(token: string) {
  const url = `${window.env.config.apiPath}/HodnotiaceParametre/DajHodnotiaceParametrePublic`;
  const { data } = await axios.post(url, { token });
  return data;
}

export async function dajVerzieAplikacieApi() {
  const url = `${window.env?.config?.apiPath}/Version/Full`;
  const { data } = await axios.get(url);
  return data;
}

export async function logPEKAPI(idVZ, idPouzivatel) {
  const url = `${window.env?.config?.apiPath}/CMS/LogPEK`;
  const { data } = await axios.post(url, { idVZ, idPouzivatel });
  return data;
}

export const checkSystemsStatus = createAsyncThunk(
  'app/checkSystemsStatus',
  async (payload: { systemy: ('TXS' | 'MD' | 'EP' | 'DMS' | string)[] }) => {
    const mocked = false;
    if (mocked && window.env.ENV === 'VYV') {
      return {
        systemy: [
          { name: 'TXS', online: true },
          { name: 'EP', online: true },
        ],
      };
    }

    const url = `${window.env?.config?.apiPath}/Version/DajStavSystemov`;
    const {
      data,
    }: AxiosResponse<{ systemy: { name: string; online: boolean }[] }> =
      await axios.post(url, payload, { timeout: 20000 });

    return data;
  },
);

export const getSystemNotifications = createAsyncThunk(
  'app/systemNotifications',
  async () => {
    const { data }: AxiosResponse<GetSystemNotificationsResponse> =
      await axios.get(
        `${window.env?.config?.apiPath}/Version/DajSystemoveOznamy`,
      );
    return data;
  },
);
