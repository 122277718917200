import React from 'react';

const IconErrorInline = ({
  height,
  width,
  ...other
}: React.JSX.IntrinsicElements['svg']) => (
  <svg
    fill="none"
    height={height || 16}
    viewBox={`0 0 ${width} ${height}`}
    width={width || 16}
    xmlns="http://www.w3.org/2000/svg"
    {...other}
  >
    <path
      d="M0 8C0 12.4183 3.58172 16 8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8Z"
      fill="#E91C0D"
    />
    <path
      d="M5 5L11 11"
      stroke="white"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    />
    <path
      d="M11 5L5 11"
      stroke="white"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    />
  </svg>
);

export default IconErrorInline;
