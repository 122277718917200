import { Icon } from '@dovera/design-system';

import useStyles from './SubjektivnaSpokojnostTooltip.styles';

import { CustomTooltip } from '../CustomTooltip';
import { getCMSText } from '../../utils/cms.utils';
import { cmsPath } from '../../constants/cmsPath';

const SubjektivnaSpokojnostTooltip = () => {
  const classes = useStyles();

  return (
    <CustomTooltip
      dialog={getCMSText(
        cmsPath.HP.Kvalita['subj-spok'].tooltipy.prehlad,
        '',
        false,
        false,
        '',
        true,
      )}
      id="tooltip-SUBJ"
    >
      <Icon
        className={classes.tooltipIcon}
        id={`icon-info-tooltip-SUBJ`}
        name="info"
        size="small"
      />
    </CustomTooltip>
  );
};

export default SubjektivnaSpokojnostTooltip;
