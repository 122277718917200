import React from 'react';

const IconBulb = ({ ...other }: React.JSX.IntrinsicElements['svg']) => (
  <svg
    fill="none"
    height="32"
    viewBox="0 0 32 32"
    width="32"
    xmlns="http://www.w3.org/2000/svg"
    {...other}
  >
    <path
      clipRule="evenodd"
      d="M8 13.0274C8 8.59397 11.594 5 16.0274 5C20.4607 5 24.0547 8.59397 24.0547 13.0274C24.0547 15.2956 23.1127 17.3455 21.6012 18.8042C21.2791 19.115 21.0471 19.5817 20.9228 20.1377C20.7686 20.8276 20.4698 21.4852 20.041 22.058V23.0616C20.041 24.4493 19.102 25.6177 17.8246 25.9661C17.7283 26.159 17.6013 26.3353 17.4484 26.4882C17.262 26.6746 17.0408 26.8224 16.7973 26.9233C16.5539 27.0241 16.2929 27.076 16.0293 27.076C15.7658 27.076 15.5048 27.0241 15.2614 26.9233C15.0179 26.8224 14.7966 26.6746 14.6103 26.4882C14.4577 26.3357 14.331 26.1597 14.2347 25.9673C12.955 25.6205 12.0137 24.451 12.0137 23.0616V22.0579C11.5853 21.4851 11.287 20.8271 11.1326 20.1391C11.0077 19.5823 10.7753 19.1148 10.4529 18.8036C8.94177 17.3449 8 15.2953 8 13.0274ZM16.0274 7.00684C12.7023 7.00684 10.0068 9.70232 10.0068 13.0274C10.0068 14.7286 10.7111 16.2635 11.8467 17.3597C12.5331 18.0222 12.9088 18.8885 13.0908 19.6998C13.1175 19.8189 13.1513 19.9362 13.1918 20.0509H15.0259V14.0325L14.0186 14.0316C13.4644 14.0316 13.0152 13.5824 13.0152 13.0282C13.0152 12.474 13.4644 12.0248 14.0186 12.0248H18.0323C18.5864 12.0248 19.0357 12.474 19.0357 13.0282C19.0357 13.5824 18.5864 14.0316 18.0323 14.0316H17.0328V20.0509H18.8635C18.904 19.9362 18.9377 19.819 18.9643 19.6999C19.1458 18.8883 19.5213 18.0224 20.2076 17.3601C21.3434 16.264 22.0479 14.7288 22.0479 13.0274C22.0479 9.70232 19.3524 7.00684 16.0274 7.00684ZM15.9642 22.0578C15.9857 22.0591 16.0075 22.0598 16.0294 22.0598C16.0513 22.0598 16.073 22.0591 16.0946 22.0578H18.0342V23.0616C18.0342 23.6157 17.585 24.065 17.0308 24.065H15.0239C14.4698 24.065 14.0205 23.6157 14.0205 23.0616V22.0578H15.9642Z"
      fill="white"
      fillRule="evenodd"
    />
  </svg>
);

export default IconBulb;
