import { Bar, BarItem } from '@dovera/design-system';
import cx from 'classnames';
import useStyles from './PriceBox.styles';

// eslint-disable-next-line
import { Hodnotenie } from '../../types/index';
import { CustomTooltip } from '../CustomTooltip';
import IconHelp from '../CustomIcons/IconHelp';

type Props = {
  align?: 'right';
  id: string;
} & Hodnotenie;

const PriceBox = ({ align, className, hodnota, id, popis, tooltip }: Props) => {
  const classes = useStyles();
  return (
    <CustomTooltip dialog={tooltip} id={`tooltip-${id}`}>
      <div
        className={cx(
          align === 'right' && 'text-right',
          classes.box,
          className,
          'clickable',
        )}
        id={id}
      >
        <span>{popis}</span>
        <Bar
          className={cx('no-mrg-bottom', {
            'align-items-right': align === 'right',
          })}
          space="small"
        >
          <BarItem className={cx(align === 'right' && 'text-right')}>
            <h3 className="h4">{hodnota}</h3>
          </BarItem>
          <BarItem>
            <IconHelp id={`icon-help-tooltip--${id}`} />
          </BarItem>
        </Bar>
      </div>
    </CustomTooltip>
  );
};

export default PriceBox;
