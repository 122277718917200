import { breakpoints, color, getRem } from '@dovera/design-system';
import { createUseStyles } from 'react-jss';

export default createUseStyles({
  layout: {
    position: 'relative',
    '& .grid__col': {
      padding: 0,
      justifyContent: 'center',
      [`@media screen and (max-width: 1400px)`]: {
        '&:first-child': {
          paddingLeft: getRem(32),
        },
        '&:last-child': {
          paddingRight: getRem(32),
        },
      },
    },
  },
  secondaryGraph: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'center',
    '& > div': {
      transition: 'all 0.2s ease',
      '& .recharts-wrapper': {
        paddingTop: getRem(48),
        maxWidth: '100% !important',
        maxHeight: '100% !important',
        [`@media screen and (max-width: 1500px)`]: {
          paddingTop: getRem(56),
        },
      },
      '& > svg': {
        padding: `${getRem(4)} ${getRem(16)} 0`,
        height: 'auto',
        position: 'absolute',
        bottom: getRem(-10),
        right: getRem(12),
        [`@media screen and (max-width: 1600px)`]: {
          position: 'relative',
          bottom: 'auto',
        },
        [`@media screen and (max-width: 1400px)`]: {
          bottom: getRem(16),
        },
        [`@media screen and (max-width: 1240px)`]: {
          bottom: getRem(32),
        },
      },
    },
  },
  hoverSecondaryGraph: {
    '& > div': {
      opacity: 0.9,
    },
  },
  secondaryLabel: {
    '& > div > div, & > div > span': {
      // transition: 'all 0.2s ease',
      '& svg': {
        '&:not(:first-child)': {
          // transition: 'all 0.2s ease',
        },
      },
    },
  },
  hoverSecondaryLabel: {
    '& > div > div, & > div > span': {
      color: color('primary', 500),
      '& svg': {
        color: `${color('primary', 500)} !important`,
        '& path': {
          fill: `${color('primary', 500)} !important`,
        },
      },
    },
  },
  buttonArrow: {
    marginBottom: 0,
    '& svg': {
      verticalAlign: 'middle',
    },
  },
  graphSection: {
    '@media (min-width: 1400px)': {
      paddingLeft: '4rem',
      paddingRight: '4rem',
    },
  },
  hpDashboardSectionWrapper: {
    marginTop: '0 !important',
    '& .container': {
      [`@media screen and (max-width: ${breakpoints.s}px)`]: {
        padding: `0 ${getRem(16)} !important`,
      },
    },
  },
  showEvaluatedParams: {
    marginTop: getRem(-24),
  },
  futureNotification: {
    display: 'flex',
    justifyContent: 'center',
    '& .notification': {
      width: 'fit-content',
      marginBottom: getRem(24),
    },
  },
  btnAbsolute: {
    position: 'absolute',
    right: 0,
    top: getRem(24),
    paddingTop: 0,
    paddingRight: 0,
    margin: 0,
    display: 'flex',
    alignItems: 'center',
    '& svg': {
      marginLeft: getRem(12),
    },
    '& .loader': {
      marginLeft: getRem(20),
      marginRight: 0,
    },
    '&.btn--disabled': {
      color: '#a7a9ac !important',
    },
    '&:not(.btn--link)': {
      position: 'relative',
      top: 0,
      padding: '0.6875rem 1.4375rem',
    },
    '&:focus': {
      '&.btn--disabled': {
        color: '#a7a9ac !important',
      },
    },
    '@media (max-width: 1200px)': {
      top: getRem(-40),
    },
    [`@media screen and (max-width: ${breakpoints.m}px)`]: {
      top: '0 !important',
      position: 'relative',
      padding: 0,
    },
  },
});
