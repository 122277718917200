import React, { useMemo, useState } from 'react';
import { Card, CardSection, Modal } from '@dovera/design-system';

import {
  Parameter,
  SkupinaParametrov,
  VysledkyObdobia,
} from '../../slices/hodnotiaceParametre.slice';

import CategoryCardRow from './CategoryCardRow';
import CategoryCardExtra from './CategoryCardExtra';
import { text } from '../../utils/strings.utils';
import { sendDataLayer } from '../../utils/gtm.utils';
import { CategoryCardExchange } from '.';
import OverlayContent from '../OverlayContent/OverlayContent';

type CategoryCardType = {
  href?: string;
  isFuture?: boolean;
  predbezny?: boolean;
  skupina?: SkupinaParametrov;
  special?: {
    DodKapZakl: number;
    GYN: boolean;
    vysledokObdobia: VysledkyObdobia;
  };
} & React.JSX.IntrinsicElements['div'];

function getModalContent(
  skupina: SkupinaParametrov,
  nazovParametraState: string,
): Parameter {
  return skupina?.parametre.filter(
    (p) => p.nazovParametra === nazovParametraState,
  )[0];
}

const CategoryCard = ({
  href,
  isFuture,
  predbezny,
  skupina,
  special,
}: CategoryCardType) => {
  const [nazovParametraState, setNazovParametraState] = useState('');
  const [isVisibleModal, setIsVisibleModal] = useState(false);
  const modalContent = useMemo(
    () => skupina && getModalContent(skupina, nazovParametraState),
    [nazovParametraState, skupina],
  );
  return (
    <Card>
      <CardSection className="no-pad" isCondensed>
        <OverlayContent isOverlayed={isFuture}>
          <CategoryCardExtra
            GYN={special && special.GYN}
            vysledokObdobia={special && special.vysledokObdobia}
          />
        </OverlayContent>

        {skupina?.parametre?.some((s) => s.moznaVymenaZaParamId) && (
          <CategoryCardExchange
            href={href}
            parameters={
              skupina?.parametre?.filter((s) => s.moznaVymenaZaParamId) || []
            }
          />
        )}
        {skupina?.parametre
          .filter((s) => !s.moznaVymenaZaParamId)
          .map(
            ({
              hodnota,
              hranicnaHodnotaMax,
              hranicnaHodnotaMin,
              kodParametra,
              nazovParametra,
              pasmo,
              plnenie,
              popisParametra,
              typHodnoty,
            }: Parameter) => (
              <OverlayContent
                key={`${nazovParametra}--overlay`}
                isOverlayed={isFuture}
              >
                <CategoryCardRow
                  key={nazovParametra}
                  hodnota={hodnota}
                  hranicnaHodnotaMax={hranicnaHodnotaMax}
                  hranicnaHodnotaMin={hranicnaHodnotaMin}
                  href={href}
                  isFuture={isFuture}
                  kodParametra={kodParametra || ''}
                  modalContent={special && special.GYN}
                  nazovParametra={nazovParametra}
                  pasmo={pasmo}
                  plnenie={plnenie}
                  popisParametra={popisParametra}
                  predbezny={predbezny || false}
                  showModalContent={(nazov) => {
                    setNazovParametraState(nazov);
                    setIsVisibleModal(true);
                    sendDataLayer('modal', {
                      title: nazov,
                    });
                  }}
                  typHodnoty={typHodnoty}
                />
              </OverlayContent>
            ),
          )}
      </CardSection>

      {special && special.GYN && (
        <Modal
          closeOnOverlayClick={false}
          data-modal-initial-focus
          header={modalContent?.nazovParametra}
          id={`dashboard-content-modal${special?.DodKapZakl}`}
          isVisible={isVisibleModal}
          onHide={() => setIsVisibleModal(false)}
        >
          <p className="no-mrg-bottom">
            {modalContent &&
              text(
                `hp.detailParametra.parametre.${modalContent.kodParametra}.popis`,
              )}
          </p>
        </Modal>
      )}
    </Card>
  );
};

export default CategoryCard;
