import { Button, ButtonLayout, ButtonSecondary } from '@dovera/design-system';
import { cx } from '../../../../utils/exports';
import useStyles from '../../AuthPage.styles';
import routes from '../../../../routes';
import { useNavigate } from 'react-router';

interface Props {
  homeWithBackButton?: boolean;
  isLoadingPrimary?: boolean;
  mtSmall?: boolean;
  onSecondaryButtonClick: () => void;
  onSubmit: () => void;
  onlyHome?: boolean;
  primaryButtonText: string;
  secondaryButtonText: string;
  withoutSecondaryButton?: boolean;
}

const BottomButtons = ({
  homeWithBackButton,
  isLoadingPrimary,
  mtSmall,
  onlyHome,
  onSecondaryButtonClick,
  onSubmit,
  primaryButtonText,
  secondaryButtonText,
  withoutSecondaryButton,
}: Props) => {
  const classes = useStyles();
  const navigate = useNavigate();
  return (
    <ButtonLayout
      className={cx(
        'align-items-center',
        classes.buttonLayout,
        mtSmall ? 'mt-small' : '',
      )}
      data-testid="auth--bottom-buttons"
      direction="horizontal"
    >
      {onlyHome && !homeWithBackButton ? (
        <Button onClick={() => onSubmit()}>Domov</Button>
      ) : onlyHome && homeWithBackButton ? (
        <>
          <Button
            isLoading={isLoadingPrimary}
            onClick={() => onSubmit()}
            role="button"
            submit
          >
            Späť
          </Button>
          <ButtonSecondary onClick={() => navigate(routes.dashboard)}>
            Domov
          </ButtonSecondary>
        </>
      ) : (
        <>
          {!withoutSecondaryButton && (
            <ButtonSecondary onClick={() => onSecondaryButtonClick()}>
              {secondaryButtonText}
            </ButtonSecondary>
          )}
          <Button
            isLoading={isLoadingPrimary}
            onClick={() => onSubmit()}
            role="button"
            submit
          >
            {primaryButtonText}
          </Button>
        </>
      )}
    </ButtonLayout>
  );
};

export default BottomButtons;
