import SolveRow from '../../../../components/SolveRow/SolveRow';
import { getSolveWidgetData } from '../../../../utils/dashboard.utils';
import routes from '../../../../routes';
import useStyles from '../NaRiesenieWidget.styles';
import { ButtonLink } from '@dovera/design-system';
import { useNavigate } from 'react-router';
import { composeDetailRoutePZS } from '../../../../utils/zmluvy.utils';
import { isSTAT } from '../../../../utils/app.utils';

interface Props {
  sekciaZmluvy: boolean;
}

const Zmluvy = ({ sekciaZmluvy }: Props) => {
  const data = getSolveWidgetData('zmluvy');
  const navigate = useNavigate();
  const classes = useStyles();
  if (!data) return <span />;
  return (
    <SolveRow
      countToSolve={data.pocetNaRiesenie}
      description={data.popis}
      showAll={
        data.polozkyNaRiesenie.length > 3
          ? {
              route: routes.zmluvyDodatky,
              title: 'Zobraziť všetky',
            }
          : null
      }
      state={data.typIkona}
      title={data.nadpis}
    >
      {data.polozkyNaRiesenie.map(
        (p, key) =>
          key < 3 && (
            <div key={`naRiesenie-${p.nadpis}-${key}`} className="child-row">
              <div className={classes.zmluvyRow}>
                <div>
                  <b>{p.nadpis}</b>
                  <span>{p.popis}</span>
                </div>
                <div>
                  <span>{p.text1}</span>
                  <span className={`text-color-${p.text3}`}>{p.text2}</span>
                </div>
                <div>
                  <ButtonLink
                    className="no-pad no-mrg-bottom"
                    onClick={() =>
                      navigate(
                        composeDetailRoutePZS(
                          p.hodnota2,
                          p.hodnota3,
                          p.hodnota4,
                          isSTAT(),
                          sekciaZmluvy,
                        ),
                      )
                    }
                  >
                    {p.typAkcie}
                  </ButtonLink>
                </div>
              </div>
            </div>
          ),
      )}
    </SolveRow>
  );
};

export default Zmluvy;
