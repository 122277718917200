import { ReactNode, useCallback } from 'react';
import strings from '../../../../constants/strings';
import {
  addressLowerCases,
  formatNameStr,
  formatPhone,
  hotjarMasking,
} from '../../../../utils/strings.utils';
import {
  ButtonLink,
  Grid,
  GridCol,
  Icon,
  Loader,
  Notification,
  Skeleton,
  Tag,
} from '@dovera/design-system';
import useStyles from '../../Proposals.styles';
import {
  GetProposalDetailDocumentsResponse,
  GetProposalDetailResponse,
} from '../../../../types/spaProposals.types';
import { cx } from '../../../../utils/exports';
import SafeHtml from '../../../../components/SafeHtml/SafeHtml';
import { getPatientIcon } from '../../../../utils/pacienti.utils';
import { useSelector } from 'react-redux';
import { fetchObsahDokumentu } from '../../../../slices/dokumenty.slice';
import { DATE_FORMAT_SLOVAK, getMoment } from '../../../../utils/date.utils';
import { RootState } from '../../../../rootReducer';
import { useAppDispatch } from '../../../../hooks/useStore';

const texts = strings.proposals.detail;

type ContentSectionType = {
  className?: string;
  extra?: string | ReactNode;
  rows?: {
    extraText?: string | ReactNode;
    label: string;
    value: string | ReactNode;
  }[];
  title: string | ReactNode;
  titleIcon?: ReactNode;
  withBorder?: boolean;
};

interface Props {
  data: GetProposalDetailResponse;
  documents: {
    data: GetProposalDetailDocumentsResponse | null;
    error: string | null;
    isLoading: boolean;
  };
  proposalId: number;
}

const Content = ({ data, documents, proposalId }: Props) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const { doplnujuceInformacie, informacie, navrhujuciLekar, pacient } = data;

  const fetchedDocuments = useSelector(
    (state: RootState) => state.dokumenty.dokumenty,
  );

  const getDocument = useCallback(
    (document: string, ciarovyKod: boolean) => {
      if (document)
        dispatch(
          fetchObsahDokumentu(
            ciarovyKod
              ? { dms: { ciarovyKod: document } }
              : { navrh: { idNavrhNaZS: proposalId, overovaciKod: document } },
            true,
            false,
          ),
        );
    },
    [dispatch, proposalId],
  );
  const renderSection = ({
    className,
    extra,
    rows,
    title,
    titleIcon,
    withBorder,
  }: ContentSectionType) => (
    <div
      className={cx(
        withBorder && classes.sectionWithBorder,
        titleIcon && classes.sectionWithTitleIcon,
        classes.contentSection,
        className,
      )}
    >
      <h4 className="mb-small">
        {titleIcon || ''}
        {title}
      </h4>
      {rows && (
        <div>
          {rows.map((r, key) => (
            <div key={`proposal-detail-section--${key}`}>
              <span
                className={cx(
                  'd-block',
                  rows.length - 1 === key ? 'no-mrg-bottom' : 'mb-xsmall',
                )}
              >
                <b>{`${r.label}: `}</b>
                {r.value}
              </span>
              {r.extraText || ''}
            </div>
          ))}
        </div>
      )}
      {!!extra && extra}
    </div>
  );
  const renderPatient = renderSection({
    rows: [
      {
        label: texts.labels.name,
        value: hotjarMasking(formatNameStr(pacient.meno)),
      },
      {
        label: texts.labels.insureeId,
        value: hotjarMasking(pacient.rodneCislo),
      },
      {
        label: texts.labels.address,
        value: hotjarMasking(addressLowerCases(pacient.adresa, true)),
      },
      {
        label: texts.labels.mobile,
        value: pacient.mobil
          ? formatPhone(pacient.mobil, true)
          : strings.undefined,
      },
    ],
    title: texts.subtitles.patient,
    titleIcon: getPatientIcon(pacient.pohlavie, pacient.datumNarodenia),
  });
  const renderDoctor = renderSection({
    rows: [
      {
        label: texts.labels.doctor,
        value: hotjarMasking(formatNameStr(navrhujuciLekar.lekar)),
      },
      {
        label: texts.labels.hospital,
        value: navrhujuciLekar.nemocnica
          ? formatNameStr(navrhujuciLekar.nemocnica)
          : strings.undefined,
      },
      {
        label: texts.labels.ambulance,
        value: formatNameStr(navrhujuciLekar.ambulancia) || strings.undefined,
      },
      {
        label: texts.labels.email,
        value: navrhujuciLekar.email
          ? hotjarMasking(navrhujuciLekar.email)
          : strings.undefined,
      },
    ],
    title: texts.subtitles.proposalDoctor,
  });
  const renderInformation = renderSection({
    rows: [
      {
        label: texts.labels.diagnose,
        value: informacie.diagnoza,
      },
      {
        label: texts.labels.indicatorGroup,
        value: informacie.indikacnaSkupina,
      },
      {
        label: texts.labels.aproove,
        value: informacie.priznakVysetrenieNad70R ? 'Áno' : 'Nie',
      },
      ...(informacie.datumUdalosti
        ? [
            {
              label: texts.labels.event(informacie.typUdalosti),
              value: getMoment(informacie.datumUdalosti).format(
                DATE_FORMAT_SLOVAK,
              ),
            },
          ]
        : []),
      {
        label: texts.labels.contraindications,
        value: informacie.priznakKontraindikacie ? 'Má' : 'Nemá',
      },
      {
        extraText: informacie.vsetkyVysetrenia ? (
          <div className={classes.sectionTags}>
            {informacie.vsetkyVysetrenia.split('\n').map((v, key) => (
              <Tag key={`proposal-examination--${key}`}>{v}</Tag>
            ))}
          </div>
        ) : (
          ''
        ),
        label: texts.labels.examinations,
        value: informacie.priznakVsetkyVysetrenia ? 'Áno' : 'Nie',
      },
      {
        label: texts.labels.svlz3Month,
        value: informacie.priznakKlinALabVysetreniaNieViacAko3M ? 'Áno' : 'Nie',
      },
      {
        label: texts.labels.epicrisis,
        value: <SafeHtml html={informacie.epikriza || strings.undefined} />,
      },
      {
        label: texts.labels.anannese,
        value: (
          <SafeHtml
            html={
              !informacie.anamneza && !informacie.objektivnyNalez
                ? strings.undefined
                : `${
                    informacie.objektivnyNalez
                      ? `${informacie.objektivnyNalez}<br />`
                      : ''
                  }${informacie.anamneza || ''}`
            }
          />
        ),
      },
      {
        label: texts.labels.additionalDiagnoses,
        value: (
          <SafeHtml html={informacie.vedlajsiaDiagnoza || strings.undefined} />
        ),
      },
    ],
    title: texts.subtitles.information,
    withBorder: true,
  });
  const renderAdditionalInfo = renderSection({
    className: 'text-space-top',
    rows: [
      {
        label: texts.labels.spaType,
        value: doplnujuceInformacie.kupelnaStarostlivost || strings.undefined,
      },
      {
        label: texts.labels.escort,
        value: doplnujuceInformacie.sprievod ? 'Áno' : 'Nie',
      },
      ...(doplnujuceInformacie.sprievod
        ? [
            {
              label: texts.labels.escortType,
              value: doplnujuceInformacie.dovodSprievodu
                ? doplnujuceInformacie.dovodSprievodu.split(';').join(', ')
                : strings.undefined,
            },
          ]
        : []),
      {
        label: texts.labels.mobility,
        value: doplnujuceInformacie.pohyblivostPacienta || strings.undefined,
      },
    ],
    title: texts.subtitles.additionalInfo,
  });
  const renderDocuments =
    documents.data?.dokumenty &&
    renderSection({
      extra: (
        <>
          {documents.data.dokumenty.map((d, key) => {
            const documentCode: string = d.ciarovyKod || d.overovaciKod || '';
            if (documentCode) {
              return (
                <ButtonLink
                  key={`proposal-document-${key}`}
                  className={cx(
                    classes.documentLink,
                    'no-mrg no-pad text-left',
                  )}
                  isDisabled={!!fetchedDocuments[documentCode]?.isLoading}
                  onClick={() => getDocument(documentCode, !!d.ciarovyKod)}
                >
                  {fetchedDocuments[documentCode]?.isLoading ? (
                    <Loader className="text-space-right" size={16} />
                  ) : (
                    <Icon name="file-blank" />
                  )}
                  {d.nazov}
                </ButtonLink>
              );
            }
            return <span />;
          })}
        </>
      ),
      title: texts.subtitles.documents,
    });
  const renderDocumentsLoader = documents.isLoading && (
    <>
      <h4 className="mb-small">
        <Skeleton width="50%" />
      </h4>
      <span className="d-block mb-small">
        <Skeleton width="40%" />
      </span>
    </>
  );
  const renderDocumentsError = documents.error && (
    <>
      <h4 className="mb-small">{texts.subtitles.documents}</h4>
      <Notification message={documents.error} variant="error" />
    </>
  );
  return (
    <>
      <Grid className="mb-xlarge">
        <GridCol size={6}>{renderPatient}</GridCol>
        <GridCol size={6}>{renderDoctor}</GridCol>
      </Grid>
      <Grid>
        <GridCol size={6}>{renderInformation}</GridCol>
        <GridCol size={6}>
          {renderAdditionalInfo}
          <div className="mb-xlarge" />
          {renderDocuments}
          {renderDocumentsLoader}
          {renderDocumentsError}
        </GridCol>
      </Grid>
    </>
  );
};

export default Content;
