// @ts-nocheck
import { Circle, Path, Svg, Text, View } from '@react-pdf/renderer';
import { ReactNode } from 'react';

/**
 * Static graph values in percentage
 * Quality = pink ; Effectivity = green ; innovations = blue
 * @date 3. 5. 2023 - 17:00:30
 *
 * @type {{ 0: { quality: string; effIno: string; isFill: boolean; }; 5: { quality: string; effIno: string; isFill: boolean; }; 10: { quality: string; effIno: string; isFill: boolean; }; 15: { quality: string; effIno: string; isFill: boolean; }; ... 16 more ...; 100: { ...; }; }}
 */
const graphValues = {
  0: {
    quality: '',
    effIno: '',
    isFill: false,
  },
  5: {
    quality: 'M86 56C88.2 56 91.5 56.5 94.9251 57.8',
    effIno: 'M86 33C91.4491 33 96.8643 34.1184 102.047 35.8',
    isFill: false,
  },
  10: {
    quality: 'M85 56C91.1355 56 97.1129 57.946 102.073 61.558',
    effIno: 'M85 33C96.0105 33 106.737 36.4949 115.634 42.9814',
    isFill: false,
  },
  15: {
    quality:
      'M100.378 73.8419C103.621 78.312 109.875 79.3065 114.345 76.0631C118.815 72.8197 119.809 66.5666 116.566 62.0965L100.378 73.8419ZM85.029 46C79.5062 45.9958 75.0256 50.4696 75.0215 55.9925C75.0173 61.5153 79.4911 65.9958 85.014 66L85.029 46ZM116.566 62.0965C112.952 57.1156 108.211 53.0606 102.729 50.263L93.6372 68.0769C96.3076 69.4398 98.6174 71.4153 100.378 73.8419L116.566 62.0965ZM102.729 50.263C97.2482 47.4654 91.1828 46.0046 85.029 46L85.014 66C88.012 66.0023 90.9669 66.7139 93.6372 68.0769L102.729 50.263Z',
    effIno:
      'M85 0C79.4772 -6.58593e-08 75 4.47715 75 10C75 15.5228 79.4772 20 85 20L85 0ZM137.643 46.8727C140.883 51.3456 147.135 52.3455 151.608 49.1059C156.081 45.8663 157.081 39.6141 153.841 35.1412L137.643 46.8727ZM85 20C95.2741 20 105.402 22.4355 114.553 27.1067L123.646 9.29334C111.679 3.18485 98.4354 1.60215e-07 85 0L85 20ZM114.553 27.1067C123.704 31.7779 131.617 38.5517 137.643 46.8727L153.841 35.1412C145.96 24.2599 135.612 15.4018 123.646 9.29334L114.553 27.1067Z',
    isFill: true,
  },
  20: {
    quality:
      'M85 56C91.1376 56 97.117 57.9473 102.078 61.5616C107.038 65.1759 110.724 70.2709 112.605 76.1132',
    effIno:
      'M85 33C95.9584 33 106.637 36.462 115.51 42.8917C124.384 49.3214 131 58.3902 134.413 68.8035',
    isFill: false,
  },
  25: {
    quality:
      'M85 56C88.8083 56 92.5794 56.7501 96.0978 58.2075C99.6163 59.6649 102.813 61.801 105.506 64.4939C108.199 67.1868 110.335 70.3837 111.793 73.9022C113.25 77.4206 114 81.1917 114 85',
    effIno:
      'M85 33C91.8287 33 98.5906 34.345 104.9 36.9583C111.208 39.5715 116.941 43.4018 121.77 48.2305C126.598 53.0591 130.428 58.7915 133.042 65.1005C135.655 71.4094 137 78.1713 137 85',
    isFill: false,
  },
  30: {
    quality:
      'M85 56C89.5769 56 94.0888 57.0833 98.1668 59.1614C102.245 61.2394 105.773 64.2532 108.463 67.9562C111.153 71.6592 112.928 75.9463 113.644 80.467C114.359 84.9876 113.994 89.6134 112.579 93.9661',
    effIno:
      'M85 33C93.1966 33 101.277 34.9376 108.582 38.6548C115.887 42.372 122.21 47.7634 127.036 54.3893C131.861 61.0152 135.051 68.6879 136.346 76.7814C137.642 84.8749 137.006 93.1601 134.49 100.961',
    isFill: false,
  },
  35: {
    quality:
      'M85 56C90.341 56 95.5783 57.475 100.134 60.2623C104.69 63.0497 108.388 67.0411 110.82 71.7964C113.252 76.5517 114.323 81.8863 113.916 87.2117C113.508 92.5372 111.638 97.6467 108.511 101.977',
    effIno:
      'M85 33C94.5873 33 103.988 35.6505 112.163 40.6586C120.339 45.6667 126.97 52.8375 131.325 61.3785C135.68 69.9195 137.589 79.4983 136.842 89.0564C136.094 98.6145 132.718 107.78 127.087 115.539',
    isFill: false,
  },
  40: {
    quality:
      'M85 56C91.1277 56 97.098 57.941 102.054 61.5446C107.01 65.1482 110.698 70.2291 112.587 76.0583C114.476 81.8874 114.471 88.1653 112.571 93.9911C110.671 99.8169 106.975 104.891 102.012 108.486',
    effIno:
      'M85 33C95.9794 33 106.677 36.4753 115.56 42.9279C124.444 49.3805 131.056 58.4793 134.452 68.9207C137.847 79.362 137.85 90.61 134.46 101.053C131.071 111.496 124.462 120.599 115.583 127.056',
    isFill: false,
  },
  45: {
    quality:
      'M85 56C91.9033 56 98.5801 58.4626 103.83 62.945C109.08 67.4274 112.559 73.6356 113.641 80.4535C114.724 87.2714 113.338 94.2518 109.734 100.14C106.131 106.028 100.544 110.437 93.9806 112.574',
    effIno:
      'M85 33C97.3889 33 109.371 37.4232 118.788 45.473C128.205 53.5229 134.439 64.6705 136.367 76.9084C138.294 89.1464 135.79 101.671 129.303 112.226C122.817 122.781 112.775 130.673 100.986 134.482',
    isFill: false,
  },
  50: {
    quality:
      'M85 114C101.016 114 114 101.016 114 85C114 68.9837 101.016 56 85 56',
    effIno:
      'M85 137C113.719 137 137 113.719 137 85C137 56.2812 113.719 33 85 33',
    isFill: false,
  },
  55: {
    quality:
      'M85.2297 56C89.4252 56 93.5706 56.9103 97.38 58.6681C101.189 60.4259 104.572 62.9893 107.295 66.1814C110.017 69.3735 112.015 73.1183 113.149 77.1574C114.284 81.1965 114.528 85.4337 113.866 89.5765C113.204 93.7194 111.651 97.6692 109.314 101.154C106.977 104.638 103.912 107.574 100.33 109.758C96.7487 111.943 92.7356 113.324 88.5681 113.807C84.4005 114.29 80.1779 113.863 76.1914 112.556',
    effIno:
      'M85.2305 33C92.7408 33 100.162 34.6269 106.984 37.7687C113.805 40.9105 119.865 45.4927 124.747 51.2001C129.629 56.9075 133.216 63.6047 135.263 70.8308C137.309 78.057 137.766 85.6407 136.602 93.0603C135.438 100.48 132.68 107.559 128.519 113.811C124.358 120.064 118.892 125.34 112.497 129.278C106.101 133.216 98.9291 135.722 91.4731 136.624C84.017 137.526 76.4542 136.801 69.3047 134.501',
    isFill: false,
  },
  60: {
    quality:
      'M84.9962 56C89.5761 56 94.091 57.0847 98.171 59.1654C102.251 61.2461 105.78 64.2635 108.47 67.9707C111.159 71.6778 112.932 75.9692 113.644 80.4935C114.356 85.0178 113.986 89.6463 112.564 94.0001C111.143 98.3539 108.711 102.309 105.466 105.542C102.222 108.775 98.2585 111.193 93.8997 112.599C89.541 114.006 84.9112 114.359 80.3894 113.632C75.8676 112.904 71.5825 111.116 67.8848 108.414',
    effIno:
      'M84.9967 33C93.2025 33 101.292 34.942 108.603 38.6673C115.915 42.3925 122.241 47.7952 127.065 54.4338C131.888 61.0723 135.072 68.7581 136.356 76.8628C137.64 84.9676 136.988 93.2612 134.453 101.066C131.917 108.87 127.571 115.964 121.769 121.766C115.967 127.569 108.875 131.917 101.071 134.453C93.2666 136.99 84.9731 137.643 76.8681 136.361C68.7632 135.078 61.0768 131.895 54.4375 127.073',
    isFill: false,
  },
  65: {
    quality:
      'M84.576 56C89.5353 56 94.4118 57.2718 98.7394 59.6939C103.067 62.116 106.701 65.6074 109.295 69.8346C111.888 74.0617 113.354 78.8833 113.553 83.8386C113.751 88.794 112.676 93.7174 110.429 98.1386C108.182 102.56 104.839 106.331 100.719 109.091C96.5993 111.852 91.8403 113.51 86.8969 113.907C81.9535 114.304 76.9909 113.426 72.4833 111.358C67.9757 109.29 64.0738 106.101 61.1504 102.095',
    effIno:
      'M84.5767 33C93.4693 33 102.213 35.2805 109.973 39.6235C117.733 43.9666 124.249 50.2271 128.9 57.8068C133.55 65.3865 136.179 74.0321 136.535 82.9175C136.891 91.8029 134.963 100.631 130.934 108.559C126.905 116.486 120.911 123.248 113.523 128.198C106.136 133.149 97.6024 136.122 88.7384 136.833C79.8744 137.545 70.976 135.972 62.8934 132.263C54.8109 128.555 47.8143 122.836 42.5723 115.653',
    isFill: false,
  },
  70: {
    quality:
      'M85.2842 56C90.5764 56 95.7657 57.477 100.279 60.2679C104.793 63.0588 108.454 67.055 110.861 71.8153C113.267 76.5756 114.323 81.9148 113.914 87.2434C113.505 92.5721 111.645 97.6828 108.541 102.011C105.436 106.34 101.208 109.718 96.322 111.772C91.4363 113.826 86.0835 114.477 80.8546 113.653C75.6256 112.828 70.724 110.561 66.691 107.1C62.6579 103.64 59.6502 99.1207 58 94.0425',
    effIno:
      'M85.2493 33C94.7976 33 104.16 35.6544 112.3 40.6694C120.44 45.6844 127.04 52.8646 131.371 61.4153C135.702 69.9661 137.594 79.554 136.837 89.1182C136.081 98.6824 132.706 107.85 127.086 115.606C121.466 123.363 113.82 129.406 104.994 133.066C96.1685 136.727 86.5066 137.863 77.0781 136.348C67.6495 134.833 58.8219 130.726 51.572 124.483C44.3221 118.239 38.9327 110.102 36 100.971',
    isFill: false,
  },
  75: {
    quality:
      'M85 46C79.4771 46 75 50.4771 75 56C75 61.5228 79.4771 66 85 66L85 46ZM105.506 105.506L98.435 98.435L105.506 105.506ZM73.9022 111.792L77.729 102.554L73.9022 111.792ZM60.8874 101.112L52.5727 106.667L52.5727 106.667L60.8874 101.112ZM66 85C66 79.4771 61.5228 75 56 75C50.4772 75 46 79.4771 46 85L66 85ZM85 66C88.7578 66 92.4313 67.1143 95.5558 69.2021L106.667 52.5727C100.254 48.2873 92.7135 46 85 46L85 66ZM95.5558 69.2021C98.6804 71.2898 101.116 74.2572 102.554 77.729L121.031 70.0753C118.079 62.949 113.081 56.858 106.667 52.5727L95.5558 69.2021ZM102.554 77.729C103.992 81.2008 104.368 85.0211 103.635 88.7067L123.251 92.6085C124.755 85.0432 123.983 77.2016 121.031 70.0753L102.554 77.729ZM103.635 88.7067C102.902 92.3923 101.092 95.7778 98.435 98.435L112.577 112.577C118.031 107.123 121.746 100.174 123.251 92.6085L103.635 88.7067ZM98.435 98.435C95.7778 101.092 92.3924 102.902 88.7067 103.635L92.6085 123.251C100.174 121.746 107.123 118.031 112.577 112.577L98.435 98.435ZM88.7067 103.635C85.0211 104.368 81.2008 103.992 77.729 102.554L70.0753 121.031C77.2017 123.983 85.0433 124.755 92.6085 123.251L88.7067 103.635ZM77.729 102.554C74.2572 101.116 71.2898 98.6804 69.2021 95.5558L52.5727 106.667C56.8581 113.081 62.949 118.079 70.0753 121.031L77.729 102.554ZM69.2021 95.5558C67.1143 92.4313 66 88.7578 66 85L46 85C46 92.7135 48.2873 100.254 52.5727 106.667L69.2021 95.5558Z',
    effIno:
      'M85 23C79.4771 23 75 27.4771 75 33C75 38.5228 79.4771 43 85 43L85 23ZM133.042 65.1004L142.281 61.2736L133.042 65.1004ZM136.001 95.1447L145.809 97.0956L136.001 95.1447ZM121.77 121.77L114.698 114.698L121.77 121.77ZM95.1447 136.001L93.1938 126.193L95.1447 136.001ZM43 85C43 79.4771 38.5228 75 33 75C27.4771 75 23 79.4771 23 85L43 85ZM85 43C93.3068 43 101.427 45.4632 108.334 50.0783L119.445 33.4489C109.25 26.6362 97.2624 23 85 23L85 43ZM108.334 50.0783C115.241 54.6933 120.624 61.2528 123.803 68.9273L142.281 61.2736C137.588 49.9446 129.641 40.2615 119.445 33.4489L108.334 50.0783ZM123.803 68.9273C126.982 76.6018 127.814 85.0466 126.193 93.1938L145.809 97.0956C148.201 85.0688 146.973 72.6026 142.281 61.2736L123.803 68.9273ZM126.193 93.1938C124.572 101.341 120.572 108.825 114.698 114.698L128.841 128.841C137.511 120.17 143.416 109.122 145.809 97.0956L126.193 93.1938ZM114.698 114.698C108.825 120.572 101.341 124.572 93.1938 126.193L97.0956 145.809C109.122 143.416 120.17 137.511 128.841 128.841L114.698 114.698ZM93.1938 126.193C85.0466 127.814 76.6018 126.982 68.9273 123.803L61.2736 142.281C72.6026 146.973 85.0688 148.201 97.0956 145.809L93.1938 126.193ZM68.9273 123.803C61.2528 120.624 54.6933 115.241 50.0783 108.334L33.4489 119.445C40.2615 129.641 49.9446 137.588 61.2736 142.281L68.9273 123.803ZM50.0783 108.334C45.4633 101.427 43 93.3068 43 85L23 85C23 97.2624 26.6362 109.249 33.4489 119.445L50.0783 108.334Z',
    isFill: true,
  },
  80: {
    quality:
      'M85 56C91.1254 56 97.0934 57.9395 102.049 61.5405C107.004 65.1415 110.691 70.219 112.583 76.045C114.474 81.8711 114.472 88.1464 112.577 93.9713C110.683 99.7962 106.992 104.871 102.035 108.47C97.0774 112.068 91.1082 114.004 84.9828 114C78.8575 113.996 72.8906 112.053 67.9376 108.449C62.9846 104.845 59.3 99.7658 57.412 93.9387C55.5239 88.1115 55.5295 81.8362 57.4279 76.0124',
    effIno:
      'M85 33C95.9781 33 106.675 36.4744 115.557 42.9255C124.44 49.3766 131.053 58.4735 134.449 68.913C137.845 79.3525 137.85 90.5991 134.464 101.042C131.077 111.484 124.472 120.587 115.596 127.046C106.719 133.506 96.0257 136.99 85.0476 137C74.0695 137.01 63.3699 133.545 54.4814 127.102C45.5929 120.659 38.9715 111.569 35.5657 101.132C32.1599 90.6958 32.1445 79.4493 35.5216 69.0036',
    isFill: false,
  },
  85: {
    quality:
      'M85 56C91.5116 56 97.8337 58.1915 102.948 62.2215C108.063 66.2516 111.672 71.8857 113.196 78.2167C114.719 84.5476 114.067 91.207 111.345 97.1224C108.623 103.038 103.989 107.865 98.1903 110.827C92.3912 113.788 85.7642 114.712 79.3762 113.449C72.9882 112.187 67.2111 108.811 62.9751 103.865C58.7391 98.92 56.2908 92.6929 56.0243 86.1867C55.7578 79.6806 57.6887 73.2741 61.5061 67.9989',
    effIno:
      'M85 33C96.6838 33 108.027 36.9348 117.201 44.1701C126.375 51.4053 132.845 61.5194 135.568 72.8815C138.291 84.2436 137.108 96.1916 132.21 106.799C127.312 117.407 118.984 126.056 108.57 131.352C98.155 136.647 86.2603 138.282 74.8033 135.99C63.3463 133.699 52.9947 127.617 45.4176 118.723C37.8404 109.829 33.4793 98.6428 33.0372 86.9673C32.5952 75.2919 36.0981 63.8077 42.9811 54.3666',
    isFill: false,
  },
  90: {
    quality:
      'M85 56C91.9043 56 98.582 58.4633 103.832 62.9468C109.083 67.4303 112.561 73.64 113.642 80.459C114.723 87.2781 113.336 94.2592 109.73 100.147C106.124 106.034 100.536 110.442 93.9699 112.578C87.4043 114.713 80.2921 114.436 73.9124 111.797C67.5328 109.157 62.3041 104.328 59.1669 98.1775C56.0296 92.0273 55.1895 84.9595 56.7977 78.2451C57.8896 73.6863 60.0615 69.4935 63.0911 66',
    effIno:
      'M85.0001 33C97.3762 33 109.347 37.4141 118.76 45.4491C128.173 53.484 134.412 64.6129 136.355 76.8355C138.298 89.0582 135.818 101.573 129.361 112.131C122.904 122.689 112.892 130.598 101.127 134.436C89.3606 138.274 76.6114 137.79 65.1705 133.071C53.7295 128.351 44.3469 119.706 38.709 108.688C33.0711 97.671 31.5476 85.0039 34.4122 72.9639C37.2768 60.9238 44.3418 50.3002 54.3374 43.0024',
    isFill: false,
  },
  95: {
    quality:
      'M85.0001 56C92.3007 56 99.3327 58.7536 104.692 63.711C110.052 68.6684 113.344 75.4648 113.912 82.7433C114.48 90.0218 112.282 97.2468 107.757 102.976C103.232 108.705 96.712 112.516 89.4997 113.649C82.2875 114.782 74.9134 113.152 68.8497 109.087C62.786 105.021 58.4789 98.8176 56.7883 91.7154C55.0977 84.6132 56.1481 77.1347 59.7297 70.773C60.6704 69.102 61.7676 67.5405 63 66.1055',
    effIno:
      'M85.0001 33C98.0901 33 110.699 37.9369 120.308 46.8251C129.918 55.7133 135.822 67.8988 136.842 80.949C137.862 93.9993 133.922 106.954 125.81 117.227C117.697 127.5 106.009 134.336 93.0774 136.369C80.1463 138.402 66.924 135.484 56.0502 128.196C45.1764 120.909 37.4512 109.788 34.4166 97.0546C31.3821 84.3211 33.2617 70.9117 39.68 59.5032C44.2846 51.3186 50.9822 44.5958 59 39.9666',
    isFill: false,
  },
  100: {
    quality: '',
    effIno: '',
    isFill: false,
  },
};

interface Props {
  effectivity: number;
  innovations: number;
  percentage: number;
  quality: number;
}

const VASChart = ({ effectivity, innovations, percentage, quality }: Props) => {
  const renderCircle = ({
    type,
  }: {
    type: 'quality' | 'innovations' | 'effectivity';
  }): ReactNode => {
    let chartValue: {
      color: string;
      key: string;
      value: number;
    } = { color: '#EA5DD1', key: 'quality', value: quality }; // for quality
    if (type === 'effectivity')
      chartValue = { ...chartValue, color: '#42D848', value: effectivity };
    if (type === 'innovations')
      chartValue = { color: '#3EAEFF', key: 'effIno', value: innovations };

    const { color, key, value } = chartValue;

    switch (true) {
      case value === 100:
        return (
          <Circle cx="85" cy="85" r="29" stroke={color} strokeWidth="20" />
        );
      case graphValues[value - (value % 5)]?.isFill:
        return <Path d={graphValues[value - (value % 5)][key]} fill={color} />;
      default:
        return (
          <Path
            d={graphValues[value - (value % 5)][key]}
            stroke={color}
            // @ts-ignore
            strokeLinecap="round"
            strokeWidth="20"
          />
        );
    }
  };
  return (
    <View>
      <Svg style={{ width: 170, height: 170, marginTop: -50 }}>
        <Path
          d="M85.0013 23.2709C119.094 23.2709 146.731 50.9083 146.731 85.0007C146.731 119.093 119.094 146.73 85.0013 146.73C50.9089 146.73 23.2715 119.093 23.2715 85.0007C23.2715 50.9083 50.9088 23.2709 85.0013 23.2709ZM85.0013 126.054C107.674 126.054 126.055 107.674 126.055 85.0007C126.055 62.3275 107.674 43.9473 85.0013 43.9473C62.3281 43.9473 43.9478 62.3275 43.9479 85.0007C43.9479 107.674 62.3281 126.054 85.0013 126.054Z"
          fill="#EAEBEC"
        />
        <Path
          d="M85.0009 46.5396C106.242 46.5396 123.461 63.7587 123.461 84.9996C123.461 106.24 106.242 123.459 85.0009 123.459C63.7601 123.459 46.541 106.24 46.541 84.9996C46.541 63.7587 63.7601 46.5396 85.0009 46.5396ZM85.0009 102.768C94.8142 102.768 102.769 94.8128 102.769 84.9996C102.769 75.1863 94.8142 67.231 85.0009 67.231C75.1876 67.231 67.2324 75.1863 67.2324 84.9996C67.2324 94.8128 75.1876 102.768 85.0009 102.768Z"
          fill="#EAEBEC"
        />
        {renderCircle({ type: 'quality' })}
        {renderCircle({ type: 'innovations' })}

        <Text fill="#000000" style={{ fontSize: 11 }} x="73" y="88">
          {`${percentage} %`}
        </Text>

        <Path
          d="M80 31.1711C80 28.3152 82.3152 26 85.1711 26C88.027 26 90.3422 28.3152 90.3422 31.1711C90.3422 32.6322 89.7353 33.9527 88.7616 34.8924C88.5542 35.0926 88.4047 35.3933 88.3246 35.7514C88.2253 36.1958 88.0328 36.6194 87.7566 36.9885V37.6349C87.7566 38.5294 87.1511 39.2824 86.3276 39.5063C86.2655 39.6304 86.1838 39.7439 86.0854 39.8423C85.9653 39.9624 85.8228 40.0576 85.666 40.1226C85.5091 40.1875 85.341 40.221 85.1713 40.221C85.0015 40.221 84.8334 40.1875 84.6765 40.1226C84.5197 40.0576 84.3772 39.9624 84.2571 39.8423C84.1587 39.744 84.077 39.6305 84.015 39.5064C83.1913 39.2826 82.5855 38.5295 82.5855 37.6349V36.9884C82.3096 36.6194 82.1174 36.1955 82.018 35.7523C81.9375 35.3936 81.7878 35.0925 81.5801 34.892C80.6067 33.9524 80 32.632 80 31.1711ZM85.1711 27.2928C83.0292 27.2928 81.2928 29.0291 81.2928 31.1711C81.2928 32.267 81.7464 33.2558 82.478 33.9619C82.9201 34.3887 83.1622 34.9467 83.2794 35.4694C83.2966 35.5461 83.3184 35.6216 83.3445 35.6955H84.5258L84.5258 31.8186C84.5258 31.8187 84.5258 31.8184 84.5258 31.8186L83.8761 31.818C83.5191 31.818 83.2297 31.5286 83.2297 31.1716C83.2297 30.8147 83.5191 30.5253 83.8761 30.5253H86.4616C86.8186 30.5253 87.108 30.8147 87.108 31.1716C87.108 31.5286 86.8186 31.818 86.4616 31.818H85.8186C85.8186 31.8179 85.8186 31.8182 85.8186 31.818V35.6955H86.9981C87.0242 35.6216 87.0459 35.5461 87.063 35.4694C87.1799 34.9466 87.4218 34.3888 87.8639 33.9622C88.5956 33.256 89.0494 32.2671 89.0494 31.1711C89.0494 29.0291 87.313 27.2928 85.1711 27.2928ZM85.1302 36.9883C85.1441 36.9892 85.1581 36.9896 85.1722 36.9896C85.1863 36.9896 85.2004 36.9892 85.2143 36.9883H86.4639V37.6349C86.4639 37.9919 86.1745 38.2813 85.8175 38.2813H84.5247C84.1677 38.2813 83.8783 37.9919 83.8783 37.6349V36.9883H85.1302Z"
          fill="#F7F7F7"
        />
        <Path
          d="M86.2783 61.2422C86.0241 61.6089 85.4819 61.6089 85.2277 61.2422L80.2525 54.0647C80.1007 53.8457 80.1007 53.5555 80.2525 53.3365L81.7271 51.2091C81.8465 51.0369 82.0428 50.9341 82.2524 50.9341L89.2536 50.9341C89.4632 50.9341 89.6595 51.0369 89.7789 51.2091L91.2535 53.3365C91.4053 53.5555 91.4053 53.8457 91.2535 54.0647L86.2783 61.2422Z"
          stroke="white"
          strokeWidth="1.27825"
        />
      </Svg>
      <Svg style={{ width: 170, height: 170, marginTop: -50 }}>
        <Path
          d="M85.0009 46.5396C106.242 46.5396 123.461 63.7587 123.461 84.9996C123.461 106.24 106.242 123.459 85.0009 123.459C63.7601 123.459 46.541 106.24 46.541 84.9996C46.541 63.7587 63.7601 46.5396 85.0009 46.5396ZM85.0009 102.768C94.8142 102.768 102.769 94.8128 102.769 84.9996C102.769 75.1863 94.8142 67.231 85.0009 67.231C75.1876 67.231 67.2324 75.1863 67.2324 84.9996C67.2324 94.8128 75.1876 102.768 85.0009 102.768Z"
          fill="#EAEBEC"
        />

        {renderCircle({ type: 'effectivity' })}

        <Text fill="#000000" style={{ fontSize: 11 }} x="73" y="88">
          {`${effectivity} %`}
        </Text>

        <Path
          d="M86.8415 49.2764C85.2908 49.0722 83.7188 49.4387 82.4184 50.3077C82.1128 50.5118 82.0306 50.9251 82.2348 51.2306C82.4389 51.5362 82.8522 51.6184 83.1577 51.4142C84.1897 50.7246 85.4372 50.4338 86.6678 50.5958C87.8983 50.7578 89.028 51.3616 89.8464 52.2948C90.4257 52.9554 90.8211 53.7493 91.0026 54.597H90.107C89.5643 54.597 89.2498 55.2118 89.5674 55.6518L91.1806 57.8872C91.4462 58.2552 91.9942 58.2552 92.2598 57.8872L93.873 55.6518C94.1906 55.2118 93.8761 54.597 93.3334 54.597H92.3572C92.1596 53.4244 91.6401 52.3218 90.8469 51.4173C89.8157 50.2414 88.3921 49.4805 86.8415 49.2764Z"
          fill="white"
        />
        <Path
          d="M89.5816 61.5782C88.2812 62.4471 86.7092 62.8137 85.1585 62.6095C83.6079 62.4054 82.1843 61.6445 81.1531 60.4686C80.3596 59.5637 79.84 58.4606 79.6426 57.2875H78.6666C78.1239 57.2875 77.8094 56.6728 78.127 56.2327L79.7402 53.9974C80.0058 53.6294 80.5538 53.6294 80.8194 53.9974L82.4326 56.2327C82.7502 56.6728 82.4357 57.2875 81.893 57.2875H80.9971C81.1785 58.1358 81.574 58.9301 82.1536 59.5911C82.972 60.5243 84.1017 61.1281 85.3322 61.2901C86.5628 61.4521 87.8103 61.1612 88.8423 60.4717C89.1478 60.2675 89.5611 60.3497 89.7652 60.6553C89.9694 60.9608 89.8872 61.374 89.5816 61.5782Z"
          fill="white"
        />
      </Svg>
    </View>
  );
};

export default VASChart;
