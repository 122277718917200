const AUTH_KEY = 'dzp_auth';
const HEADER_HEIGHT = 56;
const PARAMETER_TOP_NAVIGATION = 75;
const TABLE_PAGINATION = 10;

const RADIAN = Math.PI / 180;

const SUBJEKTIVNA_SPOKOJNOST = 'subj-spok';
const PRISTROJOVE_VYBAVENIE = 'pristr-vyb';
const PRISTROJOVE_VYBAVENIE_TKHO = 'pristr-vyb-tkho';
const DOSTUPNOST_GYN_ZDRAVOTNEJ_STAROSTLIVOSTI = 'dostup-gyn';

export {
  AUTH_KEY,
  HEADER_HEIGHT,
  RADIAN,
  PARAMETER_TOP_NAVIGATION,
  SUBJEKTIVNA_SPOKOJNOST,
  PRISTROJOVE_VYBAVENIE,
  PRISTROJOVE_VYBAVENIE_TKHO,
  DOSTUPNOST_GYN_ZDRAVOTNEJ_STAROSTLIVOSTI,
  TABLE_PAGINATION,
};
