// @ts-nocheck
import { Button, Card, CardSection, PageSection } from '@dovera/design-system';
import IllustrationSuccess from '../../assets/illustrations/global/success-check.png';
import IllustrationWarning from '../../assets/illustrations/global/disconnect.png';
import { useCallback } from 'react';
import useStyles from './styles';
import { useQuery } from '../../hooks/useQuery';
import routes from '../../routes';
import { useNavigate } from 'react-router';
// @ts-check

const LogoutPage = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const query = useQuery();
  const isAutoLogout = query.get('auto');
  const loginCallback = useCallback(() => {
    navigate(routes.login);
  }, [navigate]);
  return (
    <PageSection bg="grey" className={classes.root}>
      <Card>
        <CardSection isCondensed>
          <div className="align-items-center">
            <img
              alt="illustration"
              src={isAutoLogout ? IllustrationWarning : IllustrationSuccess}
            />
            {isAutoLogout ? (
              <h4 className="text-color-warning">
                Boli ste odhlásený z dôvodu nečinnosti
              </h4>
            ) : (
              <h4 className="text-color-success">Boli ste úspešne odhlásený</h4>
            )}
            <Button onClick={loginCallback}>Prihlásiť sa</Button>
          </div>
        </CardSection>
      </Card>
    </PageSection>
  );
};

export default LogoutPage;
