import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../rootReducer';
import routes from '../../routes';
import { setGuid } from '../../slices/auth.slice';
import { getPageTitle } from '../../utils/app.utils';
import { sendDataLayer } from '../../utils/gtm.utils';
import useStyles from './Page.styles';
import { useQuery } from '../../hooks/useQuery';
import { useAppDispatch } from '../../hooks/useStore';

export type Props = { bgColor?: string } & React.JSX.IntrinsicElements['div'];

const Page = ({ bgColor = 'grey', children, ...other }: Props) => {
  const classes = useStyles({ bgColor });
  const query = useQuery();
  const dispatch = useAppDispatch();
  const { guid, pouzivatelId } = useSelector((state: RootState) => state.auth);
  const { kodTypZs, poslednaZmenaOdbornosti } = useSelector(
    (state: RootState) => state.poskytovatel,
  );
  // Log GA Pageview
  useEffect(() => {
    if (
      query.get('token') &&
      window.location.pathname.includes(routes.prehladHPPublic)
    )
      dispatch(setGuid(query.get('token') || ''));
    // if (window.env.ENV === 'PROD' || window.env.ENV === 'TEST')
    setTimeout(() => {
      if ((kodTypZs && poslednaZmenaOdbornosti) || guid)
        sendDataLayer('pageview', {
          title: getPageTitle(),
          location: window.location.href,
          page: window.location.pathname,
          provider: {
            id: pouzivatelId,
            typZS: kodTypZs,
            kodOdb: poslednaZmenaOdbornosti,
          },
        });
    }, 1000);
    // eslint-disable-next-line
  }, []);

  return (
    <div className={classes.page} {...other}>
      {children}
    </div>
  );
};

export default Page;
