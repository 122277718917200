import { createUseStyles } from 'react-jss';

import { color, fontWeight, spaces } from '../../constants';

export default createUseStyles({
  pieGraphBlured: {
    filter: 'blur(2.5px)',
  },
  centeredText: {
    fontSize: `${spaces.default}px`,
    fontWeight: fontWeight.bold,
    fill: color('secondary'),
  },
});
