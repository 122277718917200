import React, { useMemo, useState } from 'react';
import { Cell, Pie, PieChart, ResponsiveContainer } from 'recharts';
import { flatMap, values } from 'lodash-es';

import useStyles from './PieGraph.styles';
import { RADIAN, color } from '../../constants';

import {
  Parameter,
  SkupinaParametrov,
  SkupinyParametrov,
  VysledokObdobia,
} from '../../slices/hodnotiaceParametre.slice';

import { getPercentage } from '../../utils/number.utils';

import { GraphPopup } from '..';
import { getSlugSkupiny } from '../../utils/hodnotiaceParametre.utils';
import routes from '../../routes';

interface PopupState {
  id: number;
  isVisible: boolean;
  position: { x: number; y: number };
}

type Props = {
  predbezny?: boolean;
  skupinyParametrov: SkupinyParametrov;
  vysledokObdobia?: VysledokObdobia;
} & React.JSX.IntrinsicElements['div'];

function calculateSliceCenterCoordinates({
  cx,
  cy,
  innerRadius,
  midAngle,
  multiplyBy = 0.35,
  outerRadius,
}) {
  const radius = innerRadius + (outerRadius - innerRadius) * multiplyBy;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return { x, y };
}

function renderCustomizedLabel({
  cx,
  cy,
  innerRadius,
  midAngle,
  name,
  outerRadius,
}: any) {
  const { x, y } = calculateSliceCenterCoordinates({
    cx,
    cy,
    innerRadius,
    midAngle,
    outerRadius,
  });

  return (
    <text
      dominantBaseline="central"
      fill="grey"
      style={{ pointerEvents: 'none' }}
      textAnchor={x > cx ? 'start' : 'end'}
      x={x}
      y={y}
    >
      {name}
    </text>
  );
}

const PieGraph = ({
  predbezny,
  skupinyParametrov,
  vysledokObdobia,
  ...other
}: Props) => {
  const classes = useStyles();
  const [popup, setPopup] = useState<PopupState>({
    isVisible: false,
    id: 0,
    position: {
      x: 0,
      y: 0,
    },
  });

  const skupiny = useMemo(() => values(skupinyParametrov), [skupinyParametrov]);
  const parametre = useMemo(
    () =>
      flatMap(
        skupinyParametrov,
        (skupina: SkupinaParametrov) => skupina.parametre,
      ),
    [skupinyParametrov],
  );

  return (
    <div className={classes.pieGraphWrapper} {...other}>
      <ResponsiveContainer aspect={1} debounce={10} maxHeight={350}>
        <PieChart>
          <Pie
            data={skupiny}
            dataKey="vaha"
            fill={color('white')}
            isAnimationActive={false}
            label={renderCustomizedLabel}
            nameKey="nazov"
            onMouseEnter={(
              { cx, cy, innerRadius, midAngle, outerRadius },
              id,
            ) =>
              setPopup({
                id,
                isVisible: true,
                position: {
                  ...calculateSliceCenterCoordinates({
                    cx,
                    cy,
                    innerRadius,
                    midAngle,
                    multiplyBy: 0.55,
                    outerRadius,
                  }),
                },
              })
            }
            outerRadius="100%"
          >
            {skupiny.map((skupina, key) => (
              <Cell key={`cell-${key}`} stroke={color('grey', 200)} />
            ))}
          </Pie>
          <Pie
            data={parametre}
            dataKey="vaha"
            innerRadius="95%"
            isAnimationActive={false}
            outerRadius="100%"
            stroke={color('grey', 200)}
          >
            {parametre.map((parameter: Parameter, key) => (
              <Cell
                key={key}
                fill={parameter.plnenie ? color('primary') : color('error')}
                stroke={color('white')}
              />
            ))}
          </Pie>
          {vysledokObdobia && (
            <g>
              <circle
                className={classes.innerCircle}
                cx="50%"
                cy="50%"
                r="11%"
              />
              <text
                className={classes.innerCircleText}
                dy=".3em"
                textAnchor="middle"
                x="50%"
                y="50%"
              >
                {getPercentage(vysledokObdobia.cena, 0)}%
              </text>
            </g>
          )}
        </PieChart>
      </ResponsiveContainer>

      {skupiny && skupiny[0] && (
        <GraphPopup
          href={`${routes.hp}/${getSlugSkupiny(skupiny[popup.id].nazov)}`}
          isVisible={popup.isVisible}
          nazov={skupiny[popup.id].nazov}
          onMouseEnter={() =>
            setPopup((prevState) => ({ ...prevState, isVisible: true }))
          }
          onMouseLeave={() =>
            setPopup((prevState) => ({ ...prevState, isVisible: false }))
          }
          predbezny={predbezny!!}
          skupina={skupiny[popup.id]}
          x={popup.position.x}
          y={popup.position.y}
        />
      )}
    </div>
  );
};

export default PieGraph;
