import { useMemo } from 'react';
import useStyles from './CategoryCardExchange.styles';
import { Parameter } from '../../../slices/hodnotiaceParametre.slice';
import CategoryCardRow from '../CategoryCardRow';
import IconExchange from '../../CustomIcons/IconExchange';
import { useNavigate } from 'react-router';
import { ButtonSecondary, color } from '@dovera/design-system';
import { isSTAT } from '../../../utils/app.utils';

interface Props {
  href?: string;
  parameters: Parameter[];
}

const CategoryCardExchange = ({ href, parameters }: Props) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const changeIds = parameters[0]?.moznaVymenaZaParamId;
  const firstParameter = useMemo(
    () =>
      parameters.filter(
        (p) =>
          (p.moznaVymenaZaParamId === changeIds && p?.vybranyParameterPZS) ||
          (p.defaultParameter &&
            !parameters.some((par) => par.vybranyParameterPZS)),
      )[0],
    [changeIds, parameters],
  );
  const secondParameter = useMemo(
    () =>
      parameters.filter(
        (p) =>
          p.moznaVymenaZaParamId === changeIds && p.id !== firstParameter?.id,
      )[0],
    [changeIds, firstParameter?.id, parameters],
  );
  const detailUrl = `${href}/${secondParameter?.kodParametra}`;
  const middleText = firstParameter?.vybranyParameterPZS
    ? 'Tento parameter bol vymenený za pôvodný parameter'
    : 'Tento parameter môže štatutár vymeniť za nový:';
  const renderExchangeBtn = isSTAT() && (
    <ButtonSecondary
      className={classes.exchangeBtn}
      onClick={() => navigate(detailUrl)}
    >
      <IconExchange color={color('primary', 600)} id="btn-exchange" />
      Vymeniť parameter
    </ButtonSecondary>
  );
  if (!changeIds || !firstParameter || !secondParameter) return <span />;
  return (
    <div className={classes.exchangeWrapper}>
      <CategoryCardRow
        hodnota={firstParameter.hodnota}
        hranicnaHodnotaMax={firstParameter.hranicnaHodnotaMax}
        hranicnaHodnotaMin={firstParameter.hranicnaHodnotaMin}
        href={href}
        isFuture
        kodParametra={firstParameter.kodParametra || ''}
        nazovParametra={firstParameter.nazovParametra}
        pasmo={firstParameter.pasmo}
        pictogram={firstParameter.vybranyParameterPZS ? 'star' : null}
        plnenie={firstParameter.plnenie}
        popisParametra={firstParameter.popisParametra}
        predbezny={false}
        typHodnoty={firstParameter.typHodnoty}
      />
      <div>
        {!isSTAT() && !firstParameter.vybranyParameterPZS ? (
          <>
            <IconExchange id={`exchange--${firstParameter.id}`} />
            <button onClick={() => navigate(detailUrl)} type="button">
              {middleText}
            </button>
          </>
        ) : (
          <b>{middleText}</b>
        )}
      </div>
      <CategoryCardRow
        hodnota={secondParameter.hodnota}
        hranicnaHodnotaMax={secondParameter.hranicnaHodnotaMax}
        hranicnaHodnotaMin={secondParameter.hranicnaHodnotaMin}
        href={href}
        isFuture
        kodParametra={secondParameter.kodParametra || ''}
        nazovParametra={secondParameter.nazovParametra}
        pasmo={secondParameter.pasmo}
        pictogram={secondParameter.vybranyParameterPZS ? 'star' : null}
        plnenie={secondParameter.plnenie}
        popisParametra={secondParameter.popisParametra}
        predbezny={false}
        typHodnoty={secondParameter.typHodnoty}
      />
      {renderExchangeBtn}
    </div>
  );
};

export default CategoryCardExchange;
