const CobrowseTest = () => (
  <div>
    <h1>Cobrowse.io Cross Domain Example</h1>
    <p>
      This page will allow sessions to continue to pages hosted on a domain of
      your choice, codepen, and localhost. Follow the instructions in the README
      to try it yourself!
      <ul>
        <li>
          {' '}
          PLATITEL:{' '}
          <a
            href="https://test.zamestnavatel.mojadovera.sk/cobrowse-test"
            rel="noreferrer"
            target="_self"
          >
            https://test.zamestnavatel.mojadovera.sk/cobrowse-test
          </a>
        </li>
        <li>
          {' '}
          IDP:{' '}
          <a
            href="https://test.prihlasenie.dovera.sk/oidc/login/"
            rel="noreferrer"
            target="_self"
          >
            https://test.prihlasenie.dovera.sk/oidc/login/
          </a>
        </li>
        <li>
          {' '}
          MD:{' '}
          <a
            href="https://test.mojadovera.sk/poistenec/registracia"
            rel="noreferrer"
            target="_self"
          >
            https://test.mojadovera.sk/poistenec/registracia
          </a>
        </li>
        <li>
          {' '}
          Stara EP:{' '}
          <a
            href="https://iweb10t.dovera.local/EP_SSO/Provider/Index.aspx"
            rel="noreferrer"
            target="_self"
          >
            https://iweb10t.dovera.local/EP_SSO/Provider/Index.aspx
          </a>
        </li>
      </ul>
    </p>
  </div>
);

export default CobrowseTest;
