interface Props {
  className?: string;
  id: string;
}

const IconWarningCircleSmall = ({ className, id }: Props) => (
  <svg
    className={className}
    fill="none"
    height="16"
    id={id}
    viewBox="0 0 20 21"
    width="16"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0)">
      <path
        d="M0 10.5C0 16.0228 4.47715 20.5 10 20.5C15.5228 20.5 20 16.0228 20 10.5C20 4.97715 15.5228 0.5 10 0.5C4.47715 0.5 0 4.97715 0 10.5Z"
        fill="#FF9800"
      />
      <path
        d="M10 6V11"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2.5"
      />
      <circle cx="10" cy="14.7002" fill="white" r="1.5" />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect
          fill="white"
          height="20"
          transform="translate(0 0.5)"
          width="20"
        />
      </clipPath>
    </defs>
  </svg>
);

export default IconWarningCircleSmall;
