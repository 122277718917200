// @ts-nocheck
import useStyles from './HPBanner.styles';
import Illustration from '../../../assets/illustrations/HP-change-dashboard-illustration.png';
import { ButtonSecondary } from '@dovera/design-system';
import { useCallback } from 'react';
import { useNavigate } from 'react-router';
import { cx } from '../../../utils/exports';
// @ts-check

interface Props {
  isLarge: boolean;
  path: string;
}

const HPBanner = ({ isLarge, path }: Props) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const clickCallback = useCallback(() => {
    navigate(path);
  }, [navigate, path]);
  const renderContent = (
    <div>
      <h3 className="mb-small">Prispôsobte si hodnotiace parametre</h3>
      <p className={cx(isLarge ? 'mb-large' : 'mb-small')}>
        Odteraz Vám ponúkame možnosť vymeniť nový parameter za pôvodný, vďaka
        čomu môžete dosiahnuť lepšie hodnotenie a získať vyššiu cenu bodu.{' '}
      </p>
      <ButtonSecondary className="no-mrg" onClick={clickCallback}>
        Zobraziť nový parameter
      </ButtonSecondary>
    </div>
  );
  if (isLarge)
    return (
      <div className={cx(classes.hpBanner, isLarge && classes.large)}>
        <img alt="Ilustrácia" src={Illustration} />
        {renderContent}
      </div>
    );
  return <div className={classes.hpBanner}>{renderContent}</div>;
};

export default HPBanner;
