import { round } from 'lodash-es';

export function getPercentage(
  value?: number | null,
  toFixed: number = 2,
): number {
  if (!value) return 0;
  return parseFloat((value * 100).toFixed(toFixed));
}

export function replaceWithComma(value?: number | string): string {
  return value ? value.toString().replace('.', ',') : '0';
}

export function getPriceFormat(
  value: number | string | null,
  withEuro?: boolean,
  nullValue?: string,
): string {
  if (nullValue && value === null) return nullValue;
  let euroSymbol: string = '';
  if (withEuro) euroSymbol = ' €';
  if (!value) return `0${euroSymbol}`;
  if (
    value.toString().includes('.') &&
    value.toString().split('.')[1].length > 2
  )
    return `${replaceWithComma(
      Number(value.toString().replace(',', '.')),
    )}${euroSymbol}`.replace(/ /g, '\u00a0');
  return `${replaceWithComma(
    getValueWithNDecimal(Number(value.toString().replace(',', '.'))),
  )}${euroSymbol}`
    .replace(/\B(?=(\d{3}){1,150}(?!\d))/g, ' ')
    .replace(/ /g, '\u00a0');
}

export const currencyNumberFormat = (v: string | number): number => {
  if (!v) return 0;
  return parseFloat(
    v.toString().replace(/\s/g, '').replace(/€/, '').replace(',', '.'),
  );
};

export const currencySplitter = (s: string): string =>
  s.replace(/\B(?=(\d{3}){1,150}(?!\d))/g, ' ').replace('.', ',');

export function getValueWithNDecimal(
  value: number,
  toFixed: number = 2,
  format: string = ',',
): string {
  if (!value) return '0';
  if (Number.isInteger(value)) return value.toString();
  return value.toFixed(toFixed).replace('.', format);
}

export function numberBetween(
  value: number,
  min: number,
  max: number,
): boolean {
  return value >= min && value <= max;
}

export const safeNumber = (value: any): number => {
  if (!Number.isNaN(value)) return value;
  const converted = Number(value);
  if (Number.isNaN(converted)) return 0;
  return converted;
};

export const getInteger = (value: number): number =>
  Number.parseInt(value.toString(), 10);

export const roundFloat = (value: number): number => round(value * 100) / 100;

export const getRandomNumber = (): number => {
  const array = new Uint32Array(1);
  crypto.getRandomValues(array);
  return array[0] / 2 ** 32;
};
