// @ts-nocheck
import { ReactNode } from 'react';
import { Circle, Path, StyleSheet, Svg, Text, View } from '@react-pdf/renderer';

const styles = StyleSheet.create({
  root: {
    borderRadius: 50,
    color: '#FFFFFF',
    padding: 3,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    maxWidth: 80,
    height: 24,
  },
  percentage: {
    fontWeight: 'bold',
    fontSize: 14,
    paddingLeft: 3,
    paddingRight: 2,
    lineHeight: 1,
    marginTop: -3,
  },
});

interface Props {
  category: 'Q' | 'E' | 'I';
  percentage: number;
}

const CategoryBadge = ({ category, percentage }: Props) => {
  let color: string = '';
  let icon: ReactNode;
  switch (category) {
    case 'Q': {
      color = '#EA5DD1';
      icon = (
        <Svg style={{ width: 20, height: 20, marginTop: -2 }}>
          <Circle cx="10" cy="10" fill={color} r="10" />
          <Path
            d="M10.4918 15.623C10.2415 15.942 9.75845 15.942 9.50822 15.623L4.05252 8.66696C3.8749 8.44049 3.8749 8.12201 4.05252 7.89554L5.64566 5.86429C5.76413 5.71323 5.94546 5.625 6.13744 5.625L13.8626 5.625C14.0545 5.625 14.2359 5.71323 14.3543 5.86429L15.9475 7.89554C16.1251 8.12201 16.1251 8.44049 15.9475 8.66696L10.4918 15.623Z"
            stroke="white"
            strokeWidth="1.25"
          />
        </Svg>
      );
      break;
    }
    case 'E': {
      color = '#42D848';
      icon = (
        <Svg style={{ width: 18, height: 13 }}>
          <Path
            d="M14.17 5.96197C14.17 4.70758 13.7139 3.49596 12.8869 2.55285C12.0598 1.60975 10.918 0.999482 9.67438 0.83575C8.43072 0.672019 7.16994 0.965991 6.12695 1.66289"
            stroke="white"
            strokeLinecap="round"
            strokeWidth="1.25"
          />
          <Path
            d="M14.6225 7.91396C14.3731 8.25963 13.8584 8.25963 13.6089 7.91396L12.2247 5.99585C11.9263 5.5825 12.2217 5.0051 12.7315 5.0051L15.5 5.0051C16.0098 5.0051 16.3051 5.5825 16.0068 5.99585L14.6225 7.91396Z"
            fill="white"
          />
          <Path
            d="M3.83004 6.45502C3.83004 7.70942 4.28606 8.92103 5.11314 9.86414C5.94022 10.8072 7.08195 11.4175 8.32562 11.5812C9.56928 11.745 10.8301 11.451 11.873 10.7541"
            stroke="white"
            strokeLinecap="round"
            strokeWidth="1.25"
          />
          <Path
            d="M3.37746 4.50401C3.62692 4.15834 4.1416 4.15834 4.39106 4.504L5.77534 6.42212C6.07365 6.83547 5.7783 7.41287 5.26854 7.41287L2.49998 7.41287C1.99023 7.41287 1.69487 6.83547 1.99318 6.42212L3.37746 4.50401Z"
            fill="white"
          />
        </Svg>
      );
      break;
    }
    default: {
      color = '#3EAEFF';
      icon = (
        <Svg style={{ width: 20, height: 20 }}>
          <Circle cx="10" cy="10" fill="#3EAEFF" r="10" />
          <Path
            clipRule="evenodd"
            d="M5 8.125C5 5.36357 7.23859 3.125 10 3.125C12.7614 3.125 15 5.36357 15 8.125C15 9.53779 14.4132 10.8146 13.4718 11.7232C13.2712 11.9168 13.1266 12.2075 13.0492 12.5538C12.9532 12.9835 12.7671 13.3931 12.5 13.7499V14.375C12.5 15.24 11.9142 15.9682 11.1178 16.1846C11.0578 16.3044 10.9789 16.4139 10.8839 16.5089C10.7678 16.625 10.63 16.717 10.4784 16.7798C10.3267 16.8427 10.1642 16.875 10 16.875C9.83585 16.875 9.6733 16.8427 9.52165 16.7798C9.36999 16.717 9.23219 16.625 9.11612 16.5089C9.02113 16.4139 8.94221 16.3044 8.88225 16.1846C8.08576 15.9682 7.5 15.24 7.5 14.375V13.7498C7.23319 13.3931 7.04735 12.9832 6.95122 12.5547C6.87343 12.2078 6.72868 11.9167 6.52786 11.7228C5.5866 10.8143 5 9.5376 5 8.125ZM10 4.375C7.92895 4.375 6.25 6.05393 6.25 8.125C6.25 9.18463 6.68866 10.1407 7.39598 10.8235C7.82354 11.2362 8.05755 11.7757 8.17091 12.2811C8.18756 12.3553 8.20863 12.4285 8.2339 12.5H9.375V8.75H8.75C8.40482 8.75 8.125 8.47018 8.125 8.125C8.125 7.77982 8.40482 7.5 8.75 7.5H11.25C11.5952 7.5 11.875 7.77982 11.875 8.125C11.875 8.47018 11.5952 8.75 11.25 8.75H10.625V12.5H11.7665C11.7917 12.4285 11.8127 12.3554 11.8293 12.2811C11.9423 11.7756 12.1763 11.2363 12.6037 10.8237C13.3112 10.141 13.75 9.18477 13.75 8.125C13.75 6.05393 12.0711 4.375 10 4.375ZM10 13.75H8.75V14.375C8.75 14.7202 9.02983 15 9.375 15H10.625C10.9702 15 11.25 14.7202 11.25 14.375V13.75H10Z"
            fill="#F7F7F7"
            fillRule="evenodd"
          />
        </Svg>
      );
    }
  }

  return (
    <View style={{ ...styles.root, backgroundColor: color }}>
      {icon}
      <Text style={styles.percentage}>{`${percentage} %`}</Text>
    </View>
  );
};

export default CategoryBadge;
