import { createUseStyles } from 'react-jss';

import { color, fontWeight, spaces } from '../../constants';
import { getRem } from '@dovera/design-system';

export default createUseStyles({
  barItem: {
    display: 'block',
    marginBottom: `${getRem(12)} !important`,
    '&:not(:last-child)': {
      marginRight: getRem(40),
    },
  },
  icon: {
    marginBottom: spaces.xxxsmall,
    display: 'inline-flex',
  },
  nazov: {
    fontSize: '0.875rem',
    fontWeight: fontWeight.semiBold,
    color: color('grey', 500),
    display: 'inline-block',
    verticalAlign: 'middle',
    marginTop: -24,
    marginLeft: 10,
  },
});
