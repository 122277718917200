import React, { Ref, forwardRef } from 'react';
import { Bar, BarItem, Card } from '@dovera/design-system';

import useStyles from './GraphTooltip.styles';

import {
  Parameter,
  SkupinaParametrov,
} from '../../slices/hodnotiaceParametre.slice';

import { getPercentage } from '../../utils/number.utils';

import GraphTooltipItem from './GraphTooltipItem';

type GraphTooltipProps = {
  buduci: boolean;
  href?: string;
  isVisible?: boolean;
  nazov: string;
  onHide?: () => void;
  predbezny: boolean;
  skupina: SkupinaParametrov;
  x: number;
  y: number;
} & React.JSX.IntrinsicElements['div'];

const GraphTooltip = forwardRef(
  (
    {
      buduci,
      href,
      isVisible,
      nazov,
      predbezny,
      skupina,
      x,
      y,
      ...other
    }: GraphTooltipProps,
    ref: Ref<HTMLDivElement>,
  ) => {
    const classes = useStyles({
      x,
      y,
      isVisible,
      poradie: skupina?.poradie,
    });

    const title = href ? (
      <Bar className="mb-xxxsmall" space="small">
        <BarItem className="text-left" isFilling>
          <h4>{nazov}</h4>
        </BarItem>
        {/* <BarItem>
          <Icon
            className="clickable"
            color={color('grey', 200)}
            name="close"
            onClick={onHide}
            size="medium"
          />
        </BarItem> */}
      </Bar>
    ) : (
      <h4 className="mb-xxxsmall">{nazov}</h4>
    );
    return (
      // eslint-disable-next-line
      <div ref={ref} className={classes.graphTooltip} {...other}>
        <Card className={classes.card}>
          {title}
          {skupina && (
            <>
              {!buduci ? (
                <Bar className="mb-xxxsmall" space="small">
                  {/* <BarItem>
                    <p className="text-color-grey text-left mb-xxxsmall text-small">
                      Váha: <strong>{getPercentage(skupina.vaha, 0)} %</strong>
                    </p>
                  </BarItem> */}
                  <BarItem>
                    <p className="text-color-grey text-left mb-xxxsmall text-small">
                      Plnenie:{' '}
                      <span className="text-color-black">
                        <strong>{getPercentage(skupina.plnenie, 0)} %</strong>
                      </span>
                    </p>
                  </BarItem>
                </Bar>
              ) : (
                <div />
              )}
              {skupina.parametre.map(
                ({
                  hodnota,
                  kodParametra,
                  nazovParametra,
                  pasmo,
                  plnenie,
                  typHodnoty,
                }: Parameter) => (
                  <GraphTooltipItem
                    key={nazovParametra}
                    hodnota={hodnota}
                    href={href}
                    kodParametra={kodParametra || ''}
                    nazovParametra={nazovParametra}
                    pasmo={pasmo}
                    plnenie={plnenie}
                    predbezny={predbezny || buduci}
                    typHodnoty={typHodnoty}
                  />
                ),
              )}
            </>
          )}
        </Card>
      </div>
    );
  },
);

export default GraphTooltip;
