import { breakpoints, getRem } from '@dovera/design-system';
import { createUseStyles } from 'react-jss';

import { color } from '../../constants';

export default createUseStyles({
  root: {
    '& input': {
      paddingRight: 0,
      minHeight: '40px !important',
      padding: '.42857rem 0 .42857rem .85714rem !important',
      cursor: 'pointer !important',
      color: [color('black'), '!important'],
      '&:focus': {
        boxShadow: 'none',
      },
    },
    '& .form-control--input': {
      cursor: 'pointer',
    },
    '& .input-addons__item': {
      height: 40,
    },
  },
  choices: {
    display: 'none',
    position: 'absolute',
    zIndex: 999,
    width: getRem(345),
    maxHeight: getRem(400),
    overflowY: 'auto',
    padding: getRem(15),
    backgroundColor: color('white'),
    boxShadow: '0px 10px 15px rgba(0, 0, 0, 0.0451483)',
    borderRadius: 8,
    '& .form-control--checkbox:not(:first-child):not(:last-child)': {
      margin: '15px 0 !important',
    },
    '&.activeArea': {
      display: 'block',
    },
    color: color('black'),
    '& button': {
      color: color('black'),
      fontWeight: 100,
      width: '100%',
      padding: 12,
      textDecoration: 'none',
      '&:hover': {
        textDecoration: 'none',
        backgroundColor: color('success', 100),
      },
    },
    [`@media screen and (max-width: ${breakpoints.l}px)`]: {
      marginTop: getRem(-32),
      width: '100%',
      maxHeight: getRem(250),
    },
  },
  activeChoices: {
    '& svg': {
      transform: 'rotate(180deg)',
    },
  },
  odbornosti: {
    display: 'inline-block',
    width: getRem(251),
    '& input': {
      cursor: 'pointer',
    },
    [`@media screen and (min-width: ${
      breakpoints.m + 1
    }px) and (max-width: 1100px)`]: {
      width: getRem(200),
    },
    [`@media screen and (max-width: ${breakpoints.l}px)`]: {
      marginRight: getRem(90),
      width: '100%',
      position: 'relative',
    },
  },
});
