import { createUseStyles } from 'react-jss';
import { color } from '../../constants';

export default createUseStyles({
  bar: {
    flexWrap: 'initial',
  },
  primaryColor: {
    color: color('primary', 500),
    '@media(min-width: 1300px) and (max-width: 1530px)': {
      maxWidth: '80%',
    },
  },
  icon: {
    '& svg': {
      verticalAlign: 'middle',
      marginTop: -5,
      marginRight: 10,
    },
  },
});
