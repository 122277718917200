import { color as ddsColor } from '../../constants';

interface Props {
  color?: string;
  height?: number | string;
  id: string;
  width?: number | string;
}

const IconClose = ({ color, height, id, width }: Props) => (
  <svg
    fill="none"
    height={height || 24}
    id={id}
    viewBox="0 0 24 24"
    width={width || 24}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18 6L6 18"
      stroke={color || ddsColor('primary', 600)}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    />
    <path
      d="M6 6L18 18"
      stroke={color || ddsColor('primary', 600)}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    />
  </svg>
);

export default IconClose;
