import { isArray } from 'lodash';
import { StringMap } from '../types';
import { iterateObject } from './object.utils';

export const createQueryParams = ({
  hasAnotherQueries,
  queryParams = {},
  urlEncoded,
  withoutEmpty,
}: {
  hasAnotherQueries?: boolean;
  queryParams: StringMap<
    string | number | string[] | number[] | boolean | undefined | null
  >;
  urlEncoded?: boolean;
  withoutEmpty?: boolean;
}): string => {
  let result: string = '';
  let counter = 0;
  iterateObject(queryParams, (value, key) => {
    let res = '';
    if (counter === 0 && !hasAnotherQueries) {
      res += '?';
    } else {
      res += '&';
    }
    if (!(withoutEmpty && !value?.toString()) && !isArray(value)) {
      result += `${res}${key}=${value}`;
      counter++;
    }
    if (isArray(value)) {
      value.forEach((v, index) => {
        const prefix = res === '?' && index > 0 ? '&' : res;
        result += `${prefix}${key}=${v}`;
      });
    }
  });
  if (urlEncoded) return encodeURIComponent(result);
  return result;
};

export const createUrl = (
  url: string,
  queryParams?: StringMap<string>,
): string =>
  // @ts-ignore
  `${window.env.config.apiPath}/${url}${createQueryParams({ queryParams })}`;
