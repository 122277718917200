interface Props {
  height?: string | number;
  id: string;
  width?: string | number;
}

const IconCheckCircle = ({ height = 50, id, width = 50 }: Props) => (
  <svg
    fill="none"
    height={height}
    id={id}
    viewBox="0 0 50 50"
    width={width}
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="25" cy="25" fill="#4CAF50" r="25" />
    <path
      d="M15 26.8L20.5417 32L34 19"
      stroke="white"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="4"
    />
  </svg>
);

export default IconCheckCircle;
