import React, { useMemo } from 'react';
import { Cell, Pie, PieChart, ResponsiveContainer } from 'recharts';
import { flatMap } from 'lodash-es';

import useStyles from './PieGraphBlured.styles';

import {
  Parameter,
  SkupinaParametrov,
  SkupinyParametrov,
  VysledokObdobia,
} from '../../slices/hodnotiaceParametre.slice';

import { color } from '../../constants';
import { getPercentage } from '../../utils/number.utils';

type Props = {
  skupinyParametrov: SkupinyParametrov;
  vysledokObdobia?: VysledokObdobia;
} & React.JSX.IntrinsicElements['div'];

const PieGraphBlured = ({ skupinyParametrov, vysledokObdobia }: Props) => {
  const classes = useStyles();

  const parametre = useMemo(
    () =>
      flatMap(
        skupinyParametrov,
        (skupina: SkupinaParametrov) => skupina.parametre,
      ),
    [skupinyParametrov],
  );

  return (
    <ResponsiveContainer
      aspect={1}
      className={classes.pieGraphBlured}
      debounce={10}
      maxHeight={150}
    >
      <PieChart>
        <Pie
          data={parametre}
          dataKey="vaha"
          fill={color('white')}
          isAnimationActive={false}
          outerRadius="100%"
        />
        <Pie
          data={parametre}
          dataKey="vaha"
          innerRadius="90%"
          isAnimationActive={false}
          outerRadius="100%"
          paddingAngle={2}
        >
          {parametre.map((parameter: Parameter, key) => (
            <Cell
              key={key}
              fill={parameter.plnenie ? color('primary') : color('error')}
              stroke={color('white')}
            />
          ))}
        </Pie>
        {vysledokObdobia && (
          <text
            className={classes.centeredText}
            dy=".3em"
            textAnchor="middle"
            x="50%"
            y="50%"
          >
            {getPercentage(vysledokObdobia.cena, 0)}%
          </text>
        )}
      </PieChart>
    </ResponsiveContainer>
  );
};

export default PieGraphBlured;
