// @ts-nocheck
import { useEffect, useState } from 'react';
import { Button } from '@dovera/design-system';
import routes from '../../routes';
import Illustration from '../../assets/illustrations/notFound.png';
import { useNavigate } from 'react-router';
// @ts-check

const ChybaPrihlasenia = () => {
  const [chybaSystemovyCas] = useState(false);
  const navigate = useNavigate();
  //   const [dajAktualnyCas] = requests.status.dajAktualnyCas(response => {
  //     const serverTime = format(
  //       toISOTimestamp(response.data.aktualnyDatumCas),
  //       'H:m',
  //     );
  //     const clientTime = `${new Date().getHours()}:${new Date().getMinutes()}`;
  //     if (serverTime !== clientTime) setChybaSystemovyCas(true);
  //   });
  useEffect(() => {
    if (!localStorage.getItem('poskytovatel_neuspesne_prihlasenie')) {
      navigate(routes.dashboard);
    } else {
      setTimeout(() => {
        localStorage.removeItem('poskytovatel_neuspesne_prihlasenie');
      }, 5000);
    }
    /* eslint-disable-next-line */
  }, []);
  return (
    <div className="notSupportedBrowsers" id="notSupportedBrowser">
      <div className="notSupportedBrowsers--header">
        <img alt="Logo" src="unsupportedBrowsers/logo.png" />
      </div>
      <div className="notSupportedBrowsers--body">
        <div>
          <img
            alt="Warning"
            className="notSupportedBrowsers--body--warning mb"
            src={Illustration}
          />
          <h4 className="mb-small text-color-error">
            Prepáčte, nepodarilo sa prihlásiť
          </h4>
          {chybaSystemovyCas ? (
            <p className="mb-large">
              Skúste načítať stránku znova. Ak chyba pretrváva,
              <strong>
                {' '}
                nastavte vo Windowse správny aktuálny čas na dolnej pracovnej
                lište vpravo
              </strong>
              .
            </p>
          ) : (
            <p className="mb-large">
              Kliknutím na tlačidlo si znova načítajte stránku.
              <br />
              Ak chyba pretrváva, prosím, napíšte nám na{' '}
              <a href="mailto:poskytovatel@dovera.sk">poskytovatel@dovera.sk</a>
              .
            </p>
          )}
          <Button onClick={() => navigate(routes.dashboard)} size="s">
            Načítať znovu
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ChybaPrihlasenia;
