import { breakpoints, getRem } from '@dovera/design-system';
import { createUseStyles } from 'react-jss';

export default createUseStyles({
  hpBanner: {
    display: 'flex',
    background: 'transparent',
    padding: getRem(30),
    alignItems: 'center',
    '& img': {
      width: getRem(248),
      marginRight: getRem(32),
    },
    '& > div p': {
      maxWidth: '100%',
    },
    [`@media screen and (max-width: ${breakpoints.m}px)`]: {
      padding: `${getRem(16)} !important`,
      '& img': {
        display: 'none',
      },
    },
  },
  large: {
    padding: `${getRem(32)} ${getRem(56)}`,
    '& > div p': {
      maxWidth: getRem(492),
    },
  },
});
