import { ButtonLink, Icon, Loader } from '@dovera/design-system';
import { useCallback, useState } from 'react';
import * as Excel from 'exceljs';
import { saveAs } from 'file-saver';
import { useSelector } from 'react-redux';
import { getHPReportData } from '../../../api/hp';
import { RootState } from '../../../rootReducer';
import { GetReportDataResponse } from '../../../types/hp.types';
import useStyles from './ExportXlsxReport.styles';
import {
  DATE_FORMAT_SLOVAK,
  DATE_TIME_FORMAT,
  getMoment,
} from '../../../utils/date.utils';
import _ from 'lodash';
import { uniqueArray } from '../../../utils/array.utils';
import { getPZS } from '../../../slices/auth.slice';
import { getAlphabetLetter } from '../../../utils/strings.utils';
import { formatHodnota } from '../../../utils/hodnotiaceParametre.utils';
import { cx } from '../../../utils/exports';

const blobType =
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';

const getTypePrice = (odb: string, typCena: string, typZs: string): string => {
  if (typCena === 'CenaBodu' && Number(typZs) === 200) return 'Cena bodu ŠAS';
  const VLD =
    Number(typZs) === 101 ? 'VLD' : Number(typZs) === 102 ? 'VLDD' : '';
  if (typCena.toLowerCase().includes('vykon'))
    return `Výkony v kapitačno-výkonovom kontrakte ${VLD}`;
  if (typCena === 'DodKap')
    return `Dodatková kapitácia ${odb !== '009' ? VLD : 'GYN'}`;
  if (typCena === 'DodKapZakl' && odb === '009')
    return 'Základ pre výpočet DK pre GYN';
  if (typCena === 'PlneniePar' && odb === '009' && typZs === '103')
    return 'Hodnotiaci koeficient GYN';
  return '';
};

interface Props {
  buttonTitle?: string;
  idCyklus: number;
  isRelative?: boolean;
  plnenie: string;
  predbezne?: boolean;
}

const ExportXlsxReport = ({
  buttonTitle,
  idCyklus,
  isRelative,
  plnenie,
  predbezne,
}: Props) => {
  const FILE_NAME = `prehlad-za-vsetky-odbornosti-(${plnenie})-${getMoment().format(
    DATE_TIME_FORMAT,
  )}.xlsx`;

  const classes = useStyles({ isRelative });
  const [isLoading, setIsLoading] = useState(false);
  const pzs = useSelector((state: RootState) => getPZS(state.auth));
  const idHodnotenyPZS = useSelector(
    (state: RootState) => state.poskytovatel.idHodnotenyPZS,
  );
  const makeExport = async (data: GetReportDataResponse) => {
    const workbook = new Excel.Workbook();
    workbook.creator = 'DÔVERA - zdravotná poisťovňa';
    workbook.lastModifiedBy = 'DÔVERA - zdravotná poisťovňa';
    workbook.created = getMoment().toDate();
    workbook.modified = getMoment().toDate();
    workbook.lastPrinted = getMoment().toDate();
    const worksheet = workbook.addWorksheet(
      'Prehľad plnenia hodnotiacich parametrov za všetky odbornosti',
    );
    worksheet.getCell(`A1`).value =
      'Prehľad plnenia hodnotiacich parametrov za všetky odbornosti';
    worksheet.mergeCells(`A1:H1`);
    worksheet.getCell(`A1`).alignment = { horizontal: 'center' };
    worksheet.getCell(`A1`).font = { size: 16, bold: true };
    const uhrada100 = 'Úhrada za 100% plnenie';
    worksheet.getRow(3).values = [
      'IČO PZS',
      'Názov PZS',
      'Kód odbornosti ambulancie (iba ŠAS)',
      'Kód výkonu (iba VLDD a DP)',
      'Typ (cena)',
      'Cena',
      'Dátum OD',
      'Dátum DO',
      'Úhrada navyše',
      ...(predbezne ? [uhrada100] : []),
    ];

    /**
     * zgroupenie na zaklade skupin parametra a nasledne zoradenie do jedneho pola
     * @date 19. 7. 2023 - 23:06:17
     *
     * @type {*}
     */
    const paramsHeader = Object.values(
      _.groupBy(
        data.dynamickaHlavicka.filter(
          (h) => !h.nazovSkupinaParametra.toLowerCase().includes('špeciálne'),
        ),
        'nazovSkupinaParametra',
      ),
    ).flat(1);

    const groups = uniqueArray(
      paramsHeader.map((h) => h.nazovSkupinaParametra),
    );

    // FROM A3
    const cellInitNumber = predbezne ? 10 : 9;
    let cellNumber = cellInitNumber; // poradove cislo aktualneho stlpca
    let cellLetter = '';
    groups.forEach((g, key) => {
      if (key > 0) cellNumber++;
      cellLetter = getAlphabetLetter(cellNumber, true);
      worksheet.getCell(`${cellLetter}3`).value = g.toUpperCase();
      cellNumber +=
        paramsHeader.filter((h) => h.nazovSkupinaParametra === g).length * 2 -
        1;
      worksheet.mergeCells(
        `${cellLetter}3:${getAlphabetLetter(cellNumber, true)}3`,
      );
      cellLetter = getAlphabetLetter(cellNumber, true);
    });

    let paramColsIndex = 0;
    paramsHeader.forEach((p) => {
      const firstColLetter = getAlphabetLetter(
        cellInitNumber + paramColsIndex,
        true,
      );
      const secondColLetter = getAlphabetLetter(
        cellInitNumber + paramColsIndex + 1,
        true,
      );
      worksheet.getCell(`${firstColLetter}4`).value = p.nazovParametra;
      worksheet.mergeCells(`${firstColLetter}4:${secondColLetter}4`);
      worksheet.getCell(`${firstColLetter}5`).value = 'Plnenie';
      worksheet.getCell(`${secondColLetter}5`).value = 'Hodnota';
      paramColsIndex += 2;
    });
    worksheet.getRow(5).font = { italic: true };

    const ico = data.icoHzPzs;
    const pzsName = data.nazovPzs;
    const rowForDates = data.reportData.filter(
      (d) => d.datumOd && d.datumDo,
    )?.[0];
    const from = getMoment(`${rowForDates?.datumOd}01`)
      .startOf('month')
      .format(DATE_FORMAT_SLOVAK);
    const to = getMoment(`${rowForDates?.datumDo}01`)
      .endOf('month')
      .format(DATE_FORMAT_SLOVAK);

    worksheet.addRows(
      uniqueArray(data.reportData)
        .filter(
          (d) =>
            Number(d.typZS) <= 200 &&
            (d.typCena !== 'PlneniePar' ||
              (d.typCena === 'PlneniePar' &&
                d.kodOdbornostiAmbulancie === '009' &&
                d.typZS === '103')),
        )
        .map((d) => {
          const paramValues: Array<number | string> = Array(
            paramsHeader.length,
          ).fill('');
          d.zoznamParametrov.forEach((z) => {
            const colIndex =
              paramsHeader.findIndex((p) => p.idStlpca === z.idStlpca) * 2;
            paramValues[colIndex] = formatHodnota(z.plnenie, z.plnenie, 'P');
            const value = paramsHeader
              .find((p) => p.idStlpca === z.idStlpca)
              ?.nazovParametra?.toLowerCase()
              ?.includes('subjektívna spokojnosť')
              ? '100 %'
              : formatHodnota(z.hodnota, z.plnenie, z.typHodnoty);
            paramValues[colIndex + 1] = value;
          });
          return [
            ico,
            pzsName,
            d.kodOdbornostiAmbulancie,
            d.typCena.toLowerCase().includes('vykon')
              ? d.typCena.toLowerCase().split('vykon')[1]
              : '',
            getTypePrice(d.kodOdbornostiAmbulancie, d.typCena, d.typZS),
            d.cena,
            from,
            to,
            d.uhradaNavyse,
            ...(predbezne ? [d.uhradaMax] : []),
            ...paramValues,
          ];
        }),
    );
    worksheet.columns.forEach((column, i) => {
      let maxLength = 0;
      // @ts-ignore
      column?.eachCell({ includeEmpty: true }, (cell) => {
        const columnLength = cell.value
          ? cell.value.toString().length - 10
          : 10;
        if (columnLength > maxLength) {
          maxLength = columnLength;
        }
      });
      column.width = i === 0 ? 5 : maxLength < 10 ? 10 : maxLength;
    });
    worksheet.columns.forEach((column, index) => {
      let stringLegth = 0;
      const isParamCol = (predbezne && index > 9) || (!predbezne && index > 8);
      if (isParamCol) {
        const paramName =
          paramsHeader[Number.parseInt(((index - 10) / 2).toString(), 10)]
            ?.nazovParametra;
        stringLegth =
          Number(paramName?.length) / 1.5 > 15
            ? Number(paramName?.length) / 1.7
            : 15;
      }
      const pzsNameWidth = pzsName.length / 1.5 || 30;
      switch (index) {
        case 1:
          column.width = pzsNameWidth > 15 ? pzsNameWidth : 15;
          break;
        case 4:
          column.width = 30;
          break;
        default:
          column.width = isParamCol ? stringLegth : 15;
          break;
      }
      column.alignment = { vertical: 'middle', wrapText: true };
      column.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' },
      };
    });

    // add description to next sheet
    const descWorksheet = workbook.addWorksheet(
      'Vysvetlivky k zobrazeným údajom',
    );
    descWorksheet.state = 'visible';
    descWorksheet.mergeCells('A1:Z1');
    descWorksheet.mergeCells('A2:Z2');
    descWorksheet.mergeCells('A3:Z3');
    descWorksheet.mergeCells('A4:Z4');
    descWorksheet.mergeCells('A5:Z5');

    descWorksheet.getRow(1).values = [
      'Hlavička tabuľky zobrazuje všetky parametre, ktoré sa týkajú jednotlivých odborností. Dosiahnutú hodnotu a percento plnenia konkrétneho parametra nájdete v riadku podľa odbornosti.',
    ];
    descWorksheet.getRow(2).values = [
      'Stĺpec "Plnenie" vyjadruje percentuálne plnenie parametra na základe dosiahnutej hodnoty.',
    ];
    descWorksheet.getRow(3).values = [
      'Stĺpec "Hodnota" vyjadruje dosiahnutú hodnotu pre daný parameter.',
    ];
    descWorksheet.getRow(4).values = [
      'Ak chcete zistiť dolnú a hornú hranicu plnenia jednotlivého parametra, prejdite na jeho detail v sekcii Prehľad parametrov v DôveraLekárom.',
    ];
    descWorksheet.getRow(5).values = [
      'Bližšie informácie o plnení jednotlivých parametrov nájdete v ich detaile v sekcii Prehľad parametrov v DôveraLekárom.',
    ];

    // save under export.xlsx
    await workbook.xlsx.writeBuffer().then((data) => {
      const blob = new Blob([data], { type: blobType });
      saveAs(blob, FILE_NAME);
      setIsLoading(false);
    });
  };
  const clickCallback = useCallback(() => {
    if (idHodnotenyPZS && pzs.vzId) {
      setIsLoading(true);
      getHPReportData({
        idCyklus,
        idHodnotenyPzs: idHodnotenyPZS,
        idVZ: pzs.vzId,
      }).then((data: GetReportDataResponse) => {
        makeExport(data);
      });
    }
    // eslint-disable-next-line
  }, [idCyklus, idHodnotenyPZS, pzs.vzId]);
  return (
    <div
      className={cx(
        classes.exportReport,
        classes.common,
        isRelative ? classes.responsiveRelative : classes.responsive,
      )}
    >
      <ButtonLink
        className="no-pad"
        isDisabled={isLoading}
        onClick={clickCallback}
      >
        {buttonTitle || 'Prehľad za všetky odbornosti'}
        {isLoading ? (
          <Loader color="#a7a9ac" size={16} />
        ) : (
          <Icon name="download" size="medium" />
        )}
      </ButtonLink>
    </div>
  );
};

export default ExportXlsxReport;
