import {
  Bar,
  BarItem,
  Card,
  CardSection,
  Container,
  Grid,
  GridCol,
  PageSection,
} from '@dovera/design-system';

import { Skeleton } from '../../components';

const DashboardSkeleton = () => (
  <>
    <PageSection space="small">
      <Container>
        {/* DashboardGraph */}

        <h1 className="h3 text-center">
          <Skeleton width="25%" />
        </h1>

        <Grid className="mb">
          <GridCol
            className="d-flex align-items-middle align-items-right show-m"
            offset={{ m: 1 }}
            size={{ m: 3 }}
          >
            <Skeleton isCircle width={300} />
          </GridCol>

          <GridCol
            className="text-center"
            offset={{ s: 2, m: 0 }}
            size={{ s: 8, m: 4 }}
          >
            <Skeleton isCircle width={350} />
          </GridCol>

          <GridCol
            className="d-flex align-items-middle align-items-left show-m"
            size={{ m: 3 }}
          >
            <Skeleton isCircle width={300} />
          </GridCol>
        </Grid>

        <Grid className="mb">
          <GridCol className="show-m" offset={{ m: 1 }} size={{ m: 2, l: 3 }}>
            <h3 className="no-mrg-bottom">
              <Skeleton />
            </h3>
            <span>
              <Skeleton />
            </span>
          </GridCol>

          <GridCol
            className="text-center"
            offset={{ s: 2, m: 0 }}
            size={{ s: 8, m: 6, l: 4 }}
          >
            <Grid hasGutters={false}>
              <GridCol
                className="d-flex align-items-bottom"
                offset={{ m: 1 }}
                size={{ xs: 1 }}
              />

              <GridCol
                className="d-flex-column align-items-center"
                size={{ xs: 10, m: 8 }}
              >
                <h2 className="mb-xxsmall">
                  <Skeleton width="75%" />
                </h2>
                <h3 className="mb-xsmall">
                  <Skeleton />
                </h3>
                <span>
                  <Skeleton />
                </span>
              </GridCol>

              <GridCol className="d-flex align-items-bottom" size={{ xs: 1 }} />
            </Grid>
          </GridCol>

          <GridCol className="show-m" size={{ m: 2, l: 3 }}>
            <h3 className="no-mrg-bottom">
              <Skeleton />
            </h3>
            <span>
              <Skeleton />
            </span>
          </GridCol>
        </Grid>
      </Container>
    </PageSection>

    <PageSection spaceBottom="small" spaceTop="none">
      <Container>
        {/* DashboardCenaBodu */}
        <Grid>
          <GridCol
            equalizeHeight
            offset={{ m: 1, l: 2 }}
            size={{ m: 10, l: 8 }}
          >
            <Card>
              <CardSection isCondensed>
                <Grid>
                  <GridCol size="auto">
                    <span className="no-mrg-bottom">
                      <Skeleton width="75%" />
                    </span>
                    <h4 className="no-mrg-bottom">
                      <Skeleton width="50%" />
                    </h4>
                  </GridCol>
                  <GridCol size="auto">
                    <span className="no-mrg-bottom">
                      <Skeleton width="75%" />
                    </span>
                    <h4 className="no-mrg-bottom">
                      <Skeleton width="50%" />
                    </h4>
                  </GridCol>
                </Grid>
              </CardSection>
            </Card>
          </GridCol>
        </Grid>
      </Container>
    </PageSection>

    <PageSection spaceBottom="small" spaceTop="none">
      <Container>
        {/* DashboardCategoryCards */}
        <Grid>
          {[...Array(3)].map((item, index) => (
            <GridCol key={index} equalizeHeight size={{ s: 6, l: 4 }}>
              <Card>
                <CardSection isCondensed>
                  {[...Array(3)].map((value, key) => (
                    <Bar key={key} align="top" space="small">
                      <BarItem style={{ width: '16px' }}>
                        <Skeleton isCircle width={16} />
                      </BarItem>
                      <BarItem isFilling>
                        <Skeleton width="60%" />
                        <Skeleton width="70%" />
                      </BarItem>
                      <BarItem style={{ width: '15%' }}>
                        <Skeleton width="75%" />
                        <Skeleton width="100%" />
                      </BarItem>
                    </Bar>
                  ))}
                </CardSection>
              </Card>
            </GridCol>
          ))}
        </Grid>
      </Container>
    </PageSection>
  </>
);

export default DashboardSkeleton;
