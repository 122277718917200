import { createUseStyles } from 'react-jss';

export default createUseStyles({
  overlayed: {
    position: 'relative',
    width: '100%',
    height: 'auto',
    zIndex: 1,
    opacity: 0.3,
    pointerEvents: 'none',
  },
  layer: {
    position: 'absolute',
    pointerEvents: 'none',
    width: '100%',
    height: '100%',
    background: `rgba(255, 255, 255, .7)`,
    zIndex: 999999,
    top: 0,
    left: 0,
  },
});
