import { createUseStyles } from 'react-jss';

import { borderRadius, color, fontWeight, spaces } from '../../constants';
import { isIE } from '../../utils/app.utils';

export default createUseStyles({
  graphPopupItem: {
    padding: spaces.xxsmall,
    fontWeight: fontWeight.semiBold,
    fontSize: '0.875rem',
    '&:nth-child(odd)': {
      backgroundColor: color('grey', 50),
      borderRadius: `${borderRadius}px !important`,
    },
    '& a': {
      textDecoration: 'none',
    },
  },
  nameBarItem: {
    display: isIE ? 'inline-block' : 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start !important',
    textAlign: 'left',
  },
  valueBarItem: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end !important',
  },
});
