/* eslint-disable */
// @ts-nocheck
import { useCallback, useEffect } from 'react';
import {
  Button,
  DropdownItem,
  HeaderUserMenu,
  Icon,
  breakpoints,
  color,
} from '@dovera/design-system';
import useStyles from './AppHeader.styles';

import MessagesCount from '../../components/MessagesCount/MessagesCount';
import { Header } from '../../components';
import { useSelector } from 'react-redux';
import { RootState } from '../../rootReducer';
import {
  initialState,
  switchDataPzsDoctor,
  zmenQueryOdbornost,
} from '../../slices/poskytovatel.slice';
import { setStandardMenuPage } from '../../slices/menu.slice';
import {
  firstCharToUpper,
  getAcronym,
  splitTextWithTooltip,
} from '../../utils/strings.utils';
import routes from '../../routes';
import { inlineCondition, safeNode, scrollToTop } from '../../utils/app.utils';
import { useLocation, useNavigate } from 'react-router-dom';
import usePrevious from '../../hooks/usePrevious';
import {
  dajObdobie,
  fetchHodnotiacePrametre,
} from '../../slices/hodnotiaceParametre.slice';
import { isEmpty } from 'lodash-es';
import { initVisibilityPzsAuth } from '../../slices/pzsAuth.slice';
import { useWindowSize } from '../../hooks/useWindowSize';
import CompanyImg from '../../assets/illustrations/pobocka-dlek.png';
import { cx } from '../../utils/exports';
import IconCheck from '../../components/CustomIcons/IconCheck';
import { useAppDispatch } from '../../hooks/useStore';
import { setLoadingForPatients } from '../../slices/pacienti.slice';
import { resetWidgetNaRiesenie } from '../../slices/widgetNaRiesenie.slice';
import { useQuery } from '../../hooks/useQuery';
import { URL_QUERIES } from '../../constants/systemConstants';
import { ExpertisesCombo } from '../../components/SelectOdbornosti';
import { CustomTooltip } from '../../components/CustomTooltip';
import { useAuthContext } from '../../hooks/useAuthContext';
// @ts-check

const AppHeader = ({ ...other }) => {
  const authContext = useAuthContext();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const windowSize = useWindowSize();
  const query = useQuery();
  const queryExpertise = query.get(URL_QUERIES.expertise);
  const queryPzsType = query.get(URL_QUERIES.pzsType);
  const {
    aktivnaOdbornost,
    autentifikovaneOsoby,
    idHodnotenyPZS,
    idVZ,
    isSUPR,
    menoLekara,
    menuState,
    nazovZobrazPzs,
    obdobia: { zoznamObdobi },
    poradie: { aktualnePoradie },
    preLekara,
    udajeAutentifikacie,
  } = useSelector((state: RootState) => ({
    aktivnaOdbornost: state.poskytovatel.odbornosti.some((d) => d.aktivna)
      ? state.poskytovatel.odbornosti.filter((d) =>
          state.poskytovatel.poslednaZmenaOdbornosti
            ? d.kodOdbNz === state.poskytovatel.poslednaZmenaOdbornosti &&
              (d.kodTypZS === state.poskytovatel.kodTypZs ||
                !state.poskytovatel.kodTypZs)
            : d.aktivna,
        )[0]
      : state.poskytovatel.odbornosti[0],
    autentifikovaneOsoby: state.poskytovatel.autentifikovaneOsoby,
    idHodnotenyPZS: state.poskytovatel.idHodnotenyPZS,
    idVZ: state.auth.vztahId,
    isSUPR: state.auth.isSUPR,
    kodTypZs: state.poskytovatel.kodTypZs,
    menoLekara: state.poskytovatel.menoZobrazLekar,
    menuState: state.menu,
    nazovZobrazPzs:
      state.poskytovatel.nazovZobrazPzs || initialState.nazovZobrazPzs,
    obdobia: state.hodnotiaceParametre.obdobia,
    odbornosti: state.poskytovatel.odbornosti,
    poradie: state.hodnotiaceParametre.poradie,
    preLekara: state.poskytovatel.preLekara,
    udajeAutentifikacie: state.pzsAuth.authAvailability.data,
    zmenenaOdbornost: state.poskytovatel.zmenenaOdbornost,
  }));
  const guid = useSelector((state: RootState) => state.auth.guid);
  const menoLekar = autentifikovaneOsoby?.udajeLekarOsoba?.lekarOsobaId
    ? `${firstCharToUpper(
        autentifikovaneOsoby?.udajeLekarOsoba?.meno || '',
      )} ${firstCharToUpper(
        autentifikovaneOsoby?.udajeLekarOsoba?.priezvisko || '',
      )}`
    : null;
  const acronym = getAcronym(
    !autentifikovaneOsoby?.udajeLekarOsoba?.lekarOsobaId
      ? menoLekara || ''
      : `${autentifikovaneOsoby?.udajeLekarOsoba?.meno} ${autentifikovaneOsoby?.udajeLekarOsoba?.priezvisko}`,
  );
  const classes = useStyles({
    pzsCharLength: ((menoLekar && preLekara && menoLekara) || nazovZobrazPzs)
      ?.length,
    guid,
  });
  const previousZoznamObdobi = usePrevious(zoznamObdobi);

  useEffect(() => {
    if (
      !new RegExp(`${routes.hp}/(.)*`).test(location.pathname) &&
      !new RegExp(`${routes.zmluvyDodatky}[/(.)*]?`).test(location.pathname) &&
      routes.pp !== location.pathname &&
      location.pathname !== routes.dashboard &&
      menuState.isStatic
    ) {
      dispatch(setStandardMenuPage());
    }
  }, [dispatch, location, menuState]);

  useEffect(() => {
    if (
      queryExpertise &&
      queryPzsType &&
      queryExpertise !== aktivnaOdbornost?.kodOdbNz
    ) {
      dispatch(
        zmenQueryOdbornost({
          odbornost: queryExpertise,
          kodTypZs: queryPzsType,
        }),
      );
    }
    // eslint-disable-next-line
  }, [aktivnaOdbornost, dispatch]);

  useEffect(() => {
    if (
      idVZ &&
      aktivnaOdbornost &&
      aktivnaOdbornost.povolenyVstupHK &&
      idHodnotenyPZS &&
      (location.pathname.includes(routes.hp) ||
        location.pathname === routes.dashboard) &&
      (!query.get(URL_QUERIES.pzsType) ||
        query.get(URL_QUERIES.pzsType) === aktivnaOdbornost.kodTypZS)
    ) {
      dispatch(
        fetchHodnotiacePrametre(
          idHodnotenyPZS,
          idVZ,
          aktivnaOdbornost.kodOdbNz,
          aktivnaOdbornost.kodTypZS,
        ),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idVZ, idHodnotenyPZS, aktivnaOdbornost]);

  useEffect(() => {
    // vypôsobí refresh kalkulácie poradia období keď sa zavolá `fetchHodnotiacePrametre`
    if (
      zoznamObdobi !== previousZoznamObdobi &&
      location.pathname.includes(routes.hp)
    ) {
      dispatch(dajObdobie({ poradie: aktualnePoradie }));
    }
  }, [
    aktualnePoradie,
    dispatch,
    location.pathname,
    previousZoznamObdobi,
    zoznamObdobi,
  ]);

  useEffect(() => {
    scrollToTop();
  }, [location.pathname, location.search]);

  useEffect(() => {
    if (udajeAutentifikacie)
      dispatch(
        initVisibilityPzsAuth({
          pocetOdmietnutiAutentifikacie:
            udajeAutentifikacie?.pocetOdmietnutiAutentifikacie || 0,
          vyzadovatAutentifikaciu:
            udajeAutentifikacie?.vyzadovatAutentifikaciu || false,
        }),
      );
  }, [dispatch, udajeAutentifikacie]);

  const switchPersonalizedAccount = useCallback(
    (forPZS: boolean) => {
      dispatch(setLoadingForPatients());
      dispatch(resetWidgetNaRiesenie());
      dispatch(switchDataPzsDoctor({ forPZS }));
    },
    [dispatch],
  );

  const handleLogout = useCallback(() => {
    authContext.logout('/odhlasenie');
  }, [authContext]);

  const renderLogoutButton = (
    <span className={cx('no-pad-right', classes.logoutButton)}>
      {!guid && <Icon className="icon--left" name="log-out" size="medium" />}
      {guid ? 'Prihlásiť sa' : 'Odhlásiť'}
    </span>
  );

  const renderInitials =
    !autentifikovaneOsoby?.udajeLekarOsoba?.lekarOsobaId || !isSUPR ? (
      <div className={cx(classes.userPlate)}>
        {inlineCondition(
          !!(preLekara && menoLekar),
          <span>{acronym}</span>,
          <img alt="Pobočka" src={CompanyImg} />,
        )}
      </div>
    ) : (
      <>
        <div className={cx(classes.userPlate, 'smaller')}>
          {inlineCondition(
            !!preLekara,
            <img alt="Pobočka" src={CompanyImg} />,
            <span>{acronym}</span>,
          )}
        </div>
        <div className={classes.userPlate}>
          {inlineCondition(
            !!preLekara,
            <span>{acronym}</span>,
            <img alt="Pobočka" src={CompanyImg} />,
          )}
        </div>
      </>
    );
  const renderPzsTitle = (
    <div className={classes.loggedUser} data-hj-masked>
      {windowSize.width <= 1300 ? (
        <CustomTooltip
          dialog={inlineCondition(
            !!(menoLekar && preLekara),
            menoLekar,
            nazovZobrazPzs,
          )}
          id={`pzs-initials--tooltip`}
        >
          {renderInitials}
        </CustomTooltip>
      ) : (
        renderInitials
      )}

      <div className="show-xl-only">
        {splitTextWithTooltip(
          'pzs-title-tooltip',
          menoLekar && preLekara ? menoLekar : nazovZobrazPzs,
          48,
        )}
      </div>
    </div>
  );
  const renderPZsSwitcher = autentifikovaneOsoby?.udajeLekarOsoba
    ?.lekarOsobaId &&
    isSUPR && (
      <div
        key="header--pzsSwitcher"
        className={classes.pzsSwitcher}
        data-hj-masked
      >
        <button
          className={cx(preLekara && 'active-option')}
          onClick={() => switchPersonalizedAccount(false)}
          type="button"
        >
          <div className={cx(classes.userPlate, 'smaller')}>
            <span>{acronym}</span>
          </div>
          <span>
            {menoLekar}
            <span>(lekár)</span>
          </span>
          {preLekara && <IconCheck color={color('success', 600)} />}
        </button>
        <button
          className={cx(!preLekara && 'active-option')}
          onClick={() => switchPersonalizedAccount(true)}
          type="button"
        >
          <div className={cx(classes.userPlate, 'smaller')}>
            <img alt="Pobočka" src={CompanyImg} />
          </div>
          <span>{nazovZobrazPzs}</span>
          {!preLekara && <IconCheck color={color('success', 600)} />}
        </button>
      </div>
    );
  const renderUserArea = (
    <div className={classes.pzsProfile} id="header-user-profile">
      <HeaderUserMenu
        dropdownItems={
          !guid && [
            renderPZsSwitcher,
            <DropdownItem
              key="header-dropdown-2"
              id="header-user-logout"
              onClick={handleLogout}
            >
              {renderLogoutButton}
            </DropdownItem>,
          ]
        }
        name={
          guid ? (
            <Button className="no-mrg" onClick={() => navigate('/')} size="s">
              Prihlásiť sa
            </Button>
          ) : (
            renderPzsTitle
          )
        }
      />
    </div>
  );

  if (location.pathname === routes.testIDP) return <Header />;

  return (
    <Header
      actionArea={() =>
        safeNode(<MessagesCount />, windowSize.width > breakpoints.l && idVZ)
      }
      menuArea={() =>
        safeNode(<ExpertisesCombo />, windowSize.width > breakpoints.l && idVZ)
      }
      userArea={() =>
        safeNode(renderUserArea, windowSize.width > breakpoints.l && idVZ)
      }
      {...other}
    />
  );
};

export default AppHeader;
