import { getRem } from '@dovera/design-system';
import { createUseStyles } from 'react-jss';

export default createUseStyles({
  exchangeWrapper: {
    marginBottom: getRem(8),
    padding: `${getRem(8)} 0 ${getRem(4)}`,
    background: 'rgba(210, 229, 255, .5)',
    borderRadius: getRem(5),
    '& a': {
      '&:hover': {
        background: 'inherit',
      },
    },
    '& > div:not([class^="row"])': {
      padding: getRem(16),
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      '& svg': {
        marginRight: getRem(8),
      },
      '& b': {
        width: `calc(100% - ${getRem(16)})`,
      },
      '& button': {
        width: `calc(100% - ${getRem(16)})`,
        textAlign: 'left',
        cursor: 'pointer',
        textDecoration: 'underline',
        fontWeight: 700,
      },
    },
  },
  exchangeBtn: {
    display: 'flex',
    justifyContent: 'center',
    paddingLeft: getRem(16),
    paddingRight: getRem(16),
    margin: `${getRem(8)} ${getRem(16)} ${getRem(16)}`,
    '& svg': {
      transform: 'rotate(90deg)',
      marginRight: getRem(8),
    },
  },
});
