import React from 'react';
import { Bar, BarItem, Disc } from '@dovera/design-system';
import { map } from 'lodash-es';

import useStyles from './GraphLegend.styles';

import { SkupinyParametrov } from '../../slices/hodnotiaceParametre.slice';
import { getColor, getIcon } from '../../utils/hodnotiaceParametre.utils';
import { cx } from '../../utils/exports';
import { isGYN009 } from '../../utils/dashboard.utils';
import { useSelector } from 'react-redux';
import { RootState } from '../../rootReducer';

type Props = {
  skupiny: SkupinyParametrov;
} & React.JSX.IntrinsicElements['div'];

const GraphLegend = ({ skupiny }: Props) => {
  const classes = useStyles({});
  const aktivnaOdbornost = useSelector(
    (state: RootState) =>
      state.poskytovatel.odbornosti.filter((d) => d.aktivna)[0],
  );

  return (
    <Bar className="d-flex align-items-center no-mrg-bottom" space="small">
      {map(
        skupiny,
        ({ nazov, poradie }) =>
          !isGYN009(
            nazov,
            aktivnaOdbornost?.kodOdbNz,
            aktivnaOdbornost?.kodTypZS,
          ) &&
          nazov !== 'Špeciálne' && (
            <BarItem
              key={nazov}
              className={cx(classes.barItem, 'align-items-center text-center')}
            >
              <Disc
                className={classes.icon}
                size="x-small"
                style={{ background: getColor(poradie) }}
              >
                {getIcon({ id: poradie })}
              </Disc>
              <span className={classes.nazov}>{nazov}</span>
            </BarItem>
          ),
      )}
    </Bar>
  );
};

export default GraphLegend;
