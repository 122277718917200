import { breakpoints } from '@dovera/design-system';
import { dajZoznamSpravVlakna, dajZoznamVlakien } from '../api/schrankaSprav';
import { systemConstants } from '../constants/systemConstants';
import store from '../store';
import { KategoriaSprav } from '../types/spravy.types';

interface ThreadsCall {
  category?: string;
  offset?: number;
  search?: string;
}

interface ThreadDetail {
  id?: number;
  scrollToBottom?: boolean;
}

export const callThreads = ({ category, offset, search }: ThreadsCall) => {
  store.dispatch(
    dajZoznamVlakien({
      limit: systemConstants.MAX_MESSAGES_IN_THREAD,
      offset: offset || 0,
      typVlakna: category && category !== 'all' ? category : undefined,
      vyhladavanie: search || '',
    }),
  );
};

export const callThreadDetail = ({ id, scrollToBottom }: ThreadDetail) => {
  if (id) return store.dispatch(dajZoznamSpravVlakna({ id, scrollToBottom }));
  const {
    detail,
    kategoria,
    vlakna: { data, offset },
  } = store.getState().spravy;
  const firstThreadId =
    kategoria !== KategoriaSprav.Vymazane
      ? data.find((v) => v.typVlakna !== KategoriaSprav.Vymazane)?.id
      : data[0]?.id || 0;
  if (
    firstThreadId &&
    detail?.id !== firstThreadId &&
    !offset &&
    window.innerWidth > breakpoints.xl
  )
    return store.dispatch(dajZoznamSpravVlakna({ id: firstThreadId }));
  return null;
};
