import { useCallback, useState } from 'react';
import { ButtonLink, Grid, GridCol, Notification } from '@dovera/design-system';
import { useSelector } from 'react-redux';
import useStyles from './DashboardGraph.styles';

import { RootState } from '../../rootReducer';

import {
  SkupinyParametrov,
  VysledokObdobia,
  dajObdobie,
} from '../../slices/hodnotiaceParametre.slice';

import { GraphLegend, PeriodPlate, RadialBarGraph } from '../../components';
import { cx } from '../../utils/exports';
import { safeString } from '../../utils/strings.utils';
import { objectLength } from '../../utils/object.utils';
import { KOD_TYP_ZS } from '../../constants/kodTypZS';
import { pickBy } from 'lodash-es';
import { dividedGraph } from '../../utils/hodnotiaceParametre.utils';
import IconArrowLeftLong from '../../components/CustomIcons/IconArrowLeftLong';
import IconArrowRightLong from '../../components/CustomIcons/IconArrowRightLong';
import { useAppDispatch } from '../../hooks/useStore';
import { useNavigate } from 'react-router';
import { createViewUrl } from '../../utils/app.utils';
import routes from '../../routes';
import { CustomTooltip } from '../../components/CustomTooltip';
import strings from '../../constants/strings';

function reorderVLD(skupiny: SkupinyParametrov): SkupinyParametrov {
  return {
    ...pickBy(skupiny, (s) => s.vaha !== 1),
    ...pickBy(skupiny, (s) => s.vaha === 1),
  };
}

const DashboardGraph = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const {
    aktualnaOdbornost,
    obdobia: { zoznamObdobi },
    poradie: { aktualneId, aktualnePoradie, nasledujuceId, predchadzajuceId },
  } = useSelector((state: RootState) => ({
    aktualnaOdbornost: state.poskytovatel.odbornosti.filter(
      (o) => o.kodOdbNz === state.poskytovatel.poslednaZmenaOdbornosti,
    )[0],
    dostupneOdbornosti: state.poskytovatel.odbornosti,
    obdobia: state.hodnotiaceParametre.obdobia,
    poradie: state.hodnotiaceParametre.poradie,
  }));
  const guid = useSelector((state: RootState) => state.auth.guid);
  const [hoverLeft, setHoverLeft] = useState(false);
  const [hoverRight, setHoverRight] = useState(false);
  const [periodPlateLoading, setPeriodPlateLoading] = useState(false);

  const onClick = useCallback(
    (poradie: number, nasledujuce?: boolean) => {
      setPeriodPlateLoading(true);
      dispatch(dajObdobie({ poradie, datNasledujuce: nasledujuce }));
      setTimeout(() => {
        setPeriodPlateLoading(false);
      }, 500);
    },
    [dispatch],
  );

  const posledneId =
    Object.keys(zoznamObdobi).length > 1 &&
    zoznamObdobi[
      Object.keys(zoznamObdobi)[Object.keys(zoznamObdobi).length - 1]
    ]?.id;

  function vysledokObdobia(
    id: number,
    dividedGraph?: boolean,
    primary?: boolean,
  ): VysledokObdobia {
    if (
      dividedGraph &&
      zoznamObdobi &&
      zoznamObdobi[id].typyCien.some(
        (t) => t === 'PlnKIPouz' || t === 'PlnEPouz',
      )
    ) {
      // Ak obdobie obsahuje typ ceny PlnKIPouz (plnenie Kvality a Inovacie)
      // a PlnEPouz (efektivnost), zobrazujeme separatne grafy
      if (primary) return zoznamObdobi[id]?.vysledokObdobia.PlnKIPouz || 0;
      return zoznamObdobi[id]?.vysledokObdobia.PlnEPouz || 0;
    }

    return zoznamObdobi[id]?.vysledokObdobia.PlnParPouz || 0;
  }

  function predbezny(id: number): boolean {
    return zoznamObdobi[id]?.predbezny === 1;
  }

  return (
    <div className={classes.layout}>
      {(aktualnaOdbornost || guid) && (
        <Grid className="mb-small">
          <GridCol
            className={cx(
              'd-flex align-items-middle align-items-right show-m',
              classes.secondaryGraph,
              hoverLeft && classes.hoverSecondaryGraph,
              aktualnePoradie !== 1 && 'clickable',
            )}
            // offset={{ m: 1 }}
            onBlur={() => setHoverLeft(false)}
            onClick={() => {
              if (aktualnePoradie !== 1) onClick(aktualnePoradie - 1);
            }}
            onFocus={() => setHoverLeft(true)}
            onMouseOut={() => setHoverLeft(false)}
            onMouseOver={() => setHoverLeft(true)}
            size={{ m: 3 }}
          >
            {zoznamObdobi[predchadzajuceId] && (
              <RadialBarGraph
                buduci={zoznamObdobi[predchadzajuceId].buduci}
                graphId={`radial-graph-previous`}
                isSecondary
                skupinyParametrov={zoznamObdobi[predchadzajuceId]?.skupiny}
                VAS={{
                  isVLD: dividedGraph(zoznamObdobi[predchadzajuceId]),
                  primaryVLD: true,
                }}
                vysledokObdobia={vysledokObdobia(
                  predchadzajuceId,
                  dividedGraph(zoznamObdobi[predchadzajuceId]),
                  true,
                )}
              />
            )}
            {zoznamObdobi[predchadzajuceId] &&
              dividedGraph(zoznamObdobi[predchadzajuceId]) && (
                <RadialBarGraph
                  buduci={zoznamObdobi[predchadzajuceId]?.buduci}
                  graphId={`radial-graph-previous`}
                  isSecondary
                  skupinyParametrov={zoznamObdobi[predchadzajuceId]?.skupiny}
                  VAS={{
                    isVLD: true,
                    primaryVLD: false,
                  }}
                  vysledokObdobia={vysledokObdobia(
                    predchadzajuceId,
                    true,
                    false,
                  )}
                />
              )}
          </GridCol>
          <GridCol
            className="text-center d-flex align-items-middle"
            offset={{ s: 2, m: 0 }}
            size={{ s: 8, m: 6 }}
          >
            {zoznamObdobi[aktualneId] && (
              <RadialBarGraph
                buduci={zoznamObdobi[aktualneId]?.buduci}
                graphId={`radial-graph-actual`}
                predbezny={zoznamObdobi[aktualneId].predbezny}
                skupinyParametrov={zoznamObdobi[aktualneId].skupiny}
                VAS={{
                  isVLD: dividedGraph(zoznamObdobi[aktualneId]),
                  primaryVLD: true,
                }}
                vysledokObdobia={vysledokObdobia(
                  aktualneId,
                  dividedGraph(zoznamObdobi[aktualneId]),
                  true,
                )}
              />
            )}
            {zoznamObdobi[aktualneId] &&
              dividedGraph(zoznamObdobi[aktualneId]) && (
                <RadialBarGraph
                  buduci={zoznamObdobi[aktualneId].buduci}
                  graphId={`radial-graph-actual`}
                  predbezny={zoznamObdobi[aktualneId].predbezny}
                  skupinyParametrov={zoznamObdobi[aktualneId].skupiny}
                  VAS={{
                    isVLD: true,
                    primaryVLD: false,
                  }}
                  vysledokObdobia={vysledokObdobia(aktualneId, true, false)}
                />
              )}
          </GridCol>
          <GridCol
            className={cx(
              'd-flex align-items-middle align-items-left show-m',
              classes.secondaryGraph,
              hoverRight && classes.hoverSecondaryGraph,
              objectLength(zoznamObdobi) < aktualnePoradie && 'clickable',
            )}
            onBlur={() => setHoverRight(false)}
            onClick={() => onClick(aktualnePoradie + 1, true)}
            onFocus={() => setHoverRight(true)}
            onMouseOut={() => setHoverRight(false)}
            onMouseOver={() => setHoverRight(true)}
            size={{ m: 3 }}
          >
            {zoznamObdobi[nasledujuceId] && (
              <RadialBarGraph
                buduci={zoznamObdobi[nasledujuceId].buduci}
                graphId={`radial-graph-next`}
                isSecondary
                skupinyParametrov={zoznamObdobi[nasledujuceId]?.skupiny}
                VAS={{
                  isVLD: dividedGraph(zoznamObdobi[nasledujuceId]),
                  primaryVLD: true,
                }}
                vysledokObdobia={vysledokObdobia(
                  nasledujuceId,
                  dividedGraph(zoznamObdobi[nasledujuceId]),
                  true,
                )}
              />
            )}
            {zoznamObdobi[nasledujuceId] &&
              dividedGraph(zoznamObdobi[nasledujuceId]) && (
                <RadialBarGraph
                  buduci={zoznamObdobi[nasledujuceId].buduci}
                  graphId={`radial-graph-next`}
                  isSecondary
                  skupinyParametrov={zoznamObdobi[nasledujuceId]?.skupiny}
                  VAS={{
                    isVLD: true,
                    primaryVLD: false,
                  }}
                  vysledokObdobia={vysledokObdobia(nasledujuceId, true, false)}
                />
              )}
          </GridCol>
        </Grid>
      )}

      <Grid className="mb">
        <GridCol
          className="text-center"
          offset={
            zoznamObdobi[aktualneId]?.buduci ? { s: 0, m: 1 } : { s: 2, m: 3 }
          }
          size={
            zoznamObdobi[aktualneId]?.buduci
              ? { s: 12, m: 10, l: 10 }
              : { s: 8, m: 6, l: 6 }
          }
        >
          <Grid hasGutters={false}>
            <GridCol size={12}>
              {!zoznamObdobi[aktualneId]?.buduci ? (
                <GraphLegend
                  skupiny={
                    aktualnaOdbornost &&
                    aktualnaOdbornost.kodTypZS === KOD_TYP_ZS.VLD &&
                    zoznamObdobi[aktualneId]
                      ? reorderVLD(zoznamObdobi[aktualneId].skupiny)
                      : zoznamObdobi[aktualneId]?.skupiny
                  }
                />
              ) : (
                <div className={classes.futureNotification}>
                  <CustomTooltip
                    dialog={strings.hp.buduceObdobie.tooltip}
                    id="futureNotification--exchange"
                  >
                    <div className="clickable">
                      <Notification
                        message={strings.hp.buduceObdobie.info}
                        variant="blue"
                      />
                    </div>
                  </CustomTooltip>
                </div>
              )}
            </GridCol>
            <GridCol
              className="d-flex align-items-center"
              offset={{ l: 1 }}
              size={{ xs: 1 }}
            >
              {zoznamObdobi[predchadzajuceId] && (
                <ButtonLink
                  className={classes.buttonArrow}
                  id="radial-graph-show-previous"
                  onClick={() => onClick(aktualnePoradie - 1)}
                  style={{ padding: 4 }}
                >
                  <IconArrowLeftLong id="icon-arrow-left-graph" />
                </ButtonLink>
              )}
            </GridCol>

            <GridCol
              className="d-flex-column align-items-center"
              size={{ xs: 10, m: 10, l: 8 }}
            >
              {zoznamObdobi[aktualneId] && (
                <PeriodPlate
                  datumDo={safeString(
                    zoznamObdobi[aktualneId].obdobieVypocetDo,
                  )}
                  datumOd={safeString(
                    zoznamObdobi[aktualneId].obdobieVypocetOd,
                  )}
                  futureExists={posledneId && zoznamObdobi[posledneId].buduci}
                  id="radial-graph-period-plate"
                  isActive
                  isFuture={zoznamObdobi[aktualneId].buduci}
                  isLoading={periodPlateLoading}
                  nasledujuceId={nasledujuceId}
                  popisObdobie={zoznamObdobi[aktualneId].popisObdobie}
                  posledneId={posledneId}
                  predbezny={predbezny(aktualneId)}
                  predposledneId={
                    Object.keys(zoznamObdobi).length > 2 &&
                    zoznamObdobi[
                      Object.keys(zoznamObdobi)[
                        Object.keys(zoznamObdobi).length - 2
                      ]
                    ]?.id
                  }
                />
              )}
            </GridCol>

            <GridCol className="d-flex align-items-center" size={{ xs: 1 }}>
              {zoznamObdobi[nasledujuceId] && (
                <ButtonLink
                  className={classes.buttonArrow}
                  id="radial-graph-show-next"
                  onClick={() => onClick(aktualnePoradie + 1, true)}
                  style={{ padding: 4 }}
                >
                  <IconArrowRightLong id="icon-arrow-right-graph" />
                </ButtonLink>
              )}
            </GridCol>
          </Grid>
        </GridCol>
      </Grid>
      <div className={cx('text-center', classes.showEvaluatedParams)}>
        {(zoznamObdobi[aktualneId]?.predbezny ||
          zoznamObdobi[aktualneId]?.buduci) && (
          <ButtonLink
            className="no-mrg"
            onClick={() =>
              navigate(createViewUrl(routes.hp, { obdobie: 'uzavrete' }))
            }
          >
            Zobraziť vyhodnotené parametre
          </ButtonLink>
        )}
      </div>
    </div>
  );
};

export default DashboardGraph;
