import axios, { AxiosResponse } from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  DajDetailParametraHodnoteniaPayload,
  DajDetailParametraHodnoteniaResponse,
  GetReportDataPayload,
  GetReportDataResponse,
  UlozParameterHodnoteniaPayload,
  UlozParameterHodnoteniaResponse,
} from '../types/hp.types';

const API_URL = `${window.env?.config?.apiPath}/HodnotiaceParametre/`;

export const fetchHPDetail = createAsyncThunk(
  'hp/fetchHPDetail',
  async (req: DajDetailParametraHodnoteniaPayload) => {
    const url = `${API_URL}DajDetailParametraHodnotenia`;
    const { data }: AxiosResponse<DajDetailParametraHodnoteniaResponse> =
      await axios.post(url, req);
    return data;
  },
);

export const saveHPExchange = createAsyncThunk(
  'hp/saveHPExchange',
  async (req: UlozParameterHodnoteniaPayload) => {
    const url = `${API_URL}UlozParameterHodnotenia`;
    const { data }: AxiosResponse<UlozParameterHodnoteniaResponse> =
      await axios.post(url, req);
    return data;
  },
);

export const getHPReportData = async (
  payload: GetReportDataPayload,
): Promise<GetReportDataResponse> => {
  const url = `${API_URL}DajReportData`;
  const { data } = await axios.post(url, payload);
  return data || null;
};
