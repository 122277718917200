import { text } from '../../utils/strings.utils';
import { Bar, BarItem, Icon } from '@dovera/design-system';
import { replaceWithComma } from '../../utils/number.utils';
import strings from '../../constants/strings';
import { VysledkyObdobia } from '../../slices/hodnotiaceParametre.slice';
import { CustomTooltip } from '../CustomTooltip';

interface Props {
  GYN?: boolean;
  vysledokObdobia?: VysledkyObdobia;
}

const CategoryCardExtra = ({ GYN, vysledokObdobia }: Props) =>
  GYN ? (
    <div>
      <div className="mb-small">
        {text(`dashboard.zakladDodKap.description`)}
      </div>

      <div className="text-center">
        <p className="no-mrg-bottom">{text(`dashboard.zakladDodKap.value`)}</p>
        <Bar align="middle" className="d-flex align-items-center" space="small">
          <BarItem>
            <h3 className="mb-small h4 no-mrg-bottom">
              {`${replaceWithComma(
                (vysledokObdobia && vysledokObdobia?.DodKapZakl?.[0]?.cena) ||
                  0,
              )} €`}
            </h3>
          </BarItem>
          <BarItem>
            <CustomTooltip
              dialog={
                <span>
                  <strong>
                    {strings.dashboard.zakladDodKap.tooltip.title}
                  </strong>
                  <br />
                  <span
                    className="no-mrg"
                    dangerouslySetInnerHTML={{
                      __html: strings.dashboard.zakladDodKap.tooltip.first(
                        (vysledokObdobia &&
                          vysledokObdobia?.DodKapPas1 &&
                          vysledokObdobia?.DodKapPas1?.[0]?.cena) ||
                          0,
                      ),
                    }}
                  />
                  <br />
                  <span
                    className="no-mrg"
                    dangerouslySetInnerHTML={{
                      __html: strings.dashboard.zakladDodKap.tooltip.second(
                        (vysledokObdobia &&
                          vysledokObdobia?.DodKapPas2 &&
                          vysledokObdobia?.DodKapPas2?.[0]?.cena) ||
                          0,
                      ),
                    }}
                  />
                  <br />
                  <span
                    className="no-mrg"
                    dangerouslySetInnerHTML={{
                      __html: strings.dashboard.zakladDodKap.tooltip.third(
                        (vysledokObdobia &&
                          vysledokObdobia?.DodKapPas3 &&
                          vysledokObdobia?.DodKapPas3?.[0]?.cena) ||
                          0,
                      ),
                    }}
                  />
                  <br />
                  <span
                    className="no-mrg"
                    dangerouslySetInnerHTML={{
                      __html: strings.dashboard.zakladDodKap.tooltip.fourth(
                        (vysledokObdobia &&
                          vysledokObdobia?.DodKapPas4 &&
                          vysledokObdobia?.DodKapPas4?.[0]?.cena) ||
                          0,
                      ),
                    }}
                  />
                </span>
              }
              id={`tooltip-categoryExtra`}
            >
              <Icon
                className="text-color-grey"
                id="icon-info-tooltip-categoryExtra"
                name="info"
              />
            </CustomTooltip>
          </BarItem>
        </Bar>
      </div>
    </div>
  ) : (
    <span />
  );

export default CategoryCardExtra;
