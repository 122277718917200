import React from 'react';
import { Bar, BarItem } from '@dovera/design-system';
import useStyles from './GraphPopupItem.styles';
import {
  formatHodnota,
  formatPlnenie,
  hodnotaPodlaPlnenia,
} from '../../utils/hodnotiaceParametre.utils';
import { SUBJEKTIVNA_SPOKOJNOST } from '../../constants';
import { IconDashedRing, IconError, IconSuccess } from '../CustomIcons';
import { DOSTUPNOST_GYN_ZDRAVOTNEJ_STAROSTLIVOSTI } from '../../constants/misc';
import { inlineCondition } from '../../utils/app.utils';

type Props = {
  hodnota: number | null;
  kodParametra: string;
  nazovParametra: string;
  pasmo: string;
  plnenie: boolean | number;
  predbezny: boolean;
  typHodnoty: string;
} & React.JSX.IntrinsicElements['div'];

const GraphPopupItem = ({
  hodnota,
  kodParametra,
  nazovParametra,
  pasmo,
  plnenie,
  predbezny,
  typHodnoty,
  ...other
}: Props) => {
  const classes = useStyles();

  return (
    <div className={classes.graphPopupItem} {...other}>
      <Bar align="middle" className="no-mrg-bottom" space="small">
        <BarItem className="d-flex align-items-center">
          {formatPlnenie(plnenie, pasmo) ? (
            <IconSuccess
              className="icon--pos--category"
              height={16}
              id={`icon-success-graphPopup-${typHodnoty}`}
              width={16}
            />
          ) : (
            inlineCondition(predbezny, <IconDashedRing />, <IconError />)
          )}
        </BarItem>
        <BarItem className={classes.nameBarItem} isFilling>
          {nazovParametra}
        </BarItem>
        <BarItem className={classes.valueBarItem}>
          <span>
            {kodParametra === SUBJEKTIVNA_SPOKOJNOST ||
            kodParametra === DOSTUPNOST_GYN_ZDRAVOTNEJ_STAROSTLIVOSTI
              ? hodnotaPodlaPlnenia(plnenie)
              : formatHodnota(hodnota, Number(plnenie), typHodnoty)}
          </span>
        </BarItem>
      </Bar>
    </div>
  );
};

export default GraphPopupItem;
