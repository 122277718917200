import { ReactNode, useState } from 'react';
import useStyles from './ActualityRow.styles';
import IconActuality from '../../../components/CustomIcons/IconActuality';
import IconTarget from '../../../components/CustomIcons/IconTarget';
import IconPatient from '../../../components/CustomIcons/IconPatient';
import IconDashboardDiagnose from '../../../components/CustomIcons/IconDashboardDiagnose';
import { cx } from '../../../utils/exports';
import { DATE_FORMAT_SLOVAK, formatDate } from '../../../utils/date.utils';
import { Link, useNavigate } from 'react-router-dom';
import { splitTextWithTooltip } from '../../../utils/strings.utils';
import { useWindowSize } from '../../../hooks/useWindowSize';
import { isJSON } from '../../../utils/object.utils';
import { ButtonLink, ButtonSecondary, Tag, color } from '@dovera/design-system';
import SmoothCollapse from 'react-smooth-collapse';
import IconAngle from '../../../components/CustomIcons/IconAngle';
import { useSelector } from 'react-redux';
import { RootState } from '../../../rootReducer';
import { hasSection } from '../../../utils/poskytovatel.utils';
import IconEuroInvoice from '../../../components/CustomIcons/IconEuroInvoice';
import SafeHtml from '../../../components/SafeHtml/SafeHtml';
import strings from '../../../constants/strings';
import { ActualityType } from '../../../types/dashboard.types';
import { EPSections } from '../../../constants/systemConstants';

interface Props {
  aktualizovatelne: boolean;
  code?: string;
  data?: string | null;
  date: string;
  description: ReactNode | string;
  id: number;
  isExternal?: boolean;
  onClick: (aktualizovatelne: boolean, id: number, type: string | null) => void;
  read?: boolean;
  title: string;
  type: ActualityType | null;
  url: string;
}

const ActualityRow = ({
  aktualizovatelne,
  code,
  data,
  date,
  description,
  id,
  isExternal,
  onClick,
  read,
  title,
  type,
  url,
}: Props) => {
  const classes = useStyles({ read });
  const windowSize = useWindowSize();
  const navigate = useNavigate();
  const { odbornosti } = useSelector((state: RootState) => state.poskytovatel);
  const [isRowCollapsed, setIsRowCollapsed] = useState(false);
  const parsedData: any | null =
    data && isJSON(data) ? JSON.parse(data)?.Data || null : null;
  const renderRow = (
    <div className={classes.row} id={`dashboard-actuality-${id}`}>
      {type && (
        <div>
          {type === 'A' && (
            <IconActuality active={!read} id={`icon-actuality-${id}`} />
          )}
          {['UPD_HK', 'HPMV', 'HPV'].includes(type) && (
            <IconTarget active={!read} id={`icon-actuality-${id}`} />
          )}
          {type === 'UPD_DLEKPOI' && (
            <IconPatient active={!read} id={`icon-actuality-${id}`} />
          )}
          {type === 'UPD_DLEKPOI_NAKL' && (
            <IconDashboardDiagnose active={!read} id={`icon-actuality-${id}`} />
          )}
          {type === 'ZUC_CHYB_PROT' && (
            <IconEuroInvoice active={!read} id={`icon-actuality-${id}`} />
          )}
        </div>
      )}
      <div style={{ width: '100%' }}>
        <h6 className={cx('mb-xxsmall', classes.title)}>
          {splitTextWithTooltip(
            `actuality-${id}`,
            title,
            windowSize.width < 991
              ? 25
              : windowSize.width > 1920
                ? 45
                : Math.floor(windowSize.width / 30),
          )}
          {code && ['HPMV', 'HPV'].includes(code) && !read && (
            <Tag
              style={{
                backgroundColor: '#FDEDE6',
                border: 'none',
                color: '#6F4F41',
                fontWeight: 100,
                fontSize: 14,
                borderRadius: 23,
                padding: '3px 12px',
                margin: '0 0 0 24px',
              }}
            >
              Nové
            </Tag>
          )}
        </h6>
        <div
          className={cx(
            'd-flex',
            parsedData?.length > 1 && classes.showMoreWrapper,
          )}
        >
          {typeof description !== 'string' ? (
            description
          ) : (
            <p className="no-mrg">
              {description}
              {code === 'HPV' && parsedData && parsedData?.length === 1 && (
                <ButtonLink
                  className={classes.btnLink}
                  onClick={() => navigate(parsedData?.[0]?.Url)}
                >
                  {parsedData?.[0]?.NazovParametra}
                </ButtonLink>
              )}
              {code === 'HPV' &&
                parsedData &&
                parsedData?.length === 1 &&
                odbornosti?.length > 1 && (
                  <span style={{ marginLeft: 3 }}>
                    pre odbornosť{' '}
                    <b style={{ fontWeight: 700 }}>
                      {parsedData?.[0]?.NazovOdbornost?.trim()}
                    </b>
                    .
                  </span>
                )}
              {code === 'ZUC_CHYB_PROT' && (
                <SafeHtml html={strings.dashboard.news.textDlekZucChybProt} />
              )}
            </p>
          )}
          {parsedData && parsedData?.length > 1 && (
            // eslint-disable-next-line
            <ButtonLink
              className={cx(classes.showMore, isRowCollapsed && 'less')}
              onClick={() => setIsRowCollapsed(!isRowCollapsed)}
            >
              <span>Zobraziť {isRowCollapsed ? 'menej' : 'viac'}</span>
              <IconAngle
                color={color('primary', 600)}
                height={16}
                id={`actuality-row-`}
                width={16}
              />
            </ButtonLink>
          )}
          {parsedData && parsedData?.length === 1 && code === 'HPMV' && (
            <ButtonSecondary
              className={classes.btnExchange}
              onClick={() => navigate(parsedData[0]?.Url)}
            >
              Zobraziť parameter
            </ButtonSecondary>
          )}
        </div>
      </div>
      <div>
        {!date || date.includes('0001')
          ? ' - '
          : formatDate(date, DATE_FORMAT_SLOVAK)}
      </div>
    </div>
  );
  const renderHPCollapsable = !!parsedData &&
    code === 'HPMV' &&
    parsedData.length > 1 && (
      <SmoothCollapse expanded={isRowCollapsed}>
        <div className={classes.collapsableContent}>
          {parsedData.map((d, key) => (
            <div
              key={`HPMV--${key}`}
              className={cx(classes.collapsableItem, 'd-flex')}
            >
              <b>{d?.NazovOdbornost}</b>
              <ButtonSecondary onClick={() => navigate(d?.Url)}>
                Zobraziť parameter
              </ButtonSecondary>
            </div>
          ))}
        </div>
      </SmoothCollapse>
    );
  if (
    type === 'ZUC_CHYB_PROT' &&
    (!hasSection(EPSections.Zuc, 'Zuctovanie') ||
      !hasSection(EPSections.Fkt, 'Zuctovanie'))
  )
    return <span />;
  if (isExternal)
    return (
      <a
        className={classes.link}
        href={url}
        onClick={() => onClick(aktualizovatelne, id, type)}
        rel="noopener noreferrer"
        target="_blank"
      >
        {renderRow}
      </a>
    );
  if (renderHPCollapsable) {
    return (
      <div className={classes.link}>
        {renderRow}
        {renderHPCollapsable}
      </div>
    );
  }
  return (
    <Link
      className={classes.link}
      onClick={() => onClick(aktualizovatelne, id, type)}
      to={parsedData?.length === 1 ? parsedData?.[0]?.Url : url}
    >
      {renderRow}
    </Link>
  );
};

export default ActualityRow;
