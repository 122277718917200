/* eslint-disable */
import { useQuery } from '../../hooks/useQuery';
import useStyles from './PrintHP.styles';

const PrintHP = () => {
  const classes = useStyles();
  const query = useQuery();
  return (
    <iframe
      className={classes.iframe}
      src={`blob:${window.location.origin}/${query.get('blob')}`}
      width="100%"
    />
  );
};

export default PrintHP;
