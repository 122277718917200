import { StyleSheet, Text, View } from '@react-pdf/renderer';
import CategoryBadge from '../assets/svg/CategoryBadge';
import { color } from '../../../constants';
import StandardGraph from './StandardGraph';
import SuccessIcon from '../assets/svg/SuccessIcon';
import ErrorIcon from '../assets/svg/ErrorIcon';
import { formatHodnota } from '../../../utils/hodnotiaceParametre.utils';
import WarningIcon from '../assets/svg/WarningIcon';
import { getCatStandardColor, getStylesCatValue } from '../utils';

const styles = StyleSheet.create({
  title: {
    color: color('secondary', 500),
    fontSize: 20,
    fontWeight: 'bold',
    marginBottom: 20,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  subtitle: {
    color: color('secondary', 500),
    fontSize: 14,
    fontWeight: 'bold',
  },
  subsubtitle: {
    color: color('secondary', 500),
    fontSize: 13,
    fontWeight: 'bold',
  },
  h5: {
    color: color('secondary', 500),
    fontSize: 12,
    marginBottom: 15,
  },
  description: {
    color: color('grey', 500),
    marginBottom: 15,
  },
  hr: {
    marginTop: 30,
    paddingBottom: 15,
    display: 'flex',
    borderTop: `1px solid ${color('grey', 200)}`,
  },
  graphContent: {
    display: 'flex',
    flexDirection: 'row',
  },
  bold: {
    fontSize: 12,
    fontWeight: 'bold',
  },
  success: {
    color: color('primary', 500),
  },
  secondary: {
    color: color('secondary', 500),
  },
  error: {
    color: color('error'),
  },
  warning: {
    color: color('warning'),
  },
  flex: {
    display: 'flex',
    flexDirection: 'row',
  },
});

export interface DetailCategoryPdf {
  graf: {
    hodnota: number;
    hranica1Hodnota: number;
    hranica1Text: string;
    hranica2Hodnota?: number;
    hranica2Text?: string;
    kodParametra: string;
    plnenie?: number;
    typHodnoty: string | null;
    vasaHodnota: string;
  }[];
  nadpis: string;
  popis: string;
  priemernaHodnota: string;
  splnene: 'Splnené' | 'Čiastočne splnené' | 'Nesplnené';
  vaha: number;
  vasaPozicia: string;
}

interface Props {
  badgePercetage: number;
  category: 'Q' | 'E' | 'I';
  data?: DetailCategoryPdf[];
  title: string;
}

const CategoryDetail = ({ badgePercetage, category, data, title }: Props) => {
  const renderSplnene = (s: 'Splnené' | 'Čiastočne splnené' | 'Nesplnené') => {
    if (s === 'Splnené')
      return (
        <View style={styles.flex}>
          <SuccessIcon />
          <Text style={[styles.success, { marginLeft: 15, fontSize: 13 }]}>
            {s}
          </Text>
        </View>
      );
    if (s === 'Nesplnené')
      return (
        <View style={styles.flex}>
          <ErrorIcon />
          <Text style={[styles.error, { marginLeft: 15, fontSize: 13 }]}>
            {s}
          </Text>
        </View>
      );
    return (
      <View style={styles.flex}>
        <WarningIcon />
        <Text style={[styles.warning, { marginLeft: 15, fontSize: 13 }]}>
          {s}
        </Text>
      </View>
    );
  };
  return (
    <View>
      <View style={styles.title}>
        <Text style={{ marginRight: 20, marginTop: 3 }}>{title}</Text>
        <CategoryBadge category={category} percentage={badgePercetage} />
      </View>
      {data &&
        data.map((d, key) => (
          <View key={`category-${key}`} break={key === 3}>
            <View style={styles.subtitle}>
              <Text>{d.nadpis}</Text>
            </View>
            <View style={styles.h5}>
              <Text>{`Váha parametra: ${d.vaha} %`}</Text>
            </View>
            <View style={styles.description}>
              <Text>{d.popis.replace(/(<([^>]{1,5000})>)/gi, '')}</Text>
            </View>
            {!!d.graf?.length &&
              d.graf.map((g, key2) => (
                <View style={{ marginTop: key2 !== 0 ? 30 : 0 }}>
                  {g.kodParametra === 'dostup-gyn' && key2 === 0 && (
                    <Text style={styles.subsubtitle}>Úväzok na lekára</Text>
                  )}
                  {g.kodParametra === 'dostup-gyn' && key2 === 1 && (
                    <Text style={styles.subsubtitle}>
                      Týždenný ordinačný čas
                    </Text>
                  )}
                  <View
                    key={`category-graph-${key2}`}
                    style={[styles.graphContent]}
                  >
                    <View
                      style={{
                        width:
                          d.splnene === 'Čiastočne splnené' ? '25%' : '15%',
                      }}
                    >
                      {renderSplnene(d.splnene)}
                    </View>
                    <View style={{ width: '50%', padding: '0 30px' }}>
                      <View
                        style={{
                          display: 'flex',
                          justifyContent:
                            g.plnenie && g.plnenie > 0 && g.plnenie < 1
                              ? 'space-between'
                              : 'flex-start',
                        }}
                      >
                        <Text
                          style={[
                            styles.bold,
                            styles.secondary,
                            {
                              fontSize:
                                g.plnenie && g.plnenie > 0 && g.plnenie < 1
                                  ? '10px'
                                  : '13px',
                            },
                          ]}
                        >
                          Vaša hodnota:{' '}
                          <Text
                            style={[
                              getStylesCatValue({ styles, plnenie: g.plnenie }),
                              { marginRight: 10 },
                            ]}
                          >
                            {g.vasaHodnota}
                          </Text>
                          {g.plnenie &&
                          g.plnenie > 0 &&
                          g.plnenie < 1 &&
                          d.splnene ? (
                            <Text style={{ paddingLeft: 5, display: 'flex' }}>
                              {'   '}Plnenie:{' '}
                              <Text style={[styles.warning]}>
                                {formatHodnota(g.plnenie, g.plnenie, 'P')}
                              </Text>
                            </Text>
                          ) : (
                            <Text />
                          )}
                        </Text>
                      </View>

                      <StandardGraph
                        fitColor={getCatStandardColor(g.plnenie)}
                        percentage={g.hodnota}
                        point={g.hranica1Hodnota}
                        point2={g.hranica2Hodnota}
                        pointText={g.hranica1Text}
                        pointText2={g.hranica2Text}
                      />
                    </View>

                    <View style={{ width: '35%', paddingLeft: 20 }}>
                      <Text>
                        Priemerná hodnota:{' '}
                        <Text style={styles.bold}>
                          {g.kodParametra === 'subj-spok'
                            ? '100%'
                            : d.priemernaHodnota}
                        </Text>
                      </Text>
                      <Text>
                        Vaša pozícia:{' '}
                        <Text style={styles.bold}>{d.vasaPozicia}</Text>
                      </Text>
                    </View>
                  </View>
                </View>
              ))}
            {key !== data.length - 1 && <Text style={styles.hr} />}
          </View>
        ))}
    </View>
  );
};

export default CategoryDetail;
