import { Container, PageSection } from '@dovera/design-system';
import { useSelector } from 'react-redux';

import { RootState } from '../../rootReducer';

import { Page } from '../../components';
import StaryDashboardGraph from './StaryDashboardGraph';
import StaryDashboardCategoryCards from './StaryDashboardCategoryCards';
import StaryDashboardCenaBodu from './StaryDashboardCenaBodu';
import StaryDashboardSkeleton from './StaryDashboardSkeleton';
import { NoData } from '../../components/EmptyState';

const StaryDashboard = () => {
  const {
    obdobia: { error, isLoading, zoznamObdobi },
    poradie: { aktualneId },
  } = useSelector((state: RootState) => state.hodnotiaceParametre);

  return (
    <Page>
      {isLoading ? (
        <StaryDashboardSkeleton />
      ) : (
        !error && (
          <>
            <PageSection spaceBottom="small">
              <Container>
                <StaryDashboardGraph />
              </Container>
            </PageSection>

            <PageSection spaceBottom="small" spaceTop="none">
              <Container>
                <StaryDashboardCenaBodu obdobie={zoznamObdobi[aktualneId]} />
              </Container>
            </PageSection>

            <PageSection spaceBottom="small" spaceTop="none">
              <Container>
                <StaryDashboardCategoryCards />
              </Container>
            </PageSection>
          </>
        )
      )}
      {error && <NoData illustration="woman-question" />}

      {/* <PageSection spaceTop="none">
        <Container type="narrow">
          <Grid>
            {map(wiki, (karta: any) => (
              <GridCol key={karta.nazov} size={{ s: 6, m: 4 }}>
                <WikiCard {...karta} href="/" obrazok={image1} />
              </GridCol>
            ))}
          </Grid>
        </Container>
      </PageSection> */}
    </Page>
  );
};

export default StaryDashboard;
