import React, { ReactNode } from 'react';
import { Bar, BarItem, ButtonLink } from '@dovera/design-system';
import useStyles from './GraphTooltipItem.styles';
import {
  formatHodnota,
  formatPlnenie,
  hodnotaPodlaPlnenia,
} from '../../utils/hodnotiaceParametre.utils';
import { SUBJEKTIVNA_SPOKOJNOST } from '../../constants';
import { IconDashedRing, IconError, IconSuccess } from '../CustomIcons';
import { useNavigate } from 'react-router-dom';
import { DOSTUPNOST_GYN_ZDRAVOTNEJ_STAROSTLIVOSTI } from '../../constants/misc';
import IconWarningCircleSmall from '../CustomIcons/IconWarningCircleSmall';
import { cx } from '../../utils/exports';

type Props = {
  hodnota: number | null;
  href?: string;
  kodParametra: string;
  nazovParametra: string;
  pasmo: string;
  plnenie: boolean | number;
  predbezny: boolean;
  typHodnoty: string;
} & React.JSX.IntrinsicElements['div'];

const getIcon = ({
  kodParametra,
  pasmo,
  plnenie,
  predbezny,
  typHodnoty,
}: {
  kodParametra: string;
  pasmo: string;
  plnenie: number | boolean;
  predbezny: boolean;
  typHodnoty: string;
}): ReactNode => {
  if (Number(plnenie) > 0 && Number(plnenie) < 1)
    return (
      <IconWarningCircleSmall
        className="icon--pos--category"
        id={`icon-warning-graphPopup-${typHodnoty}-${kodParametra}`}
      />
    );
  if (formatPlnenie(plnenie, pasmo))
    return (
      <IconSuccess
        className="icon--pos--category"
        height={16}
        id={`icon-success-graphPopup-${typHodnoty}-${kodParametra}`}
        width={16}
      />
    );
  if (predbezny)
    return (
      <IconDashedRing
        className="icon--pos--category"
        id={`icon-dashedRing-graphPopup-${typHodnoty}-${kodParametra}`}
      />
    );
  return (
    <IconError
      className="icon--pos--category"
      id={`icon-error-graphPopup-${typHodnoty}-${kodParametra}`}
    />
  );
};

const GraphTooltipItem = ({
  hodnota,
  href,
  kodParametra,
  nazovParametra,
  pasmo,
  plnenie,
  predbezny,
  typHodnoty,
  ...other
}: Props) => {
  const classes = useStyles({
    pasmo,
    plnenie,
    predbezny,
  });
  const navigate = useNavigate();

  return (
    <div
      className={cx(
        classes.graphPopupItem,
        formatPlnenie(plnenie, pasmo) && classes.graphPopupItemPrimary,
      )}
      {...other}
    >
      <ButtonLink
        className="no-pad no-mrg"
        onClick={() => navigate(`${href}/${kodParametra}`)}
      >
        <Bar align="middle" className="no-mrg-bottom" space="small">
          <BarItem className="d-flex">
            {getIcon({ kodParametra, pasmo, plnenie, predbezny, typHodnoty })}
          </BarItem>
          <BarItem className={classes.nameBarItem} isFilling>
            {nazovParametra}
          </BarItem>
          <BarItem className={classes.valueBarItem}>
            <span className={classes.hodnota}>
              {kodParametra === SUBJEKTIVNA_SPOKOJNOST ||
              kodParametra === DOSTUPNOST_GYN_ZDRAVOTNEJ_STAROSTLIVOSTI ||
              (typHodnoty === 'P' && plnenie)
                ? hodnotaPodlaPlnenia(plnenie)
                : formatHodnota(hodnota, Number(plnenie), typHodnoty)}
            </span>
          </BarItem>
        </Bar>
      </ButtonLink>
    </div>
  );
};

export default GraphTooltipItem;
