import { PayloadAction, createSelector, createSlice } from '@reduxjs/toolkit';
import {
  BenchmarkDiagnozy,
  DajBenchmarkDiagnozPayload,
  DajDetailZoznamuPoistencovPayload,
  DajPrehladDiagnostikyPacientovPayload,
  DajPrehladPacientovPayload,
  DajZoznamPoistencovPayload,
  DetailPacientovState,
  FilterPrehladuDiagnostikyPacientov,
  KodHodnoty,
  KodUdaj,
  ObdobiePP,
  Okres,
  PacientiFilterState,
  PoistenecUdaj,
  PrehladDiagnostikyPacientovState,
  PrehladPacientovState,
  UdajDetailuPoistenca,
  VUC,
  ZoznamPacientovState,
} from '../types/pacienti.types';
import { AppThunk } from '../store';
import {
  dajBenchmarkDiagnozApi,
  dajDetailZoznamuPoistencovApi,
  dajPrehladDiagnostikyPacientovApi,
  dajPrehladPacientovApi,
  dajVucOkresyPreBenchmarkyDiagnozApi,
  dajZoznamPoistencovApi,
} from '../api/prehladPacientov';
import {
  dajFilterEdukaciePacientov,
  dajFilterObdobiePacientov,
  dajSpecifickyVyberPreFilterPacientov,
} from '../utils/pacienti.utils';
import { RadioButtonType, SelectType } from '../types';
import { archivujPoistenca } from '../api/pacienti';
import strings from '../constants/strings';
import { getMoment } from '../utils/date.utils';

interface PacientiState {
  archivacia: {
    error: string | null;
    isLoading: boolean;
    success: boolean | null;
  };
  benchmark: {
    benchmarkyDiagnoz: BenchmarkDiagnozy[];
    error: string | null;
    errorType: null | 'technical' | 'not-allowed';
    isLoading: boolean;
    okresy: Okres[];
    vuc: VUC[];
    vucOkresyLoading: boolean;
    vybranyOkres: string;
    vybranyVUC: string;
  };
  countLoadingErrors: number;
  error: string | null;
  errorType: null | 'technical' | 'not-allowed';
  filter: FilterPrehladuDiagnostikyPacientov;
  isLoaded: boolean;
  isLoading: boolean;
  prehladDiagnostikyPacientov: PrehladDiagnostikyPacientovState;
  prehladPacientov: {
    detailPacienta: {
      data: UdajDetailuPoistenca[];
      error: string | null;
      isLoading: boolean;
    };
    odbornost: string;
    prehlad: {
      error: string | null;
      fetchTime: number;
      isLoaded: boolean;
      isLoading: boolean;
      kodyHodnoty: KodHodnoty[];
    };
    zoznam: {
      archiv: boolean;
      archivLoading: boolean;
      archivovaniPoistenci: { idPoistenec: number; kodUdaj: string }[];
      detailPoistenciUdaje: UdajDetailuPoistenca[];
      error: string | null;
      filter: PacientiFilterState;
      filterCovid: {
        podlaVeku: string;
        zranitelni: boolean;
      };
      filterPOIState: string;
      isFilterReady: boolean;
      isLoaded: boolean;
      isLoading: boolean;
      kodyHodnoty: KodHodnoty[];
      lastRequest: string | null;
      notLoadMore: boolean;
      obdobieDo: string | number;
      obdobieOd: string | number;
      offset: number;
      poistenciUdaje: PoistenecUdaj[];
      request: any;
      route: string;
      stavyFiltrov: {
        edukacia: RadioButtonType[] | null;
        obdobie: RadioButtonType[] | null;
        specifickyVyber: SelectType[] | null;
      };
      stavyFiltrovBE: { pocet: number; text: string }[];
      typPacientov: KodUdaj | string | null;
      typPacientovLocal: KodUdaj | string | null;
      typPrehladuPacientov: 'ZS' | 'FARM' | 'CHR';
    };
  };
  vybrateObdobie: ObdobiePP | undefined;
}

// @ts-ignore
export const initialState: PacientiState = {
  archivacia: {
    error: null,
    isLoading: false,
    success: null,
  },
  benchmark: {
    benchmarkyDiagnoz: [],
    error: null,
    errorType: null,
    isLoading: false,
    okresy: [],
    vuc: [],
    vucOkresyLoading: false,
    vybranyOkres: '',
    vybranyVUC: '',
  },
  countLoadingErrors: 0,
  error: null,
  errorType: null,
  filter: 'A',
  isLoading: false,
  isLoaded: false,
  prehladDiagnostikyPacientov: {
    obdobia: [],
    poistenciUdaje: [],
    udaje: [],
  },
  prehladPacientov: {
    detailPacienta: {
      data: [],
      error: null,
      isLoading: false,
    },
    odbornost: '',
    prehlad: {
      error: null,
      fetchTime: 0,
      isLoaded: false,
      isLoading: false,
      kodyHodnoty: [],
    },
    zoznam: {
      archiv: false,
      archivLoading: false,
      archivovaniPoistenci: [],
      detailPoistenciUdaje: [],
      error: null,
      filter: {
        archiv: false,
        edukacia: null,
        obdobie: null,
        offset: 0,
        specifickyVyber: null,
        typyDiagnoz: null,
        typyPacientov: null,
      },
      filterCovid: {
        zranitelni: true,
        podlaVeku: '80+',
      },
      filterPOIState: '',
      isFilterReady: false,
      isLoaded: false,
      isLoading: false,
      kodyHodnoty: [],
      lastRequest: null,
      notLoadMore: false,
      obdobieDo: '',
      obdobieOd: '',
      offset: -1,
      poistenciUdaje: [],
      request: null,
      route: '',
      stavyFiltrov: {
        edukacia: null,
        obdobie: null,
        specifickyVyber: null,
      },
      stavyFiltrovBE: [],
      typPacientov: null,
      typPacientovLocal: null,
      typPrehladuPacientov: 'ZS',
    },
  },
  vybrateObdobie: undefined,
};

const pacientiSlice = createSlice({
  name: 'prehladPacientov',
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(archivujPoistenca.pending.toString(), (state) => ({
        ...state,
        archivacia: {
          ...initialState.archivacia,
          isLoading: true,
        },
      }))
      .addCase(
        archivujPoistenca.fulfilled.toString(),
        (state, action: PayloadAction<{ uspesne: boolean }>) => ({
          ...state,
          archivacia: {
            ...initialState.archivacia,
            error: !action.payload.uspesne ? strings.defaultError : null,
            success: action.payload.uspesne,
          },
        }),
      )
      .addCase(archivujPoistenca.rejected.toString(), (state) => ({
        ...state,
        archivacia: {
          ...initialState.archivacia,
          error: strings.defaultError,
          success: false,
        },
      }));
  },
  reducers: {
    dajPrehladDiagnostikyPacientovStart(state) {
      return {
        ...state,
        isLoading: true,
        countLoadingErrors: state.countLoadingErrors,
        prehladDiagnostikyPacientov: {
          ...initialState.prehladDiagnostikyPacientov,
          obdobia: state.prehladDiagnostikyPacientov.obdobia,
        },
      };
    },
    dajPrehladDiagnostikyPacientov(
      state,
      action: PayloadAction<PrehladDiagnostikyPacientovState>,
    ) {
      return {
        ...state,
        error: null,
        isLoading: false,
        isLoaded: true,
        prehladDiagnostikyPacientov: action.payload,
        vybrateObdobie:
          action.payload.obdobia?.filter(
            (o) => o.kodTypObdobie === state.filter,
          )?.[0] || action.payload.obdobia?.[0],
      };
    },
    dajPrehladDiagnostikyPacientovChyba(state, action) {
      return {
        ...state,
        isLoading: false,
        error: action.payload,
        errorType: 'technical',
        countLoadingErrors: state.countLoadingErrors + 1,
        isLoaded: state.countLoadingErrors === 5,
      };
    },
    dajPrehladPacientovStart(
      state,
      action: PayloadAction<{ kodOdbNz: string }>,
    ) {
      return {
        ...state,
        prehladPacientov: {
          ...state.prehladPacientov,
          odbornost: action.payload.kodOdbNz,
          prehlad: {
            ...state.prehladPacientov.prehlad,
            isLoading: true,
            fetchTime: getMoment().valueOf(),
          },
        },
      };
    },
    dajPrehladPacientov(
      state,
      action: PayloadAction<{
        odbornost: string;
        response: PrehladPacientovState;
      }>,
    ) {
      return {
        ...state,
        prehladPacientov: {
          ...state.prehladPacientov,
          prehlad: {
            ...state.prehladPacientov.prehlad,
            error: null,
            isLoaded: true,
            isLoading: false,
            kodyHodnoty: action.payload.response.kodyHodnoty || [],
          },
          zoznam: {
            ...state.prehladPacientov.zoznam,
            isFilterReady:
              state.prehladPacientov.odbornost === action.payload.odbornost,
          },
        },
      };
    },
    dajPrehladPacientovChyba(state, action) {
      return {
        ...state,
        prehladPacientov: {
          ...state.prehladPacientov,
          prehlad: {
            ...state.prehladPacientov.prehlad,
            error: action.payload,
            isLoading: false,
          },
        },
      };
    },
    dajZoznamPacientovStart(state, action) {
      return {
        ...state,
        prehladPacientov: {
          ...state.prehladPacientov,
          zoznam: {
            ...state.prehladPacientov.zoznam,
            isFilterReady: false,
            isLoading: true,
            lastRequest: JSON.stringify(action.payload),
            notLoadMore: true,
            poistenciUdaje:
              action.payload.offset === 0
                ? []
                : state.prehladPacientov.zoznam.poistenciUdaje,
            request: action.payload,
            route: action.payload.route,
            stavyFiltrov:
              (action.payload.filterZoznamPacientov &&
                (action.payload.filterZoznamPacientov.text1 ||
                  action.payload.filterZoznamPacientov.hodnota2)) ||
              (action.payload.kodObdobie !== 'R' &&
                action.payload.kod !== 'CHR_HYP') ||
              (action.payload.kodObdobie && action.payload.kod === 'POC_NAROC')
                ? state.prehladPacientov.zoznam.stavyFiltrov
                : initialState.prehladPacientov.zoznam.stavyFiltrov,
            typPacientov: action.payload.kod,
            offset:
              state.prehladPacientov.zoznam.typPacientov !== action.payload.kod
                ? 0
                : state.prehladPacientov.zoznam.offset,
            filter: {
              ...state.prehladPacientov.zoznam.filter,
              specifickyVyber:
                action.payload.kod === 'FARM_POLY' &&
                !action.payload.filterZoznamPacientov.hodnota2 &&
                Number.isNaN(
                  Number(state.prehladPacientov.zoznam.filter.specifickyVyber),
                )
                  ? '10'
                  : action.payload.kod === 'FARM_POLY' ||
                      (state.prehladPacientov.zoznam.filter.specifickyVyber !==
                        'all' &&
                        !action.payload.filterZoznamPacientov.hodnota2 &&
                        Number.isNaN(
                          Number(
                            state.prehladPacientov.zoznam.filter
                              .specifickyVyber,
                          ),
                        ))
                    ? action.payload.filterZoznamPacientov.hodnota2
                      ? action.payload.filterZoznamPacientov.hodnota2.toString()
                      : state.prehladPacientov.zoznam.filter.specifickyVyber
                    : !Number.isNaN(
                          Number(
                            state.prehladPacientov.zoznam.filter
                              .specifickyVyber,
                          ),
                        ) &&
                        Number(
                          state.prehladPacientov.zoznam.filter.specifickyVyber,
                        ) < 2
                      ? state.prehladPacientov.zoznam.filter.specifickyVyber
                      : state.prehladPacientov.zoznam.filter.specifickyVyber ||
                        'all',
              typyPacientov:
                action.payload?.kod ||
                state.prehladPacientov.zoznam.filter.typyPacientov,
            },
            filterPOIState: action.payload.filterZoznamPacientov.vyhladavanie,
            filterCovid:
              action.payload.kod !== 'ZS_NEOCK'
                ? {
                    podlaVeku: '80+',
                    zranitelni: true,
                  }
                : state.prehladPacientov.zoznam.filterCovid,
          },
        },
      };
    },
    dajZoznamPacientov(
      state,
      action: PayloadAction<{
        kod: KodUdaj | string;
        obdobie: string | null;
        offset: number;
        request: DajZoznamPoistencovPayload;
        response: ZoznamPacientovState;
        specifickyVyber: string | null;
      }>,
    ) {
      const poistenciUdajeArr: PoistenecUdaj[] =
        !action.payload.offset ||
        (action.payload.kod === 'ZS_NEOCK' && action.payload.offset === -1)
          ? action.payload.response.poistenciUdaje
          : action.payload.response.poistenciUdaje
            ? [
                ...state.prehladPacientov.zoznam.poistenciUdaje,
                ...action.payload.response.poistenciUdaje,
              ]
            : state.prehladPacientov.zoznam.poistenciUdaje;
      return {
        ...state,
        prehladPacientov: {
          ...state.prehladPacientov,
          zoznam: {
            ...state.prehladPacientov.zoznam,
            error: null,
            isLoaded: true,
            isLoading: false,
            isFilterReady: false,
            offset: action.payload.offset,
            notLoadMore: !(
              action.payload.response.poistenciUdaje &&
              action.payload.response.poistenciUdaje.length > 0
            ),
            detailPoistenciUdaje: action.payload.response.detailPoistenciUdaje,
            kodyHodnoty: action.payload.response.kodyHodnoty,
            poistenciUdaje: poistenciUdajeArr,
            typPacientov: action.payload.kod,
            obdobieDo:
              action.payload.response.kodyHodnoty &&
              action.payload.response.kodyHodnoty[0]?.obdobieDo,
            obdobieOd:
              action.payload.response.kodyHodnoty &&
              action.payload.response.kodyHodnoty[0]?.obdobieOd,
            stavyFiltrov: {
              ...state.prehladPacientov.zoznam.stavyFiltrov,
              specifickyVyber:
                action.payload.response.poistenciUdaje &&
                action.payload.response.poistenciUdaje.length > 0 &&
                !action.payload.offset
                  ? dajSpecifickyVyberPreFilterPacientov(
                      action.payload.response.poistenciUdaje,
                      action.payload.kod,
                    )
                  : action.payload.offset ||
                      action.payload.specifickyVyber ||
                      action.payload.obdobie
                    ? state.prehladPacientov.zoznam.stavyFiltrov.specifickyVyber
                    : null,
              edukacia:
                action.payload.response.poistenciUdaje &&
                action.payload.response.poistenciUdaje.length > 0 &&
                !action.payload.offset
                  ? dajFilterEdukaciePacientov(action.payload.kod)
                  : action.payload.offset ||
                      action.payload.specifickyVyber ||
                      action.payload.obdobie
                    ? state.prehladPacientov.zoznam.stavyFiltrov.edukacia
                    : null,
              obdobie:
                action.payload.response.poistenciUdaje &&
                action.payload.response.poistenciUdaje.length > 0 &&
                !action.payload.offset
                  ? dajFilterObdobiePacientov(action.payload.kod)
                  : action.payload.offset ||
                      action.payload.specifickyVyber ||
                      action.payload.obdobie
                    ? state.prehladPacientov.zoznam.stavyFiltrov.obdobie
                    : null,
            },
            stavyFiltrovBE: action.payload.response.filterUdaje
              ? action.payload.response.filterUdaje
              : state.prehladPacientov.zoznam.stavyFiltrovBE,
            typPrehladuPacientov: action.payload.kod.startsWith('FARM')
              ? 'FARM'
              : action.payload.kod.startsWith('CHR')
                ? 'CHR'
                : 'ZS',
          },
        },
      };
    },
    dajZoznamPacientovChyba(state) {
      return {
        ...state,
        prehladPacientov: {
          ...state.prehladPacientov,
          zoznam: {
            ...state.prehladPacientov.zoznam,
            error: state.prehladPacientov.zoznam.offset
              ? null
              : 'Pre vybraný filter sme nenašli žiadnych pacientov.',
            notLoadMore: true,
            isFilterReady: false,
            isLoading: false,
            request: null,
          },
        },
      };
    },
    dajDetailPacientaStart(state) {
      return {
        ...state,
        prehladPacientov: {
          ...state.prehladPacientov,
          detailPacienta: {
            ...state.prehladPacientov.detailPacienta,
            data: [],
            error: null,
            isLoading: true,
          },
        },
      };
    },
    dajDetailPacienta(state, action: PayloadAction<DetailPacientovState>) {
      return {
        ...state,
        prehladPacientov: {
          ...state.prehladPacientov,
          detailPacienta: {
            ...state.prehladPacientov.detailPacienta,
            data: action.payload.detailPoistenciUdaje,
            isLoading: false,
          },
        },
      };
    },
    dajDetailPacientaChyba(state, action) {
      return {
        ...state,
        prehladPacientov: {
          ...state.prehladPacientov,
          detailPacienta: {
            data: [],
            isLoading: false,
            error: action.payload,
          },
        },
      };
    },
    changeFilter(
      state,
      action: PayloadAction<FilterPrehladuDiagnostikyPacientov>,
    ) {
      state.filter = action.payload;
    },
    nastavVybrateObdobie(state, action: PayloadAction<ObdobiePP>) {
      state.vybrateObdobie = action.payload;
    },
    dajBenchmarkDiagnozStart(state, action) {
      return {
        ...state,
        benchmark: {
          ...state.benchmark,
          isLoading: true,
          vybranyVUC: action.payload.vuc,
          vybranyOkres: action.payload.okres,
        },
      };
    },
    dajBenchmarkDiagnoz(
      state,
      action: PayloadAction<{
        benchmarkyDiagnoz: BenchmarkDiagnozy[];
      }>,
    ) {
      return {
        ...state,
        benchmark: {
          ...state.benchmark,
          isLoading: false,
          benchmarkyDiagnoz: action.payload.benchmarkyDiagnoz,
        },
      };
    },
    dajBenchmarkDiagnozChyba(state, action) {
      return {
        ...state,
        benchmark: {
          ...state.benchmark,
          isLoading: false,
          error: action.payload,
          errorType: 'technical',
        },
      };
    },
    dajVucOkresyPreBenchmarkyDiagnozStart(state) {
      return {
        ...state,
        benchmark: {
          ...state.benchmark,
          vucOkresyLoading: true,
        },
      };
    },
    dajVucOkresyPreBenchmarkyDiagnoz(
      state,
      action: PayloadAction<{
        okresy: Okres[];
        vuc: VUC[];
      }>,
    ) {
      return {
        ...state,
        benchmark: {
          ...state.benchmark,
          okresy: action.payload.okresy,
          vuc: action.payload.vuc,
          vucOkresyLoading: false,
        },
      };
    },
    dajVucOkresyPreBenchmarkyDiagnozChyba(state, action) {
      return {
        ...state,
        benchmark: {
          ...state.benchmark,
          error: action.payload,
          errorType: 'technical',
        },
      };
    },
    odstranDetailPacientov(state) {
      return {
        ...state,
        prehladPacientov: {
          ...state.prehladPacientov,
          detailPacienta: {
            ...state.prehladPacientov.detailPacienta,
            data: [],
          },
        },
      };
    },
    dajArchiv(state, action: PayloadAction<boolean>) {
      return {
        ...state,
        prehladPacientov: {
          ...state.prehladPacientov,
          zoznam: {
            ...state.prehladPacientov.zoznam,
            filter: {
              ...state.prehladPacientov.zoznam.filter,
              archiv: action.payload,
            },
            archiv: action.payload,
            isFilterReady: true,
          },
        },
      };
    },
    stopLoadingZoznam(state) {
      return {
        ...state,
        prehladPacientov: {
          ...state.prehladPacientov,
          zoznam: {
            ...state.prehladPacientov.zoznam,
            isLoading: false,
          },
        },
      };
    },
    filterZoznamPacientov(state) {
      return {
        ...state,
        prehladPacientov: {
          ...state.prehladPacientov,
          zoznam: {
            ...state.prehladPacientov.zoznam,
            isLoading: true,
          },
        },
      };
    },
    zmenStavFiltraPacientov(state, action: PayloadAction<PacientiFilterState>) {
      return {
        ...state,
        prehladPacientov: {
          ...state.prehladPacientov,
          zoznam: {
            ...state.prehladPacientov.zoznam,
            filter: {
              ...action.payload,
              archiv: action.payload.archiv || false,
            },
            isFilterReady: true,
            poistenciUdaje: action.payload.offset
              ? state.prehladPacientov.zoznam.poistenciUdaje
              : [],
          },
        },
      };
    },
    zmenaOdbornostiPacienti(state) {
      return {
        ...state,
        prehladPacientov: {
          ...state.prehladPacientov,
          zoznam: {
            ...state.prehladPacientov.zoznam,
            filter: initialState.prehladPacientov.zoznam.filter,
          },
        },
      };
    },
    zmenFilterCovid(
      state,
      action: PayloadAction<{ podlaVeku: string; zranitelni: boolean }>,
    ) {
      return {
        ...state,
        prehladPacientov: {
          ...state.prehladPacientov,
          zoznam: {
            ...state.prehladPacientov.zoznam,
            poistenciUdaje: [],
            filterCovid: {
              podlaVeku: action.payload.podlaVeku,
              zranitelni: action.payload.zranitelni,
            },
            isFilterReady: true,
            offset: 0,
            filter: {
              ...state.prehladPacientov.zoznam.filter,
              offset: 0,
            },
          },
        },
      };
    },
    zmenFilterCovidLoading(state) {
      return {
        ...state,
        prehladPacientov: {
          ...state.prehladPacientov,
          zoznam: {
            ...state.prehladPacientov.zoznam,
            isLoading: true,
          },
        },
      };
    },
    changeFilterReady(
      state,
      action: PayloadAction<{ isFilterReady: boolean }>,
    ) {
      return {
        ...state,
        prehladPacientov: {
          ...state.prehladPacientov,
          zoznam: {
            ...state.prehladPacientov.zoznam,
            isFilterReady: action.payload.isFilterReady,
          },
        },
      };
    },
    setLoadingForPatients(state) {
      state.isLoaded = false;
      state.prehladDiagnostikyPacientov =
        initialState.prehladDiagnostikyPacientov;
      state.prehladPacientov.prehlad.isLoading = true;
      state.prehladPacientov.detailPacienta.isLoading = true;
    },
    resetPatients() {
      return initialState;
    },
    resetAfterArchived(state) {
      return {
        ...state,
        archivacia: initialState.archivacia,
        prehladPacientov: {
          ...state.prehladPacientov,
          zoznam: {
            ...state.prehladPacientov.zoznam,
            isFilterReady: true,
            lastRequest: initialState.prehladPacientov.zoznam.lastRequest,
            request: initialState.prehladPacientov.zoznam.request,
          },
        },
      };
    },
  },
});

export const {
  changeFilter,
  changeFilterReady,
  dajArchiv,
  dajBenchmarkDiagnoz,
  dajBenchmarkDiagnozChyba,
  dajBenchmarkDiagnozStart,
  dajDetailPacienta,
  dajDetailPacientaChyba,
  dajDetailPacientaStart,
  dajPrehladDiagnostikyPacientov,
  dajPrehladDiagnostikyPacientovChyba,
  dajPrehladDiagnostikyPacientovStart,
  dajPrehladPacientov,
  dajPrehladPacientovChyba,
  dajPrehladPacientovStart,
  dajVucOkresyPreBenchmarkyDiagnoz,
  dajVucOkresyPreBenchmarkyDiagnozChyba,
  dajVucOkresyPreBenchmarkyDiagnozStart,
  dajZoznamPacientov,
  dajZoznamPacientovChyba,
  dajZoznamPacientovStart,
  filterZoznamPacientov,
  nastavVybrateObdobie,
  odstranDetailPacientov,
  resetAfterArchived,
  resetPatients,
  setLoadingForPatients,
  stopLoadingZoznam,
  zmenaOdbornostiPacienti,
  zmenFilterCovid,
  zmenFilterCovidLoading,
  zmenStavFiltraPacientov,
} = pacientiSlice.actions;

export default pacientiSlice.reducer;

export const fetchPrehladDiagnostikyPacientov =
  (payload: DajPrehladDiagnostikyPacientovPayload): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(dajPrehladDiagnostikyPacientovStart());
      const prehladPacientov = await dajPrehladDiagnostikyPacientovApi(payload);
      dispatch(dajPrehladDiagnostikyPacientov(prehladPacientov));
    } catch (err) {
      dispatch(dajPrehladDiagnostikyPacientovChyba(err));
    }
  };

export const fetchPrehladPacientov =
  (payload: DajPrehladPacientovPayload): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(dajPrehladPacientovStart({ kodOdbNz: payload.kodOdbNz }));
      const prehladPacientov = await dajPrehladPacientovApi(payload);
      dispatch(
        dajPrehladPacientov({
          response: prehladPacientov,
          odbornost: payload.kodOdbNz,
        }),
      );
    } catch (err) {
      dispatch(dajPrehladPacientovChyba(err));
    }
  };

export const fetchZoznamPacientov =
  (payload: DajZoznamPoistencovPayload): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(dajZoznamPacientovStart(payload));
      const zoznamPacientov = await dajZoznamPoistencovApi(payload);
      dispatch(
        dajZoznamPacientov({
          response: zoznamPacientov,
          kod: payload.kod,
          offset: payload.offset,
          specifickyVyber:
            payload.filterZoznamPacientov &&
            payload.filterZoznamPacientov.text1,
          obdobie: payload.kodObdobie,
          request: payload,
        }),
      );
      // dispatch(stopLoadingZoznam());
    } catch (err) {
      dispatch(dajZoznamPacientovChyba());
    }
  };

export const fetchZmenFilterCovid =
  (kodOdbNz: string, podlaVeku: string, zranitelni: boolean): AppThunk =>
  async (dispatch) => {
    try {
      // dispatch(zmenFilterCovidLoading());
      dispatch(zmenFilterCovid({ podlaVeku, zranitelni }));
    } catch (err) {
      dispatch(dajZoznamPacientovChyba());
    }
  };

export const fetchDetailPacienta =
  (payload: DajDetailZoznamuPoistencovPayload): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(dajDetailPacientaStart());
      const detailPacienta = await dajDetailZoznamuPoistencovApi(payload);
      dispatch(dajDetailPacienta(detailPacienta));
    } catch (err) {
      dispatch(dajDetailPacientaChyba(err));
    }
  };

export const fetchBenchmarkDiagnoz =
  (payload: DajBenchmarkDiagnozPayload): AppThunk =>
  async (dispatch) => {
    try {
      const benchmarkData = {
        vuc: payload.kodVUC,
        okres: payload.kodOkres,
      };
      dispatch(dajBenchmarkDiagnozStart(benchmarkData));
      const response = await dajBenchmarkDiagnozApi(payload);
      dispatch(dajBenchmarkDiagnoz(response));
    } catch (err) {
      dispatch(dajBenchmarkDiagnozChyba(err));
    }
  };

export const fetchVucOkresyPreBenchamrkyDiagnoz =
  (
    idVZ: number,
    kodTypZS: string,
    kodOdbNz: string,
    kodTypObdobie: string,
  ): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(dajVucOkresyPreBenchmarkyDiagnozStart());
      const response = await dajVucOkresyPreBenchmarkyDiagnozApi(
        idVZ,
        kodTypZS,
        kodOdbNz,
        kodTypObdobie,
      );
      dispatch(dajVucOkresyPreBenchmarkyDiagnoz(response));
    } catch (err) {
      dispatch(dajVucOkresyPreBenchmarkyDiagnozChyba(err));
    }
  };

const kodyHodnotyZoznam = (state: PacientiState) =>
  state.prehladPacientov.zoznam.kodyHodnoty;

const kodyHodnotyPrehlad = (state: PacientiState) =>
  state.prehladPacientov.prehlad.kodyHodnoty;

const filterZoznamu = (state: PacientiState) =>
  state.prehladPacientov.zoznam.filter;

const zoznamPacientov = (state: PacientiState) => state.prehladPacientov.zoznam;

const prehladPacientov = (state: PacientiState) =>
  state.prehladPacientov.prehlad;

export const selectKodyHodnoty = createSelector(
  [
    kodyHodnotyZoznam,
    (
      state: PacientiState,
      kodUdaj: KodUdaj,
      urovenUdaj: string | null,
      incl?: boolean,
    ) => ({
      incl,
      kodUdaj,
      urovenUdaj,
    }),
  ],
  (kody, { incl, kodUdaj, urovenUdaj }) =>
    kody?.filter(
      (k) =>
        k.kodUdaj === kodUdaj &&
        (k.kodUrovenUdaj === urovenUdaj ||
          (incl && urovenUdaj && k.kodUrovenUdaj?.includes(urovenUdaj))),
    ),
);

export const selectKodHodnoty = createSelector(
  [
    kodyHodnotyZoznam,
    (
      state: PacientiState,
      kodUdaj: KodUdaj | string | null,
      urovenUdaj: string | null,
    ) => ({
      kodUdaj,
      urovenUdaj,
    }),
  ],
  (kody, { kodUdaj, urovenUdaj }) =>
    kody?.find((k) => k.kodUdaj === kodUdaj && k.kodUrovenUdaj === urovenUdaj),
);

export const canShowPatientHeader = createSelector(
  [
    kodyHodnotyPrehlad,
    filterZoznamu,
    (state: PacientiState, kodUdaj: KodUdaj | string | null) => ({
      kodUdaj,
    }),
  ],
  (kody, filter, { kodUdaj }) =>
    kody?.length > 0 &&
    kody.some((k) => k.kodUdaj === kodUdaj) &&
    !filter.offset,
);

export const selectPatientData = createSelector(
  [zoznamPacientov, (state: PacientiState, idPacienta: number) => idPacienta],
  (zoznamPacientov, idPacienta) =>
    zoznamPacientov?.poistenciUdaje?.find((p) => p.idPoistenec === idPacienta),
);

export const selectArchivBtn = createSelector(
  [zoznamPacientov, () => {}],
  (zoznamPacientov) =>
    zoznamPacientov?.poistenciUdaje?.[0]?.typArchivacie || '',
);

export const isPatientTypeAvailable = createSelector(
  [
    prehladPacientov,
    (state: PacientiState, kodUdaj: KodUdaj | string | null) => kodUdaj,
  ],
  (prehlad, kodUdaj) =>
    prehlad?.kodyHodnoty?.some((k) => k.kodUdaj === kodUdaj),
);

export const showPatientError = createSelector(
  [
    zoznamPacientov,
    prehladPacientov,
    (state: PacientiState, kodUdaj: KodUdaj | string | null) => kodUdaj,
  ],
  (zoznamPacientov, prehlad, kodUdaj) => {
    const isAvailable = prehlad?.kodyHodnoty?.some(
      (k) => k.kodUdaj === kodUdaj,
    );
    if (
      !prehlad?.isLoading &&
      prehlad?.isLoaded &&
      !zoznamPacientov?.isLoading &&
      zoznamPacientov?.isLoaded &&
      (!isAvailable || !zoznamPacientov?.poistenciUdaje?.length)
    )
      return true;
    return (
      (zoznamPacientov.error ||
        (!zoznamPacientov?.poistenciUdaje?.length && kodUdaj !== 'CHR_HYP') ||
        (!zoznamPacientov?.poistenciUdaje && kodUdaj !== 'CHR_HYP')) &&
      !zoznamPacientov?.isLoading &&
      !zoznamPacientov?.isFilterReady &&
      !prehlad?.isLoading &&
      !zoznamPacientov?.filterPOIState &&
      (!zoznamPacientov?.poistenciUdaje ||
        !zoznamPacientov?.poistenciUdaje?.length)
    );
  },
);
