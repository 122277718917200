interface Props {
  id: string;
}

const IconStar = ({ id }: Props) => (
  <svg
    fill="none"
    height="20"
    id={id}
    viewBox="0 0 20 20"
    width="20"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.28696 2.1407C9.60456 1.50844 10.511 1.50844 10.8286 2.1407L12.8533 6.17139C12.9901 6.44369 13.2617 6.62344 13.5669 6.64369L17.5279 6.90653C18.2844 6.95673 18.6118 7.88483 18.0529 8.39482L14.7518 11.4068C14.5152 11.6227 14.4187 11.9515 14.5014 12.2602L15.5976 16.3519C15.789 17.0661 15.0437 17.6708 14.3795 17.3402L10.4432 15.3811C10.2006 15.2604 9.91501 15.2604 9.67238 15.3811L5.60113 17.4073C4.95968 17.7266 4.22998 17.1718 4.37063 16.4718L5.22733 12.2082C5.28384 11.9269 5.19539 11.6362 4.99164 11.4334L1.92074 8.37667C1.39717 7.85552 1.73401 6.96318 2.47281 6.91416L6.54874 6.64369C6.85392 6.62344 7.12546 6.44369 7.26225 6.17139L9.28696 2.1407Z"
      fill="#FAC832"
      stroke="#FAC832"
      strokeLinejoin="round"
      strokeWidth="2"
    />
  </svg>
);

export default IconStar;
