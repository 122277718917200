// @ts-nocheck
import {
  Document,
  Font,
  Image,
  Page,
  StyleSheet,
  Text,
  View,
} from '@react-pdf/renderer';
import dzpFont from './fonts/DoveraSans-Regular.ttf';
import dzpFontBold from './fonts/DoveraSans-Bold.ttf';
import dzpFontThin from './fonts/DoveraSans-Thin.ttf';
import logo from './assets/dovera.png';
import { color } from '../../constants';
import CategoryBadge from './assets/svg/CategoryBadge';
import CategoryDetail from './parts/CategoryDetail';
import {
  SkupinyParametrov,
  VysledkyObdobia,
  VysledokObdobia,
  ZoznamObdobi,
} from '../../slices/hodnotiaceParametre.slice';
import {
  calculateChartValue,
  calculateHranica1Hodn,
  calculateHranica2Hodn,
  calculateKeyCenaBodu,
  calculatePointer1,
  calculatePointer2,
  formatHodnota,
} from '../../utils/hodnotiaceParametre.utils';
import { textWithValueString } from '../../utils/strings.utils';
import InfoIcon from './assets/svg/InfoIcon';
import {
  getFillStatus,
  getHPBaseContent,
  getHPChart,
  getIcon,
  getQualityGraphData,
  getTextStyles,
} from './utils';
import { inlineCondition, safeNode } from '../../utils/app.utils';
import { getPercentage } from '../../utils/number.utils';
// @ts-check./assets/svg/CategoryBadge

Font.register({
  family: 'DoveraSans',
  fonts: [
    {
      format: 'truetype',
      fontWeight: '400',
      src: dzpFont,
    },
    {
      format: 'truetype',
      fontWeight: 'bold',
      src: dzpFontBold,
    },
    {
      format: 'truetype',
      fontWeight: '100',
      src: dzpFontThin,
    },
  ],
});

const styles = StyleSheet.create({
  page: {
    backgroundColor: '#FFFFFF',
    fontFamily: 'DoveraSans',
    fontWeight: '400',
    padding: '20px 40px',
    fontSize: 11,
    lineHeight: 1.5,
    position: 'relative',
  },
  footer: {
    position: 'absolute',
    bottom: 20,
    right: 40,
    color: color('grey', 500),
    fontSize: 10,
    width: '100%',
  },
  footerLeft: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
  },
  footerRight: {
    position: 'absolute',
    right: 0,
  },
  flex: {
    display: 'flex',
    flexDirection: 'row',
  },
  flexRow: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    width: '100%',
    marginTop: 15,
  },
  flexCol: {
    flex: '1 1 50%',
    paddingTop: 5,
  },
  flexColFullWidth: {
    flex: '1 1 100%',
    paddingTop: 5,
  },
  flexCol40: {
    flex: '1 1 45%',
    paddingTop: 5,
  },
  flexCol60: {
    flex: '1 1 55%',
    paddingTop: 5,
  },
  header: {
    padding: '24px 0',
  },
  title: {
    color: color('secondary'),
    fontSize: 24,
    lineHeight: 1,
    fontWeight: 'bold',
  },
  subTitle: {
    color: color('secondary'),
    fontSize: 16,
    fontWeight: '100',
    marginTop: 10,
    lineHeight: 1,
  },
  colorSecondary: {
    color: color('secondary'),
  },
  logo: {
    position: 'absolute',
    width: 71,
    right: 0,
  },
  subtitle: {
    fontWeight: 'bold',
    fontSize: 18,
    lineHeight: 1,
    color: color('secondary'),
  },
  subsubtitle: {
    fontWeight: 'bold',
    fontSize: 14,
    lineHeight: 1,
    color: color('secondary'),
  },
  hr: {
    marginTop: 15,
    paddingBottom: 15,
    display: 'block',
    borderTop: `1px solid ${color('grey', 200)}`,
  },
  h4: {
    fontWeight: 'bold',
    fontSize: 13,
    marginBottom: 15,
    lineHeight: 1,
  },
  h4mt: {
    fontWeight: 'bold',
    fontSize: 13,
    marginTop: 20,
    marginBottom: 10,
    lineHeight: 1,
  },
  colorGrey: {
    color: color('grey', 500),
  },
  baseContent: {
    fontSize: 11,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  leftContent: {
    width: '60%',
  },
  graph: {
    '& svg': {
      fontSize: 11,
      fontFamily: 'DoveraSans',
    },
  },
  categories: {
    marginTop: 50,
  },
  green: {
    color: color('primary'),
    fontWeight: 'bold',
  },
  red: {
    color: color('error'),
    fontWeight: 'bold',
  },
  orange: {
    color: color('warning'),
    fontWeight: 'bold',
  },
});

interface Props {
  aktualneId: number;
  isVAS: boolean;
  nazovPZS: string;
  obdobie: string;
  odbornost: string;
  platnostCenyOd: string;
  skupiny?: SkupinyParametrov;
  vypocetObdobia: any;
  vysledkyObdobia: VysledkyObdobia;
  vysledokObdobia: VysledokObdobia[];
  zoznamObdobi: ZoznamObdobi;
}

const HPPdfTemplate = ({
  aktualneId,
  isVAS,
  nazovPZS,
  obdobie,
  odbornost,
  platnostCenyOd,
  skupiny,
  vypocetObdobia,
  vysledkyObdobia,
  vysledokObdobia,
  zoznamObdobi,
}: Props) => {
  const keys = calculateKeyCenaBodu(zoznamObdobi[aktualneId]);
  const renderGraph = (
    <View styles={styles.graph}>
      {getHPChart({ isVAS, vysledokObdobia, skupiny })}
    </View>
  );
  const renderFixedHeader = (
    <View fixed>
      <Text>{nazovPZS}</Text>
      <Text style={styles.colorSecondary}>{odbornost}</Text>
      <Image src={logo} style={styles.logo} />
      <Text style={styles.hr} />
    </View>
  );

  const renderFixedFooter = (
    <View fixed style={styles.footer}>
      <View style={styles.footerLeft}>
        <InfoIcon
          style={{ marginRight: 8, verticalAlign: 'middle', marginTop: 1 }}
        />
        <Text>
          Platnost vypočítanej ceny je od {platnostCenyOd} po dobu jedného
          kalendárneho polroka.
        </Text>
      </View>
      <View style={styles.footerRight}>
        <Text
          render={({ pageNumber, totalPages }) => `${pageNumber}/${totalPages}`}
        />
      </View>
    </View>
  );
  const renderTitle = (
    <View>
      {vypocetObdobia?.length > 3 ? (
        <View style={[styles.flexRow, { justifyContent: 'space-between' }]}>
          <View style={[styles.flexCol, { width: '60%', marginTop: 15 }]}>
            <Text style={styles.title}>Prehľad hodnotiacich parametrov</Text>
            <Text style={styles.subTitle}>{obdobie}</Text>
          </View>
          <View style={[styles.flexCol, { position: 'relative', height: 150 }]}>
            <View
              style={{
                width: 150,
                position: 'absolute',
                right: 15,
                marginTop: -15,
              }}
            >
              {renderGraph}
            </View>
          </View>
        </View>
      ) : (
        <View style={styles.header}>
          <Text style={styles.title}>Prehľad hodnotiacich parametrov</Text>
          <Text style={styles.subTitle}>{obdobie}</Text>
        </View>
      )}
    </View>
  );
  const renderBaseContent = (
    <View style={styles.baseContent}>
      {getHPBaseContent({ keys, styles, vypocetObdobia, vysledkyObdobia })}
      {vypocetObdobia?.length <= 3 && renderGraph}
    </View>
  );
  const renderQuality = skupiny && skupiny.Kvalita && (
    <View>
      <View style={[styles.flex, { alignItems: 'center' }]}>
        <CategoryBadge
          category="Q"
          percentage={
            skupiny &&
            skupiny.Kvalita &&
            getPercentage(skupiny.Kvalita?.plnenie, 0)
          }
        />
        <Text style={[styles.subsubtitle, { marginLeft: 15 }]}>Kvalita</Text>
      </View>
      <View style={styles.flexRow}>
        {skupiny &&
          skupiny.Kvalita &&
          skupiny.Kvalita.parametre &&
          skupiny.Kvalita?.parametre.map((p) => (
            <View
              key={`quality-${p.id}`}
              style={
                p.nazovParametra.length > 40
                  ? styles.flexColFullWidth
                  : styles.flexCol
              }
            >
              <View style={styles.flex}>
                {getIcon({ pasmo: p.pasmo, plnenie: p.plnenie })}
                <Text style={{ marginLeft: 10 }}>{p.nazovParametra}</Text>
                <Text
                  style={[
                    getTextStyles({
                      pasmo: p.pasmo,
                      plnenie: p.plnenie,
                      styles,
                    }),
                    { marginLeft: 10 },
                  ]}
                >
                  {p.kodParametra === 'subj-spok'
                    ? '100 %'
                    : inlineCondition(
                        p.plnenie && p.typHodnoty === 'P',
                        '100 %',
                        formatHodnota(p.hodnota, p.plnenie, p.typHodnoty),
                      ).toString()}
                </Text>
              </View>
            </View>
          ))}
      </View>
      <Text style={styles.hr} />
    </View>
  );
  const renderEffectivity = skupiny && skupiny.Efektívnosť && (
    <View>
      <View style={[styles.flex, { alignItems: 'center' }]}>
        <CategoryBadge
          category="E"
          percentage={
            skupiny &&
            skupiny.Efektívnosť &&
            getPercentage(skupiny.Efektívnosť?.plnenie, 0)
          }
        />
        <Text style={{ ...styles.subsubtitle, marginLeft: 15 }}>
          Efektívnosť
        </Text>
      </View>
      <View style={styles.flexRow}>
        {skupiny?.Efektívnosť?.parametre?.map((p) => (
          <View
            key={`effectivity-${p.id}`}
            style={
              p.nazovParametra.length > 40
                ? styles.flexColFullWidth
                : styles.flexCol
            }
          >
            <View style={styles.flex}>
              {getIcon({ pasmo: p.pasmo, plnenie: p.plnenie })}
              <Text style={{ marginLeft: 10 }}>{p.nazovParametra}</Text>
              <Text
                style={[
                  getTextStyles({
                    pasmo: p.pasmo,
                    plnenie: p.plnenie,
                    styles,
                  }),
                  { marginLeft: 10 },
                ]}
              >
                {p.plnenie && p.typHodnoty === 'P'
                  ? '100 %'
                  : formatHodnota(p.hodnota, p.plnenie, p.typHodnoty)}
              </Text>
            </View>
          </View>
        ))}
      </View>
      <Text style={styles.hr} />
    </View>
  );
  const renderInovations = safeNode(
    <View>
      <View style={[styles.flex, { alignItems: 'center' }]}>
        <CategoryBadge
          category="I"
          percentage={
            skupiny &&
            skupiny.Inovácie &&
            getPercentage(skupiny.Inovácie?.plnenie, 0)
          }
        />
        <Text style={{ ...styles.subsubtitle, marginLeft: 15 }}>Inovácie</Text>
      </View>
      <View style={styles.flexRow}>
        {skupiny &&
          skupiny.Inovácie &&
          skupiny.Inovácie.parametre &&
          skupiny.Inovácie?.parametre.map((p) => (
            <View
              key={`inovation-${p.id}`}
              style={
                p.nazovParametra.length > 40
                  ? styles.flexColFullWidth
                  : styles.flexCol
              }
            >
              <View style={styles.flex}>
                {getIcon({ pasmo: p.pasmo, plnenie: p.plnenie })}
                <Text style={{ marginLeft: 10 }}>{p.nazovParametra}</Text>
                <Text
                  style={[
                    getTextStyles({
                      pasmo: p.pasmo,
                      plnenie: p.plnenie,
                      styles,
                    }),
                    { marginLeft: 10 },
                  ]}
                >
                  {p.plnenie && p.typHodnoty === 'P'
                    ? '100 %'
                    : formatHodnota(p.hodnota, p.plnenie, p.typHodnoty)}
                </Text>
              </View>
            </View>
          ))}
      </View>
    </View>,
    !!skupiny?.Inovácie,
  );
  const renderCategories = (
    <View break={vypocetObdobia?.length > 3} style={styles.categories}>
      {renderQuality}
      {renderEffectivity}
      {renderInovations}
    </View>
  );
  const renderKvalita = safeNode(
    <View break>
      <CategoryDetail
        badgePercetage={getPercentage(skupiny.Kvalita?.plnenie, 0)}
        category="Q"
        data={skupiny.Kvalita?.parametre.map((p) => ({
          nadpis: p.nazovParametra,
          popis: textWithValueString(
            p.popisParametra,
            formatHodnota(p.hranicnaHodnotaMax, p.plnenie, p.typHodnoty),
            formatHodnota(p.hranicnaHodnotaMin, p.plnenie, p.typHodnoty),
          ),
          priemernaHodnota:
            p.kodParametra === 'dostup-gyn'
              ? 'N/A'
              : formatHodnota(p.priemernaHodnota, p.plnenie, p.typHodnoty),
          vasaPozicia: p.kodParametra === 'dostup-gyn' ? 'N/A' : p.poziciaPzs,
          vaha: p.vaha * 100,
          splnene: getFillStatus({ pasmo: p.pasmo, plnenie: p.plnenie }),
          graf: getQualityGraphData({ parameter: p }),
        }))}
        title="Kvalita"
      />
    </View>,
    !!skupiny?.Kvalita?.parametre,
  );
  const renderInovacie = safeNode(
    <View break>
      <CategoryDetail
        badgePercetage={getPercentage(skupiny.Efektívnosť?.plnenie, 0)}
        category="E"
        data={skupiny.Efektívnosť?.parametre.map((p) => ({
          nadpis: p.nazovParametra,
          popis: textWithValueString(
            p.popisParametra,
            formatHodnota(p.hranicnaHodnotaMax, p.plnenie, p.typHodnoty),
            formatHodnota(p.hranicnaHodnotaMin, p.plnenie, p.typHodnoty),
          ),
          priemernaHodnota: formatHodnota(
            p.priemernaHodnota,
            p.plnenie,
            p.typHodnoty,
          ),
          vasaPozicia: p.poziciaPzs,
          vaha: p.vaha * 100,
          splnene: getFillStatus({ pasmo: p.pasmo, plnenie: p.plnenie }),
          graf: [
            {
              hranica1Hodnota: calculateHranica1Hodn(p),
              hranica1Text: calculatePointer1(p, true),
              hranica2Hodnota: calculateHranica2Hodn(p),
              hranica2Text: calculatePointer2(p, true),
              vasaHodnota:
                p.kodParametra === 'subj-spok'
                  ? '100 %'
                  : inlineCondition(
                      p.plnenie && p.typHodnoty === 'P',
                      '100 %',
                      formatHodnota(p.hodnota, p.plnenie, p.typHodnoty),
                    ).toString(),
              hodnota: calculateChartValue(p, p.hodnota),
              kodParametra: p.kodParametra,
              plnenie: p.plnenie,
              typHodnoty: p.typHodnoty,
            },
          ],
        }))}
        title="Efektívnosť"
      />
    </View>,
    !!skupiny?.Efektívnosť?.parametre,
  );
  const renderEfektivnost = safeNode(
    <View break>
      <CategoryDetail
        badgePercetage={getPercentage(skupiny.Inovácie?.plnenie, 0)}
        category="E"
        data={skupiny.Inovácie?.parametre.map((p) => ({
          nadpis: p.nazovParametra,
          popis: textWithValueString(
            p.popisParametra,
            formatHodnota(p.hranicnaHodnotaMax, p.plnenie, p.typHodnoty),
            formatHodnota(p.hranicnaHodnotaMin, p.plnenie, p.typHodnoty),
          ),
          priemernaHodnota: formatHodnota(
            p.priemernaHodnota,
            p.plnenie,
            p.typHodnoty,
          ),
          vasaPozicia: p.poziciaPzs,
          vaha: p.vaha * 100,
          splnene: getFillStatus({ pasmo: p.pasmo, plnenie: p.plnenie }),
          graf: [
            {
              hranica1Hodnota: calculateHranica1Hodn(p),
              hranica1Text: calculatePointer1(p, true),
              hranica2Hodnota: calculateHranica2Hodn(p),
              hranica2Text: calculatePointer2(p, true),
              vasaHodnota:
                p.kodParametra === 'subj-spok'
                  ? '100 %'
                  : inlineCondition(
                      p.plnenie && p.typHodnoty === 'P',
                      '100 %',
                      formatHodnota(
                        p.hodnota,
                        p.plnenie,
                        p.typHodnoty,
                      ).toString(),
                    ),
              hodnota: calculateChartValue(p, p.hodnota),
              kodParametra: p.kodParametra,
              plnenie: p.plnenie,
              typHodnoty: p.typHodnoty,
            },
          ],
        }))}
        title="Inovácie"
      />
    </View>,
    !!skupiny?.Inovácie?.parametre,
  );
  return (
    <Document>
      <Page size="A4" style={styles.page} wrap>
        {renderFixedHeader}
        {renderTitle}
        {renderBaseContent}
        {renderCategories}

        {renderKvalita}
        {renderInovacie}
        {renderEfektivnost}
        {renderFixedFooter}
      </Page>
    </Document>
  );
};

export default HPPdfTemplate;
