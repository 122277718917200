import React from 'react';

const IconLockOpen = ({ ...other }: React.JSX.IntrinsicElements['svg']) => (
  <svg
    fill="none"
    height="24"
    viewBox="0 0 24 24"
    width="24"
    xmlns="http://www.w3.org/2000/svg"
    {...other}
  >
    <path
      d="M1 14.1579C1 12.9371 1.98497 11.9474 3.2 11.9474H14.2C15.415 11.9474 16.4 12.9371 16.4 14.1579V20.7895C16.4 22.0103 15.415 23 14.2 23H3.2C1.98497 23 1 22.0103 1 20.7895V14.1579Z"
      fill="currentColor"
    />
    <path
      clipRule="evenodd"
      d="M17.5 4.21053C15.6775 4.21053 14.2 5.69506 14.2 7.52632V10.8421H12V7.52632C12 4.47422 14.4624 2 17.5 2C20.5376 2 23 4.47422 23 7.52632V10.8421H20.8V7.52632C20.8 5.69506 19.3225 4.21053 17.5 4.21053Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);

export default IconLockOpen;
