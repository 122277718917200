import { createUseStyles } from 'react-jss';
import { breakpoints } from '../../hooks/screen-width/useScreenWidth';
import { color, getRem } from '@dovera/design-system';

interface StylesProps {
  isFuture?: boolean;
}

export default createUseStyles({
  customGrid: ({ isFuture }: StylesProps) => ({
    '&': {
      '& .grid__col--equalized': {
        display: 'flex',
        [`@media screen and (max-width: ${breakpoints.xl}px)`]: {
          display: 'block',
          '& p': {
            maxWidth: '100%',
          },
        },
      },
      '& .grid__col': {
        '&:not(:last-child)': {
          [`@media screen and (min-width: ${breakpoints.xl}px)`]: {
            borderRight: `1px solid ${
              isFuture ? 'rgba(0, 0, 0, .06)' : color('grey', 100)
            }`,
          },
        },
      },
    },
  }),
  hpCategoriesContainer: {
    padding: `0 ${getRem(32)}`,
    [`@media screen and (max-width: ${breakpoints.s}px)`]: {
      padding: 0,
      '& .grid__col': {
        padding: `0 !important`,
      },
    },
  },
  hpCategories: {
    padding: `0 ${getRem(16)}`,
  },
});
