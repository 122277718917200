import {
  Bar,
  BarItem,
  Card,
  CardSection,
  Container,
  Grid,
  GridCol,
  PageSection,
} from '@dovera/design-system';

import { Skeleton } from '../../components';

const StaryDashboardSkeleton = () => (
  <>
    <PageSection spaceBottom="small">
      <Container>
        {/* DashboardGraph */}
        <h1 className="h3 text-center mb-xsmall">
          <Skeleton width="30%" />
        </h1>
        <h4 className="text-center">
          <Skeleton width="50%" />
        </h4>
        <Grid className="mb">
          <GridCol
            className="d-flex align-items-middle align-items-right show-m"
            offset={{ l: 2 }}
            size={{ m: 3, l: 2 }}
          >
            <Skeleton isCircle width={150} />
          </GridCol>
          <GridCol
            className="text-center"
            offset={{ s: 2, m: 0 }}
            size={{ s: 8, m: 6, l: 4 }}
          >
            <Skeleton isCircle width={350} />
          </GridCol>
          <GridCol
            className="d-flex align-items-middle align-items-left show-m"
            size={{ m: 3, l: 2 }}
          >
            <Skeleton isCircle width={150} />
          </GridCol>
        </Grid>
        <Grid>
          <GridCol
            className="text-center"
            offset={{ s: 2, m: 4 }}
            size={{ s: 8, m: 6, l: 4 }}
          >
            <Skeleton />
            <Skeleton />
          </GridCol>
        </Grid>
      </Container>
    </PageSection>

    <PageSection spaceBottom="small" spaceTop="none">
      <Container>
        {/* DashboardCenaBodu */}
        <Grid>
          <GridCol equalizeHeight offset={{ m: 0, l: 1 }} size={{ s: 3 }}>
            <Card>
              <CardSection isCondensed>
                <span>
                  <Skeleton />
                </span>
                <h3 className="h4">
                  <Skeleton width="65%" />
                </h3>
              </CardSection>
            </Card>
          </GridCol>
          <GridCol equalizeHeight size={{ s: 9, l: 7 }}>
            <Card>
              <CardSection isCondensed>
                <p>
                  <Skeleton />
                  <Skeleton />
                </p>
              </CardSection>
            </Card>
          </GridCol>
        </Grid>
      </Container>
    </PageSection>

    <PageSection spaceBottom="small" spaceTop="none">
      <Container>
        {/* DashboardCategoryCards */}
        <Grid>
          {[...Array(3)].map((item, index) => (
            <GridCol key={index} equalizeHeight size={{ s: 6, l: 4 }}>
              <Card>
                <CardSection isCondensed>
                  <h3>
                    <Skeleton width="75%" />
                  </h3>

                  {[...Array(3)].map((value, key) => (
                    <Bar key={key} align="top" space="small">
                      <BarItem style={{ width: '16px' }}>
                        <Skeleton isCircle width={16} />
                      </BarItem>
                      <BarItem isFilling>
                        <Skeleton width="60%" />
                        <Skeleton width="70%" />
                      </BarItem>
                      <BarItem style={{ width: '15%' }}>
                        <Skeleton width="75%" />
                        <Skeleton width="100%" />
                      </BarItem>
                    </Bar>
                  ))}
                </CardSection>
              </Card>
            </GridCol>
          ))}
        </Grid>
      </Container>
    </PageSection>
  </>
);

export default StaryDashboardSkeleton;
