import { createUseStyles } from 'react-jss';

import { color, fontWeight, spaces } from '../../constants';
import { isIE } from '../../utils/app.utils';

interface StylesProps {
  pasmo: string;
  plnenie: number | boolean;
  predbezny: boolean;
}

const getColor = ({ plnenie, predbezny }: StylesProps): string => {
  if (Number(plnenie) > 0 && Number(plnenie) < 1) return color('warning');
  if (predbezny && !plnenie) return color('black');
  return plnenie ? color('primary') : color('error');
};

export default createUseStyles({
  graphPopupItem: {
    padding: spaces.xxsmall,
    fontWeight: fontWeight.semiBold,
    fontSize: '0.875rem',
    borderRadius: 8,
    textAlign: 'left',
    '&:nth-child(even)': {
      backgroundColor: color('grey', 50),
    },
    '& button': {
      color: color('black'),
      textDecoration: 'none !important',
      minHeight: 0,
      width: '100%',
    },
    '&:hover': {
      backgroundColor: 'rgba(221, 237, 210, 0.5)',
      '& a': {
        textDecoration: 'none',
        '& .bar__item--fill': {
          textDecoration: 'underline',
        },
      },
    },
  },
  graphPopupItemPrimary: {
    color: `${color('primary')} !important`,
  },
  nameBarItem: {
    display: isIE ? 'inline-block' : 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start !important',
    textAlign: 'left',
    fontWeight: fontWeight.bold,
    color: color('secondary', 500),
    textDecoration: 'none !important',
  },
  valueBarItem: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end !important',
    fontWeight: fontWeight.bold,
  },
  hodnota: ({ pasmo, plnenie, predbezny }: StylesProps) => ({
    color: getColor({ pasmo, plnenie, predbezny }),
    fontSize: '1.125rem',
    fontWeight: fontWeight.bold,
  }),
});
