import { createUseStyles } from 'react-jss';

import { color, fontWeight } from '../../constants';
import { VASType } from '../../types/poskytovatel.types';
import {
  inlineNumberCondition,
  inlineStrCondition,
} from '../../utils/app.utils';

interface StylesProps {
  VAS?: VASType;
  isSecondary?: boolean;
}

export default createUseStyles({
  wrapper: ({ isSecondary, VAS }: StylesProps) => ({
    width:
      VAS && VAS.isVLD
        ? inlineStrCondition(VAS.primaryVLD, '60%', '40%')
        : '100%',
    display: VAS && VAS.isVLD ? 'inline-flex' : '',
    verticalAlign: VAS && VAS.isVLD ? 'middle' : '',
    position: 'relative',
    opacity: isSecondary ? 0.7 : 1,
    '& svg': {
      maxWidth: '100%',
    },
    '& > div:first-child': {
      paddingLeft:
        VAS && VAS.isVLD && VAS.primaryVLD
          ? inlineNumberCondition(!isSecondary, 12, 8)
          : 0,
      marginLeft:
        VAS && VAS.isVLD && !VAS.primaryVLD
          ? inlineNumberCondition(!isSecondary, -12, -8)
          : 0,
    },
    '& .recharts-area': {
      cursor: 'pointer',
    },
    '& .recharts-responsive-container': {
      display: 'flex',
      alignItems: 'center',
      '& circle': {
        fill: 'transparent',
        '& ~ text': {
          fontSize: isSecondary && !VAS ? '110%' : '100%',
        },
      },
    },
    '& .recharts-wrapper': {
      cursor: isSecondary ? 'pointer !important' : 'initial',
      maxWidth: '100% !important',
      maxHeight: '100% !important',
    },
    ...(!isSecondary && {
      '& .recharts-radial-bar-sector:hover': {
        transition: 'opacity 0.20s ease-out',
        opacity: 1,
        strokeLinejoin: 'round',
        strokeWidth: 7,
      },
    }),
  }),
  innerCircle: {
    fill: color('white'),
  },
  innerCircleText: {
    fontWeight: fontWeight.semiBold,
    fill: color('black'),
    fontSize: ({ isSecondary, VAS }: StylesProps) => {
      let size: number = VAS ? 10 : 0;
      if (!isSecondary && VAS?.primaryVLD) size = 20;
      if (!isSecondary && !VAS?.primaryVLD) size = 16;
      if (isSecondary && VAS?.primaryVLD) size = 12;
      if (!VAS && !isSecondary) size = 26;
      return size ? `${size}px !important` : 'inherit';
    },
  },
});
