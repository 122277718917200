import { Input } from '@dovera/design-system';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { useAppDispatch } from '../../hooks/useStore';
import { RootState } from '../../rootReducer';
import routes from '../../routes';
import { dajInitStavDiagnoz } from '../../slices/diagnozy.slice';
import {
  autoChangeExpertise,
  ulozPouzivatelskeNastavenia,
  zmenEmptyStateOdbornosti,
  zmenOdbornost,
} from '../../slices/poskytovatel.slice';
import {
  getExpertises,
  handleChangeExpertise,
  hideDropdownExpertises,
} from '../../utils/poskytovatel.utils';
import SelectOdbornosti from './SelectOdbornosti';
import useStyles from './SelectOdbornosti.styles';
import { resetPatients } from '../../slices/pacienti.slice';
import { resetHP, resetHPDetail } from '../../slices/hodnotiaceParametre.slice';
import { URL_QUERIES } from '../../constants/systemConstants';
import { useQuery } from '../../hooks/useQuery';
import { navigateApp } from '../../utils/app.utils';

const ExpertisesCombo = () => {
  const location = useLocation();
  const dispatch = useAppDispatch();
  const classes = useStyles();
  const query = useQuery();
  const [defaultComboValue, setDefaultComboValue] = useState('');
  const [dostupneOdbornosti, setDostupneOdbornosti] = useState<
    { kodOdbNz: string; kodTypZS: string; nazov: string; predvolena: boolean }[]
  >([]);
  const { odbornosti } = useSelector((state: RootState) => state.poskytovatel);
  const { aktivnaOdbornost, idVZ, odbornostPublic, pouzivatelId } = useSelector(
    (state: RootState) => ({
      aktivnaOdbornost: state.poskytovatel.odbornosti.some((d) => d.aktivna)
        ? state.poskytovatel.odbornosti.filter((d) =>
            state.poskytovatel.poslednaZmenaOdbornosti
              ? d.kodOdbNz === state.poskytovatel.poslednaZmenaOdbornosti &&
                (d.kodTypZS === state.poskytovatel.kodTypZs ||
                  !state.poskytovatel.kodTypZs)
              : d.aktivna,
          )[0]
        : state.poskytovatel.odbornosti[0],
      autentifikovaneOsoby: state.poskytovatel.autentifikovaneOsoby,
      idHodnotenyPZS: state.poskytovatel.idHodnotenyPZS,
      idVZ: state.auth.vztahId,
      isSUPR: state.auth.isSUPR,
      menoLekara: state.poskytovatel.menoZobrazLekar,
      menuState: state.menu,
      obdobia: state.hodnotiaceParametre.obdobia,
      odbornostPublic: state.poskytovatel.odbornostPublic,
      poradie: state.hodnotiaceParametre.poradie,
      pouzivatelId: state.auth.pouzivatelId,
      preLekara: state.poskytovatel.preLekara,
      udajeAutentifikacie: state.pzsAuth.authAvailability.data,
      zmenenaOdbornost: state.poskytovatel.zmenenaOdbornost,
    }),
  );
  useEffect(() => {
    if (odbornosti.length) {
      const exp = getExpertises(
        aktivnaOdbornost,
        odbornosti,
        location.pathname,
      );
      setDefaultComboValue(
        exp.isEmptyState && location.pathname !== routes.dashboard
          ? 'Vyberte si odbornosť'
          : '',
      );
      let dostOdbornostiArr: {
        kodOdbNz: string;
        kodTypZS: string;
        nazov: string;
        predvolena: boolean;
      }[] = [];
      dostOdbornostiArr = exp.items.map((o, index) => {
        const existujePredvolena: boolean = !exp.items.some(
          (od) => od.predvolenaNZ === '1',
        );
        return {
          kodOdbNz: o.kodOdbNz,
          kodTypZS: o.kodTypZS,
          nazov: o.nazovOdbNz,
          predvolena:
            existujePredvolena || o.aktivna
              ? o.predvolenaNZ === '1'
              : index === 0,
        };
      });
      setDostupneOdbornosti(dostOdbornostiArr);
      if (exp.isEmptyState && location.pathname === routes.dashboard)
        handleChangeExpertise({
          expertises: exp.items,
          isEmptyState: exp.isEmptyState,
          pathname: location.pathname,
        });
      dispatch(
        zmenEmptyStateOdbornosti({
          emptyState:
            exp.isEmptyState && location.pathname !== routes.dashboard,
        }),
      );
    }
  }, [aktivnaOdbornost, dispatch, location.pathname, odbornosti]);
  const renderOdbornosti =
    dostupneOdbornosti.length > 1 && !hideDropdownExpertises() ? (
      <div className={classes.odbornosti}>
        <SelectOdbornosti
          data={dostupneOdbornosti}
          defaultValue={defaultComboValue}
          expertise={aktivnaOdbornost}
          labelText=""
          onChange={(value, kodOdbNz, kodTypZS, userAction, autoChange) => {
            if (
              value &&
              value !== aktivnaOdbornost?.nazovOdbNz &&
              (!value?.startsWith(aktivnaOdbornost?.kodOdbNz) ||
                kodTypZS !== aktivnaOdbornost?.kodTypZS)
            ) {
              dispatch(dajInitStavDiagnoz());
              dispatch(resetPatients());
              dispatch(resetHP());
              dispatch(resetHPDetail());

              if (pouzivatelId && userAction && idVZ) {
                navigateApp({ path: location.pathname });
                query.delete(URL_QUERIES.expertise);
                query.delete(URL_QUERIES.pzsType);
                dispatch(
                  ulozPouzivatelskeNastavenia(
                    idVZ,
                    pouzivatelId,
                    kodOdbNz,
                    kodTypZS,
                  ),
                );
                dispatch(
                  zmenEmptyStateOdbornosti({
                    emptyState: false,
                  }),
                );
              }
              dispatch(
                autoChange
                  ? autoChangeExpertise({ kodOdbornosti: kodOdbNz, kodTypZS })
                  : zmenOdbornost({ value: kodOdbNz, kodTypZS, userAction }),
              );
            }
          }}
        />
      </div>
    ) : (
      odbornostPublic.length > 0 &&
      !hideDropdownExpertises() && (
        <div className={classes.odbornosti}>
          <Input
            addonsInside
            className="unselectable"
            crossOrigin
            isReadonly
            label=""
            value={odbornostPublic[0].kodOdbAmbNazov}
          />
        </div>
      )
    );
  return renderOdbornosti || <span />;
};

export default ExpertisesCombo;
