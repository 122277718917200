import { ReactNode } from 'react';
import {
  Parameter,
  SkupinyParametrov,
  TypCeny,
  VysledkyObdobia,
  VysledokObdobia,
} from '../../slices/hodnotiaceParametre.slice';
import BaseChart from './assets/svg/BaseChart';
import VASChart from './assets/svg/VASChart';
import { Text, View } from '@react-pdf/renderer';
import {
  calculateChartValue,
  calculateHranica1Hodn,
  calculateHranica2Hodn,
  calculatePointer1,
  calculatePointer2,
  formatHodnota,
  formatPlnenie,
} from '../../utils/hodnotiaceParametre.utils';
import strings from '../../constants/strings';
import WarningIcon from './assets/svg/WarningIcon';
import ErrorIcon from './assets/svg/ErrorIcon';
import SuccessIcon from './assets/svg/SuccessIcon';
import { inlineStrCondition } from '../../utils/app.utils';

/** HPPdfTemplate */
export const getHPChart = ({
  isVAS,
  skupiny,
  vysledokObdobia,
}: {
  isVAS: boolean;
  skupiny?: SkupinyParametrov;
  vysledokObdobia: VysledokObdobia[];
}): ReactNode => {
  if (isVAS)
    return (
      <VASChart
        effectivity={parseInt(
          ((skupiny?.Efektívnosť?.plnenie || 0) * 100).toString(),
          10,
        )}
        innovations={parseInt(
          ((skupiny?.Inovácie?.plnenie || 0) * 100).toString(),
          10,
        )}
        percentage={
          vysledokObdobia[0] ? Math.round(vysledokObdobia[0].cena * 100) : 0
        }
        quality={parseInt(
          ((skupiny?.Kvalita?.plnenie || 0) * 100).toString(),
          10,
        )}
      />
    );
  return (
    <BaseChart
      effectivity={(skupiny?.Efektívnosť?.plnenie || 0) * 100}
      innovations={(skupiny?.Inovácie?.plnenie || 0) * 100}
      percentage={
        vysledokObdobia[0] ? Math.round(vysledokObdobia[0].cena * 100) : 0
      }
      quality={(skupiny?.Kvalita?.plnenie || 0) * 100}
      skupiny={skupiny}
    />
  );
};

const uhradaNavyse = ({
  styles,
  vypocetObdobia,
}: {
  styles: any;
  vypocetObdobia: any;
}): ReactNode => {
  if (vypocetObdobia?.length > 3)
    return (
      <View style={{ maxWidth: '80%' }}>
        <Text style={styles.hr} />
        <Text style={styles.h4}>Vaša úhrada navyše</Text>
        <Text style={styles.colorGrey}>
          {strings.dashboard.hodnotenie.predchadzajuceObdobie.zisk.tooltipVyk}
        </Text>
        <View>
          <Text style={styles.subtitle}>
            {vypocetObdobia
              ?.filter(
                (v) => v?.hodnota.includes('€') && v?.hodnota.includes('+'),
              )?.[0]
              ?.hodnota.replace('+', '+ ')}
          </Text>
        </View>
      </View>
    );
  return (
    <>
      <Text style={styles.hr} />
      <Text style={styles.h4}>Vaša úhrada navyše</Text>
      <Text style={styles.colorGrey}>
        {
          // eslint-disable-next-line
          vypocetObdobia[0]?.popis?.props?.dangerouslySetInnerHTML?.__html ===
          'Dodatková kapitácia'
            ? strings.dashboard.hodnotenie.predchadzajuceObdobie.zisk
                .tooltipDodKap
            : strings.dashboard.hodnotenie.predchadzajuceObdobie.zisk.tooltip
        }
      </Text>
      <View>
        <Text style={styles.subtitle}>
          {
            vypocetObdobia?.filter((v) => v?.hodnota.includes('€'))?.[1]
              ?.hodnota
          }
        </Text>
      </View>
    </>
  );
};

export const getHPBaseContent = ({
  keys,
  styles,
  vypocetObdobia,
  vysledkyObdobia,
}: {
  keys: TypCeny[];
  styles: any;
  vypocetObdobia: any;
  vysledkyObdobia: VysledkyObdobia;
}): ReactNode => {
  if (vypocetObdobia?.length > 3 && vypocetObdobia?.length < 6)
    return (
      <View style={styles.flexRow}>
        {vypocetObdobia.map(
          (v, key) =>
            !v?.hodnota.includes('€') &&
            !v?.hodnota.includes('N/A') && (
              <View key={`vypocetObdobie--${key}`} style={[styles.flexCol]}>
                <View style={styles.flexRow}>
                  <View style={[styles.flexCol40, { width: '30%' }]}>
                    <View>
                      <Text style={[styles.h4, { marginBottom: 5 }]}>
                        {
                          // eslint-disable-next-line no-underscore-dangle
                          v?.popis?.props?.dangerouslySetInnerHTML?.__html
                        }
                      </Text>
                    </View>
                    <View>
                      <Text
                        style={[
                          styles.subtitle,
                          vypocetObdobia.length / 2 > key
                            ? {
                                marginBottom: 15,
                              }
                            : { marginBottom: 0 },
                        ]}
                      >
                        {`${v?.hodnota} €`}
                      </Text>
                    </View>
                  </View>
                  <View
                    style={[
                      styles.flexCol60,
                      { width: '70%', paddingTop: 15, paddingRight: 10 },
                    ]}
                  >
                    <Text style={styles.colorGrey}>
                      Základná cena:{' '}
                      {formatHodnota(
                        vysledkyObdobia?.[`${keys[key]}D`]?.[0]?.cena,
                        undefined,
                        'E',
                      )}
                    </Text>
                    <Text style={styles.colorGrey}>
                      Zvýhodnená cena:{' '}
                      {formatHodnota(
                        vysledkyObdobia?.[`${keys[key]}V`]?.[0]?.cena,
                        undefined,
                        'E',
                      )}
                    </Text>
                  </View>
                </View>
              </View>
            ),
        )}
        {uhradaNavyse({ styles, vypocetObdobia })}
      </View>
    );
  // Vykony
  if (vypocetObdobia?.length >= 6)
    return (
      <View style={styles.flexRow}>
        {vypocetObdobia
          .filter((o, key) => key < 4)
          .map((o, key) => (
            <View style={{ maxWidth: '80%' }}>
              <Text style={styles[key > 0 ? 'h4mt' : 'h4']}>
                {
                  // eslint-disable-next-line
                  o?.popis?.props?.dangerouslySetInnerHTML?.__html
                }
              </Text>

              <Text style={styles.colorGrey}>
                {
                  // eslint-disable-next-line
                  o?.popis?.props?.dangerouslySetInnerHTML?.__html
                }{' '}
                bola vypočítaná na základe Vášho plnenia hodnotiacich
                parametrov.
              </Text>
              <View>
                <Text style={styles.subtitle}>{o?.hodnota}</Text>
              </View>
            </View>
          ))}
        {uhradaNavyse({ styles, vypocetObdobia })}
      </View>
    );
  return (
    <View style={styles.leftContent}>
      <View>
        <>
          <View>
            <Text style={styles.h4}>
              {vypocetObdobia &&
                // eslint-disable-next-line no-underscore-dangle
                vypocetObdobia[0]?.popis?.props?.dangerouslySetInnerHTML
                  ?.__html}
            </Text>
            {
              // eslint-disable-next-line no-underscore-dangle
              vypocetObdobia[0]?.popis?.props?.dangerouslySetInnerHTML
                ?.__html === 'Dodatková kapitácia' ? (
                <Text style={styles.colorGrey}>
                  Cenu dodatkovej kapitácie sme vypočítali na základe Vášho
                  plnenia hodnotiacich parametrov.
                </Text>
              ) : (
                <Text style={styles.colorGrey}>
                  Cenu ambulantného bodu sme vypočítali na základe Vášho plnenia
                  hodnotiacich parametrov.
                </Text>
              )
            }
          </View>
          <View>
            <Text style={styles.subtitle}>
              {vypocetObdobia && vypocetObdobia[0]?.hodnota}
            </Text>
          </View>
        </>
      </View>
      {uhradaNavyse({ styles, vypocetObdobia })}
    </View>
  );
};

export const getIcon = ({
  pasmo,
  plnenie,
}: {
  pasmo: string;
  plnenie: number;
}): ReactNode => {
  if (plnenie > 0 && plnenie < 1) return <WarningIcon />;
  return formatPlnenie(plnenie, pasmo) ? <SuccessIcon /> : <ErrorIcon />;
};

export const getTextStyles = ({
  pasmo,
  plnenie,
  styles,
}: {
  pasmo: string;
  plnenie: number;
  styles: any;
}): any => {
  if (plnenie > 0 && plnenie < 1) return styles.orange;
  return formatPlnenie(plnenie, pasmo) ? styles.green : styles.red;
};

export const getFillStatus = ({
  pasmo,
  plnenie,
}: {
  pasmo: string;
  plnenie: number;
}): string => {
  if (plnenie > 0 && plnenie < 1) return 'Čiastočne splnené';
  return formatPlnenie(plnenie, pasmo) ? 'Splnené' : 'Nesplnené';
};

export const getQualityGraphData = ({
  parameter,
}: {
  parameter: Parameter;
}): {
  hodnota: number;
  hranica1Hodnota: number;
  hranica1Text: string;
  hranica2Hodnota?: number;
  hranica2Text?: string;
  kodParametra: string | null;
  plnenie: number;
  typHodnoty: string;
  vasaHodnota: string;
}[] => {
  if (parameter.kodParametra !== 'dostup-gyn')
    return [
      {
        hranica1Hodnota: calculateHranica1Hodn(parameter),
        hranica1Text: calculatePointer1(parameter, true),
        hranica2Hodnota: calculateHranica2Hodn(parameter),
        hranica2Text: calculatePointer2(parameter, true),
        vasaHodnota:
          parameter.kodParametra === 'subj-spok'
            ? '100 %'
            : inlineStrCondition(
                !!(parameter.plnenie && parameter.typHodnoty === 'P'),
                '100 %',
                formatHodnota(
                  parameter.hodnota,
                  parameter.plnenie,
                  parameter.typHodnoty,
                ),
              ),
        hodnota: calculateChartValue(parameter, parameter.hodnota || 0),
        kodParametra: parameter.kodParametra,
        plnenie: parameter.plnenie,
        typHodnoty: parameter.typHodnoty,
      },
    ];
  return [
    {
      hranica1Hodnota: 50,
      hranica1Text: calculatePointer1(
        parameter,
        true,
        parameter.hranicnaHodnotaMax,
      ),
      vasaHodnota: formatHodnota(
        parameter.hodnotaCitatel,
        parameter.plnenie,
        parameter.typHodnoty,
      ).toString(),
      hodnota: (parameter.hodnotaCitatel / parameter.hranicnaHodnotaMax) * 50,
      kodParametra: parameter.kodParametra,
      plnenie: parameter.plnenie,
      typHodnoty: parameter.typHodnoty,
    },
    {
      hranica1Hodnota: 50,
      hranica1Text: calculatePointer1(
        parameter,
        true,
        parameter.hranicnaHodnotaMin,
      ),
      vasaHodnota: formatHodnota(
        parameter.hodnotaMenovatel,
        parameter.plnenie,
        parameter.typHodnoty,
      ).toString(),
      hodnota: (parameter.hodnotaMenovatel / parameter.hranicnaHodnotaMin) * 50,
      kodParametra: parameter.kodParametra,
      plnenie: parameter.plnenie,
      typHodnoty: parameter.typHodnoty,
    },
  ];
};

export const getStylesCatValue = ({
  plnenie,
  styles,
}: {
  plnenie?: number;
  styles: any;
}): any => {
  if (plnenie && plnenie > 0 && plnenie < 1) return styles.warning;
  if (plnenie) return styles.success;
  return styles.error;
};

export const getCatStandardColor = (
  plnenie?: number,
): 'primary' | 'warning' | 'error' => {
  if (!plnenie) return 'error';
  if (plnenie > 0 && plnenie < 1) return 'warning';
  return 'primary';
};
