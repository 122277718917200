import { createUseStyles } from 'react-jss';

import { spaces, zIndex } from '../../constants';

const boxShadow =
  '0 3.4px 5px rgba(0, 0, 0, 0.032), 0 11.4px 16.8px rgba(0, 0, 0, 0.048), 0 51px 75px rgba(0, 0, 0, 0.08)';
const pointerEvents = (isVisible?: boolean) => (isVisible ? 'all' : 'none');

interface GraphPopup {
  isVisible?: boolean;
  x: number;
  y: number;
}

export default createUseStyles({
  graphPopup: ({ isVisible, x, y }: GraphPopup) => ({
    position: 'absolute',
    top: y,
    left: x,
    width: '15rem',
    padding: `${spaces.small}px`,
    opacity: isVisible ? 1 : 0,
    pointerEvents: pointerEvents(isVisible),
    boxShadow,
    transition: 'opacity 0.20s ease-out',
    transform: `translate(-50%, -40%)`,
    zIndex: zIndex.topbar - 1,
    '&:hover': {
      boxShadow,
    },
    '& a': {
      pointerEvents: `${pointerEvents(isVisible)} !important`,
    },
  }),
});
