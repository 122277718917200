import { createUseStyles } from 'react-jss';

import { borderRadius, color, spaces, zIndex } from '../../constants';

import { getColor } from '../../utils/hodnotiaceParametre.utils';

const boxShadow =
  '0 3.4px 5px rgba(0, 0, 0, 0.032), 0 11.4px 16.8px rgba(0, 0, 0, 0.048), 0 51px 75px rgba(0, 0, 0, 0.08)';
const pointerEvents = (isVisible?: boolean) => (isVisible ? 'all' : 'none');

interface StylesProps {
  isVisible?: boolean;
  poradie: number;
  x: number;
  y: number;
}

export default createUseStyles({
  graphTooltip: ({ isVisible, x, y }: StylesProps) => ({
    position: 'absolute',
    top: y === 0 ? '50%' : y,
    left: x === 0 ? '50%' : x,
    width: '18rem',
    opacity: isVisible ? 1 : 0,
    pointerEvents: pointerEvents(isVisible),
    transition: 'all 0.20s ease-out',
    transform: `translate(0, -50%)`,
    borderRadius: `${borderRadius}px`,
    boxShadow,
    zIndex: zIndex.topbar - 1,
    '&:hover': {
      boxShadow,
    },
    '& a': {
      pointerEvents: `${pointerEvents(isVisible)} !important`,
    },
    '&:before': {
      content: '""',
      position: 'absolute',
      left: '-1rem',
      top: '50%',
      transform: 'translateY(-50%)',
      width: 0,
      height: 0,
      borderTop: '0.75rem solid transparent',
      borderBottom: '0.75rem solid transparent',
      borderRight: `1rem solid ${color('white')}`,
      zIndex: zIndex.topbar - 1,
    },
  }),
  card: {
    padding: `${spaces.xsmall}px`,
    marginBottom: 0,
    '&:hover': {
      boxShadow: 'none',
    },
    '& > a': {
      borderRadius: `${borderRadius}px`,
    },
    '& > div:first-child, & > div:nth-child(2)': {
      marginLeft: spaces.xxsmall,
    },
    '& .bar__item': {
      wordWrap: 'normal !important',
      wordBreak: 'normal !important',
    },
  },
  plnenie: ({ poradie }: StylesProps) => ({
    color: getColor(poradie),
  }),
});
