interface Props {
  color?: string;
  height?: number;
  id: string;
  width?: number;
}

const IconExchange = ({ color, height, id, width }: Props) => (
  <svg
    fill="none"
    height={height || 16}
    id={id}
    viewBox={`0 0 ${width} ${height}`}
    width={width || 16}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14 11.5L11.5 14M11.5 14L9 11.5M11.5 14L11.5 2"
      stroke={color || '#2B2B2B'}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />
    <path
      d="M2 4.5L4.5 2M4.5 2L7 4.5M4.5 2L4.5 14"
      stroke={color || '#2B2B2B'}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />
  </svg>
);

export default IconExchange;
