/* eslint-disable */
import { validation } from '../constants/validation';
import strings from '../constants/strings';

export const getPasswordStrengthNumber = (password, debug = 0) => {
  let sum = 0;
  if (!password) return sum;
  if (/^(?=.*[\\.\\_\\-\\@])/.test(password)) {
    if (debug) console.log('special character');
    sum = +sum + 20;
  }
  if (/^(?=.{6,128})/.test(password)) {
    if (debug) console.log('length > 8');
    sum = +sum + 20;
  }
  if (/^(?=.*[0-9])/.test(password)) {
    if (debug) console.log('contain number');
    sum = +sum + 20;
  }
  if (/^(?=.*[a-z])/.test(password)) {
    if (debug) console.log('contain small character');
    sum = +sum + 20;
  }
  if (/^(?=.*[A-Z\u0161\u0111\u010D\u0107\u017E])/.test(password)) {
    if (debug) console.log('contain big character');
    sum = +sum + 20;
  }
  // if (
  //   /(?=^[0-9a-zA-Z\\.\\_\\-\\@]{6,128}$)(?=.*d)(?=.*[a-zA-Z]).*$./.test(
  //     password,
  //   )
  // ) {
  //   if (debug) console.log('contain DZP regex');
  //   sum = +sum + 20;
  // }
  return sum;
};

export const validationMsg = (code: string, someParam?: string): string => {
  if (code && validation[code])
    return someParam ? validation[code](someParam) : validation[code];
  return strings.defaultError;
};
