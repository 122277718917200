import { Grid, GridCol } from '@dovera/design-system';
import { useSelector } from 'react-redux';
import { map } from 'lodash-es';

import { RootState } from '../../rootReducer';

import { SkupinaParametrov } from '../../slices/hodnotiaceParametre.slice';
import { getSlugSkupiny } from '../../utils/hodnotiaceParametre.utils';

import { CategoryCard } from '../../components';
import CategoryCardTitle from '../../components/CategoryCard/CategoryCardTitle';
import useStyles from './StaryDashboardCategoryCards.styles';
import { cardTitle, isGYN009 } from '../../utils/dashboard.utils';
import routes from '../../routes';
import { cx } from '../../utils/exports';

const StaryDashboardCategoryCards = () => {
  const {
    obdobia: { zoznamObdobi },
    poradie: { aktualneId },
  } = useSelector((state: RootState) => state.hodnotiaceParametre);
  const classes = useStyles({ isFuture: zoznamObdobi[aktualneId]?.buduci });
  const aktivnaOdbornost = useSelector(
    (state: RootState) =>
      state.poskytovatel.odbornosti.filter((d) => d.aktivna)[0],
  );

  return (
    <>
      <Grid
        className={cx(
          classes.hpCategoriesContainer,
          'hide-before show-xl align-items-center',
        )}
      >
        {zoznamObdobi[aktualneId] &&
          map(
            zoznamObdobi[aktualneId].skupiny,
            (skupina: SkupinaParametrov) =>
              (skupina.nazov !== 'Špeciálne' ||
                isGYN009(
                  skupina.nazov,
                  aktivnaOdbornost?.kodOdbNz,
                  aktivnaOdbornost?.kodTypZS,
                )) && (
                <GridCol
                  key={`${skupina.nazov}-title`}
                  equalizeHeight
                  size={{ s: 12, m: 12, l: 12, xl: 4 }}
                >
                  <CategoryCardTitle
                    className={classes.hpCategories}
                    disabled={zoznamObdobi[aktualneId].buduci}
                    nazov={cardTitle(
                      skupina.nazov,
                      aktivnaOdbornost?.kodOdbNz,
                      aktivnaOdbornost?.kodTypZS,
                    )}
                    skupina={skupina}
                    special={isGYN009(
                      skupina.nazov,
                      aktivnaOdbornost?.kodOdbNz,
                      aktivnaOdbornost?.kodTypZS,
                    )}
                  />
                </GridCol>
              ),
          )}
      </Grid>
      <div className={cx(classes.hpCategoriesContainer, classes.customGrid)}>
        <Grid className="align-items-center">
          {zoznamObdobi[aktualneId] &&
            map(
              zoznamObdobi[aktualneId].skupiny,
              (skupina: SkupinaParametrov) =>
                (skupina.nazov !== 'Špeciálne' ||
                  isGYN009(
                    skupina.nazov,
                    aktivnaOdbornost?.kodOdbNz,
                    aktivnaOdbornost?.kodTypZS,
                  )) && (
                  <GridCol
                    key={skupina.nazov}
                    equalizeHeight
                    size={{ s: 12, m: 12, l: 12, xl: 4 }}
                  >
                    <CategoryCardTitle
                      className={cx(classes.hpCategories, 'hide-xl')}
                      disabled={zoznamObdobi[aktualneId].buduci}
                      id={`hp-category-card-title-${skupina.nazov}`}
                      nazov={cardTitle(
                        skupina.nazov,
                        aktivnaOdbornost?.kodOdbNz,
                        aktivnaOdbornost?.kodTypZS,
                      )}
                      skupina={skupina}
                      special={isGYN009(
                        skupina.nazov,
                        aktivnaOdbornost?.kodOdbNz,
                        aktivnaOdbornost?.kodTypZS,
                      )}
                      style={{ breakAfter: 'always' }}
                    />
                    <CategoryCard
                      href={`${routes.hp}/${getSlugSkupiny(skupina.nazov)}`}
                      isFuture={zoznamObdobi[aktualneId].buduci}
                      predbezny={zoznamObdobi[aktualneId].predbezny}
                      skupina={skupina}
                      special={{
                        GYN: isGYN009(
                          skupina.nazov,
                          aktivnaOdbornost?.kodOdbNz,
                          aktivnaOdbornost?.kodTypZS,
                        ),
                        vysledokObdobia:
                          zoznamObdobi[aktualneId].vysledokObdobia,
                        DodKapZakl:
                          zoznamObdobi[aktualneId].vysledokObdobia.DodKapZakl
                            ?.cena || 0,
                      }}
                    />
                  </GridCol>
                ),
            )}
        </Grid>
      </div>
    </>
  );
};

export default StaryDashboardCategoryCards;
