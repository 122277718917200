import React from 'react';

const IconLockClosed = ({ ...other }: React.JSX.IntrinsicElements['svg']) => (
  <svg
    fill="none"
    height="24"
    viewBox="0 0 24 24"
    width="24"
    xmlns="http://www.w3.org/2000/svg"
    {...other}
  >
    <path
      d="M4 13.7368C4 12.4579 5.03681 11.4211 6.31579 11.4211H17.8947C19.1737 11.4211 20.2105 12.4579 20.2105 13.7368V20.6842C20.2105 21.9632 19.1737 23 17.8947 23H6.31579C5.03681 23 4 21.9632 4 20.6842V13.7368Z"
      fill="currentColor"
    />
    <path
      clipRule="evenodd"
      d="M12.1053 3.31579C10.1868 3.31579 8.63158 4.87101 8.63158 6.78948V10.2632H6.31579V6.78948C6.31579 3.59204 8.90782 1 12.1053 1C15.3027 1 17.8947 3.59204 17.8947 6.78948V10.2632H15.5789V6.78948C15.5789 4.87101 14.0237 3.31579 12.1053 3.31579Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);

export default IconLockClosed;
