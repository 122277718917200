import React, { useCallback } from 'react';
import { Bar, BarItem } from '@dovera/design-system';

import useStyles from './CategoryCardRow.styles';

import {
  formatHodnota,
  formatPlnenie,
  getHranica,
  hpRoute,
  paramsForNewGraph,
} from '../../utils/hodnotiaceParametre.utils';

import { IconErrorInline, IconSuccess } from '..';
import { textWithValue } from '../../utils/strings.utils';
import { IconDashedRing } from '../CustomIcons';
import { SubjektivnaSpokojnostTooltip } from '../SubjektivnaSpokojnostTooltip';
import { SUBJEKTIVNA_SPOKOJNOST } from '../../constants';
import { Link } from 'react-router-dom';
import { Nullable } from '../../types';
import { cx } from '../../utils/exports';
import IconWarningCircleSmall from '../CustomIcons/IconWarningCircleSmall';
import IconStar from '../CustomIcons/IconStar';
import { inlineStrCondition } from '../../utils/app.utils';
import SafeHtml from '../SafeHtml/SafeHtml';

type Props = {
  hodnota: number | null;
  hranicnaHodnotaMax: number;
  hranicnaHodnotaMin: number;
  href?: string;
  isFuture?: boolean;
  kodParametra: string;
  modalContent?: boolean;
  nazovParametra: string;
  pasmo: string;
  pictogram?: 'star' | string | null;
  plnenie: boolean | number;
  popisParametra: Nullable<string>;
  predbezny: boolean;
  showModalContent?: (nazovParametra: string) => void;
  typHodnoty: string;
} & React.JSX.IntrinsicElements['div'];

const CategoryCardRow = ({
  hodnota,
  hranicnaHodnotaMax,
  hranicnaHodnotaMin,
  href,
  isFuture,
  kodParametra,
  modalContent,
  nazovParametra,
  pasmo,
  pictogram,
  plnenie,
  popisParametra,
  predbezny,
  showModalContent,
  typHodnoty,
}: Props) => {
  const classes = useStyles({
    isFuture,
    plnenie,
    predbezny,
    exchangeableParam: paramsForNewGraph.includes(kodParametra),
  });
  const clickRowCallback = useCallback(
    (e) => {
      if (modalContent && showModalContent) {
        e.preventDefault();
        showModalContent(nazovParametra);
      }
    },
    [modalContent, nazovParametra, showModalContent],
  );
  const hranica = getHranica(
    hranicnaHodnotaMax,
    hranicnaHodnotaMin,
    pasmo,
    kodParametra,
  );
  const getPictogram = () => {
    if (pictogram && pictogram === 'star')
      return <IconStar id={`icon-star-cardRow-${kodParametra}`} />;
    if (
      Number(plnenie) > 0 &&
      Number(plnenie) < 1 &&
      !paramsForNewGraph.includes(kodParametra)
    )
      return (
        <IconWarningCircleSmall
          className="icon--pos--category"
          id={`icon-warning-cardRow-${kodParametra}`}
        />
      );
    if (
      formatPlnenie(plnenie, pasmo) ||
      (Number(plnenie) > 0 &&
        Number(plnenie) < 1 &&
        paramsForNewGraph.includes(kodParametra))
    )
      return (
        <IconSuccess
          className="icon--pos--category"
          height={16}
          id={`icon-success-cardRow-${kodParametra}`}
          width={16}
        />
      );
    if (predbezny || isFuture)
      return (
        <IconDashedRing
          className="icon--pos--category"
          id={`icon-dashedRing-cardRow-${kodParametra}`}
        />
      );
    return (
      <IconErrorInline
        className="icon--pos--category"
        id={`icon-error-cardRow-${kodParametra}`}
      />
    );
  };
  return (
    <div className={classes.row} id={`category-cardRow-${kodParametra}`}>
      <Link
        onClick={clickRowCallback}
        role="button"
        to={inlineStrCondition(
          !!(!modalContent && href),
          hpRoute(`${href}/${kodParametra}`),
          href ? `${href}` : '#',
        )}
      >
        <Bar align="top" className="text-color-grey mb-xxxsmall" space="small">
          <BarItem className={classes.icon}>{getPictogram()}</BarItem>
          <BarItem className={classes.nameBarItem} isFilling>
            <span className={cx(classes.hodnota)}>{nazovParametra}</span>
          </BarItem>
          {!isFuture && (
            <BarItem className={classes.valueBarItem}>
              <span className={classes.hodnota}>
                {kodParametra === SUBJEKTIVNA_SPOKOJNOST ? (
                  '100 %'
                ) : (
                  <SafeHtml
                    html={formatHodnota(
                      hodnota,
                      Number(plnenie),
                      typHodnoty,
                      false,
                      true,
                    )}
                  />
                )}
              </span>
            </BarItem>
          )}
        </Bar>
        <p className="text-small no-mrg-bottom text-color-grey">
          {popisParametra &&
            textWithValue(
              popisParametra,
              formatHodnota(
                hranicnaHodnotaMax || hranica,
                Number(plnenie),
                typHodnoty === null &&
                  isFuture &&
                  (hranicnaHodnotaMax || hranica) < 1
                  ? 'P'
                  : typHodnoty,
                true,
              ),
              formatHodnota(
                hranicnaHodnotaMin,
                Number(plnenie),
                typHodnoty,
                true,
              ),
            )}{' '}
          {kodParametra === SUBJEKTIVNA_SPOKOJNOST && (
            <SubjektivnaSpokojnostTooltip />
          )}
        </p>
      </Link>
    </div>
  );
};

export default CategoryCardRow;
