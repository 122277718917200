import { getNestedPropValue } from './object.utils';

export const swapPositions = (array, a, b) => {
  [array[a], array[b]] = [array[b], array[a]];
};

/**
 * Metoda pre zoradenie objektov pola podla specifickeho kluca json objektu
 * @date 20. 2. 2023 - 9:18:03
 *
 * @export
 * @template T
 * @param {T[]} array
 * @param {?string} [sortBy]
 * @param {?(boolean | 'desc')} [alhpabetically]
 * @param {?boolean} [sortByDateDesc]
 * @param {?boolean} [sum]
 * @returns {*}
 */
export function arraySort<T>(
  array: T[],
  sortBy?: string,
  alhpabetically?: boolean | 'asc' | 'desc',
  sortByDateDesc?: boolean,
  sum?: boolean,
) {
  if (!array) return [];
  if (!sortBy) {
    // @ts-ignore
    return [...array].sort((a, b) => a - b);
  }
  if (sortBy && sum) {
    return array
      .map((a) => ({ ...a, [sortBy]: Number(a[sortBy]) }))
      .sort((a, b) => {
        if (alhpabetically)
          return (
            Number(getNestedPropValue(a, sortBy)) -
            Number(getNestedPropValue(b, sortBy))
          );
        return (
          Number(getNestedPropValue(b, sortBy)) -
          Number(getNestedPropValue(a, sortBy))
        );
      });
  }
  if (sortBy && sortByDateDesc) {
    return array.sort(
      (a, b) =>
        Date.parse(getNestedPropValue(alhpabetically ? a : b, sortBy)) -
        Date.parse(getNestedPropValue(alhpabetically ? b : a, sortBy)),
    );
  }
  if (alhpabetically && alhpabetically !== 'desc' && sortBy) {
    return array.sort((a, b) => {
      if (getNestedPropValue(a, sortBy) < getNestedPropValue(b, sortBy)) {
        return -1;
      }
      if (getNestedPropValue(a, sortBy) > getNestedPropValue(b, sortBy)) {
        return 1;
      }
      return 0;
    });
  }
  if (alhpabetically === 'desc' && sortBy) {
    return array.sort((a, b) => {
      if (getNestedPropValue(b, sortBy) < getNestedPropValue(a, sortBy)) {
        return -1;
      }
      if (getNestedPropValue(b, sortBy) > getNestedPropValue(a, sortBy)) {
        return 1;
      }
      return 0;
    });
  }
  return array
    .filter((a) => !a[sortBy])
    .concat(
      array.filter((a) => a[sortBy]).sort((a, b) => a[sortBy] - b[sortBy]),
    );
}

export function toHashMap<T>(
  array: T[],
  key: string,
  objToArr: boolean = false,
) {
  if (!array || !array.length || !key) {
    return {};
  }

  const result = {};

  array.forEach((obj) => {
    if (obj[key]) {
      result[obj[key]] = objToArr ? [obj] : obj;
    }
  });

  return result;
}

/**
 *  Converts array with multiple values into a single array with all items:
 */
export const mergeMultidimensionalArray = (arrays: any[]): any =>
  /* eslint-disable-next-line */
  [].concat.apply([], arrays);

export function uniqueArray<T>(
  arr: T[],
  ignoreProps?: string[],
  encoder = JSON.stringify,
  decoder = JSON.parse,
): T[] {
  let arrRes: any[] = arr;
  if (ignoreProps)
    ignoreProps.forEach((p) => {
      arrRes = arr.map((a) => ({
        ...a,
        [`${p}`]: undefined,
      }));
    });
  return [...new Set(arrRes?.map((item) => encoder(item)))]?.map((item) =>
    decoder(item),
  );
}

export function groupByItem<T>(array: T[] | null, key: string): T[] {
  if (!array || !array.length) return [];
  const resp: T[] = [];
  array.forEach((item) => {
    if (!resp.some((r) => r[key] === item[key])) resp.push(item);
  });
  return resp;
}
