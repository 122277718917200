import { createRef, useCallback, useEffect, useState } from 'react';
import {
  Checkbox,
  CheckboxGroup,
  Icon,
  Input,
  Textarea,
} from '@dovera/design-system';
import useStyles from './MultiSelect.styles';
import { Choice } from 'choices.js';
import useOutsideClick from '../../hooks/useOutsideClick';

export type Props = {
  defaultOption: string;
  id: string;
  initialValue?: any;
  maxChoose?: number;
  onSelected: (values: string) => void;
  options: Choice[];
  width?: number;
} & Omit<Input, 'value'>;

const MultiSelect = ({
  defaultOption,
  help,
  id,
  initialValue,
  label,
  maxChoose,
  onSelected,
  options,
  width,
  ...other
}: Props) => {
  const inputRef = createRef<HTMLInputElement>();
  const wrapperRef = createRef<HTMLDivElement>();
  const [visibleOptions, setVisibleOptions] = useState(false);
  const [optionsTop, setOptionsTop] = useState(false);
  const [selected, setSelected] = useState<
    { label: string; value: string }[] | null
  >(null);
  const classes = useStyles({ optionsTop, width });
  const showOptions = useCallback(() => {
    const pageSize = window.innerHeight - 71;
    const elementPosition = wrapperRef.current?.getBoundingClientRect()?.y || 0;
    if (pageSize && !visibleOptions && pageSize - elementPosition < 280) {
      setOptionsTop(true);
    } else {
      setOptionsTop(false);
    }
    setVisibleOptions(true);
  }, [visibleOptions, wrapperRef]);
  useOutsideClick(wrapperRef, (e) => {
    const element = e.target as HTMLElement;
    if (!element.classList.toString().includes('radiocheck') && visibleOptions)
      setVisibleOptions(false);
  });
  useEffect(() => {
    if (initialValue)
      setSelected(
        initialValue
          .split(';')
          .map((s) => options.find((o) => o.value === s))
          .filter((o) => o) as { label: string; value: string }[],
      );
  }, [initialValue, options]);
  useEffect(() => {
    onSelected(selected?.map((s) => s.value).join(';') || '');
    // eslint-disable-next-line
  }, [selected]);
  useEffect(() => {
    /** Effect for dynamically resize textarea field */
    const value: string = selected?.map((s) => s.label).join(', ') || '';
    const element = document.getElementById(id);
    if (element) {
      if (value.length >= 21) {
        if (parseInt(element.style.height, 10) > 75)
          element.style.height = 'auto';
        element.style.height = element?.scrollHeight
          ? `${element?.scrollHeight}px`
          : 'auto';
      } else {
        element.style.height = '48px';
      }
    }
  }, [id, selected]);
  const renderOptions = visibleOptions &&
    (!maxChoose || (selected && selected.length < maxChoose) || !selected) && (
      <div className={classes.optionsWrapper}>
        <div className={classes.options}>
          {options.length > 0 && (
            <CheckboxGroup>
              {options.map((o, key) => (
                <Checkbox
                  key={`${id}-option--${o.value}--${key}`}
                  data-checked={selected?.map((s) => s.value).includes(o.value)}
                  id={`${id}-option--${o.value}--${key}`}
                  isChecked={selected?.map((s) => s.value).includes(o.value)}
                  onChange={() =>
                    setSelected(
                      selected?.map((s) => s.value).includes(o.value)
                        ? selected?.filter((s) => s.value !== o.value)
                        : [
                            ...(selected || []),
                            {
                              label: o.label,
                              value: o.value,
                            },
                          ],
                    )
                  }
                  value={o.value}
                >
                  {o.label}
                </Checkbox>
              ))}
            </CheckboxGroup>
          )}
        </div>
      </div>
    );
  return (
    <div ref={wrapperRef} className={classes.multiSelectWrapper}>
      <Textarea
        {...other}
        // @ts-ignore
        ref={inputRef}
        addonsInside
        aria-autocomplete="none"
        autoComplete="off"
        help={help}
        id={id}
        isDisabled={!!(maxChoose && maxChoose === selected?.length)}
        label={label}
        onClick={() =>
          !visibleOptions ? showOptions() : setVisibleOptions(false)
        }
        readOnly
        rightAddons={
          <button
            className={classes.chevronBtn}
            onClick={() =>
              !visibleOptions ? showOptions() : setVisibleOptions(false)
            }
            type="button"
          >
            <Icon name={`16-chevron-${visibleOptions ? 'up' : 'down'}`} />
          </button>
        }
        value={selected?.map((s) => s.label).join(', ') || defaultOption}
      />
      {renderOptions}
    </div>
  );
};

export default MultiSelect;
