import { breakpoints, getRem } from '@dovera/design-system';
import { createUseStyles } from 'react-jss';

export default createUseStyles({
  btn: {
    position: 'absolute',
    right: 0,
    top: getRem(24),
    paddingTop: 0,
    paddingRight: 0,
    margin: 0,
    display: 'flex',
    alignItems: 'center',
    '& svg': {
      marginLeft: getRem(12),
    },
    '& .loader': {
      marginLeft: getRem(20),
      marginRight: 0,
    },
    '&.btn--disabled': {
      color: '#a7a9ac !important',
    },
    '&:not(.btn--link)': {
      position: 'relative',
      top: 0,
      padding: '0.6875rem 1.4375rem',
    },
    '&:focus': {
      '&.btn--disabled': {
        color: '#a7a9ac !important',
      },
    },
    '@media (max-width: 1200px)': {
      top: getRem(-40),
    },
    [`@media screen and (max-width: ${breakpoints.m}px)`]: {
      top: '0 !important',
      position: 'relative',
      padding: 0,
    },
  },
  btnIE: {
    position: 'relative',
    marginTop: getRem(5),
    '& span': {
      marginTop: getRem(5),
    },
    '& svg': {
      marginLeft: getRem(10),
      verticalAlign: 'middle',
      marginTop: getRem(-2),
    },
  },
  iconPositionLeft: {
    '& svg': {
      marginRight: getRem(12),
      marginLeft: 0,
    },
    '& .loader': {
      marginLeft: 0,
      marginRight: getRem(18),
    },
  },
});
