interface Props {
  color?: string;
  height?: number;
  id?: string;
  width?: number;
}

const RadialBarDashed = ({ color, height, id, width }: Props) => (
  <svg
    fill="none"
    height={height || 305}
    id={id || 'radial-bar-dashed'}
    viewBox={`0 0 ${width || 305} ${height || 305}`}
    width={width || 305}
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask fill="white" id="path-1-inside-1_13425_287019">
      <path d="M151.701 76.3165C193.788 76.3165 227.907 110.485 227.907 152.633C227.907 194.782 193.788 228.95 151.701 228.95C109.614 228.95 75.4961 194.782 75.4961 152.633C75.4961 110.485 109.614 76.3165 151.701 76.3165ZM151.701 187.891C171.146 187.891 186.908 172.106 186.908 152.633C186.908 133.16 171.146 117.375 151.701 117.375C132.257 117.375 116.494 133.16 116.494 152.633C116.494 172.106 132.257 187.891 151.701 187.891Z" />
    </mask>
    <path
      d="M151.701 76.3165C193.788 76.3165 227.907 110.485 227.907 152.633C227.907 194.782 193.788 228.95 151.701 228.95C109.614 228.95 75.4961 194.782 75.4961 152.633C75.4961 110.485 109.614 76.3165 151.701 76.3165ZM151.701 187.891C171.146 187.891 186.908 172.106 186.908 152.633C186.908 133.16 171.146 117.375 151.701 117.375C132.257 117.375 116.494 133.16 116.494 152.633C116.494 172.106 132.257 187.891 151.701 187.891Z"
      mask="url(#path-1-inside-1_13425_287019)"
      stroke={color || '#6A7070'}
      strokeDasharray="5 5"
      strokeWidth="2"
    />
    <mask fill="white" id="path-2-inside-2_13425_287019">
      <path d="M152.723 8.3856e-05C236.823 7.96053e-05 305 68.2765 305 152.5C305 236.723 236.823 304.999 152.723 305C68.6222 305 0.44531 236.723 0.445307 152.5C0.445304 68.2765 68.6222 8.81068e-05 152.723 8.3856e-05ZM152.723 267.89C216.358 267.89 267.944 216.228 267.944 152.5C267.944 88.7716 216.358 37.1097 152.723 37.1097C89.0874 37.1097 37.5008 88.7716 37.5008 152.5C37.5008 216.228 89.0874 267.89 152.723 267.89Z" />
    </mask>
    <path
      d="M152.723 8.3856e-05C236.823 7.96053e-05 305 68.2765 305 152.5C305 236.723 236.823 304.999 152.723 305C68.6222 305 0.44531 236.723 0.445307 152.5C0.445304 68.2765 68.6222 8.81068e-05 152.723 8.3856e-05ZM152.723 267.89C216.358 267.89 267.944 216.228 267.944 152.5C267.944 88.7716 216.358 37.1097 152.723 37.1097C89.0874 37.1097 37.5008 88.7716 37.5008 152.5C37.5008 216.228 89.0874 267.89 152.723 267.89Z"
      mask="url(#path-2-inside-2_13425_287019)"
      stroke={color || '#6A7070'}
      strokeDasharray="5 5"
      strokeWidth="2"
    />
  </svg>
);

export default RadialBarDashed;
