// @ts-nocheck
import { useEffect, useRef } from 'react';
import {
  Card,
  CardSection,
  Container,
  Link as DDSLink,
  Grid,
  GridCol,
  PageSection,
} from '@dovera/design-system';
import StaticMenuLayout from '../../layouts/StaticMenuLayout';
import useStyles from './Dashboard.styles';
import { useSelector } from 'react-redux';
import { RootState } from '../../rootReducer';
import {
  fetchAktualityNovinky,
  fetchDataStatutara,
} from '../../slices/poskytovatel.slice';
import routes from '../../routes';
import Illustration from '../../assets/illustrations/contracts/dashboard.png';
import { cx } from '../../utils/exports';
import IconWarningCircle from '../../components/CustomIcons/IconWarningCircle';
import IconArrowRight from '../../components/CustomIcons/IconArrowRight';
import { Link } from 'react-router-dom';
import { fetchZoznamKampaniPreWidget } from '../../slices/zmluvy.slice';
import { IconSuccess, Skeleton } from '../../components';
import IconBlueInfo from '../../components/CustomIcons/IconBlueInfo';
import IconErrorInfo from '../../components/CustomIcons/IconErrorInfo';
import { epSekcie } from '../../constants/epLinks';
import { EP_SEKCIE } from '../../types';
import IconArrowRightLong from '../../components/CustomIcons/IconArrowRightLong';
import NaRiesenieWidget from './NaRiesenieWidget/NaRiesenieWidget';
import { showModal, userHasSection } from '../../utils/app.utils';
import DoveraNews from './DoveraNews/DoveraNews';
import strings from '../../constants/strings';
import { getSalutation, hotjarMasking } from '../../utils/strings.utils';
import { sendDataLayer } from '../../utils/gtm.utils';
import { saveAuthRejection } from '../../api/poskytovatel';
import { useAppDispatch } from '../../hooks/useStore';
import DashboardBanner from '../../components/DashboardBanner/DashboardBanner';
import AuthBanner from '../auth-pzs/components/DashboardBanner/AuthBanner';
import { fetchBanners, hideBanner } from '../../api/baner';
import { FetchBannersPayload } from '../../types/baner.types';
import HPBanner from '../hp-dashboard/HPBanner/HPBanner';
import WidgetCenyHP from './WidgetCenyHP/WidgetCenyHP';
import DashboardNtf from './DashboardNtf/DashboardNtf';
import {
  hasSection,
  hideEpDashboardLinks,
} from '../../utils/poskytovatel.utils';
import { EPSections } from '../../constants/systemConstants';
import EPNotifications from '../../components/EPNotifications/EPNotifications';
import ReviewCarousel from './ReviewCarousel/ReviewCarousel';
// @ts-check

const texts = strings.dashboard;

const Dashboard = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const { isASOC, pouzivatelId, sekciaZmluvy, vztahId } = useSelector(
    (state: RootState) => state.auth,
  );
  const bannerToShow = useSelector(
    (state: RootState) => state.banner.bannerToShow,
  );
  const bannerVersion = useSelector(
    (state: RootState) => state.pzsAuth.isOpen.bannerVersion,
  );
  const authModalVisible = useSelector(
    (state: RootState) => state.pzsAuth.isOpen.modal,
  );
  const zoznamKampaniWidget = useSelector(
    (state: RootState) => state.zmluvy.zoznamKampaniWidget,
  );
  const {
    autentifikovaneOsoby,
    dashboard,
    ico,
    idHz,
    isLoaded,
    preLekara,
    statutar,
  } = useSelector((state: RootState) => state.poskytovatel);
  const { aktuality, error, isLoading, novinky } = dashboard;
  const isLoadedDashboard = dashboard.isLoaded;
  const authModalRef = useRef(null);
  const udajLekara = preLekara ? autentifikovaneOsoby?.udajeLekarOsoba : null;
  useEffect(() => {
    if (!statutar.isLoading && !statutar.data && !statutar.error)
      dispatch(fetchDataStatutara(pouzivatelId));
  }, [dispatch, pouzivatelId, statutar]);

  useEffect(() => {
    /** Zavislost je zmena isLoading - ak sluzba spadne na err 500, zavola sa znovu. */
    if (
      vztahId &&
      isLoaded &&
      !isLoadedDashboard &&
      !aktuality?.length &&
      !novinky?.length &&
      !isLoading &&
      !error
    )
      dispatch(fetchAktualityNovinky(vztahId, pouzivatelId));
    // eslint-disable-next-line
  }, [
    dispatch,
    error,
    isLoadedDashboard,
    isLoaded,
    isLoading,
    pouzivatelId,
    vztahId,
  ]);

  useEffect(() => {
    /** Prevolanie sluzby DajBanery, ak nemam ziadne data v state */
    const payload: FetchBannersPayload = {
      ico,
      idLekarOsoba: udajLekara?.lekarOsobaId || null,
      idPouzivatel: pouzivatelId || 0,
      idVZ: vztahId || 0,
    };
    if (ico && !bannerToShow) dispatch(fetchBanners(payload));
  }, [
    bannerToShow,
    dispatch,
    ico,
    pouzivatelId,
    udajLekara?.lekarOsobaId,
    vztahId,
  ]);

  useEffect(() => {
    if (
      !zoznamKampaniWidget.isLoading &&
      !zoznamKampaniWidget.error &&
      !zoznamKampaniWidget.isLoaded &&
      (isASOC || sekciaZmluvy) &&
      idHz
    )
      dispatch(fetchZoznamKampaniPreWidget({ idHz }));
    // eslint-disable-next-line
  }, [idHz]);

  useEffect(() => {
    if (authModalVisible) setTimeout(() => showModal(authModalRef), 1000);
  }, [authModalVisible]);

  const renderOslovenie = (
    <h3 id="contracts-card-title">
      {udajLekara?.lekarOsobaId
        ? hotjarMasking(
            texts.customTitle(
              getSalutation(
                udajLekara.titulPred,
                udajLekara.meno,
                udajLekara.priezvisko,
                udajLekara.titulZa,
              ),
            ),
          )
        : texts.title}
    </h3>
  );

  const renderEPLinks = !hideEpDashboardLinks() && (
    <Card className={classes.sideCard} id="epLinks-card">
      <CardSection isCondensed>
        <h3 className="mb-small no-pad">Elektronická pobočka</h3>
        {userHasSection('FKT') && !hasSection(EPSections.Fkt, 'Zuctovanie') && (
          <DDSLink
            href={epSekcie[EP_SEKCIE.faktury]}
            rel="noopener noreferrer"
            target="_blank"
          >
            Faktúry <IconArrowRightLong id="profile-card-arrow-icon" />
          </DDSLink>
        )}
        {userHasSection('ZUC') && !hasSection(EPSections.Zuc, 'Zuctovanie') && (
          <DDSLink
            href={epSekcie[EP_SEKCIE.odoslanieDavok]}
            rel="noopener noreferrer"
            target="_blank"
          >
            Odoslanie dávok <IconArrowRightLong id="profile-card-arrow-icon" />
          </DDSLink>
        )}
        {userHasSection('MAST') && (
          <DDSLink
            href={epSekcie[EP_SEKCIE.spravaPristupov]}
            rel="noopener noreferrer"
            target="_blank"
          >
            Správa prístupov <IconArrowRightLong id="profile-card-arrow-icon" />
          </DDSLink>
        )}
        {userHasSection('ZUC') && !hasSection(EPSections.Zuc, 'Zuctovanie') && (
          <DDSLink
            href={epSekcie[EP_SEKCIE.dispenzarizovaniPoistenci]}
            rel="noopener noreferrer"
            target="_blank"
          >
            Dispenzačné záznamy{' '}
            <IconArrowRightLong id="profile-card-arrow-icon" />
          </DDSLink>
        )}
        {userHasSection('NAVZS') && (
          <DDSLink
            href={epSekcie[EP_SEKCIE.zoznamPodanychNavrhov]}
            rel="noopener noreferrer"
            target="_blank"
          >
            Návrhy <IconArrowRightLong id="profile-card-arrow-icon" />
          </DDSLink>
        )}
      </CardSection>
    </Card>
  );

  // ak PZS/asociacia ma nejake zmluvy na podpis, zobrazime kartu
  const renderZmluvyNaPodpis =
    zoznamKampaniWidget.stav && zoznamKampaniWidget.nadpis ? (
      <Grid className={cx('mb-large', classes.contracts)}>
        <GridCol size={12}>
          <Card id="contracts-card">
            <CardSection isCondensed>
              <Grid>
                <GridCol size={{ s: 12, l: 8 }}>
                  {zoznamKampaniWidget.isLoading ? (
                    <div>
                      <h1 className="text-left mb-small">
                        <Skeleton width="75%" />
                      </h1>
                      <p className="no-mrg-bottom">
                        <Skeleton width="45%" />
                      </p>
                      <p className="no-mrg-bottom">
                        <Skeleton width="35%" />
                      </p>
                    </div>
                  ) : (
                    <>
                      <div
                        className={cx(
                          classes.contactsInfo,
                          zoznamKampaniWidget.stav === 'I' && 'mb-small',
                        )}
                      >
                        {zoznamKampaniWidget.stav === 'W' && (
                          <IconWarningCircle id="contracts-card-icon" />
                        )}
                        {zoznamKampaniWidget.stav === 'I' && (
                          <IconBlueInfo
                            height={33}
                            id="contracts-card-icon"
                            width={33}
                          />
                        )}
                        {zoznamKampaniWidget.stav === 'E' && (
                          <IconErrorInfo id="contracts-card-icon" />
                        )}
                        <h6>{zoznamKampaniWidget.nadpis}</h6>
                      </div>
                      {zoznamKampaniWidget.popis && (
                        <p className="mb">{zoznamKampaniWidget.popis}</p>
                      )}
                      {zoznamKampaniWidget.linkTlacidla ? (
                        <a
                          href={zoznamKampaniWidget.linkTlacidla}
                          rel="noopener noreferrer"
                          target="_blank"
                        >
                          {zoznamKampaniWidget.textTlacidla}
                          <IconArrowRight id="contracts-link-dashboard" />
                        </a>
                      ) : (
                        <Link to={routes.zmluvyDodatky}>
                          {zoznamKampaniWidget.textTlacidla}
                          <IconArrowRight id="contracts-link-dashboard" />
                        </Link>
                      )}
                    </>
                  )}
                </GridCol>
                <GridCol
                  className="hide-m-only hide-s-only hide-xs-only"
                  size={{ s: 12, l: 4 }}
                >
                  <img alt="Illustration" src={Illustration} />
                </GridCol>
              </Grid>
            </CardSection>
          </Card>
        </GridCol>
      </Grid>
    ) : (
      isASOC && (
        <Grid className={cx('mb-large', classes.contracts)}>
          <GridCol size={12}>
            <Card id="contracts-card">
              <CardSection isCondensed>
                <Grid>
                  <GridCol size={{ s: 12, l: 8 }}>
                    {zoznamKampaniWidget.isLoading ? (
                      <div>
                        <h1 className="text-left mb-small">
                          <Skeleton width="75%" />
                        </h1>
                        <p className="no-mrg-bottom">
                          <Skeleton width="45%" />
                        </p>
                        <p className="no-mrg-bottom">
                          <Skeleton width="35%" />
                        </p>
                      </div>
                    ) : (
                      <>
                        <div className={cx(classes.contactsInfo, 'mb-small')}>
                          <IconSuccess
                            height={33}
                            id="contracts-card-icon"
                            width={33}
                          />
                          <h6>Nemáte žiadne dokumenty na podpis.</h6>
                        </div>
                        <Link to={routes.zmluvyDodatky}>
                          Prejsť k zmluvám a dodatkom
                          <IconArrowRight id="contracts-link-dashboard" />
                        </Link>
                      </>
                    )}
                  </GridCol>
                </Grid>
              </CardSection>
            </Card>
          </GridCol>
        </Grid>
      )
    );
  const getDashboardBanner = ({ isLarge }: { isLarge: boolean }) => (
    <DashboardBanner
      dataLayerEvent={
        bannerToShow?.theme?.includes('Aut')
          ? `aut_v${bannerVersion === 'A' ? '1' : '2'}_banner_hp_show`
          : ''
      }
      isCard={!isLarge}
      isModal={bannerToShow?.visible.popup}
      onClose={() => {
        // dispatch(toggleVisibilityPzsAuth({ type: 'banner' }));
        dispatch(saveAuthRejection({ idPouzivatel: pouzivatelId || 0 }));
        sendDataLayer('auth_banner_hp_close');
        dispatch(
          hideBanner({
            idBaner: bannerToShow?.idBaner || 0,
            idPouzivatel: pouzivatelId || 0,
            idVZ: vztahId || 0,
          }),
        );
      }}
      show={
        bannerToShow?.visible.banner ||
        bannerToShow?.visible.popup ||
        bannerToShow?.visible.card
      }
      theme={bannerToShow?.theme || ''}
    >
      {bannerToShow?.theme?.includes('Aut') ? (
        <AuthBanner isCard={!isLarge} version={bannerVersion} />
      ) : (
        <HPBanner isLarge={isLarge} path={bannerToShow?.path || ''} />
      )}
    </DashboardBanner>
  );
  // const renderPromoWidget = hasHPKEI && (
  //   <PromoWidget
  //     cta={{
  //       route: `${routes.hp}?obdobie=uzavrete`,
  //       text: 'Pozrieť plnenie parametrov',
  //     }}
  //     description={
  //       <div>
  //         <div className="mb-small">
  //           Vyhodnotili sme plnenie hodnotiacich parametrov za obdobie apríl -
  //           september 2021.
  //         </div>
  //         <div className="no-mrg">
  //           Od 1. 10. 2021 je pre Vás platná <b>nová cena bodu.</b>
  //         </div>
  //       </div>
  //     }
  //     id="hp-promo-widget"
  //     title="Hodnotiace parametre"
  //   />
  // );
  return (
    <StaticMenuLayout hideEPNotifications>
      <PageSection className={classes.dashboard}>
        <Container>
          {renderOslovenie}
          {(bannerToShow?.visible.banner || bannerToShow?.visible.popup) &&
            getDashboardBanner({ isLarge: true })}
          {isASOC && renderZmluvyNaPodpis}
          {!isASOC && (
            <Grid>
              <GridCol
                className="mb"
                equalizeHeight={false}
                size={{ s: 12, l: 12, xl: 8 }}
              >
                <EPNotifications />
                <NaRiesenieWidget />
                <DashboardNtf />
                <DoveraNews news={aktuality} />
                {/* {renderPromoWidget} */}
              </GridCol>
              <GridCol equalizeHeight={false} size={{ s: 12, l: 12, xl: 4 }}>
                {/* {renderProfileCard} */}
                <WidgetCenyHP />
                {bannerToShow?.visible.card &&
                  getDashboardBanner({ isLarge: false })}
                {renderEPLinks}
                <ReviewCarousel />
              </GridCol>
            </Grid>
          )}
        </Container>
      </PageSection>
    </StaticMenuLayout>
  );
};

export default Dashboard;
