import { ReactNode } from 'react';

import {
  getPercentage,
  getPriceFormat,
  getValueWithNDecimal,
  replaceWithComma,
  roundFloat,
} from './number.utils';
import {
  dynamicText,
  getTypHodnotyPostfix,
  replaceStringWithParams,
  text,
} from './strings.utils';

import {
  IconBulb,
  IconCycle,
  IconDashedRing,
  IconDiamond,
  IconError,
  IconLockClosed,
  IconSuccess,
} from '../components';
import { Hodnotenie, Nullable } from '../types';
import { DATE_FORMAT_MONTH_YEAR, formatDate } from './date.utils';
import {
  Cyklus,
  Navigacia,
  Obdobie,
  Parameter,
  SkupinaParametrov,
  SkupinyParametrov,
  TypCeny,
  VysledkyObdobia,
  VysledokObdobia,
  ZoznamObdobi,
} from '../slices/hodnotiaceParametre.slice';
import { OdbornostHodnoteneho, VASType } from '../types/poskytovatel.types';
import { isGYN009 } from './dashboard.utils';
import { SUBJEKTIVNA_SPOKOJNOST, color } from '../constants';
import IconWarningCircle from '../components/CustomIcons/IconWarningCircle';
import { getCMSText } from './cms.utils';
import { cmsPath } from '../constants/cmsPath';
import strings from '../constants/strings';
import { find, values } from 'lodash';
import { DOSTUPNOST_GYN_ZDRAVOTNEJ_STAROSTLIVOSTI } from '../constants/misc';
import store from '../store';

export const exchangeableParams: string[] = [
  'podiel-fix-hyp',
  'vakc-hpv',
  'farm-sz',
  'prev-gyn-cyt',
  'alerg-imt',
  'std-nefro',
  'liecba-po-cmp',
  'vys-deti',
  'vys-novi',
  'atb-orl',
  'tele',
  'polyp',
];

export const paramsForNewGraph: string[] = [
  ...exchangeableParams,
  ...['bolest-chrb'],
];

export function formatPlnenie(
  plnenie: boolean | number,
  pasmo: string,
): boolean {
  if (!plnenie || (plnenie !== 1 && pasmo === 'N')) {
    return false;
  }
  return !!(plnenie || pasmo === 'P');
}

export function formatHodnota(
  hodnota?: number | string | null,
  plnenie?: number | string,
  typHodnoty?: string,
  nbsp?: boolean,
  br?: boolean,
): string | number {
  if (hodnota === null && Number(plnenie) === 1 && typHodnoty === 'P')
    return '100 %';
  if (typeof hodnota === 'string') return hodnota;
  if (!hodnota) return '';
  if (typHodnoty === 'P' && nbsp) {
    return hodnota
      ? `${replaceWithComma(getPercentage(hodnota))}&nbsp;%`
      : '0&nbsp;%';
  }
  if (typHodnoty === 'P') {
    return hodnota ? `${replaceWithComma(getPercentage(hodnota))} %` : '0 %';
  }
  if (typHodnoty === 'D') {
    return hodnota ? replaceWithComma(parseFloat(hodnota.toFixed(3))) : '0';
  }
  if (typHodnoty === 'D2') {
    return hodnota ? replaceWithComma(parseFloat(hodnota.toFixed(2))) : '0';
  }
  if (typHodnoty === 'E' && nbsp) {
    return hodnota
      ? `${getPriceFormat(hodnota.toFixed(2))}&nbsp;€`
      : '0&nbsp;€';
  }
  if (typHodnoty === 'E') {
    return hodnota ? `${getPriceFormat(hodnota.toFixed(2))} €` : '0 €';
  }
  if (typHodnoty === 'B') {
    return `${getValueWithNDecimal(hodnota || 0)} ${getTypHodnotyPostfix(
      'B',
      hodnota || 0,
      br,
    )}`;
  }
  if (typHodnoty === 'R') {
    return `${getValueWithNDecimal(hodnota || 0, 3)}`;
  }
  if (typHodnoty === 'N') {
    return `${getValueWithNDecimal(hodnota || 0)} ${getTypHodnotyPostfix(
      'N',
      hodnota || 0,
      br,
    )}`;
  }
  if (typHodnoty === 'M') {
    return `${getValueWithNDecimal(hodnota || 0)} ${getTypHodnotyPostfix(
      'M',
      hodnota || 0,
      br,
    )}`;
  }
  if (typHodnoty === 'A') {
    return '';
  }
  /** custom hodnota, vrati iba hodnota zaokruhlenu na 2 des. cisla * */
  if (typHodnoty === 'V') {
    return getValueWithNDecimal(hodnota || 0);
  }
  if (typHodnoty === 'I') {
    return (
      hodnota?.toString().replace(/\B(?=(\d{3}){1,150}(?!\d))/g, ' ') || ''
    );
  }
  return getValueWithNDecimal(hodnota || 0) || 0;
}

export function cenaKod(
  typCeny: TypCeny,
  kodParametra: Nullable<string>,
  vysledokObdobia: Nullable<VysledkyObdobia>,
): number {
  return (
    (vysledokObdobia &&
      vysledokObdobia[typCeny] &&
      vysledokObdobia[typCeny].filter(
        (o) => o.kodParametra === kodParametra,
      )[0] &&
      vysledokObdobia[typCeny].filter((o) => o.kodParametra === kodParametra)[0]
        .cena) ||
    0
  );
}

export function parameterBezCien(
  typyCien: TypCeny[],
  kodParametra: Nullable<string>,
  vysledokObdobia: Nullable<VysledkyObdobia>,
): boolean {
  if (!vysledokObdobia || !kodParametra || !typyCien.length) return true;
  return typyCien
    .map(
      (c) =>
        vysledokObdobia[c] &&
        vysledokObdobia[c].filter((o) => o.kodParametra === kodParametra) &&
        vysledokObdobia[c]
          .filter((o) => o.kodParametra === kodParametra)
          .find((p) => p.cena),
    )
    .every((p) => p === undefined);
}

export function getColor(
  id: number,
  odbornost?: OdbornostHodnoteneho,
  VAS?: VASType,
): string {
  let specificOdbornost: boolean = false;
  if (odbornost && odbornost.kodOdbNz) {
    specificOdbornost =
      parseInt(odbornost.kodOdbNz, 10) >= 141 &&
      parseInt(odbornost.kodOdbNz, 10) <= 144;
  }
  if (VAS && VAS.isVLD) {
    if (VAS.primaryVLD) {
      return id === 1 ? '#EA5ED1' : '#3EAEFF';
    }
    return '#42D848';
  }
  switch (id) {
    case 1:
      return specificOdbornost ? '#42D848' : '#EA5ED1';
    case 2:
      return specificOdbornost ? '#3EAEFF' : '#42D848';
    case 3:
      return '#3EAEFF';
    default:
      return '';
  }
}

export function getIcon(
  { id, ...other },
  odbornost?: OdbornostHodnoteneho,
  VAS?: VASType,
): any {
  let specificOdbornost: boolean = false;
  if (VAS && VAS.isVLD) {
    if (VAS.primaryVLD) {
      return id === 1 ? (
        <IconDiamond id="icon-specific-diamond" {...other} width="12%" />
      ) : (
        <IconBulb id="icon-specific-bulb" {...other} width="12%" />
      );
    }
    return <IconCycle id="icon-specific-cycle" {...other} width="17%" />;
  }
  if (odbornost && odbornost.kodOdbNz) {
    specificOdbornost =
      parseInt(odbornost.kodOdbNz, 10) >= 141 &&
      parseInt(odbornost.kodOdbNz, 10) <= 144;
  }
  switch (id) {
    case 1:
      return specificOdbornost ? (
        <IconCycle id="icon-specific-cycle" {...other} />
      ) : (
        <IconDiamond {...other} />
      );
    case 2:
      return specificOdbornost ? (
        <IconBulb id="icon-specific-bulb" {...other} />
      ) : (
        <IconCycle id="icon-specific-cycle" {...other} />
      );
    case 3:
      return <IconBulb id="icon-specific-bulb" {...other} />;
    default:
      return null;
  }
}

// dočasná funkcia kým neprídu správne dáta z BE
export function getNazovSkupiny(slug?: string): string {
  switch (slug) {
    case 'efektivnost':
      return 'Efektívnosť';
    case 'kvalita':
      return 'Kvalita';
    case 'inovacie':
      return 'Inovácie';
    default:
      return '';
  }
}

// dočasná funkcia kým neprídu správne dáta z BE
export function getSlugSkupiny(slug: string): string {
  switch (slug) {
    case 'Efektívnosť':
      return 'efektivnost';
    case 'Kvalita':
      return 'kvalita';
    case 'Inovácie':
      return 'inovacie';
    default:
      return '';
  }
}

export function formatHodnotenie(
  aktivnaOdbornost: OdbornostHodnoteneho,
  buduci: boolean,
  predbezne: boolean,
  datumOd: string,
  datumDo: string,
  vysledokObdobia?: VysledkyObdobia,
  datumOdCenaBodu?: string,
  datumDoCenaBodu?: string,
  datumOdHodnZisk?: string,
  datumDoHodnZisk?: string,
  datumVykon1Od?: string,
  datumVykon1Do?: string,
  datumVykon4Od?: string,
  datumVykon8Od?: string,
  datumVykon250Od?: string,
  historickeVykazovanieOd?: string,
  historickeVykazovanieDo?: string,
  obdobiePlatnostOd?: string,
  obdobiePlatnostDo?: string,
  keys?: TypCeny[],
  previousKeys?: TypCeny[],
  withoutPrices?: boolean,
  isVyhodnoteneObdobie?: boolean,
  popisEmptyState?: string | null,
): Hodnotenie[] {
  const {
    hp: { notValidExperience },
  } = strings;
  const indikatorObdobia: 'aktualneObdobie' | 'predchadzajuceObdobie' =
    predbezne ? 'aktualneObdobie' : 'predchadzajuceObdobie';
  const buduceHodnotenie: Hodnotenie[] =
    previousKeys && buduci
      ? [
          {
            hodnota: '0 €',
            popis: text(`dashboard.hodnotenie.${previousKeys[0]}`),
            tooltip: '',
          },
        ]
      : [];
  const hodnotenia: Hodnotenie[] =
    (keys &&
      keys
        .filter((k) => k !== 'DoplnKap')
        .map((k) => {
          const staticText: ReactNode = buduci
            ? getCMSText(
                cmsPath.HP.prehlad.tooltipy?.spolocne?.buduceObdobie
                  ?.uhradaNavyse,
                '',
                false,
                true,
                '',
                true,
              )
            : getCMSText(
                cmsPath.HP.prehlad.tooltipy?.[k]?.[indikatorObdobia]?.tooltip,
                '',
                false,
                true,
                '',
                true,
              );
          return {
            hodnota: getPriceFormat(cenaKod(k, null, vysledokObdobia), true),
            popis: text(`dashboard.hodnotenie.${k}`),
            tooltip:
              popisEmptyState !== notValidExperience ? (
                <>
                  {replaceStringWithParams(staticText, [
                    {
                      id: '(OBDOBIE_OD)',
                      value:
                        indikatorObdobia === 'predchadzajuceObdobie'
                          ? datumOdCenaBodu || datumOdHodnZisk
                          : datumOdCenaBodu || datumOd,
                    },
                    {
                      id: '(OBDOBIE_DO)',
                      value:
                        indikatorObdobia === 'predchadzajuceObdobie'
                          ? datumDoCenaBodu || datumDoHodnZisk
                          : datumDoCenaBodu || datumDo,
                    },
                    {
                      id: '(OBDOBIE_OD_VYKON8)',
                      value: datumVykon8Od,
                    },
                    {
                      id: '(OBDOBIE_OD_VYKON4)',
                      value: datumVykon4Od,
                    },
                    {
                      id: '(OBDOBIE_OD_VYKON1)',
                      value: datumVykon1Od,
                    },
                    {
                      id: '(OBDOBIE_OD_VYKON250)',
                      value: datumVykon250Od,
                    },
                    {
                      id: '(OBDOBIE_OD_PLATNOST_CENY)',
                      value: obdobiePlatnostOd,
                    },
                    {
                      id: '(OBDOBIE_DO_PLATNOST_CENY)',
                      value: obdobiePlatnostDo,
                    },
                    {
                      id: '(OBDOBIE_HIST_VYKAZOVANIE_OD)',
                      value: historickeVykazovanieOd,
                    },
                    {
                      id: '(OBDOBIE_HIST_VYKAZOVANIE_DO)',
                      value: historickeVykazovanieDo,
                    },
                    {
                      id: '(ZAKLADNA_CENA)',
                      value: k.includes('Vykon')
                        ? formatHodnota(
                            vysledokObdobia?.[`${k}D`]?.[0]?.cena,
                            undefined,
                            'E',
                          ).toString()
                        : '',
                    },
                    {
                      id: '(ZVYHODNENA_CENA)',
                      value: k.includes('Vykon')
                        ? formatHodnota(
                            vysledokObdobia?.[`${k}V`]?.[0]?.cena,
                            undefined,
                            'E',
                          ).toString()
                        : '',
                    },
                  ])}
                </>
              ) : (
                notValidExperience
              ),
          };
        })) ||
    [];
  const hodnPrices: Hodnotenie[] =
    (indikatorObdobia === 'aktualneObdobie' &&
      !withoutPrices && [
        {
          hodnota: `+ ${getPriceFormat(
            cenaKod('HodnPar', null, vysledokObdobia),
            true,
          )}`,
          popis: text(`dashboard.hodnotenie.${indikatorObdobia}.hodnota.popis`),
          tooltip:
            popisEmptyState !== notValidExperience
              ? getCMSText(
                  cmsPath.HP.prehlad.tooltipy.spolocne.odmenaAktualne,
                  text(
                    `dashboard.hodnotenie.${indikatorObdobia}.hodnota.tooltip`,
                    {
                      od: datumOd,
                      do: datumDo,
                    },
                  ),
                  false,
                  false,
                  '',
                  true,
                )
              : notValidExperience,
        },
      ]) ||
    [];
  const hodnHranicaLimitu: Hodnotenie[] =
    vysledokObdobia?.Vykon250 && cenaKod('ObjemD', null, vysledokObdobia)
      ? [
          {
            hodnota: cenaKod('ObjemD', null, vysledokObdobia)
              ? `${getPriceFormat(
                  cenaKod('ObjemD', null, vysledokObdobia),
                  true,
                )}`
              : 'N/A',
            popis: text(`dashboard.hodnotenie.ObjemD`),
            tooltip:
              popisEmptyState !== notValidExperience
                ? getCMSText(
                    cmsPath.HP.prehlad.tooltipy.hranicaFinLimituVLD,
                    '',
                    false,
                    false,
                    '',
                    true,
                  )
                : notValidExperience,
          },
        ]
      : [];

  const extraPrice = (): Hodnotenie[] => {
    const extraKeys: TypCeny[] = ['DoplnKap'];
    return (
      extraKeys
        .filter(
          (k) => keys?.includes(k) && vysledokObdobia && vysledokObdobia[k],
        )
        .map((k) => ({
          className: 'with-border--left',
          hodnota: cenaKod(k, null, vysledokObdobia)
            ? `${getPriceFormat(cenaKod(k, null, vysledokObdobia), true)}`
            : '0 €',
          popis: text(`dashboard.hodnotenie.${k}`),
          tooltip: getCMSText(
            cmsPath.HP.prehlad.tooltipy?.spolocne?.[k],
            '',
            false,
            false,
            '',
            true,
          ),
        })) || []
    );
  };

  return [
    ...buduceHodnotenie,
    ...hodnotenia,
    ...hodnHranicaLimitu,
    {
      hodnota: `${
        cenaKod('HodnZisk', null, vysledokObdobia) > 0 ? '+ ' : ''
      }${getPriceFormat(cenaKod('HodnZisk', null, vysledokObdobia), true)}`,
      popis: text(`dashboard.hodnotenie.${indikatorObdobia}.zisk.popis`),
      tooltip:
        popisEmptyState !== notValidExperience
          ? replaceStringWithParams(
              buduci
                ? getCMSText(
                    cmsPath.HP.prehlad.tooltipy?.spolocne?.buduceObdobie
                      ?.uhradaNavyse,
                    '',
                    false,
                    true,
                    '',
                    true,
                  )
                : getCMSText(
                    vysledokObdobia?.Vykon8
                      ? isVyhodnoteneObdobie
                        ? cmsPath.HP.prehlad.tooltipy.uhradaNavyseVyhodnotene
                        : indikatorObdobia === 'predchadzajuceObdobie'
                          ? cmsPath.HP.prehlad.tooltipy.uhradaNavyseMinule
                          : cmsPath.HP.prehlad.tooltipy.uhradaNavyseAktualne
                      : vysledokObdobia?.DodKap
                        ? cmsPath.HP.prehlad.tooltipy.DodKap?.[
                            indikatorObdobia === 'predchadzajuceObdobie' &&
                            isVyhodnoteneObdobie
                              ? 'vyhodnoteneObdobie'
                              : indikatorObdobia
                          ]?.uhradaNavyse
                        : cmsPath.HP.prehlad.tooltipy.CenaBodu?.[
                            indikatorObdobia === 'predchadzajuceObdobie' &&
                            isVyhodnoteneObdobie
                              ? 'vyhodnoteneObdobie'
                              : indikatorObdobia
                          ]?.uhradaNavyse,
                    '',
                    false,
                    true,
                    '',
                    true,
                  ),
              [
                {
                  id: '(OBDOBIE_OD1)',
                  value: datumOdCenaBodu || datumVykon1Od,
                },
                {
                  id: '(OBDOBIE_DO1)',
                  value: datumDoCenaBodu || datumVykon1Do,
                },
                {
                  id: '(OBDOBIE_OD2)',
                  value: datumOdCenaBodu || datumVykon1Od,
                },
                {
                  id: '(OBDOBIE_DO2)',
                  value: datumDoCenaBodu || datumVykon1Do,
                },
                {
                  id: '(OBDOBIE_OD3)',
                  value: obdobiePlatnostOd,
                },
                {
                  id: '(OBDOBIE_DO3)',
                  value: obdobiePlatnostDo,
                },
                {
                  id: '(OBDOBIE_OD_MINULE)',
                  value: datumOd,
                },
                {
                  id: '(OBDOBIE_DO_MINULE)',
                  value: datumDo,
                },
                {
                  id: '(OBDOBIE_HIST_VYKAZOVANIE_OD)',
                  value: historickeVykazovanieOd,
                },
                {
                  id: '(OBDOBIE_HIST_VYKAZOVANIE_DO)',
                  value: historickeVykazovanieDo,
                },
              ],
            )
          : notValidExperience,
    },
    ...hodnPrices,
    ...extraPrice(),
  ];
}

export function datumObdobia(
  obdobie: Obdobie,
  key: 'obdobieVypocetOd' | 'obdobieVypocetDo' | 'obdobieSledovaneDo',
): string {
  return obdobie && obdobie[key]
    ? formatDate(obdobie[key].toString(), DATE_FORMAT_MONTH_YEAR)
    : '';
}

export function datumPlneniaDashboard(
  obdobie: Obdobie,
  key1: string,
  key2: 'obdobiePlatnostOd' | 'obdobiePlatnostDo' | 'obdobieSledovaneDo',
): string {
  return (
    obdobie &&
    obdobie?.vysledokObdobia?.[key1]?.[0] &&
    formatDate(
      obdobie.vysledokObdobia[key1][0][key2].toString(),
      DATE_FORMAT_MONTH_YEAR,
    )
  );
}

/**
 * TODO nove parametre
 *
 * Bolest chrbta = "bolest-chrb"
 * Vakcina rotavirusu = "vakc-rotav"
 */

export function getHranica(
  hranicnaHodnotaMax: number,
  hranicnaHodnotaMin: number,
  pasmo: string,
  kodParametra?: string,
): number {
  const parametreDolnaHranica: string[] = [
    'prev-vas',
    'prev-vas-dp',
    'prev-gyn',
    'prev-kp-gyn',
    'kont-gyn',
  ];
  const parametreHornaHranica: string[] = [
    'svlz-gyn',
    'nakl-vas',
    'starost-vas',
    'kont-sas',
  ];
  if (parametreDolnaHranica.some((p) => p === kodParametra))
    return hranicnaHodnotaMin;
  if (parametreHornaHranica.some((p) => p === kodParametra))
    return hranicnaHodnotaMax;
  const hranicaPodlaParametra = (): number => {
    switch (kodParametra) {
      case 'bezp-med':
      case 'elab':
      case 'ezuc':
      case 'erec':
      case 'enav':
      case 'subj-spok':
      case 'std-hba1c':
      case 'prev-kp-gyn':
      case 'dostup-gyn':
      case 'prev-gyn':
      case 'kont-gyn':
        return hranicnaHodnotaMin;
      default:
        return 0;
    }
  };

  const hranicaPodlaPasma = (): number => {
    switch (pasmo) {
      case 'V':
      case 'S':
        if (kodParametra === 'prev-gyn') return hranicnaHodnotaMin;
        return hranicnaHodnotaMax;
      case 'D':
        return hranicnaHodnotaMin;
      default:
        return hranicaPodlaParametra();
    }
  };

  return hranicaPodlaPasma();
}

export function dividedGraph(obdobie: Obdobie): boolean {
  if (!obdobie) return false;
  return (
    obdobie.typyCien.some((t) => t === 'DodKap') &&
    obdobie.typyCien.some((t) => t === 'PlnKI') &&
    obdobie.typyCien.some((t) => t === 'PlnE')
  );
}

export function calculateKeyCenaBodu(obdobie: Obdobie): TypCeny[] {
  if (obdobie?.typyCien.some((t) => t.includes('DodKap')))
    return ['DodKap', 'DoplnKap'];
  if (obdobie?.typyCien.some((t) => t.includes('Vykon'))) {
    const vykony: TypCeny[] = [
      'Vykon8',
      'Vykon4',
      'Vykon1',
      'Vykon250',
      'DoplnKap',
    ];
    return vykony.filter((v) => obdobie?.typyCien.filter((t) => v === t));
  }
  return ['CenaBodu'];
}

export const dajDatumyVypoctuHodnoteniaObdobia = (
  obdobie: Obdobie,
): { obdobieDo: string; obobieOd: string } => {
  const keys = calculateKeyCenaBodu(obdobie);
  const datumCenaBoduOd: string = datumPlneniaDashboard(
    obdobie,
    (keys && keys[0]) || '',
    'obdobiePlatnostOd',
  );
  const datumCenaBoduDo: string =
    datumObdobia(obdobie, 'obdobieSledovaneDo') ||
    datumObdobia(obdobie, 'obdobieVypocetDo');

  return {
    obdobieDo: datumCenaBoduDo,
    obobieOd: datumCenaBoduOd,
  };
};

export function vypocetHodnoteniaObdobia(
  aktivnaOdbornost: OdbornostHodnoteneho,
  buduci: boolean,
  predbezne: boolean,
  zoznamObdobi: ZoznamObdobi,
  obdobie: Obdobie,
  isVAS: boolean,
  keys?: TypCeny[],
  previousKeys?: TypCeny[],
  withoutPrices?: boolean,
  isVyhodnoteneObdobie?: boolean,
): Hodnotenie[] {
  const datumOd: string = datumObdobia(obdobie, 'obdobieVypocetOd');
  const datumDo: string =
    datumObdobia(obdobie, 'obdobieSledovaneDo') ||
    datumObdobia(obdobie, 'obdobieVypocetDo');

  const isDodKap = obdobie?.typyCien.some((t) => t === 'DodKap');

  const datumCenaBoduOd: string = datumPlneniaDashboard(
    obdobie,
    isDodKap ? 'DodKap' : 'CenaBodu',
    'obdobiePlatnostOd',
  );

  const datumCenaBoduDo: string = datumPlneniaDashboard(
    obdobie,
    isDodKap ? 'DodKap' : 'CenaBodu',
    'obdobiePlatnostDo',
  );

  const datumVykon8Od: string = datumPlneniaDashboard(
    obdobie,
    'Vykon8',
    'obdobiePlatnostOd',
  );

  const datumVykon4Od: string = datumPlneniaDashboard(
    obdobie,
    'Vykon4',
    'obdobiePlatnostOd',
  );

  const datumVykon1Od: string =
    datumPlneniaDashboard(obdobie, 'PlneniePar', 'obdobiePlatnostOd') ||
    datumPlneniaDashboard(obdobie, 'Vykon1', 'obdobiePlatnostOd');

  const datumVykon1Do: string =
    datumPlneniaDashboard(obdobie, 'PlneniePar', 'obdobiePlatnostDo') ||
    datumPlneniaDashboard(obdobie, 'Vykon1', 'obdobiePlatnostDo');

  const datumVykon250Od: string = datumPlneniaDashboard(
    obdobie,
    'Vykon250',
    'obdobiePlatnostOd',
  );

  const datumHodnZiskOd: string = datumPlneniaDashboard(
    obdobie,
    'HodnZisk',
    'obdobiePlatnostOd',
  );
  const datumCHodnZiskDo: string = datumPlneniaDashboard(
    obdobie,
    'HodnZisk',
    'obdobiePlatnostDo',
  );
  const obdobiePlatnostDo: string = formatDate(
    obdobie?.obdobieSledovaneDo.toString(),
    DATE_FORMAT_MONTH_YEAR,
  );
  const obdobiePlatnostOd: string = formatDate(
    obdobie?.obdobieVypocetOd.toString(),
    DATE_FORMAT_MONTH_YEAR,
  );
  const historickeObdobieVykazovania =
    predbezne || buduci
      ? Object.values(zoznamObdobi).find(
          (o) => obdobie.poradie - (buduci ? 3 : 2) === o.poradie,
        )
      : null;
  const historickeVykazovanieOd: string = historickeObdobieVykazovania
    ? datumPlneniaDashboard(
        historickeObdobieVykazovania,
        'PlneniePar',
        'obdobiePlatnostOd',
      ) ||
      datumPlneniaDashboard(
        historickeObdobieVykazovania,
        'HodnZisk',
        'obdobiePlatnostOd',
      )
    : '';
  const historickeVykazovanieDo: string = historickeObdobieVykazovania
    ? datumPlneniaDashboard(
        historickeObdobieVykazovania,
        'PlneniePar',
        'obdobiePlatnostDo',
      ) ||
      datumPlneniaDashboard(
        historickeObdobieVykazovania,
        'HodnZisk',
        'obdobiePlatnostDo',
      )
    : '';
  return (
    obdobie &&
    formatHodnotenie(
      aktivnaOdbornost,
      buduci,
      predbezne,
      datumOd,
      datumDo,
      obdobie?.vysledokObdobia,
      datumCenaBoduOd,
      datumCenaBoduDo,
      datumHodnZiskOd,
      datumCHodnZiskDo,
      datumVykon1Od,
      datumVykon1Do,
      datumVykon4Od,
      datumVykon8Od,
      datumVykon250Od,
      historickeVykazovanieOd,
      historickeVykazovanieDo,
      obdobiePlatnostOd,
      obdobiePlatnostDo,
      keys,
      previousKeys,
      withoutPrices,
      isVyhodnoteneObdobie,
      obdobie?.popisEmptyState,
    )
  );
}

export function vypocetTypCeny(
  pzsObdobieCyklus: Cyklus[],
  id: number,
  vysledokPzsObd: VysledokObdobia[],
): string[] {
  return vysledokPzsObd
    .filter((v) => v.idPzsOdbCyklus === id && !v.kodParametra)
    .map((obdobie) => obdobie.typCeny);
}

export function isCorrectParamForBarChart(kodParametra: string): boolean {
  const notAllowed: string[] = [''];
  return notAllowed.some((n) => n !== kodParametra);
}

export const hodnotaPodlaPlnenia = (plnenie: number | boolean): string =>
  plnenie ? '100 %' : '0 %';

export function dajPolozkyMenu(
  aktualneObdobie: Obdobie,
  aktivnaOdbornost: OdbornostHodnoteneho,
): Navigacia {
  // if (aktualneObdobie?.buduci)
  //   return (
  //     zoznamObdobi[predchadzajuceId]?.navigacia?.filter(
  //       (s) =>
  //         !isGYN009(
  //           s.title,
  //           aktivnaOdbornost?.kodOdbNz,
  //           aktivnaOdbornost?.kodTypZS,
  //         ),
  //     ) || []
  //   );
  if (store?.getState()?.hodnotiaceParametre.detailParametra.isEmptyState)
    return [];
  return (
    aktualneObdobie?.navigacia.filter(
      (s) =>
        !isGYN009(
          s.title,
          aktivnaOdbornost?.kodOdbNz,
          aktivnaOdbornost?.kodTypZS,
        ),
    ) || []
  );
}

// Calculation Line chart values
export const calculateChartValue = (
  parameter: Parameter,
  hodnota: number,
  firstGraph?: boolean | null,
  secondGraph?: boolean | null,
): number => {
  if (!parameter.kodParametra) return 0;
  if (parameter.kodParametra === 'subj-spok') return 100;
  if (parameter.kodParametra === 'dostup-gyn' && (firstGraph || secondGraph)) {
    if (firstGraph)
      return (parameter.hodnotaCitatel / parameter.hranicnaHodnotaMax) * 50;
    return (parameter.hodnotaMenovatel / parameter.hranicnaHodnotaMin) * 50;
  }
  if (
    graphWithTwoPointers.includes(parameter.kodParametra) &&
    parameter.hodnota !== null
  ) {
    if (parameter.hodnota < parameter.hranicnaHodnotaMin)
      return (parameter.hodnota / parameter.hranicnaHodnotaMin) * 25;
    if (parameter.hodnota > parameter.hranicnaHodnotaMax)
      return (parameter.hodnota / parameter.hranicnaHodnotaMax) * 75;
    return parameter.hodnota - parameter.hranicnaHodnotaMin >
      parameter.hranicnaHodnotaMax - parameter.hodnota
      ? 63
      : 37;
  }

  if (parameter.plnenie && parameter.typHodnoty === 'P') return 100;
  return parameter.hranicnaHodnotaMin && parameter.hodnota
    ? parameter.hranicnaHodnotaMin > parameter.hodnota
      ? parameter.typHodnoty === 'P'
        ? hodnota * 100
        : parameter.typHodnoty === 'N' || parameter.typHodnoty === 'E'
          ? Math.abs((parameter.hodnota * 75) / parameter.hranicnaHodnotaMax)
          : 15
      : (75 / parameter.hranicnaHodnotaMax) * hodnota
    : hodnota * 100;
};

export const graphWithTwoPointers = ['atb-dp', 'nakl-vas-dp', 'starost-vas-dp'];

export const calculatePointer1 = (
  parameter: Parameter,
  pdf?: boolean,
  hranica?: number | null,
): string => {
  let hranicnaHodnota = parameter.hranicnaHodnotaMin;
  const { plnenie } = parameter;
  const hornaHranicaKody = [
    'sch-dp',
    'atb',
    'nakl-vas',
    'nakl-sas',
    'starost-vas',
    'kont-sas',
    'body-log',
    'vys-log',
    'body-psyd',
    'vys-psyd',
    'body-psyt',
    'vys-psyt',
  ];
  if (hornaHranicaKody.includes(parameter.kodParametra || ''))
    hranicnaHodnota = parameter.hranicnaHodnotaMax;

  if (parameter.kodParametra === 'subj-spok') return '';

  if (hornaHranicaKody.includes(parameter.kodParametra || ''))
    return `Horná hranica: ${formatHodnota(
      hranicnaHodnota,
      plnenie,
      parameter.typHodnoty,
      !pdf,
    )}`;

  return parameter.hranicnaHodnotaMin &&
    parameter.hranicnaHodnotaMin !== parameter.hranicnaHodnotaMax &&
    graphWithTwoPointers.includes(parameter.kodParametra || '')
    ? `Hranica pod ${formatHodnota(
        parameter.hranicnaHodnotaMin,
        plnenie,
        parameter.typHodnoty,
        !pdf,
      )}`
    : `Dolná hranica: ${formatHodnota(
        hranica || hranicnaHodnota,
        plnenie,
        parameter.typHodnoty,
        !pdf,
      )}`;
};

export const calculateHranica1Hodn = (parameter: Parameter) => {
  if (parameter.kodParametra === 'subj-spok') return 0;
  if (parameter.kodParametra === 'dostup-gyn') return 50;
  return (parameter.hranicnaHodnotaMin &&
    parameter.hranicnaHodnotaMin !== parameter.hranicnaHodnotaMax &&
    graphWithTwoPointers.includes(parameter.kodParametra || '')) ||
    parameter.kodParametra === 'prev-vas-dp'
    ? 25
    : parameter.hranicnaHodnotaMax > 1
      ? 75
      : parameter.hranicnaHodnotaMax * 100;
};

export const calculateHranica2Hodn = (parameter: Parameter) =>
  parameter.hranicnaHodnotaMin &&
  parameter.hranicnaHodnotaMin !== parameter.hranicnaHodnotaMax &&
  graphWithTwoPointers.includes(parameter.kodParametra || '')
    ? 75
    : 0;

export const calculatePointer2 = (parameter: Parameter, pdf?: boolean) =>
  parameter.hranicnaHodnotaMin &&
  parameter.hranicnaHodnotaMin !== parameter.hranicnaHodnotaMax &&
  graphWithTwoPointers.includes(parameter.kodParametra || '')
    ? `Hranica nad ${formatHodnota(
        parameter.hranicnaHodnotaMax,
        parameter.plnenie,
        parameter.typHodnoty,
        !pdf,
      )}`
    : ``;

export const hpRoute = (route: string): string => {
  // @ts-ignore
  if (window.publicGuid) {
    return route.replace(
      'hodnotiace-parametre',
      'prehlad-hodnotiacich-parametrov',
    );
  }
  return route;
};

export const isParameterPartiallyDone = (parameter: Parameter): boolean =>
  parameter?.plnenie > 0 && parameter?.plnenie < 1;

export const getPlnenieValue = (
  parameter?: Parameter,
  firstGraph?: boolean | null,
): number => {
  if (!parameter) return 0;
  return parameter.kodParametra === 'dostup-gyn'
    ? firstGraph
      ? parameter.hodnotaCitatel > parameter.hranicnaHodnotaMax
        ? 1
        : 0
      : parameter.hodnotaMenovatel > parameter.hranicnaHodnotaMin
        ? 1
        : 0
    : parameter.plnenie;
};

export const getStavPlnenia = (
  parameter: Parameter,
  firstGraph?: boolean | null,
) =>
  isParameterPartiallyDone(parameter)
    ? parameter.kodParametra &&
      paramsForNewGraph.includes(parameter.kodParametra)
      ? 'success'
      : 'warning'
    : formatPlnenie(
          parameter?.kodParametra === 'dostup-gyn'
            ? firstGraph
              ? parameter?.hodnotaCitatel > parameter?.hranicnaHodnotaMax
              : parameter?.hodnotaMenovatel > parameter?.hranicnaHodnotaMin
            : parameter?.plnenie,
          parameter?.pasmo,
        )
      ? 'success'
      : 'error';

export const getStatusIcon = (
  parameter: Parameter,
  firstGraph?: boolean | null,
  isTentative?: boolean,
  isFuture?: boolean,
): ReactNode => {
  if (isFuture)
    return <IconLockClosed color={color('grey', 500)} height={22} width={22} />;
  if (
    isTentative &&
    !isParameterPartiallyDone(parameter) &&
    !getPlnenieValue(parameter)
  )
    return <IconDashedRing height={22} width={22} />;
  let valueIcon = <span />;
  switch (getStavPlnenia(parameter, firstGraph)) {
    case 'success':
      valueIcon = (
        <IconSuccess color={color('success')} height={22} width={22} />
      );
      break;
    case 'warning':
      valueIcon =
        parameter.kodParametra &&
        paramsForNewGraph.includes(parameter.kodParametra) ? (
          <IconSuccess color={color('success')} height={22} width={22} />
        ) : (
          <IconWarningCircle height={22} id="icon-warning-value" width={22} />
        );
      break;
    default:
      valueIcon = <IconError height={22} width={22} />;
      break;
  }
  return valueIcon;
};

export const isVyhodnoteneObdobie = (
  nasledujuceId: number,
  obdobie: Obdobie,
  zoznamObdobi: ZoznamObdobi,
): boolean => {
  if (!zoznamObdobi || !obdobie) return false;
  const aktivneObdobie = !!obdobie.predbezny;
  const existujeBuduce = !!find(
    zoznamObdobi,
    (zaznam: Obdobie) => !!zaznam.buduci,
  );
  const predbezneId =
    // @ts-ignore
    find(zoznamObdobi, (zaznam: Obdobie) => !!zaznam.predbezny)?.id || 0;
  const posledneId =
    Object.keys(zoznamObdobi).length > 1 &&
    zoznamObdobi[
      Object.keys(zoznamObdobi)[Object.keys(zoznamObdobi).length - 1]
    ]?.id;
  return (
    !aktivneObdobie &&
    (((nasledujuceId === 0 || nasledujuceId === posledneId) &&
      !existujeBuduce) ||
      (existujeBuduce && nasledujuceId === predbezneId))
  );
};

export const getExchangeableParam = (
  group: SkupinaParametrov,
  idParam: number,
): Parameter | null => {
  const separator = ';';
  const p = group?.parametre?.filter(
    (g) =>
      g.moznaVymenaZaParamId &&
      g.moznaVymenaZaParamId.includes(idParam.toString()) &&
      !g.defaultParameter,
  )?.[0];
  if (!idParam || !p || !p.moznaVymenaZaParamId?.includes(separator))
    return null;
  const idNewParam = p.idParametra;
  return (
    group.parametre?.filter((g) => g.idParametra === idNewParam)?.[0] || null
  );
};

export const getBarChartData = (
  hodnota: number | null,
  celkovaSirka: number,
  plnenie: number,
  plnyGraf: boolean | 0,
  typHodnoty: string,
): { name: string; plnenie: number; zvysok: number }[] => {
  let item: { name: string; plnenie: number; zvysok: number } = {
    name: 'graf',
    plnenie: Number(hodnota),
    zvysok: celkovaSirka - Number(hodnota),
  };
  if (!hodnota) return [item];
  if (!plnenie && !hodnota) item = { ...item, plnenie: 0.01 };
  if (plnyGraf) item = { ...item, plnenie: celkovaSirka, zvysok: 0 };
  if (typHodnoty === 'P')
    item = { ...item, zvysok: 1 - hodnota < 0.1 ? 0.08 : 1 - hodnota };
  if (celkovaSirka - hodnota < 0.1) item = { ...item, zvysok: 0.05 };
  return [item];
};

export const getBarChartLabelWeight = (
  celkovaSirka: number,
  hodnota: number,
  hranicnaHodnotaMin: number,
  hranicnaHodnotaMax: number,
  jeDolnaHranica?: boolean,
): number => {
  if (jeDolnaHranica)
    return roundFloat(hodnota) <= roundFloat(hranicnaHodnotaMin)
      ? celkovaSirka + 0.015
      : hranicnaHodnotaMin;
  return roundFloat(hodnota) === roundFloat(hranicnaHodnotaMax)
    ? celkovaSirka
    : hranicnaHodnotaMax;
};

export const getClassicCategoryPeriodValue = (
  hodnota: number,
  kodParametra: string,
  typHodnoty: string,
  plnenie: number,
  windowWidth: number,
): string | ReactNode => {
  if (
    kodParametra === SUBJEKTIVNA_SPOKOJNOST ||
    kodParametra === DOSTUPNOST_GYN_ZDRAVOTNEJ_STAROSTLIVOSTI ||
    (typHodnoty === 'P' && plnenie && !paramsForNewGraph.includes(kodParametra))
  )
    return hodnotaPodlaPlnenia(plnenie);
  return dynamicText(
    windowWidth < 1350 &&
      formatHodnota(hodnota, plnenie, typHodnoty, true).toString().length > 7 &&
      !formatHodnota(hodnota, plnenie, typHodnoty, true)
        .toString()
        .includes('&nbsp;')
      ? formatHodnota(hodnota, plnenie, 'D2', true).toString()
      : formatHodnota(hodnota, plnenie, typHodnoty, true).toString(),
    'span',
  );
};

export function getGraphValues(
  skupiny: SkupinyParametrov,
  VAS?: VASType,
): any[] {
  const responseValues = values(skupiny);
  if (VAS && VAS.isVLD) {
    const dividedValues = responseValues.filter((v) => v.nazov !== 'Špeciálne');
    if (dividedValues.length === 2) {
      if (VAS.primaryVLD) return [dividedValues[0]];
      return [dividedValues[1]];
    }
    if (VAS.primaryVLD) return responseValues.filter((v) => v.vaha !== 1);
    return responseValues.filter((v) => v.vaha === 1);
  }
  return responseValues.filter((v) => getSlugSkupiny(v.nazov) !== '');
}

export const getPlnenieXlsxName = ({
  aktualneId,
  nasledujuceId,
  zoznamObdobi,
}: {
  aktualneId: number;
  nasledujuceId: number;
  zoznamObdobi: ZoznamObdobi;
}): string => {
  if (
    isVyhodnoteneObdobie(nasledujuceId, zoznamObdobi[aktualneId], zoznamObdobi)
  )
    return 'vyhodnotene plnenie';
  return zoznamObdobi[aktualneId]?.predbezny
    ? 'aktualne plnenie'
    : 'plnenie v minulosti';
};
