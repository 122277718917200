import React, {
  ReactElement,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  Cell,
  Customized,
  PolarAngleAxis,
  RadialBar,
  RadialBarChart,
  ResponsiveContainer,
} from 'recharts';
import { cloneDeep } from 'lodash-es';

import useStyles from './RadialBarGraph.styles';
import useOutsideClick from '../../hooks/useOutsideClick';

import {
  SkupinyParametrov,
  VysledokObdobia,
} from '../../slices/hodnotiaceParametre.slice';
import { getPercentage } from '../../utils/number.utils';
import {
  getColor,
  getGraphValues,
  getIcon,
  getSlugSkupiny,
} from '../../utils/hodnotiaceParametre.utils';

import { RADIAN, color } from '../../constants';

import { GraphTooltip } from '../GraphTooltip';
import { useSelector } from 'react-redux';
import { RootState } from '../../rootReducer';
import { VASType } from '../../types/poskytovatel.types';
import routes from '../../routes';
import useOutsideMouseover from '../../hooks/useOutsideMouseover';
import { useWindowSize } from '../../hooks/useWindowSize';
import RadialBarDashed from './RadialBarDashed';
import { cx as classNames } from '../../utils/exports';
import { inlineNumberCondition } from '../../utils/app.utils';

type Props = {
  VAS?: VASType;
  buduci: boolean;
  graphId: string;
  isSecondary?: boolean;
  predbezny?: boolean;
  skupinyParametrov: SkupinyParametrov;
  vysledokObdobia: VysledokObdobia;
} & React.JSX.IntrinsicElements['div'];

interface PopupState {
  isHovered: boolean;
  isVisible: boolean;
  popupId: number;
  position: { x: number; y: number };
}

const CustomizedLabel = ({
  buduci,
  index,
  isSecondary,
  odbornost,
  VAS,
  viewBox: { cx, cy, innerRadius, outerRadius, startAngle },
}): ReactElement => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.25;
  const windowSize = useWindowSize();

  const yMove =
    !VAS && !isSecondary && index === 0
      ? 15
      : inlineNumberCondition(VAS && !isSecondary, 22, 20);
  const calculateX = (): number => {
    let decrement = 0;
    switch (true) {
      case windowSize.width > 1100:
        decrement = 5;
        break;
      case windowSize.width > 1500:
        decrement = 1;
        break;
      case windowSize.width < 1100:
      case VAS && !isSecondary && !buduci:
        decrement = 10;
        break;
      default:
        decrement = 3;
        break;
    }
    return (
      cx +
      radius * Math.cos(-startAngle * RADIAN) -
      (!isSecondary ? 12 - decrement : 0)
    );
  };
  const y = cy + radius * Math.sin(-startAngle * RADIAN) - yMove;
  return getIcon(
    {
      id: index + 1,
      width: '10%',
      x: calculateX(),
      y,
      pointerEvents: 'none',
    },
    odbornost,
    VAS,
  );
};

function calculateSliceCenterCoordinates({ cx, cy, innerRadius, outerRadius }) {
  const radius = innerRadius + (outerRadius - innerRadius);
  const x = cx + radius * Math.cos(RADIAN);
  const y = cy + radius * Math.sin(RADIAN);
  return { x, y };
}

function getInnerRadius(
  count: number,
  isSecondary?: boolean,
  VAS?: VASType,
): string {
  if (VAS && VAS.isVLD && VAS.primaryVLD) return '42%';
  if (VAS && VAS.isVLD) return '58%';
  if (count === 2 && (!VAS || (VAS && !VAS.isVLD)))
    return !isSecondary ? '58%' : '55%';
  return '35%';
}

const RadialBarGraph = ({
  buduci,
  graphId,
  isSecondary,
  predbezny,
  skupinyParametrov,
  VAS,
  vysledokObdobia,
}: Props) => {
  const classes = useStyles({ isSecondary, VAS });
  const barChartRef = useRef<HTMLDivElement>(null);
  const graphTooltipRef = useRef<HTMLDivElement>(null);
  const [popup, setPopup] = useState<PopupState>({
    isHovered: false,
    isVisible: false,
    popupId: 0,
    position: {
      x: 0,
      y: 0,
    },
  });
  const { odbornost } = useSelector((state: RootState) => ({
    odbornost: state.poskytovatel.odbornosti.filter((o) => o.aktivna)[0],
  }));

  const hidePopup = () =>
    setPopup((prevState) => ({
      ...prevState,
      isHovered: false,
      isVisible: false,
    }));

  const skupinyGraph = useMemo(() => {
    // Potrebujem customizovat design jednotlivych grafov (pri 0 to nevyzera dobre)
    // Preto pri plneni 0, upravujem hodnoty. Predtym sa hodnoty naplnia pomocou cloneDeep (inak to bolo readonly)
    const valParams = cloneDeep(getGraphValues(skupinyParametrov, VAS));
    valParams.forEach((el, index) => {
      if ((el.plnenie === 0 && index === 0) || Number.isNaN(el.plnenie))
        valParams[index].plnenie = 0.1 - index * 0.05;
      if (el.plnenie === 0 && index === 1) valParams[index].plnenie = 0.07;
    });
    return valParams;
  }, [VAS, skupinyParametrov]);

  const skupiny = useMemo(
    () => getGraphValues(skupinyParametrov, VAS),
    [VAS, skupinyParametrov],
  );

  useOutsideClick(graphTooltipRef, (e) => {
    const element = e.target as HTMLElement;

    // pre VLD odbornost (kde su zorbazene 2 grafy)
    const clickEventInSameGraph = popup.isVisible && popup.position.x > 150;
    if (
      popup.isVisible &&
      (!element.classList.contains('recharts-sector') ||
        (VAS && !clickEventInSameGraph))
    )
      hidePopup();
  });
  useOutsideMouseover(barChartRef, () => {
    if (popup.isHovered) {
      setPopup((prevState) => ({
        ...prevState,
        isHovered: false,
      }));
    }
  });
  useEffect(() => {
    const handleClick = (e) => {
      if (
        popup.isVisible &&
        !e.target.className.toString().includes('nameBarItem')
      )
        hidePopup();
    };
    // add when mounted
    document.addEventListener('mousedown', handleClick);
    // return function to be called when unmounted
    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, [popup]);
  if (buduci)
    return (
      <div
        ref={barChartRef}
        className={classNames(classes.wrapper, 'text-center')}
        id={graphId}
      >
        <RadialBarDashed id={`dashed--${graphId}`} />
      </div>
    );
  return (
    <div ref={barChartRef} className={classes.wrapper} id={graphId}>
      <ResponsiveContainer
        aspect={1}
        debounce={10}
        height={350}
        maxHeight={350}
      >
        <RadialBarChart
          barGap={12}
          barSize={isSecondary ? 28 : 38}
          data={skupinyGraph}
          endAngle={-270}
          innerRadius={getInnerRadius(skupinyGraph.length, isSecondary, VAS)}
          margin={{}}
          maxBarSize={38}
          outerRadius="100%"
          startAngle={90}
        >
          <PolarAngleAxis
            dataKey="plnenie"
            // @ts-ignore
            domain={[0, 1]}
            tick={false}
            type="number"
          />
          {
            /* eslint-disable */
            // @ts-ignore
            <Customized
              key="customized-element"
              // @ts-ignore
              component={(tooltipAxisBandSize: number) => (
                <g>
                  <circle
                    className={classes.innerCircle}
                    cx="50%"
                    cy="50%"
                    r="13%"
                  />
                  <text
                    className={classes.innerCircleText}
                    dy=".3em"
                    fontSize={`calc(${tooltipAxisBandSize}px - 0.5rem)`}
                    textAnchor="middle"
                    x="50%"
                    y="50%"
                  >
                    {getPercentage(vysledokObdobia?.[0]?.cena || 0, 0)} %
                  </text>
                </g>
              )}
            />
          }
          <RadialBar
            background={{ fill: color('grey', 100), opacity: 0.7 }}
            cornerRadius="50%"
            cursor="pointer"
            dataKey="plnenie"
            isAnimationActive={false}
            label={(props) => (
              <CustomizedLabel
                buduci={buduci}
                isSecondary={isSecondary}
                odbornost={odbornost}
                VAS={VAS}
                vysledokObdobia={vysledokObdobia}
                {...props}
              />
            )}
            minPointSize={-22}
            {...(!isSecondary && {
              onClick: ({ cx, cy, innerRadius, outerRadius }, id) =>
                setPopup({
                  isHovered: true,
                  isVisible: true,
                  popupId: id,
                  position: {
                    ...calculateSliceCenterCoordinates({
                      cx,
                      cy,
                      innerRadius,
                      outerRadius,
                    }),
                  },
                }),
              onMouseOver: ({ cx, cy, innerRadius, outerRadius }, id) =>
                setPopup({
                  ...popup,
                  popupId: id,
                  isHovered: true,
                }),
            })}
          >
            {skupiny.map((skupina, index) => (
              <Cell
                key={skupina.nazov}
                fill={getColor(index + 1, odbornost, VAS)}
                opacity={
                  !popup.isHovered ||
                  (popup.popupId === index && popup.isHovered)
                    ? 1
                    : 0.55
                }
              />
              /* eslint-enable */
            ))}
          </RadialBar>
        </RadialBarChart>
      </ResponsiveContainer>

      {!isSecondary && skupiny && skupiny[0] && (
        <GraphTooltip
          ref={graphTooltipRef}
          buduci={buduci}
          href={`${routes.hp}/${getSlugSkupiny(skupiny[popup.popupId]?.nazov)}`}
          isVisible={popup.isVisible}
          nazov={skupiny[popup.popupId]?.nazov}
          onHide={() =>
            setPopup({ ...popup, isHovered: false, isVisible: false })
          }
          predbezny={predbezny!!}
          skupina={skupiny[popup.popupId]}
          x={popup.position.x}
          y={popup.position.y}
        />
      )}
    </div>
  );
};

export default RadialBarGraph;
