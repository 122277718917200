import React from 'react';
import { Bar, BarItem, Card, Icon } from '@dovera/design-system';
import { Link, useNavigate } from 'react-router-dom';

import useStyles from './GraphPopup.styles';

import {
  Parameter,
  SkupinaParametrov,
} from '../../slices/hodnotiaceParametre.slice';

import { getPercentage } from '../../utils/number.utils';

import GraphPopupItem from './GraphPopupItem';

type Props = {
  href?: string;
  isVisible?: boolean;
  nazov: string;
  predbezny: boolean;
  skupina: SkupinaParametrov;
  x: number;
  y: number;
} & React.JSX.IntrinsicElements['div'];

const GraphPopup = ({
  href,
  isVisible,
  nazov,
  predbezny,
  skupina,
  x,
  y,
  ...other
}: Props) => {
  const classes = useStyles({ x, y, isVisible });
  const navigate = useNavigate();

  const title = href ? (
    <Bar className="mb-xxxsmall" space="small">
      <BarItem className="text-left" isFilling>
        <h4>{nazov}</h4>
      </BarItem>
      <BarItem>
        <Link to={href}>
          <Icon name="arrow-right" />
        </Link>
      </BarItem>
    </Bar>
  ) : (
    <h4 className="mb-xxxsmall">{nazov}</h4>
  );

  return (
    <Card
      className={classes.graphPopup}
      onClick={() => href && navigate(href)}
      {...other}
    >
      {title}
      {skupina && (
        <>
          <p className="text-color-grey text-left mb-xxxsmall text-small">
            Váha = {getPercentage(skupina.vaha, 0)} %
          </p>
          {skupina.parametre.map(
            ({
              hodnota,
              kodParametra,
              nazovParametra,
              pasmo,
              plnenie,
              typHodnoty,
            }: Parameter) => (
              <GraphPopupItem
                key={nazovParametra}
                hodnota={hodnota}
                kodParametra={kodParametra || ''}
                nazovParametra={nazovParametra}
                pasmo={pasmo}
                plnenie={plnenie}
                predbezny={predbezny}
                typHodnoty={typHodnoty}
              />
            ),
          )}
        </>
      )}
    </Card>
  );
};

export default GraphPopup;
