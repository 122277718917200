import { ReactNode } from 'react';
import { cmsPath } from '../../constants/cmsPath';
import { getCMSText } from '../../utils/cms.utils';
import {
  DATE_FORMAT_MONTH_YEAR,
  daysLeft,
  formatDate,
} from '../../utils/date.utils';
import { replaceStringWithParams, text } from '../../utils/strings.utils';

export const getTooltip = ({
  dateFrom,
  dateTo,
  isFuture,
  predbezny,
}: {
  dateFrom: string;
  dateTo: string;
  isFuture?: boolean;
  predbezny?: boolean;
}): ReactNode => {
  if (isFuture)
    return replaceStringWithParams(
      getCMSText(
        isFuture
          ? cmsPath.HP.prehlad.tooltipy.spolocne.buduceObdobie.pocasObdobiaVolby
          : cmsPath.HP.prehlad.tooltipy.spolocne.buduceObdobie.mimoObdobiaVolby,
        '',
        true,
        false,
        '',
        true,
      ),
      [
        {
          id: '(OBDOBIE_OD)',
          value: formatDate(dateFrom, DATE_FORMAT_MONTH_YEAR),
        },
        {
          id: '(OBDOBIE_DO)',
          value: formatDate(dateTo, DATE_FORMAT_MONTH_YEAR),
        },
      ],
    );
  if (predbezny)
    return text('vseobecne.aktualnePlnenie.tooltip', {
      dni: daysLeft(dateTo) > 0 ? daysLeft(dateTo) : 0,
    });
  return getCMSText(
    cmsPath.HP.prehlad.tooltipy.spolocne.vyhodnotenePlnenie,
    '',
    false,
    false,
    '',
    true,
  );
};
