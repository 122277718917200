import cx from 'classnames';

import useStyles from './PeriodPlate.styles';
import { dynamicText, text } from '../../utils/strings.utils';

import { IconLockClosed, IconLockOpen } from '..';
import { DATE_FORMAT_MONTH_YEAR, formatDate } from '../../utils/date.utils';
import { CustomTooltip } from '../CustomTooltip';
import React, { ReactNode } from 'react';
import { Skeleton, color } from '@dovera/design-system';
import { inlineStrCondition } from '../../utils/app.utils';
import { getTooltip } from './utils';

type Props = {
  datumDo: string;
  datumOd: string;
  futureExists: boolean;
  isActive?: boolean;
  isCompact?: boolean;
  isFuture?: boolean;
  isLoading?: boolean;
  nasledujuceId: number;
  nasledujuceObdobie?: boolean;
  popisObdobie?: string;
  posledneId?: number;
  predbezny?: boolean;
  predposledneId?: number;
} & React.JSX.IntrinsicElements['div'];

const PeriodPlate = ({
  datumDo,
  datumOd,
  futureExists,
  isActive,
  isCompact,
  isFuture,
  isLoading,
  nasledujuceId,
  nasledujuceObdobie,
  popisObdobie,
  posledneId,
  predbezny,
  predposledneId,
  ...other
}: Props) => {
  const classes = useStyles({ isActive, isCompact });
  const iconClasses = cx('icon', 'icon--right', classes.icon);
  const obdobie = predbezny ? 'aktualnePlnenie' : 'predchadzajucePlnenie';

  const icon = predbezny ? (
    <IconLockOpen
      className={iconClasses}
      color={inlineStrCondition(!!isFuture, color('grey', 200), '')}
      id={`icon-lock-open-${datumDo}`}
    />
  ) : (
    <IconLockClosed
      className={iconClasses}
      id={`icon-lock-closed-${datumDo}`}
    />
  );
  const tooltip = isActive ? (
    <CustomTooltip
      dialog={getTooltip({
        dateFrom: datumOd,
        dateTo: datumDo,
        isFuture,
        predbezny,
      })}
      id={`tooltip-period-actual--${datumOd}`}
    >
      {icon}
    </CustomTooltip>
  ) : (
    icon
  );

  const infoNovychObdobi = popisObdobie ? (
    <CustomTooltip
      dialog={dynamicText(popisObdobie)}
      id="tooltip-period-info-new"
    >
      {icon}
    </CustomTooltip>
  ) : (
    <span />
  );

  const getTitle = (): ReactNode => {
    switch (true) {
      case isFuture:
        return (
          <>
            {text(`vseobecne.buduceObdobie.1`)}
            <span className={classes.noWrap}>
              {text(`vseobecne.buduceObdobie.2`)}{' '}
            </span>
            {!isFuture && (
              <>
                <span className={classes.spanSvg}>{infoNovychObdobi}</span>
                <span className={classes.spanSvg}>{tooltip}</span>
              </>
            )}
          </>
        );
      case posledneId === nasledujuceId && isActive && futureExists:
      case posledneId === nasledujuceId && !isActive && nasledujuceObdobie:
        return (
          <>
            {text(`vseobecne.aktualnePlnenie.1`)}
            <span className={classes.noWrap}>
              {text(`vseobecne.aktualnePlnenie.2`)}{' '}
            </span>
            <span className={classes.spanSvg}>{infoNovychObdobi}</span>
          </>
        );
      case (nasledujuceId === predposledneId && futureExists) ||
        (posledneId === nasledujuceId && isActive && !futureExists):
      case nasledujuceId === 0 && posledneId && !isActive:
      case nasledujuceId === predposledneId && !isActive && nasledujuceObdobie:
        return (
          <>
            <span className={classes.noWrap}>
              {text(`vseobecne.vyhodnotene.1`)}{' '}
            </span>
            <span className={classes.spanSvg}>{tooltip}</span>
          </>
        );
      default:
        return (
          <>
            {text(`vseobecne.${obdobie}.1`)}
            <span className={classes.noWrap}>
              {text(`vseobecne.${obdobie}.2`)}{' '}
            </span>
            <span className={classes.spanSvg}>
              <span className={cx(!(popisObdobie && isActive) && 'hide')}>
                {infoNovychObdobi}
              </span>
              <span className={cx(popisObdobie && isActive && 'hide')}>
                {tooltip}
              </span>
            </span>
          </>
        );
    }
  };

  if (isLoading)
    return (
      <div className={classes.preloader}>
        <Skeleton className="mb-xxsmall" height={24} width={230} />
        <Skeleton width={250} />
      </div>
    );

  return (
    <div className={classes.wrapper} {...other}>
      <div className={classes.nazov}>
        <span>{getTitle()}</span>
      </div>
      <span className={classes.popis}>
        {`${formatDate(datumOd, DATE_FORMAT_MONTH_YEAR)} - ${formatDate(
          datumDo,
          DATE_FORMAT_MONTH_YEAR,
        )}`}
      </span>
    </div>
  );
};

export default PeriodPlate;
