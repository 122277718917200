import { createUseStyles } from 'react-jss';

import { contentSpacing, spaces } from '../../constants';

export default createUseStyles({
  wrapper: {
    textAlign: 'center',
    marginBottom: contentSpacing,
    '& > svg': {
      display: 'flex',
      margin: '0 auto',
    },
    '& > div': {
      marginBottom: spaces.xsmall,
      marginTop: spaces.xsmall,
    },
    '& > div:first-child': {
      marginTop: 0,
    },
    '& > div:last-child': {
      marginBottom: 0,
    },
    '& > h4': {
      textAlign: 'left',
    },
  },
});
