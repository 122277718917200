import { createUseStyles } from 'react-jss';

import { color, fontWeight, spaces } from '../../constants';

export default createUseStyles({
  pieGraphWrapper: {
    position: 'relative',
  },
  innerCircle: {
    stroke: color('grey', 200),
    strokeWidth: '1',
    fill: color('white'),
  },
  innerCircleText: {
    fontSize: spaces.default,
    fontWeight: fontWeight.bold,
    fill: color('secondary'),
  },
});
